/* global NativeApp */
/* global NativeiOSApp */
import { FeatureFlags } from './FeatureFlags'
import { Firebase } from '../Firebase'
import { createCustomToken } from 'joyable-js-api/src/models/FirebaseToken'

export async function sendWebViewAuthPayload (idpToken, email, response = null) {
  if (!(NativeApp.isHosted() || NativeiOSApp.isHosted())) return null

  if (NativeiOSApp.isHosted()) {
    NativeiOSApp.startLoading()
  }

  // Currently, the mobile apps use various data for logging in.
  // When the sms_mfa flag is 'true', we know that there will also be a
  // forced upgrade to mobile versions that expect only the idpToken
  let webViewAuthPayload
  if (FeatureFlags.current.sms_mfa !== true) {
    const customIdpToken = await createCustomToken(Firebase.auth.currentUser)
    webViewAuthPayload = { ...response, idpToken, customIdpToken }
  } else {
    webViewAuthPayload = { idpToken }
  }

  if (NativeApp.isReactNative()) {
    return NativeApp.sendWebViewAuthPayload(JSON.stringify(webViewAuthPayload))
  } else if (NativeiOSApp.isHosted()) {
    const authInfo = JSON.stringify(webViewAuthPayload)
    NativeiOSApp.stopLoading()
    return NativeiOSApp.sendWebViewAuthPayload(authInfo)
  } else {
    // Legacy Android
    NativeApp.loggedIn(JSON.stringify(response), email)
  }
}
