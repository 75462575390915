const EMAIL_AUTH_LINK_KEY = 'EMAIL_AUTH_LINK'
const ONBOARDING_EMAIL_KEY = 'ONBOARDING_EMAIL'

export const SessionStorage = {
  // Email auth link
  getEmailAuthLink () {
    return globalThis.sessionStorage.getItem(EMAIL_AUTH_LINK_KEY)
  },
  setEmailAuthLink (url) {
    return globalThis.sessionStorage.setItem(EMAIL_AUTH_LINK_KEY, url)
  },
  removeEmailAuthLink () {
    return globalThis.sessionStorage.removeItem(EMAIL_AUTH_LINK_KEY)
  },
  // Onboarding email address
  getOnboardingEmail () {
    return globalThis.sessionStorage.getItem(ONBOARDING_EMAIL_KEY)
  },
  setOnboardingEmail (email) {
    return globalThis.sessionStorage.setItem(ONBOARDING_EMAIL_KEY, email)
  },
  removeOnboardingEmail () {
    return globalThis.sessionStorage.removeItem(ONBOARDING_EMAIL_KEY)
  },
}
