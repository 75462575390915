import { math } from 'polished'
import { css } from 'styled-components'

export const fullWidth = '1024px'
export const dialogWidth = '370px'
export const mobileBreakpoint = '768px'
export const mediumMobileBreakpoint = '414px'
export const smallMobileBreakpoint = '320px'
export const iphone5 = '340px'
export const mobileFullWidthWithGutters = 'calc(100vw - 32px)'

export const navHeight = '56px'
export const mobileNavBreakpoint = '740px'
export const navSquishBreakpoint = '370px'
export const loggedInNavSquishBreakpoint = '600px'
export const mobileNextButtonHeight = '50px'

export const desktopTemplateWidth = '880px'
export const desktopTemplateHeight = '504px'

export const dashboardPadding = '20px'
export const activityStepTitleHeight = '50px'
export const dashboardActivityWidth = '386px'
export const dashboardActivitiesPadding = '36px'
export const coachChatMinWidth = '250px'
export const coachChatMaxWidth = '525px'

export const templateWithTitleHeight = math(`${desktopTemplateHeight} - ${activityStepTitleHeight}`)

const mediaSelector = query => (...args) => css`@media(${query}) { ${
  // This is for backwards compatibility with the cases in our codebase that still use
  // e.g. ${desktopMedia(css`...`)} over ${desktopMedia`...`}. If the old uses are
  // refactored, this can be simply css(...args)
  args.length === 1 ? args : css(...args)
}; }`

export const desktopMedia = mediaSelector(`min-width: ${math(`${mobileBreakpoint} + 1`)}`)
export const mobileMedia = mediaSelector(`max-width: ${mobileBreakpoint}`)
export const smallMobileMedia = mediaSelector(`max-width: ${smallMobileBreakpoint}`)
export const mediumMobileMedia = mediaSelector(`max-width: ${mediumMobileBreakpoint}`)
export const desktopNavMedia = mediaSelector(`min-width: ${math(`${mobileNavBreakpoint} + 1`)}`)
export const mobileNavMedia = mediaSelector(`max-width: ${mobileNavBreakpoint}`)
export const iphone5Media = mediaSelector(`max-width: ${iphone5}`)
export const navSquishMedia = mediaSelector(`max-width: ${navSquishBreakpoint}`)
export const fullWidthMedia = mediaSelector(`max-width: ${fullWidth}`)

export const navWidthContent = css`
  width: ${fullWidth};
  padding: 0 14px 0 20px;
  ${fullWidthMedia(css`width: 100vw;`)}
  ${mobileNavMedia(css`padding: 0 16px;`)};
  ${navSquishMedia(css`justify-content: space-between;`)};
`
