import { Connection, OptionalProps, StatePredicate } from '../models/types'
import awaitState from '../awaitState'

/**
 * Returns a promise that waits for the connection to be loaded and for a given state predicate
 * to return true given the selected state of the connection. Useful for when a model is updated
 * in a controller and the controller wants to wait for the model to finish updating.
 */
export default function awaitConnection<SelectedType, PropsType extends OptionalProps = undefined> (
  connection: Connection<SelectedType, PropsType>,
  props: PropsType,
  statePredicate: StatePredicate<SelectedType> = () => true,
): Promise<SelectedType> {
  const { selector, onMount, isLoaded, load, isLoading } = connection
  const predicate = (state: Record<string, any>) => {
    const selected = selector(state, props)
    const loadingDone = isLoaded == null || isLoaded(selected, props)
    // It's not kosher to call load() in the predicate because we're in the middle of store update
    // resolution here and calling dispatch() can cause an infinite loop in redux in some
    // circumstances. Delaying by a frame to let the resolution finish is sufficient.
    if (!loadingDone && load != null) {
      if (isLoading == null || !isLoading(state, selected, props)) {
        setTimeout(() => {
          load(selected, props)
        }, 0)
      }
    }
    return loadingDone && statePredicate(selected)
  }

  if (onMount != null) onMount(props)
  return awaitState(predicate).then(state => selector(state, props))
}
