import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import { registerModelReducer } from '../reducers'
import ApiModel from '../ApiModel'

const MODEL = new ApiModel('FAVORITES', 'api/v1/favorites')

registerModelReducer('favorites', (state = { loaded: false, favorites: [] }, { type, response }) => {
  if (type === Action.CREATE.successType(MODEL)) {
    return { ...state, loaded: true, favorites: [...(state.favorites || []), response.favorite] }
  } else if (type === Action.READ.successType(MODEL)) {
    return { ...state, loaded: true, favorites: response.favorites }
  } else if (type === Action.READ.failureType(MODEL)) {
    return { ...state, loaded: true, favorites: state.favorites || [] }
  } else if (type === Action.DESTROY.successType(MODEL)) {
    return { ...state, loaded: true, favorites: null }
  }

  return state
})

const SHAPE = PropTypes.shape({
  id: PropTypes.number,
  user_id: PropTypes.number,
  favorited: PropTypes.object,
})

const select = state => state.models.favorites.favorites
const selectIsLoaded = state => state.models.favorites.loaded

const addFavorite = async (slug, type, programActivityId) =>
  await MODEL.create({
    favorite: {
      favorited: { slug, type, program_activity_id: programActivityId },
    },
  })

const selectAvailableConnection = createSelector(
  [select, selectIsLoaded],
  (favorites, loaded) => ({
    favorites,
    loaded,
    addFavorite,
    removeFavorite: (id, userId) => MODEL.destroy({ id, query: { user_id: userId }, ignoreFailure: true }),
  }),
)

export const Favorites = {
  SHAPE,
  connection: {
    load: ({ loaded, favorites }) => {
      if (!loaded || favorites == null) MODEL.read({ ignoreFailure: true })
    },

    isLoaded: ({ loaded }) => loaded,

    selector: selectAvailableConnection,

    shape: {
      favorites: PropTypes.arrayOf(SHAPE),
      loaded: PropTypes.bool,
      addFavorite: PropTypes.func,
      removeFavorite: PropTypes.func,
    },
  },
}
