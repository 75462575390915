import Action from '../Action'
import ApiModel from '../ApiModel'
import { clearAuth0Tokens, isAuth0Enabled } from '../ApiFetch'
import { Authentication } from './Authentication'

const MODEL = new ApiModel('HEARTBEAT', 'api/v1/heartbeat')
export const SUCCESS_TYPE = Action.UPDATE_MODIFY.successType(MODEL)

let lastHeartbeat = null
const HEARTBEAT_INTERVAL = isAuth0Enabled() ? 14 * 60 * 1000 : 60 * 1000

const doHeartbeat = async () => {
  if (isAuth0Enabled()) {
    clearAuth0Tokens()
    await Authentication.loadAuth0Tokens()
  } else {
    MODEL.updateModify()
  }
}

const ping = async () => {
  // allow 50ms threshhold between runs, setInterval is not always precise
  const timeSinceLastHeartbeat = Date.now() - lastHeartbeat + 50
  const shouldDoHeartbeat = timeSinceLastHeartbeat > HEARTBEAT_INTERVAL && document.visibilityState === 'visible'

  if (shouldDoHeartbeat) {
    lastHeartbeat = Date.now()
    await doHeartbeat()
  }
}

export const Heartbeat = { ping, interval: HEARTBEAT_INTERVAL }
