import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'

const MODEL = new ApiModel('COACH_MATCHES', 'api/v1/coach_matches')

const SHAPE = PropTypes.shape({
  coach_ids: PropTypes.arrayOf(PropTypes.number),
})

export const CoachMatches = {
  connection: {
    load: ({ coachMatches }) => {
      if (coachMatches == null) MODEL.read()
    },

    isLoaded: ({ coachMatches }) => coachMatches != null,

    selector: createSelector(
      [state => state.api.coach_matches],
      coachMatches => ({
        coachMatches,
        clearCache: () => ApiModel.pruneCache('coach_matches'),
      }),
    ),

    shape: {
      coachMatches: SHAPE,
      clearCache: PropTypes.func.isRequired,
    },
  },
}
