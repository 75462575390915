const isProduction = process.env.NODE_ENV === 'production'
const isLocalHost = process.env.SERVER_HOST === 'localhost'
const isDebug = process.env.DEBUG === true

function logSentry (args, level) {
  const sentryImport = import('@sentry/browser')
  const message = args.shift()
  const error = args.find(arg => arg instanceof Error)

  sentryImport.then(Sentry => {
    Sentry.withScope(scope => {
      if (error != null) {
        scope.setExtras({ message, args })
        Sentry.captureException(error)
      } else {
        if (args.length > 0) scope.setExtras({ args })
        Sentry.captureMessage(message, level)
      }
    })
  })
}

export default class Log {
  static info () {
    if (!isProduction || isDebug) console.info.apply(console, arguments)
  }

  static debug () {
    if (!isProduction || isDebug) console.debug.apply(console, arguments)
  }

  static warn () {
    console.warn.apply(console, arguments)
    if (!isLocalHost) logSentry(Array.from(arguments), 'warning')
  }

  static error () {
    console.error.apply(console, arguments)
    if (!isLocalHost) logSentry(Array.from(arguments), 'error')
  }
}
