import ApiModel from '../ApiModel'

const MODEL = new ApiModel('FEATURE_FLAGS', 'api/v1/feature_flags')

const load = () => MODEL.read()

const select = state => state.api.feature_flags

// FeatureFlags are loaded in bootstrap and then accessed statically afterward, no connection needed
export const FeatureFlags = {
  load,

  get current () {
    return select(ApiModel.reduxState)
  },
}
