import get from 'lodash/get'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel from '../ApiModel'
import Log from '../log'
import { mergeIn, registerModelReducer } from '../reducers'
import {
  clearChatSendFailure,
  clearTextSendFailure,
  selectChatHasFailed,
  selectChatIsSending,
  selectTextHasFailed,
  selectTextIsSending,
  sendChat,
  sendText,
} from './Message'
import { extractFormData, parseId, parseQuery, userModelSelector } from './utilities'
import awaitState from '../awaitState'

const MODEL = new ApiModel('DRAFT', 'api/v1/drafts')

registerModelReducer('drafts', (state = { users: { }, drafts: { } }, { type, url }) => {
  const id = url && parseId(MODEL, url)
  if (type === Action.READ.successType(MODEL)) {
    const { user_id: userId } = parseQuery(url)
    return mergeIn(state, ['users', userId], { draftsLoaded: true })
  } else if (
    type === Action.UPDATE_MODIFY.successType(MODEL) &&
    id != null &&
    id.endsWith('send')
  ) {
    // we're about to delete the draft, and we don't want the component to think we're done sending
    // until the delete is done, so mark the id here so that selector knows we're in limbo
    const draftId = id.split('/')[0]
    return mergeIn(state, ['drafts', draftId], { draftDeleting: true })
  } else if (
    type === Action.DESTROY.successType(MODEL) ||
    type === Action.DESTROY.failureType(MODEL)
  ) {
    return mergeIn(state, ['drafts', id], { draftDeleting: false })
  }

  return state
})

const indexOptions = userId => ({ query: { user_id: userId } })

const loadDrafts = userId => MODEL.read(
  { ...indexOptions(userId), modelMapKey: ['user_id', userId] },
)

const createOptions = userId => ({ query: { user_id: userId } })

const createDraft = (userId, draft) => MODEL.create(
  extractFormData(draft, 'draft'),
  { ...createOptions(userId), modelMapKey: ['user_id', userId] },
)

const updateOptions = (userId, { id }) => ({ id, query: { user_id: userId } })

const updateDraft = (userId, draft) =>
  MODEL.updateModify(extractFormData(draft, 'draft'), updateOptions(userId, draft))

const destroyOptions = (userId, { id }) => ({ id, query: { user_id: userId } })

const deleteDraft = (userId, draft) => MODEL.destroy({
  ...destroyOptions(userId, draft),
  modelMapKey: ['user_id', userId],
})

const saveDraft = (userId, draft) => {
  if (userId == null) {
    Log.warn('Asked to save a draft for an unknown user', draft)
    return
  }

  // We only preserve email drafts on the server
  if (draft.type !== 'email') return

  if (draft.id == null) createDraft(userId, draft)
  else updateDraft(userId, draft)
}

const sendOptions = (userId, { id }) => ({ id: `${id}/send`, query: { user_id: userId } })

const awaitNotSending = (userId, draft) =>
  awaitState(state => !MODEL.isUpdatingSelector(sendOptions(userId, draft))(state))

const sendFailedSelector = (userId, draft) => state => Action.hasFailed(
  Action.UPDATE_MODIFY.getPending(
    state,
    MODEL.getUrl(sendOptions(userId, draft)),
  ),
)

const sendDraft = (userId, draft) => {
  if (draft.type === 'email') {
    MODEL.updateModify(draft, {
      ...sendOptions(userId, draft),
      ignoreFailure: true,
      modelMapKey: ['user_id'],
    })
    awaitNotSending(userId, draft).then(state => {
      if (sendFailedSelector(userId, draft)(state)) {
        Log.warn('Send failed, leaving draft intact', userId, draft)
      } else {
        deleteDraft(userId, draft)
      }
    })
  } else if (draft.type === 'text') {
    sendText(userId, draft)
  } else if (draft.type === 'chat') {
    sendChat(userId, draft)
  } else {
    Log.error('Cannot send draft of unknown type', userId, draft)
  }
}

const clearSendFailure = (userId, draft) => {
  if (draft.type === 'email') {
    ApiModel.dispatch(
      Action.UPDATE_MODIFY.getClearPendingAction(
        MODEL,
        MODEL.getUrl(sendOptions(userId, draft)),
      ),
    )
  } else if (draft.type === 'text') {
    clearTextSendFailure(userId, draft)
  } else if (draft.type === 'chat') {
    clearChatSendFailure(userId, draft)
  } else {
    Log.error('Cannot clear failure of unkonwn type', userId, draft)
  }
}

// We can get a little sloppy with selector creation/caching here because nothing in the client
// cares about showing drafts for more than one user at once, so we're not at risk of thrashing
// a selector cache based on changes to the userId parameter. If that changes, we'll need to
// borrow some patterns from Message here.

const passServiceId = (state, { inReplyTo: { service_id: serviceId } = {} }) => serviceId
const passUserId = (state, { user: { id } = {} }) => id

const selectDrafts = (state, { user }) => userModelSelector(user.id, 'drafts')(state)
const selectDraft = createSelector(
  [selectDrafts, passServiceId],
  (drafts, serviceId) => drafts && drafts.filter(
    // handles the case where one of in_reply_to and serviceId is undefined and the other is null
    (draft) =>
      draft && (draft.in_reply_to === serviceId || (draft.in_reply_to == null && serviceId == null)),
  )[0],
)

const selectDraftIsSaving = createSelector(
  [selectDraft, Action.UPDATE_MODIFY.pendingSource, Action.CREATE.pendingSource, passUserId],
  (draft, updatePending, createPending, userId) => {
    if (userId == null) return false

    let pending
    if (draft == null || draft.id == null) {
      pending = createPending[MODEL.getUrl(createOptions(userId))]
    } else {
      pending = updatePending[MODEL.getUrl(updateOptions(userId, draft))]
    }

    return pending == null ? false : Action.isActing(pending)
  },
)

const selectDraftsLoading = (state, { user }) =>
  MODEL.isReadingSelector(indexOptions(user.id))(state)

const selectDraftsLoaded = (state, { user }) =>
  get(state.models.drafts, ['users', user.id, 'draftsLoaded'], false)

const selectSendEndpointForService = createSelector(
  [selectDraft, passUserId],
  (draft, userId) => draft && userId && MODEL.getUrl(sendOptions(userId, draft)),
)

const selectDraftSendPendingForService = createSelector(
  [selectSendEndpointForService, Action.UPDATE_MODIFY.pendingSource],
  (endpoint, pending) => pending[endpoint],
)

const selectDraftIsSendingForService = createSelector(
  [selectDraftSendPendingForService],
  pending => Action.isActing(pending),
)

const selectDraftHasFailedForService = createSelector(
  [selectDraftSendPendingForService],
  pending => Action.hasFailed(pending),
)

const selectDraftIsSending = createSelector(
  [
    selectDraftIsSendingForService,
    state => state.models.drafts.drafts,
    selectDraft,
    selectChatIsSending,
    selectTextIsSending,
  ],
  (draftIsSending, draftsState, draft, chat, text) => chat || text || draftIsSending ||
    (draft && get(draftsState, [draft.id, 'draftDeleting'])) === true,
)

const selectDraftHasFailed = createSelector(
  [selectDraftHasFailedForService, selectChatHasFailed, selectTextHasFailed],
  (email, chat, text) => email || chat || text,
)

// Expects to receive { user, inReplyTo } on the second parameter to the selector.
const selectConnection = createSelector(
  [
    selectDraft,
    selectDraftIsSaving,
    selectDraftHasFailed,
    selectDraftsLoading,
    selectDraftsLoaded,
    selectDraftIsSending,
    passUserId,
  ],
  (draft, draftIsSaving, draftHasFailed, draftsLoading, draftsLoaded, draftIsSending, userId) => ({
    draft,
    draftIsSaving,
    draftHasFailed,
    draftsLoading,
    draftsLoaded,
    draftIsSending,

    saveDraft: draft => saveDraft(userId, draft),
    sendDraft: draft => sendDraft(userId, draft),

    clearSendFailure: draft => clearSendFailure(userId, draft),
  }),
)

export const Draft = {
  connection: {
    /**
     * Selector (state, { user: { id }, inReplyTo: { service_id } }) => connected state
     */
    selector: selectConnection,

    load: ({ draftsLoaded, draftsLoading }, { user }) => {
      if (!draftsLoading && !draftsLoaded) loadDrafts(user.id)
    },

    isLoaded: ({ draftsLoaded }) => draftsLoaded,

    shape: {
      draft: PropTypes.object, // TODO
      draftIsSaving: PropTypes.bool.isRequired,
      draftsLoading: PropTypes.bool.isRequired,
      draftsLoaded: PropTypes.bool.isRequired,
      draftIsSending: PropTypes.bool.isRequired,
      draftHasFailed: PropTypes.bool.isRequired,

      saveDraft: PropTypes.func.isRequired,
      sendDraft: PropTypes.func.isRequired,

      clearSendFailure: PropTypes.func.isRequired,
    },
  },
}
