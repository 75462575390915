import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'
import { selectorCache } from '../util/selectorCache'
import { invertPredicate } from './utilities'
import awaitState from '../awaitState'

const MODEL = new ApiModel('COACH_ALLOCATIONS', 'api/v1/coach_allocations')

const SHAPE = PropTypes.shape({
  id: PropTypes.number.isRequired,
  full_name: PropTypes.string.isRequired,
  total_client_quota: PropTypes.number.isRequired,
  daily_client_quota: PropTypes.number.isRequired,
  accepting_clients: PropTypes.bool.isRequired,
  active_counts: PropTypes.number.isRequired,
  active_over_last_24: PropTypes.number.isRequired,
  seven_day_call_blocks: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  fourteen_day_call_blocks: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
})

const selectAllocations = state => state.api.coach_allocations
const select = id => state => selectAllocations(state)[id]

const selectRowConnection = selectorCache(
  ({ coachId }) => coachId,
  ({ coachId }) => createSelector(
    [select(coachId), MODEL.isUpdatingSelector({ id: coachId })],
    (coachAllocation, coachAllocationIsUpdating) => ({
      coachAllocation,
      coachAllocationIsUpdating,

      updateAllocation: updates => {
        MODEL.updateModify(
          { coach_allocation: { ...coachAllocation, ...updates } },
          { id: coachId },
        )
        awaitState(invertPredicate(MODEL.isUpdatingSelector({ id: coachId })))
          .then(() => MODEL.read())
      },
    }),
  ),
)

export const CoachAllocations = {
  SHAPE,

  connection: {
    load: ({ coachAllocations }) => {
      if (coachAllocations == null) MODEL.read()
    },

    isLoaded: ({ coachAllocations }) => coachAllocations != null,

    selector: createSelector(
      [selectAllocations, MODEL.isReadingSelector()],
      (coachAllocations, coachAllocationsLoading) => ({
        coachAllocations,
        coachAllocationsLoading,
      }),
    ),

    shape: {
      coachAllocations: PropTypes.objectOf(SHAPE).isRequired,
      coachAllocationsLoading: PropTypes.bool.isRequired,
    },
  },

  rowConnection: {
    selector: selectRowConnection,

    shape: {
      coachAllocation: SHAPE.isRequired,
      coachAllocationIsUpdating: PropTypes.bool.isRequired,
      updateAllocation: PropTypes.func.isRequired,
    },
  },
}
