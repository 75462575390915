import { createSelector } from 'reselect'
import { registerModelReducer } from '../reducers'
import Action from '../Action'
import ApiModel from '../ApiModel'
import orderBy from 'lodash/orderBy'
import { COLOR_GENERATOR_COLORS } from '../util/colorGeneratorColors'

const MODEL = new ApiModel('CALENDAR_COACHES', 'admin/api/v1/calendars/coaches')

registerModelReducer('calendar_coaches', (state = null, props) => {
  const { type, response } = props
  switch (type) {
    case Action.READ.successType(MODEL): {
      const newState = response.calendar_coaches.reduce((state, coach, index) => {
        const colorIndex = index < COLOR_GENERATOR_COLORS.length ? index : index % COLOR_GENERATOR_COLORS.length
        state[coach.id] = {
          ...coach,
          color: COLOR_GENERATOR_COLORS[colorIndex],
        }
        return state
      }, {})
      return orderBy(newState, 'name')
    }
    default:
      return state
  }
})

const selectCalendarCoachesModel = createSelector(
  [
    state => state.models.calendar_coaches ? Object.values(state.models.calendar_coaches) : null,
    (state) => MODEL.getReadErrorMessage(state),
  ],
  (calendarCoaches, calendarCoachesError) => ({
    calendarCoaches,
    calendarCoachesError,
  }),
)

export const CalendarCoaches = {
  connection: {
    load: ({ calendarCoaches }) => {
      if (calendarCoaches == null) MODEL.read()
    },

    isLoaded: ({ calendarCoaches }) => calendarCoaches != null,

    selector: selectCalendarCoachesModel,
  },
}
