import moment from 'moment-timezone'

export const MAX_ROWS_PER_PAGE = 25

// Add here new custom tools that cannot be created using the old AdminTool component here
export const ADMIN_TOOLS = {
  VIEW_ALL_COACHES: 'view_all_coaches',
  EDIT_COACH: 'edit_coach',
  VIEW_ALL_ADMINS: 'view_all_admins',
  DELETE_SPONSOR: 'delete_sponsor',
  MANAGE_BENEFITS_PROVIDERS: 'manage_benefits_providers',
}

// Normalizes an array of values for the Select component options prop from the ableto component library
export const normalizeDropdownOptions = (valuesArr) => valuesArr.map(value => ({ label: value, value }))

export const urlKebabCase = slug => slug.replaceAll('_', '-')

export const unicodeRegExpTransformator = text => {
  return new RegExp(text.split('')
    .map(char => `\\u{${char.charCodeAt(0).toString(16)}}`)
    .join('.*'), 'u')
}

export const formatModelTimestamps = (modelData, timestampColumns) => {
  if (modelData instanceof Array) {
    modelData.map(obj =>
      timestampColumns.forEach(column => {
        if (moment(obj[column]).isValid()) {
          obj[column] = moment(obj[column]).format('YYYY-MM-DD HH:mm:ss')
        }
      }))
  } else if (modelData instanceof Object) {
    timestampColumns.forEach(column => {
      if (moment(modelData[column]).isValid()) {
        modelData[column] = moment(modelData[column]).format('YYYY-MM-DD HH:mm:ss')
      }
    })
  }

  return modelData
}
