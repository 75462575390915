import ApiFetch, { CALL_API } from './ApiFetch'
import Log from './log'

function isPending (state, fetcher) {
  const { method } = fetcher.options
  const pending = state.apiPending[method][fetcher.url]
  if (fetcher.ignoreFailure && pending && pending.failed) return false

  if (pending && pending.method !== method && !pending.failed) {
    Log.warn(`pending API call is using a different method [pending=${pending.method}, new=${method}]`)
  }

  // only checking endpoint for now
  return pending != null
}

function getFetcher (action) {
  const fetcher = action[CALL_API]
  if (fetcher && (fetcher instanceof ApiFetch)) return fetcher

  return null
}

export const apiMiddleware = store => next => action => {
  const fetcher = getFetcher(action)
  if (fetcher == null) return next(action)

  if (isPending(store.getState(), fetcher)) return null

  const { requestType: type, url, options } = fetcher
  next({ type, url, options })

  const fetchPromise = fetcher.fetch()
  fetchPromise.then(result => {
    if (result) next(result)
  })
  return fetchPromise
}
