/* eslint-disable camelcase */
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'
import { Connection } from './types'
import { ApiKey, ApiState } from '../types'
import { selectorCache } from '../util/selectorCache'

const MODEL = new ApiModel('CONNECT_CONFIG', 'api/v1/connect')
const GENERATE_REENTRY_LINK_MODEL = new ApiModel('GENERATE_REENTRY_LINK', 'api/v1/connect/generate_reentry_link')

type ConnectState = {
  initial_path: string | null
  initial_activity: string | null
  campaign: string | null
}

type ConnectConnection = {
  connect: ConnectState
  connectError?: string
}

type ConnectConnectionParams = {
  campaign?: string,
  path? : string,
}

const selectConnection = selectorCache(
  ({ campaign }) => campaign as ApiKey,
  ({ campaign, path }: ConnectConnectionParams) =>
    createSelector(
      [
        (state: ApiState) => (state.api as ApiState).connect as ConnectState,
        (state: ApiState) => MODEL.getReadErrorMessage(state, { id: campaign, query: { path } }),
      ],
      (connect, connectError) => ({
        connect,
        connectError,
      }),
    ),
)

export const Connect: {
  generateReentryLink: (email: string) => void,
  connection: Connection<ConnectConnection, ConnectConnectionParams>,
} = {
  generateReentryLink: (email: string) => {
    void GENERATE_REENTRY_LINK_MODEL.create({ email })
  },
  connection: {
    load: ({ connect }, { campaign, path }) => {
      if (!connect) {
        void MODEL.read({ id: campaign, query: { path } })
      }
    },

    isLoaded: ({ connect, connectError }) => !!connect || !!connectError,

    selector: selectConnection,
  },
}
