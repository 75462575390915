import * as COLORS from '@ableto/component-library/theme/colors'

export const COLOR_GENERATOR_COLORS = [
  COLORS.primary600,
  COLORS.blue600,
  COLORS.teal600,
  COLORS.yellow600,
  COLORS.orange600,
  COLORS.red600,
  COLORS.primary400,
  COLORS.blue400,
  COLORS.teal400,
  COLORS.yellow400,
  COLORS.orange400,
  COLORS.red400,
  COLORS.primary700,
  COLORS.blue700,
  COLORS.teal700,
  COLORS.yellow700,
  COLORS.orange700,
  COLORS.red700,
  COLORS.primary900,
  COLORS.blue900,
  COLORS.teal900,
  COLORS.yellow900,
  COLORS.orange900,
  COLORS.red900,
  COLORS.primary500,
  COLORS.blue500,
  COLORS.teal500,
  COLORS.yellow500,
  COLORS.orange500,
  COLORS.red500,
  COLORS.primary800,
  COLORS.blue800,
  COLORS.teal800,
  COLORS.yellow800,
  COLORS.orange800,
  COLORS.red800,
  COLORS.primary300,
  COLORS.blue300,
  COLORS.teal300,
  COLORS.yellow300,
  COLORS.orange300,
  COLORS.red300,
  COLORS.primary200,
  COLORS.blue200,
  COLORS.teal200,
  COLORS.yellow200,
  COLORS.orange200,
  COLORS.red200,
  COLORS.primary100,
  COLORS.blue100,
  COLORS.teal100,
  COLORS.yellow100,
  COLORS.orange100,
  COLORS.red100,
]
