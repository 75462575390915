/* global NativeApp */
import moment from 'moment-timezone'
import { CallStatus, FeatureFlags, Treatment } from 'joyable-js-api'
import { E2_USER_ROLES } from '../../constants'
import Pages from '@app/js/routing/Pages'
import { connectionContext } from '../modelConnect'
import { getMe } from '@app/js/activities/activityModules/contexts'

const currentTreatmentContext = connectionContext(Treatment.currentConnection)
const callStatusContext = connectionContext(CallStatus.connection)

const BASIC_OR_CONCIERGE_LEVELS = ['basic', 'concierge']

export const DASHBOARD_STATE_SLUG = {
  WELCOME: 'welcome',
  NO_ACTIVE_TREATMENT: 'no-active-treatment',
  MISSED_IC_FUA: 'missed-ic-fua',
  CANCELLED: 'cancelled',
  CANCELLED_IC_FUA: 'cancelled-ic-fua',
  IN_TREATMENT: 'in-treatment',
  PENDING_TREATMENT_SCHEDULE: 'pending-treatment-schedule',
  GRADUATED: 'graduated',
}

export const DASHBOARD_COMPONENTS = {
  SELF_CARE_DASHBOARD: 'SelfCareDashboard',
  D_PLUS_DASHBOARD: 'DPlusDashboard',
  THERAPY_DASHBOARD: 'TherapyDashboard',
  GETTING_STARTED_DASHBOARD: 'GettingStartedDashboard',
  T_PLUS_DASHBOARD: 'TPlusDashboard',
}

export const clientOnly = () => {
  const me = getMe()
  return me?.role === 'client'
}

export const dplusOnly = ({ currentTreatment, me }) =>
  (currentTreatment?.product_line === 'dplus' || me?.program_level === 'basic') && clientOnly()

export const standardDplusOnly = ({ currentTreatment, me }) =>
  currentTreatment?.product_line === 'dplus' && me?.program_level === 'standard' && clientOnly()

export const therapyOnly = ({ currentTreatment }) =>
  ['tplus', 't360'].includes(currentTreatment?.product_line) && clientOnly()

export const communityModeratorOnly = () => {
  const me = getMe()
  return me?.role === 'community_moderator'
}

export const adminOnly = () => {
  const me = getMe()
  return me?.role === 'admin' || me?.role === 'tech_admin'
}

export const selfCareDplusOnly = ({ currentTreatment, me }) =>
  dplusOnly({ currentTreatment, me }) || selfCareOnly({ currentTreatment })

export const therapyOnlyOrPreProgram = ({ currentTreatment, me }) =>
  therapyOnly({ currentTreatment }) || preProgram({ currentTreatment, me })

export const cplusShouldSeeSwitchProgram = ({ currentTreatment }) =>
  currentTreatment?.status !== 'cancelled' && currentTreatment?.status !== 'finished'

export const shouldNotSeeNavButtons = ({ me, page }) => {
  if (me?.is_escalated_role && (page === Pages.ACCOUNT || page === Pages.MOBILE_SETTINGS)) {
    return true
  }

  if (me?.deactivated_at != null || page === Pages.REFERRALS) {
    return true
  }

  return false
}

export const preProgram = ({ currentTreatment, me }) => {
  if (!clientOnly()) return false
  if (currentTreatment === Treatment.NOT_FOUND && me.program_level !== 'basic') return true

  if (['ic_fua', 'missed_ic_fua'].includes(currentTreatment?.status)) return true

  if (currentTreatment?.status === 'cancelled') {
    if (['ic_fua', 'missed_ic_fua'].includes(currentTreatment?.previous_status)) return true
  }

  return false
}

export const shouldSeeDashboardHeader = ({ me }) =>
  !E2_USER_ROLES.includes(me?.role) && !communityModeratorOnly()

export const selfCareOnly = ({ currentTreatment }) => currentTreatment?.product_line === 'self_care' && clientOnly()

export const isBasicOrConcierge = () => {
  const me = getMe()
  return BASIC_OR_CONCIERGE_LEVELS.includes(me.program_level) && clientOnly()
}

export const cancelledTreatment = ({ currentTreatment }) => {
  if (currentTreatment != null) {
    return currentTreatment?.status === 'cancelled' && currentTreatment?.previous_status === 'in_treatment'
  }

  return false
}

export const therapyInTreatmentOnly = ({ currentTreatment, me }) => {
  if (!therapyOnly({ currentTreatment })) return false

  return !(
    preProgram({ currentTreatment, me }) ||
    currentTreatment?.is_graduated ||
    cancelledTreatment({ currentTreatment })
  )
}

export const getIcSlug = ({ currentTreatment, callStatus }) => {
  const hasTreatment = currentTreatment !== Treatment.NOT_FOUND
  if (!hasTreatment) return DASHBOARD_STATE_SLUG.NO_ACTIVE_TREATMENT

  if (currentTreatment?.status === 'missed_ic_fua') return DASHBOARD_STATE_SLUG.MISSED_IC_FUA
  if (currentTreatment?.status === 'cancelled') {
    if (currentTreatment?.previous_status === 'missed_ic_fua') return DASHBOARD_STATE_SLUG.MISSED_IC_FUA
    if (currentTreatment?.previous_status === 'ic_fua') return DASHBOARD_STATE_SLUG.NO_ACTIVE_TREATMENT
  }

  if (callStatus.scheduled_for == null) return DASHBOARD_STATE_SLUG.PENDING_TREATMENT_SCHEDULE

  return DASHBOARD_STATE_SLUG.WELCOME
}

export const returnUserDashboard = ({ currentTreatment, me }) => {
  if (selfCareOnly({ currentTreatment })) {
    return DASHBOARD_COMPONENTS.SELF_CARE_DASHBOARD
  }
  if (dplusOnly({ currentTreatment, me })) {
    return DASHBOARD_COMPONENTS.D_PLUS_DASHBOARD
  }
  return DASHBOARD_COMPONENTS.THERAPY_DASHBOARD
}

export const isHomeLaunchPoint = (currentTreatment) => {
  const me = getMe()
  return selfCareOnly({ currentTreatment }) ||
    therapyOnly({ currentTreatment }) ||
    (FeatureFlags.current.coaching_plus_home_redesign && dplusOnly({ currentTreatment, me }))
}

export const defaultLaunchPoint = () => {
  const { currentTreatment } = currentTreatmentContext.select()
  return isHomeLaunchPoint(currentTreatment) ? Pages.HOME.path.slice(1) : Pages.DASHBOARD.path.slice(1)
}

export const defaultLaunchPage = () => {
  const { currentTreatment } = currentTreatmentContext.select()
  return isHomeLaunchPoint(currentTreatment) ? Pages.HOME : Pages.DASHBOARD
}

// remove function once D+ can show explore tab
export const allowExploreAccess = () => clientOnly() && !isBasicOrConcierge()

export const allowCommunities = () => clientOnly()

export const allowSelfCareOnly = () => {
  const currentTreatment = currentTreatmentContext.select().currentTreatment
  return selfCareOnly({ currentTreatment })
}

// Remove this function once all products enable the Me tab in Production
export const allowMeAccess = () => {
  const currentTreatment = currentTreatmentContext.select().currentTreatment
  const me = getMe()

  if (isBasicOrConcierge() || me.is_escalated_role) return false

  if (selfCareOnly({ currentTreatment }) && FeatureFlags.current.self_care_me_tab === true) {
    return true
  }

  if (standardDplusOnly({ currentTreatment, me }) && FeatureFlags.current.coaching_plus_me_tab === true) {
    return true
  }

  // t360 or tplus, else false
  return therapyOnly({ currentTreatment }) && FeatureFlags.current.therapy_me_tab === true
}

// Remove this function once all users are using the CP in Production
export const allowCPAccess = () => {
  const me = getMe()
  const currentTreatment = currentTreatmentContext.select().currentTreatment

  if (selfCareOnly({ currentTreatment })) {
    return !!FeatureFlags.current.self_care_communication_preferences
  }

  if (dplusOnly({ currentTreatment, me })) {
    if (me.is_post_program) return !!FeatureFlags.current.coaching_plus_communication_preferences_post_program

    return !!FeatureFlags.current.coaching_plus_communication_preferences
  }

  if (therapyOnly({ currentTreatment })) {
    if (me.is_post_program) return !!FeatureFlags.current.therapy_star_communication_preferences_post_program

    return !!FeatureFlags.current.therapy_star_communication_preferences
  }
}

export const allowCallSchedulingAccess = () => {
  const currentTreatment = currentTreatmentContext.select().currentTreatment

  if (cplusShouldSeeSwitchProgram({ currentTreatment })) {
    return true
  }
}

export const isGuest = () => {
  const me = getMe()
  return me.is_guest
}

export const hasUpcomingAppointment = (callStatus) => {
  const appointmentTime = moment.tz(callStatus?.scheduled_for, callStatus?.client_timezone)
  const currentTime = moment().tz(callStatus?.client_timezone)

  if (currentTime == null) return false

  return currentTime.isBefore(appointmentTime) &&
  (callStatus.status === 'scheduled' || callStatus.status === 'rescheduled')
}

export const allowSessionsAccess = () => {
  const me = getMe()
  if (me.is_guest) {
    return false
  }
  const currentTreatment = currentTreatmentContext.select().currentTreatment
  const callStatus = callStatusContext.select().callStatus

  return dplusOnly({ currentTreatment, me }) ||
    therapyInTreatmentOnly({ currentTreatment, me }) ||
    (currentTreatment?.is_graduated && hasUpcomingAppointment(callStatus))
}

export const getCommunicationPreferencesPathForProduct = () => {
  const currentTreatment = currentTreatmentContext.select().currentTreatment

  let path = Pages.ME_COMMUNICATION_PREFERENCES.path

  if (therapyOnly({ currentTreatment })) {
    path = `${Pages.ME_PROFILE.path}/notifications`
  }

  if (isBasicOrConcierge()) {
    path = Pages.COMMUNICATION_PREFERENCES.path
  }

  return path
}

export const showSelfCareSpanishTranslations = () => {
  const currentTreatment = currentTreatmentContext.select().currentTreatment

  return selfCareOnly({ currentTreatment }) && FeatureFlags.current.self_care_spanish_translations === true
}

export const canRequestToDeleteAccount = () =>
  NativeApp.isHosted() && FeatureFlags.current.request_to_delete_account_in_webview === true
