import { size1 } from '@ableto/component-library'
import { mobileBreakpoint } from '../themes/sizes'

export const isMaxWidth = width =>
  window.matchMedia(`(max-width: calc(${isPx(width) ? convertPxToRem(width) : width} - ${size1}))`).matches

export const isMobile = (breakpoint) => isMaxWidth(breakpoint ?? mobileBreakpoint)

export const isDesktop = () => !isMobile()

export const isPx = val => val.indexOf('px') >= 0

// converting to rem because isMaxWidth would be NaN if value was px
export const convertPxToRem = (px) =>
`${parseFloat(px) / parseFloat(window.getComputedStyle(document.documentElement).fontSize)}rem`
