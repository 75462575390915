export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU'
export const SET_ACTIVE_DIALOG = 'SET_ACTIVE_DIALOG'
export const SET_ACTIVE_BANNER = 'SET_ACTIVE_BANNER'

export const SET_DIALOG_RESULT = 'SET_DIALOG_RESULT'

export const SET_DEEP_LINKED_LOCATION = 'SET_DEEP_LINKED_LOCATION'

export const closeCurrentDialog = () => ({ type: SET_ACTIVE_DIALOG, dialog: null })

export const getActiveDialog = state => state.ui.activeDialog

export const setDialogResult = (slug, result) => ({ type: SET_DIALOG_RESULT, slug, result })

export const clearDialogResult = slug => setDialogResult(slug, null)

export const dialogResultSelector = slug => state => state.ui.dialogResults[slug]

export const setActiveMenu = menu => ({ type: SET_ACTIVE_MENU, menu })
export const closeActiveMenu = () => setActiveMenu(null)

export const toggleSettingsMenuActive = () => (dispatch, getState) => {
  dispatch(setActiveMenu(selectSettingsMenuOpen(getState()) ? null : 'settings'))
}
export const selectSettingsMenuOpen = state => state.ui.activeMenu === 'settings'

export const openAppUpsell = () => ({ type: SET_ACTIVE_BANNER, banner: 'appUpsell' })
export const closeAppUpsell = () => ({ type: SET_ACTIVE_BANNER, banner: null })
export const selectAppUpsellIsOpen = state => state.ui.activeBanner === 'appUpsell'

export const setDeepLinkedLocation = location => ({ type: SET_DEEP_LINKED_LOCATION, location })
export const clearDeepLinkedLocation = () => setDeepLinkedLocation(null)

// Coaching
export const SELECT_USER = 'SELECT_USER'
export const SELECT_ACTIONS_MENU_ITEM = 'SELECT_ACTIONS_MENU_ITEM'
// TODO (NJC): Merge the modal (from coach tool) and dialog systems.
export const SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL'

export const selectUser = (userId, selectedWorklist = null) =>
  ({ type: SELECT_USER, userId, selectedWorklist, worklistSlider: false })

export const openWorklistSlider = (userId, selectedWorklist = null) =>
  ({ type: SELECT_USER, userId, selectedWorklist, worklistSlider: true })

export const deselectUser = () =>
  ({ type: SELECT_USER, userId: null, selectedWorklist: null, worklistSlider: false })

export const toggleActionsMenuActive = () => (dispatch, getState) => {
  const active = getState().ui.activeMenu
  dispatch(setActiveMenu(active === 'accountActions' ? null : 'accountActions'))
}

export const toggleCallSchedulingMenuActive = () => (dispatch, getState) => {
  const active = getState().ui.activeMenu
  dispatch(setActiveMenu(active === 'callScheduling' ? null : 'callScheduling'))
}

export const toggleSubscriptionMenuActive = () => (dispatch, getState) => {
  const active = getState().ui.activeMenu
  dispatch(setActiveMenu(active === 'subscription' ? null : 'subscription'))
}

export const selectActionsMenuItem = menuItem => (dispatch, getState) => {
  const current = getState().ui.accountActionsMenuItemSelected
  if (current !== menuItem) dispatch({ type: SELECT_ACTIONS_MENU_ITEM, menuItem })
}

export const showModal = (name, props) => ({ type: SET_ACTIVE_MODAL, name, props })

export const closeModal = () => ({ type: SET_ACTIVE_MODAL, name: null, props: null })

// Site section
export const SET_SITE_SECTION = 'SET_SITE_SECTION'

export const enteredSiteSection = siteSection => ({ type: SET_SITE_SECTION, siteSection })
export const leftSiteSection = siteSection => (dispatch, getState) =>
  getState().ui.siteSection === siteSection
    ? ({ type: SET_SITE_SECTION, section: 'client' })
    : null

export const SET_SUPPORT_ERROR_MESSAGE = 'SET_SUPPORT_ERROR_MESSAGE'

export const setSupportErrorMessage = message => ({ type: SET_SUPPORT_ERROR_MESSAGE, message })

export const selectLandingConfig = state => {
  if (state.landingConfig == null) return null

  // Only fall back to the landing config returned on sideloaded responses if we didn't actually
  // land on a valid landing config. This pattern is only used to support the case where a client
  // hits the back button enough to get back to their own landing page after a refresh.
  if (state.landingConfig.missingLandingConfig && state.api.landing_config != null) {
    return state.api.landing_config
  }

  return state.landingConfig
}

export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION'

export const setScrollPosition = (key, scrollObj) => ({ type: SET_SCROLL_POSITION, scrollPosition: scrollObj, key })

export const getScrollPosition = key => state => state.ui.scrollPositions?.[key]

export const SET_HABITS_EDIT_MODE = 'SET_HABITS_EDIT_MODE'

export const setHabitsEditMode = (mode) => ({ type: SET_HABITS_EDIT_MODE, habitsEditMode: mode })

export const getHabitsEditMode = state => state.ui.habitsEditMode

export const SET_CONTENT_NOT_FOUND_DIALOG = 'SET_CONTENT_NOT_FOUND_DIALOG'

export const setContentNotFoundDialog = (mode) =>
  ({ type: SET_CONTENT_NOT_FOUND_DIALOG, showConentNotFoundDialog: mode })

export const getContentNotFoundDialog = state => state.ui.showConentNotFoundDialog
