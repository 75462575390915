import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel, { PRUNE_API_TREE } from '../ApiModel'
import { registerModelReducer } from '../reducers'
import { Program, SHAPE as PROGRAM_SHAPE } from './Program'
import { selectUserActivities, SHAPE as USER_ACTIVITY_SHAPE } from './UserActivity'
import { countRead, parseId, userModelSelector, parseQuery } from './utilities'

const MODEL = new ApiModel('PROGRAM_ACTIVITY', 'api/v1/program_activities')

registerModelReducer('programActivity',
  (state = { loadedMine: false }, { type, url, pruneKeys }) => {
    if (type === Action.READ.successType(MODEL) && parseId(MODEL, url) == null) {
      return { ...state, loadedMine: true }
    }

    if (type === Action.READ.successType(MODEL) && parseQuery(url).user_id != null) {
      return { ...state, [parseQuery(url).user_id]: true }
    }

    if (type === PRUNE_API_TREE) {
      const key = pruneKeys[0]
      if (key != null && key.length === 1 && key[0] === 'program_activities') {
        return { ...state, loadedMine: false }
      }
    }

    return state
  },
)

const SHAPE = PropTypes.shape({
  name: PropTypes.string,
  program_slug: PropTypes.string,
  category: PropTypes.string,
  complete: PropTypes.bool,
  program_activity_id: PropTypes.string,
  period: PropTypes.number,
  path: PropTypes.string,
  locked: PropTypes.bool,
  description: PropTypes.string,
})

const loadForUser = userId => MODEL.read({ id: userId, modelMapKey: ['user_id', userId] })

const select = state => state.api.program_activities

const selectIsLoaded = state => state.models.programActivity.loadedMine
const selectIsLoadedForUser = (state, { userId }) => state.models.programActivity[userId] != null

const nextActivity = createSelector(
  [select],
  programActivities => programActivities && programActivities.find(({ is_next: isNext }) => isNext),
)

// TODO (NJC): Maybe create a tool for connection dependencies if this becomes a common pattern
const nextActivityProgram = createSelector(
  [nextActivity, Program.availableConnection.selector],
  ({ program_slug: programSlug } = {}, { availablePrograms }) => programSlug && availablePrograms &&
    availablePrograms.find(({ slug }) => slug === programSlug),
)

const selectUserProgramActivities = (state, { userId }) =>
  userModelSelector(userId, 'program_activities', { singleResult: true })(state)

const selectMappedActivities = createSelector(
  [
    selectUserProgramActivities,
    selectUserActivities,
  ],
  ({ program } = {}, userActivities = {}) => {
    if (program == null) return null

    const activities = program.map(activity => {
      const { user_activity_id: uaId } = activity
      return (uaId && userActivities[uaId]) || activity
    })
    // reverse it so that the oldest activities are at the end
    return activities.reverse()
  },
)

const selectProgramSlug = createSelector(
  [selectUserProgramActivities],
  ({ program_slug: slug } = {}) => slug,
)

const selectNumUnreadMappedActivities = createSelector(
  [selectMappedActivities],
  programActivities => (programActivities || []).reduce(countRead, 0),
)

const selectCurrentPeriod = createSelector(
  [selectUserProgramActivities],
  ({ current_period: currentPeriod } = {}) => currentPeriod,
)

const selectFullProgram = createSelector(
  [selectUserProgramActivities],
  ({ program: programActivities } = {}) => programActivities,
)

export const ProgramActivity = {
  SHAPE,

  invalidateCache: () => ApiModel.pruneCache('program_activities'),

  connection: {
    load: ({ programActivitiesLoaded }) => {
      if (!programActivitiesLoaded) MODEL.read()
    },

    isLoaded: ({ programActivitiesLoaded }) => programActivitiesLoaded,

    selector: createSelector(
      [select, selectIsLoaded],
      (programActivities, programActivitiesLoaded) => ({
        programActivities,
        programActivitiesLoaded,
      }),
    ),

    shape: {
      programActivities: PropTypes.arrayOf(SHAPE),
      programActivitiesLoaded: PropTypes.bool.isRequired,
    },
  },

  nextActivityConnection: {
    load: props => {
      if (!props.programActivitiesLoaded) MODEL.read()
      Program.availableConnection.load(props)
    },

    isLoaded: props => props.programActivitiesLoaded && Program.availableConnection.isLoaded(props),

    selector: createSelector(
      [selectIsLoaded, nextActivityProgram, Program.availableConnection.selector],
      (programActivitiesLoaded, nextActivityProgram, programShape) => ({
        programActivitiesLoaded,
        nextActivityProgram,
        ...programShape,
      }),
    ),

    shape: alwaysAvailable => ({
      programActivitiesLoaded: alwaysAvailable ? PropTypes.bool : PropTypes.bool.isRequired,
      nextActivityProgram: PROGRAM_SHAPE,
      ...Program.availableConnection.shape(alwaysAvailable),
    }),
  },

  mappedActivityConnection: {
    load: ({ programSlug }, { userId }) => {
      if (programSlug == null && userId != null) loadForUser(userId)
    },

    isLoaded: ({ programSlug }, { userId }) => userId == null || programSlug != null,

    /**
     * Selector: (state, { userId }) => connectionShape
     */
    selector: createSelector(
      [
        selectMappedActivities,
        selectProgramSlug,
        selectNumUnreadMappedActivities,
      ],
      (mappedProgramActivities, programSlug, numUnreadProgramActivities) => ({
        mappedProgramActivities,
        programSlug,
        numUnreadProgramActivities,
      }),
    ),

    shape: {
      mappedProgramActivities: PropTypes.arrayOf(PropTypes.oneOfType([
        SHAPE, USER_ACTIVITY_SHAPE,
      ])),
      programSlug: PropTypes.string,
      numUnreadProgramActivities: PropTypes.number,
    },
  },

  providerConnection: {
    load: ({ programActivitiesLoaded }, { userId }) => {
      if (!programActivitiesLoaded) {
        MODEL.read({
          id: 'provider_view',
          query: { user_id: userId },
          modelMapKey: ['user_id', userId],
        })
      }
    },

    isLoaded: ({ programActivitiesLoaded }) => programActivitiesLoaded,

    selector: createSelector(
      [
        selectFullProgram,
        selectProgramSlug,
        selectCurrentPeriod,
        selectIsLoadedForUser,
      ],
      (programActivities, programSlug, currentPeriod, programActivitiesLoaded) => ({
        programActivities,
        programSlug,
        currentPeriod,
        programActivitiesLoaded,
      }),
    ),

    shape: {
      programActivities: PropTypes.arrayOf(SHAPE),
      programActivitiesLoaded: PropTypes.bool.isRequired,
    },
  },
}
