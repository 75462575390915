import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import { US_MIN_AGE, EU_MIN_AGE, US_COUNTRY } from '../constants'
import { isNumber } from 'lodash'

export const isUS = profile => isEmpty(profile.country) || profile.country === US_COUNTRY

export const minAge = (profile, promotion, age) => {
  if (promotion == null && isNumber(age)) {
    return age
  }
  return promotion?.min_age || isUS(profile) ? US_MIN_AGE : EU_MIN_AGE
}

export const isABCampaign = campaign => campaign === 'activision-blizzard-20171005'

export const isQuartetCampaign = campaign => campaign?.indexOf('quartet') >= 0

export const isSelfCareCampaign = campaign => campaign?.indexOf('self-care-20230101') >= 0

export const getIDPToken = () => queryString.parse(window.location.search).idp_custom_token

export const camelToDescription = function (inputString) {
  if (isNullOrWhitespace(inputString)) {
    // Return whitespace for optimal accessibility experience.
    return ' '
  }

  return inputString.replace(/([A-Z])/g, ' $1')
    .replace(/^./, function (inputString) { return inputString.toUpperCase() })
}

export const isNullOrWhitespace = function (inputString) {
  return inputString === null || inputString.match(/^ *$/) !== null
}

export const textLimitWarning = (value, maxLength, t) => {
  if (maxLength == null || value == null) return

  const remainingCharacters = maxLength - value.length
  if (remainingCharacters <= 15 && remainingCharacters >= 0) {
    if (remainingCharacters === 1) {
      return t('characterLimitWarning')
    }
    return t('characterLimitWarningCount', { count: remainingCharacters })
  }
}

export const openInNewTab = (url) => window.open(url, '_blank')

export const has2FA = (user) => user.otp_required_for_login || user.sso_id != null

// When we receive the appointment_time (2022-02-03T20:47:53+00:00) from the query params we are getting the data
// like this 2022-02-03T20:47:53 00:00 - we are losing the '+' sign since it's removed from the parsing process
// so we want to add it again because otherwise, the data is incorrect
export const adjustBrazeQueryDate = dateString => dateString?.replace(' ', '+')

/**
 * @param {*} query A query string, such as history.location.search
 * @param {*} params An object with key/value pairs to be added in the query
 * @returns A query string with the included params
 */
export const addQueryParams = (query, params) => {
  const queryObj = queryString.parse(query)

  Object.entries(params).forEach(([key, value]) => {
    queryObj[key] = value
  })

  return queryString.stringify(queryObj)
}
