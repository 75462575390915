import { Branch, User } from 'joyable-js-api'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import AbleToLoading from '@app/js/components/AbleToLoading'
import { alwaysAvailable, modelConnectWithOptions } from '../components/modelConnect'
import { safeSideEffect } from '../components/util/safeSideEffect'
import analytics from '../services/analytics'
import Log from '../services/log'
import { Redirect, StateChanges } from './Redirect'
import Requirement from './Requirement'
import { has2FA } from '@app/js/lib/helpers'
import queryString from 'query-string'

const evaluateState = props => {
  const { requirement, allowedRoles, me, redirectBranch } = props

  const fromDeepLink = queryString.parse(window.location.search).deep_link

  if (allowedRoles == null && requirement === Requirement.NONE && !fromDeepLink) return StateChanges.PAGE_ALLOWED

  if (me == null) return StateChanges.LOADING

  if (allowedRoles != null) {
    // allowed roles blocks don't use the redirect branch.
    if (me.is_guest) {
      return Redirect.login.redirect(props)
    } else if (allowedRoles.indexOf(me.role) < 0) {
      return Redirect.unauthorized.redirect(props)
    } else if (process.env.ENFORCE_2FA_REQUIREMENT && !has2FA(me)) {
      return Redirect.account.redirect()
    } else {
      // allowed - for pages with specific roles allowed we don't care about onboarding or
      // subscription
      return StateChanges.PAGE_ALLOWED
    }
  }

  if (redirectBranch == null) return StateChanges.LOADING

  const slug = requirement.redirectSlug(redirectBranch, fromDeepLink)
  if (slug == null) return StateChanges.PAGE_ALLOWED

  const redirector = Redirect[slug]
  if (redirector == null) {
    Log.error('No redirector for slug', requirement.name, slug)
    return StateChanges.LOADING
  }

  return redirector.redirect(props, fromDeepLink)
}

export default compose(
  withRouter,
  modelConnectWithOptions({
    connections: [User.meConnection, alwaysAvailable(Branch.redirectBranchConnection)],
    onMount: ({ allowedRoles, requirement }) => {
      // TODO (NJC): Use the cached copy and only reload when something about the user changes that
      // should cause a re-fetch
      if (requirement == null) requirement = Requirement.NONE
      const reload = allowedRoles == null && requirement !== Requirement.NONE
      Branch.loadRedirect(reload)
      // if we reloaded, don't process the mount now.
      return !reload
    },
  }),
  // For now, we're keeping this method instead of using React.lazy. The reason is that by doing
  // the load here, we're getting the benefit of loading the component bundle and the redirect
  // branch above at the same time (since the redirectBranch connection is made alwaysAvailable,
  // it's not blocking further load). However, React.lazy only starts loading a component when it's
  // mounted, so if we used it here, we wouldn't start loading the component bundle until the
  // redirect branch API call is complete, slowing down the page transition.
  safeSideEffect(({ load }) => load()),
)(class DelayedPage extends Component {
  /* eslint-disable react/no-unused-prop-types */
  static propTypes = {
    // A function that returns a promise that resolves to a Component instance
    load: PropTypes.func.isRequired,
    allowedRoles: PropTypes.arrayOf(PropTypes.string),
    requirement: PropTypes.oneOf(Requirement.values),

    // withRouter
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,

    // These all come from modelConnect. They're simply added here to quiet the linter until this
    // component can be converted to hooks
    ...User.meConnection.shape,
    ...Branch.redirectBranchConnection.shape,
  }

  static defaultProps = {
    requirement: Requirement.NONE,
  }

  state = {
    pageComponent: null,
    isLoading: true,
    isRedirecting: false,
  }

  componentDidMount () {
    this.setState(evaluateState(this.props))
  }

  componentDidUpdate ({ me, redirectBranch }) {
    if (
      this.props.meIsLoaded &&
      (me !== this.props.me || redirectBranch !== this.props.redirectBranch)
    ) {
      this.setState(evaluateState(this.props))
    }

    if (window.loadStartTime != null && !this.state.isLoading && !this.state.isRedirecting) {
      analytics.track(
        'Initial page load',
        { timeMillis: window.performance.now() - window.loadStartTime },
      )
      window.loadStartTime = null
    }
  }

  sideEffectComplete = pageComponent => this.setState({ pageComponent })

  render () {
    const { pageComponent, isLoading, isRedirecting } = this.state

    if (isRedirecting) {
      return null
    } else if (isLoading || pageComponent == null) {
      return <AbleToLoading fullPage={true} />
    } else {
      return pageComponent
    }
  }
})
