import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'
import Action from '../Action'
import { registerModelReducer } from '../reducers'
import { parseId, parseQuery } from './utilities'

const SHARE_HISTORY_SHAPE = PropTypes.shape({
  share_date: PropTypes.string,
  provider_name: PropTypes.string,
})

export const RESOURCE_SHAPE = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  source: PropTypes.string,
  topic: PropTypes.string,
  format: PropTypes.string,
  language: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  share_history: SHARE_HISTORY_SHAPE,
})

const RESOURCES_META_SHAPE = PropTypes.shape({
  formats: PropTypes.arrayOf(PropTypes.string),
  languages: PropTypes.arrayOf(PropTypes.string),
  topics: PropTypes.arrayOf(PropTypes.string),
})

const SHAPE = PropTypes.shape({
  resources: PropTypes.arrayOf(RESOURCE_SHAPE),
  resourcesMeta: RESOURCES_META_SHAPE,
  resourcesError: PropTypes.string,
  resourcesLoading: PropTypes.bool,
})

const MODEL = new ApiModel('RESOURCES', 'api/v1/resources')

registerModelReducer('resources',
  (state = null, { type, response, url }) => {
    if (type === Action.READ.successType(MODEL)) {
      const isUserResourcesRequest = parseId(MODEL, url) === 'shared'
      const userId = isUserResourcesRequest && parseQuery(url).user_id
      if (isUserResourcesRequest && userId != null) {
        return {
          ...state,
          userResourcesList: response.user_resources,
          isUserResourcesLoading: false,
        }
      }

      return {
        ...state,
        list: response.resources,
        meta: response.resources_metadata,
        isLoading: false,
      }
    } else if (type === Action.READ.requestType(MODEL)) {
      const isUserResourcesRequest = parseId(MODEL, url) === 'shared'
      const userId = isUserResourcesRequest && parseQuery(url).user_id
      if (isUserResourcesRequest && userId != null) {
        return { ...state, isUserResourcesLoading: true, userResourcesList: [] }
      }

      return {
        ...state,
        isLoading: true,
      }
    }

    return state
  },
)

const updateSharedResources = (userId, resourceIds) =>
  MODEL.create({
    user_id: userId,
    resource_ids: resourceIds,
  }, { noCache: true, ignoreFailure: true })

const getResourcesRequest = (userId) => MODEL.read({ query: { user_id: userId } }, { noCache: true })

const fetchUserResources = userId => MODEL.read({ id: 'shared', query: { user_id: userId } }, { noCache: true })

const selectResources = ({ models: { resources } }) => resources != null ? resources : null

const selectUserResourcesList = createSelector(
  [selectResources],
  userResources => userResources && userResources.userResourcesList,
)

const selectUserResourcesLoading = createSelector(
  [selectResources],
  userResources => userResources && userResources.isUserResourcesLoading,
)

const selectUserResourcesConnection = createSelector(
  [
    selectUserResourcesList,
    selectUserResourcesLoading,
  ],
  (userResources, isUserResourcesLoading) => ({
    userResources,
    isUserResourcesLoading,
    getUserResources: userId => {
      fetchUserResources(userId)
    },
  }),
)

const selectResourcesLoading = createSelector(
  [selectResources],
  resources => resources && resources.isLoading,
)

const selectResourcesList = createSelector(
  [selectResources],
  resources => resources && resources.list,
)

const selectResourcesMeta = createSelector(
  [selectResources],
  resources => resources && resources.meta,
)

const selectConnection = createSelector(
  [
    selectResourcesLoading,
    selectResourcesList,
    selectResourcesMeta,
    state => MODEL.getReadErrorMessage(state),
    state => MODEL.getCreateErrorMessage(state),
    MODEL.isCreatingSelector(),
  ],
  (resourcesLoading, resources, resourcesMeta, resourcesError, resourcesCreateError, isCreating) => ({
    isCreating,
    resourcesLoading,
    resources,
    resourcesMeta,
    resourcesError,
    resourcesCreateError,
    updateSharedResources,
    getResourcesRequest,
  }),
)

const USER_RESOURCES_SHAPE = PropTypes.shape({ user_resources: PropTypes.arrayOf(RESOURCE_SHAPE) })

export const Resources = {
  SHAPE,
  USER_RESOURCES_SHAPE,

  connection: {
    load: ({ resources }, { userId }) => {
      if (resources == null) {
        userId != null
          ? MODEL.read({ query: { user_id: userId } }, { noCache: true })
          : MODEL.read({}, { noCache: true })
      }
    },

    isLoaded: ({ resources }) => resources != null,

    selector: selectConnection,

    shape: {
      resources: PropTypes.arrayOf(RESOURCE_SHAPE),
      resourcesMeta: RESOURCES_META_SHAPE,
      resourcesError: PropTypes.string,
      resourcesLoading: PropTypes.bool,
      updateSharedResources: PropTypes.func,
      getResourcesRequest: PropTypes.func,
    },
  },

  forUserConnection: {
    load: ({ userResources }, { userId }) => {
      if (userResources == null) {
        fetchUserResources(userId)
      }
    },

    isLoaded: ({ userResources }) => userResources != null,

    selector: selectUserResourcesConnection,

    shape: {
      userResources: PropTypes.arrayOf(RESOURCE_SHAPE),
      isUserResourcesLoading: PropTypes.bool,
      getUserResources: PropTypes.func,
    },
  },
}
