/* eslint-disable camelcase */
import { createSelector } from 'reselect'
import { registerModelReducer } from '../reducers'
import ApiModel, { FailureLoggingFilter } from '../ApiModel'
import Action from '../Action'
import ApiFetch from '../ApiFetch'
import { Connection } from './types'
import { CommunityResponse, RESET_COMMUNITY_RESPONSES } from './CommunityResponse'
import {
  applyActionToCommunityResponse,
  RESPONSE_ACTION_CREATE_FAILURE_TYPE,
  RESPONSE_ACTION_CREATE_REQUEST_TYPE,
  RESPONSE_ACTION_DESTROY_REQUEST_TYPE,
  RESPONSE_ACTION_DESTROY_FAILURE_TYPE,
  RESPONSE_ACTION_CREATE_SUCCESS_TYPE,
} from './ResponseActions'
import { ApiSuccessAction } from '../types'
import { parseId } from './utilities'

const failureFilter: FailureLoggingFilter = (json, response: Response, fetch: ApiFetch) =>
  !(fetch.action === Action.READ && response.status === 404)

const MODEL = new ApiModel('COMMUNITY_PROMPT', 'api/v1/community_prompts', failureFilter)

export type Prompt = {
  id: number
  prompt_text: string
  time_left: string
  description: string
  community_responses: CommunityResponse[]
  end_date: string
  number_responses: number
  approved_responses: number
  publish_date: string
  start_date: string
  language?: string
  total_responses?: number
  is_active?: boolean
  is_published?: boolean
  reviewed_community_responses: number
  disapproved_community_responses: number
  sent_emergency_resources_percentage: number
  show_more?: boolean
}

export type UpdatePromptType = {
  community_prompt: Prompt
}

export type PageQuery = {
  page: number
  field?: string
  order?: string
}

type SortedByProps = {
  field: string
  order: string
}

export type ActivePrompt = {
  id: number
  prompt_text: string
  number_responses: number
  description: string | null
}

export type ModelState = {
  prompts: Prompt[]
  activePrompt: ActivePrompt | null
  singlePrompt: Prompt | null
  promptsLoaded: boolean
  activePromptLoaded: boolean
  singlePromptLoaded: boolean
  moderatorPromptsLoaded: boolean
  promptsTotalCount: number
  promptsCurrentPage: number
  promptsSortedBy: SortedByProps
  promptsMetrics: {
    promptsReviewedResponsesPercentage: number
    promptsDisapprovedResponsesPercentage: number
    promptsSentEmergencyResourcesPercentage: number
  },
}

const defaultState: ModelState = {
  prompts: [],
  activePrompt: null,
  singlePrompt: null,
  promptsLoaded: false,
  activePromptLoaded: false,
  moderatorPromptsLoaded: false,
  promptsTotalCount: 0,
  promptsCurrentPage: 1,
  promptsSortedBy: {
    field: '',
    order: '',
  },
  singlePromptLoaded: false,
  promptsMetrics: {
    promptsReviewedResponsesPercentage: 0,
    promptsDisapprovedResponsesPercentage: 0,
    promptsSentEmergencyResourcesPercentage: 0,
  },
}

type UpdateApprovedResponsesType = {
  status: string
  newStatus: string
}

export const RESET_COMMUNITY_PROMPT = 'RESET_COMMUNITY_PROMPT'

const UPDATE_APPROVED_RESPONSES = 'UPDATE_APPROVED_RESPONSES'

const { modelStateSelector } = registerModelReducer<ModelState>(
  'communityPrompts',
  (state = defaultState, action) => {
    const { response, url } = action as ApiSuccessAction

    switch (action.type) {
      case Action.UPDATE_MODIFY.successType(MODEL): {
        if (parseId(MODEL, url) === 'responses') {
          const communityResponse = response.community_prompt_response as CommunityResponse
          const prompts = updatedPromptsWithResponses(state.prompts, communityResponse)
          const singlePrompt = updateSinglePromptWithResponse(state.singlePrompt, communityResponse)

          return { ...state, singlePrompt, prompts }
        }

        if (parseId(MODEL, url) === 'publish') {
          return {
            ...state,
            singlePrompt: response.community_prompt,
          }
        }

        return state
      }
      case Action.READ.successType(MODEL): {
        if (parseId(MODEL, url) === 'active') {
          return {
            ...state,
            activePromptLoaded: true,
            activePrompt: response.community_prompt,
          }
        }

        if (parseId(MODEL, url) === 'moderator_show') {
          return {
            ...state,
            singlePromptLoaded: true,
            singlePrompt: response.community_prompt,
          }
        }
        if (parseId(MODEL, url) === 'spotlight_show') {
          return {
            ...state,
            singlePromptLoaded: true,
            singlePrompt: response.community_prompt,
          }
        }

        if (parseId(MODEL, url) === 'all_prompts') {
          return {
            ...state,
            promptsTotalCount: response.total_count,
            promptsCurrentPage: response.current_page,
            moderatorPromptsLoaded: true,
            prompts: response.prompts,
            promptsSortedBy: response.sorted_by,
            promptsMetrics: {
              promptsReviewedResponsesPercentage: response.reviewed_responses_percentage,
              promptsDisapprovedResponsesPercentage: response.disapproved_responses_percentage,
              promptsSentEmergencyResourcesPercentage: response.sent_emergency_resources_percentage,
            },
          }
        }

        return {
          ...state,
          promptsLoaded: true,
          prompts: response.community_prompts,
        }
      }
      case UPDATE_APPROVED_RESPONSES: {
        const { status, newStatus } = response as UpdateApprovedResponsesType
        const { approved_responses } = state.singlePrompt as Prompt

        if (status !== 'featured' && newStatus !== 'featured') return state

        const updatedApprovedResponses = newStatus !== 'featured' ? approved_responses - 1 : approved_responses + 1

        return {
          ...state,
          singlePrompt: {
            ...state.singlePrompt,
            approved_responses: updatedApprovedResponses,
          },
        }
      }
      case RESPONSE_ACTION_CREATE_SUCCESS_TYPE:
      case RESPONSE_ACTION_CREATE_FAILURE_TYPE:
      case RESPONSE_ACTION_CREATE_REQUEST_TYPE:
      case RESPONSE_ACTION_DESTROY_REQUEST_TYPE:
      case RESPONSE_ACTION_DESTROY_FAILURE_TYPE: {
        return applyActionToCommunityResponse(action, state) as ModelState
      }
      case RESET_COMMUNITY_PROMPT:
        return defaultState
      default:
        return state
    }
  },
)

const updatedPromptsWithResponses = (prompts: Prompt[], communityResponse: CommunityResponse) => {
  const updatedPrompts = prompts.map((prompt) => {
    if (prompt.community_responses == null) return prompt
    prompt.community_responses = prompt.community_responses.reduce((responses, curResponse) => {
      if (curResponse.id !== communityResponse.id) {
        responses.push(curResponse)
      } else if (communityResponse.status !== 'deleted') {
        responses.push(communityResponse)
      }
      return responses
    }, [] as CommunityResponse[])

    return prompt.community_responses.length === 0 ? null : prompt
  })

  return updatedPrompts.filter(prompt => prompt !== null)
}

const updateSinglePromptWithResponse = (singlePrompt: Prompt | null, communityResponse: CommunityResponse) => {
  if (singlePrompt == null) return singlePrompt
  singlePrompt.community_responses = singlePrompt.community_responses.filter((response) =>
    response.id !== communityResponse.id && response.status !== 'deleted')

  return singlePrompt
}

const selectCommunityPrompts = modelStateSelector((state) => state.prompts)
const selectPromptsLoaded = modelStateSelector((state) => state.promptsLoaded)
const selectActiveCommunityPrompt = modelStateSelector((state) => state.activePrompt)
const selectActiveCommunityPromptLoaded = modelStateSelector((state) => state.activePromptLoaded)
const selectSinglePromptLoaded = modelStateSelector((state) => state.singlePromptLoaded)
const selectSingleCommunityPrompt = modelStateSelector((state) => state.singlePrompt)

const selectModeratorPromptsLoaded = modelStateSelector((state) => state.moderatorPromptsLoaded)
const selectPromptsTotalCount = modelStateSelector(state => state.promptsTotalCount)
const selectPromptsCurrentPage = modelStateSelector(state => state.promptsCurrentPage)
const selectPromptsSortedBy = modelStateSelector(state => state.promptsSortedBy)
const selectModeratorMetrics = modelStateSelector(state => state.promptsMetrics)

export const deleteSpotlightResponse = async (id: number, responseID: number) => {
  await MODEL.updateModify(
    { community_prompt: { status: 'deleted', response_id: responseID, id } },
    { id: 'responses' },
  )
  ApiModel.dispatch({ type: RESET_COMMUNITY_RESPONSES })
}

export const publishPrompt = async (id: number) => {
  await MODEL.updateModify(
    { community_prompt: { id } },
    { id: 'publish' },
  )
}

export const unpublishPrompt = async (id: number) => {
  await MODEL.updateModify(
    { community_prompt: { id, publish_date: null } },
    { id: 'moderator_update' },
  )
}

export const updateApprovedResponsesCount = (status: string, newStatus: string) => {
  ApiModel.dispatch({
    type: UPDATE_APPROVED_RESPONSES,
    response: {
      status,
      newStatus,
    },
  })
}

export const fetchCommunityPrompts = async ({ id, query }: { id?: string, query?: PageQuery } = {}) =>
  await MODEL.read({ id, query })

const sortPrompts = async ({ order, field }: { order: string, field: string }) => {
  await MODEL.read({ id: 'all_prompts', query: { page: 1, order, field } })
}

type CommunityPromptConnection = ModelState & {
  deleteSpotlightResponse: typeof deleteSpotlightResponse,
  fetchCommunityPrompts: typeof fetchCommunityPrompts,
  sortPrompts: typeof sortPrompts,
  publishPrompt: typeof publishPrompt,
  unpublishPrompt: typeof unpublishPrompt
}

type ModeratorConnectionProps = {
  promptId: number
}

type AllPromptsConnectionProps = {
  pageQuery: PageQuery
}

const selectAvailableConnection = createSelector(
  [
    selectCommunityPrompts,
    selectPromptsLoaded,
    selectActiveCommunityPrompt,
    selectActiveCommunityPromptLoaded,
    selectModeratorPromptsLoaded,
    selectPromptsTotalCount,
    selectPromptsCurrentPage,
    selectPromptsSortedBy,
    selectSingleCommunityPrompt,
    selectSinglePromptLoaded,
    selectModeratorMetrics,
  ],
  (
    prompts,
    promptsLoaded,
    activePrompt,
    activePromptLoaded,
    moderatorPromptsLoaded,
    promptsTotalCount,
    promptsCurrentPage,
    promptsSortedBy,
    singlePrompt,
    singlePromptLoaded,
    promptsMetrics,
  ) => ({
    prompts,
    promptsLoaded,
    activePrompt,
    activePromptLoaded,
    singlePrompt,
    singlePromptLoaded,
    publishPrompt,
    updateApprovedResponsesCount,
    moderatorPromptsLoaded,
    promptsTotalCount,
    promptsCurrentPage,
    promptsSortedBy,
    deleteSpotlightResponse,
    fetchCommunityPrompts,
    sortPrompts,
    unpublishPrompt,
    promptsMetrics,
  }),
)

export const CommunityPrompt: {
  connection: Connection<CommunityPromptConnection>,
  activePromptConnection: Connection<CommunityPromptConnection>,
  singlePromptConnection: Connection<CommunityPromptConnection, ModeratorConnectionProps>,
  singleSpotlightConnection: Connection<CommunityPromptConnection, ModeratorConnectionProps>,
  moderatorPromptConnection: Connection<CommunityPromptConnection, AllPromptsConnectionProps>,
} = {
  connection: {
    load: ({ promptsLoaded }) => {
      if (!promptsLoaded) void MODEL.read({ id: 'spotlights' })
    },

    isLoaded: ({ promptsLoaded }) => promptsLoaded,

    selector: selectAvailableConnection,
    onMount: () => void ApiModel.dispatch({ type: RESET_COMMUNITY_PROMPT }),
  },
  activePromptConnection: {
    load: ({ activePromptLoaded }) => {
      if (!activePromptLoaded) {
        void fetchCommunityPrompts({ id: 'active' })
      }
    },

    isLoaded: ({ activePromptLoaded }) => activePromptLoaded,

    selector: selectAvailableConnection,
  },
  singlePromptConnection: {
    load: ({ singlePromptLoaded }, { promptId }) => {
      if (!singlePromptLoaded) {
        void MODEL.read({
          id: 'moderator_show',
          query: { id: promptId },
        })
      }
    },

    isLoaded: ({ singlePromptLoaded }) => singlePromptLoaded,

    selector: selectAvailableConnection,
    onMount: () => void ApiModel.dispatch({ type: RESET_COMMUNITY_PROMPT }),
  },
  singleSpotlightConnection: {
    load: ({ singlePromptLoaded }, { promptId }) => {
      if (!singlePromptLoaded) {
        void MODEL.read({
          id: 'spotlight_show',
          query: { id: promptId },
        })
      }
    },

    isLoaded: ({ singlePromptLoaded }) => singlePromptLoaded,

    selector: selectAvailableConnection,
    onMount: () => void ApiModel.dispatch({ type: RESET_COMMUNITY_PROMPT }),
  },
  moderatorPromptConnection: {
    load: ({ moderatorPromptsLoaded }, { pageQuery }) => {
      if (!moderatorPromptsLoaded) {
        const queryParams = pageQuery ? { ...pageQuery } : { page: 1 }
        void fetchCommunityPrompts({ id: 'all_prompts', query: queryParams })
      }
    },

    isLoaded: ({ moderatorPromptsLoaded }) => moderatorPromptsLoaded,

    selector: selectAvailableConnection,
    onMount: () => void ApiModel.dispatch({ type: RESET_COMMUNITY_PROMPT }),
  },
}
