import ApiModel from '../ApiModel'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'

const MODEL = new ApiModel('TIMEZONES', 'admin/api/v1/timezones')

export const Timezones = {
  connection: {
    load: ({ timezones }) => {
      if (timezones == null) MODEL.read()
    },

    isLoaded: ({ timezones }) => timezones != null,

    selector: createSelector(
      [state => state.api.timezones],
      timezones => ({ timezones }),
    ),

    shape: {
      timezones: PropTypes.arrayOf(PropTypes.string),
    },
  },
}
