import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'

const MODEL = new ApiModel('FLAG_SET', 'api/v1/flag_set')

const updateFlagSet = (flag, value, userId) => {
  const query = userId != null ? { user_id: userId } : null

  return MODEL.updateModify(
    {
      flag_set: { [flag]: value },
    },
    { query },
  )
}

const updateFlagSets = flags => MODEL.updateModify({ flag_set: flags })

export const FlagSet = {
  invalidateCache: () => ApiModel.pruneCache('flag_set'),

  connection: {
    load: ({ flagSet }, { userId } = {}) => {
      if (flagSet == null) MODEL.read({ query: { user_id: userId } })
    },

    isLoaded: ({ flagSet }) => flagSet != null,

    selector: createSelector(
      [state => state.api.flag_set, MODEL.isUpdatingSelector()],
      (flagSet, flagsSaving) => ({
        flagSet,
        flagsSaving,
        setFlag: updateFlagSet,
        setFlags: updateFlagSets,
      }),
    ),
  },
}
