import get from 'lodash/get'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import { selectorCache } from '../util/selectorCache'
import Action from '../Action'
import ApiModel from '../ApiModel'

const MODEL = new ApiModel('SURVEY_RESULTS', 'api/v1/survey_results',
  (json, response, fetch) => fetch.action !== Action.CREATE || response.status !== 201)

const query = userId => ({ query: { user_id: userId || 'me' } })

const createErrorSelector = userId => state => MODEL.getCreateErrorMessage(state, query(userId))

const ratingAdder = userId => (questionSlug, rating, prompt) =>
  MODEL.create(
    { survey_result: { answers: [{ question_slug: questionSlug, rating, prompt }] } },
    query(userId),
  )

const selectAnswersConnection = selectorCache(
  ({ quizSlug }) => quizSlug,
  ({ quizSlug }) => createSelector(
    [state => get(state.api.survey_results, [quizSlug, 'answers'])],
    answers => ({
      ratings: answers && answers.reduce((ratings, { question_slug: questionSlug, rating }) => ({
        ...ratings,
        [questionSlug]: rating,
      }), {}),
    }),
  ),
)

const selector = selectorCache(
  ({ userId }) => `surveyResults|${userId}`,
  ({ userId }) => createSelector(
    [MODEL.isCreatingSelector(query(userId)), createErrorSelector(userId)],
    (ratingIsAdding, ratingAddError) => ({
      ratingIsAdding,
      ratingAddError,
      addClientRating: ratingAdder(userId),
    }),
  ),
)

export const SurveyResults = {
  /**
   * connection { userId }
   * If userId is not specified, the current user is used.
   */
  connection: {
    selector,

    shape: {
      ratingIsAdding: PropTypes.bool.isRequired,
      ratingAddError: PropTypes.string,
      addClientRating: PropTypes.func.isRequired,
    },
  },

  // requires { quizSlug }
  // TODO (NJC): This doesn't currently handle the no results case, or allow using cached results
  ratingsConnection: {
    onMount: ({ quizSlug }) => {
      ApiModel.pruneCache('survey_results', quizSlug)
      MODEL.read({ id: quizSlug })
    },

    isLoaded: ({ ratings }) => ratings != null,

    selector: selectAnswersConnection,

    shape: {
      ratings: PropTypes.objectOf(PropTypes.number),
    },
  },
}
