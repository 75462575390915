import get from 'lodash/get'
import Log from '@app/js/services/log'
import analytics from '@app/js/services/analytics'

const ANALYTICS_MESSAGE = {
  scheduleIc: {
    successfulRequest: 'IC Scheduled',
    noAvailability: 'No Availability',
    treatmentBooked: 'IC Scheduled Fail',
    chooseAnotherTime: 'IC Scheduled Fail',
    failedRequest: 'Failed to schedule IC appointment',
    confirmationDialog: 'IC Scheduling Confirmation',
  },
  rescheduleIc: {
    successfulRequest: 'Successfully reschedules IC/FUA consultation',
    confirmationDialog: 'IC/FUA Rescheduling Confirmation',
    noAvailability: 'No Availability',
    treatmentBooked: 'Treatment Booked',
    chooseAnotherTime: 'Time Not Available',
    failedRequest: 'Failed to reschedule IC/FUA',
  },
  rescheduleSession: {
    successfulRequest: 'Successfully reschedules session',
    moveToNextWeek: 'Move to next week',
    moveMidSession: 'Moves mid-session',
    confirmationDialog: 'Session Rescheduling Confirmation',
    noAvailability: 'No Availability',
    treatmentBooked: 'Treatment Booked',
    chooseAnotherTime: 'Time Not Available',
    failedRequest: 'Failed to reschedule session',
    sessionRequestError: 'Sessions Request Failed',
  },
  scheduleFua: {
    successfulRequest: 'FUA Scheduled',
    noAvailability: 'No Availability',
    treatmentBooked: 'Treatment Booked',
    chooseAnotherTime: 'Time Not Available',
    failedRequest: 'Failed to schedule FUA appointment',
    confirmationDialog: 'FUA Scheduling Confirmation',
  },
  rescheduleFua: {
    successfulRequest: 'Successfully reschedules IC/FUA consultation',
    confirmationDialog: 'IC/FUA Rescheduling Confirmation',
    noAvailability: 'No Availability',
    treatmentBooked: 'Treatment Booked',
    chooseAnotherTime: 'Time Not Available',
    failedRequest: 'Failed to reschedule IC/FUA',
  },
}

const SCHEDULING_TAG = {
  scheduleIc: 'Connect',
  rescheduleIc: 'IC/FUA Rescheduling',
  rescheduleSession: 'Session Rescheduling',
  scheduleFua: 'FUA scheduling',
  rescheduleFua: 'IC/FUA Rescheduling',
}

const PRODUCT_IDENTIFIER = {
  t360: 'T360',
  tplus: 'T+',
}

type getAnalyticsTagProps = {
  schedulingType: string
  productLine?: string
  sessionProtocolCode?: string
}
/**
 * Builds the identifying tag placed in front of tracked message, which associates it with a general flow.
 * Supports several warnings, if we get unexpected values.
 */
export const getAnalyticsTag = ({
  schedulingType,
  productLine,
  sessionProtocolCode,
}: getAnalyticsTagProps) => {
  const tag = SCHEDULING_TAG[schedulingType] as string | undefined
  if (tag == null) {
    Log.warn('Unknown scheduling type', schedulingType)
    return
  }
  // For rescheduleSession we need to dynamically indicate the product line and session type
  if (schedulingType !== 'rescheduleSession') return tag

  const productIdentifier = productLine != null ? PRODUCT_IDENTIFIER[productLine] as string | undefined : null
  if (productIdentifier == null) {
    Log.warn('Got unexpected product line', productLine)
    return tag
  }
  if (sessionProtocolCode == null || !/^\d[AB]$/.test(sessionProtocolCode)) {
    Log.warn('Got unexpected session protocol code', sessionProtocolCode)
    return tag
  }

  return `${productIdentifier} ${sessionProtocolCode[1]} ${tag}`
}

type trackAnalyticsProps = {
  schedulingType: string
  messageKey: string
  messageTag?: string
  context?: any
}

const trackAnalytics = ({
  schedulingType,
  messageKey,
  messageTag,
  context,
}: trackAnalyticsProps) => {
  const message = get(ANALYTICS_MESSAGE, [schedulingType, messageKey]) as string | undefined
  if (message == null) {
    Log.warn('No matching message for the supplied arguments', schedulingType, messageKey)
    return
  }
  const analyticsMessage = messageTag == null ? message : `${messageTag} > ${message}`

  context != null ? analytics.track(analyticsMessage, context) : analytics.track(analyticsMessage)
}

export default trackAnalytics
