import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'
import Action from '../Action'
import { ApiFailure, Connection } from './types'
import { ApiAction, ApiState, ApiSuccessAction } from '../types'
import awaitState from '../awaitState'
import { registerModelReducer } from '../reducers'

const ADOBE_STATUSES = [
  'OUT_FOR_SIGNATURE',
  'OUT_FOR_DELIVERY',
  'OUT_FOR_ACCEPTANCE',
  'OUT_FOR_FORM_FILLING',
  'OUT_FOR_APPROVAL',
  'OUT_FOR_WITNESSING',
  'AUTHORING',
  'CANCELLED',
  'SIGNED',
  'APPROVED',
  'DELIVERED',
  'ACCEPTED',
  'FORM_FILLED',
  'EXPIRED',
  'ARCHIVED',
  'PREFILL',
  'WIDGET_WAITING_FOR_VERIFICATION',
  'DRAFT',
  'DOCUMENTS_NOT_YET_PROCESSED',
  'WAITING_FOR_FAXIN',
  'WAITING_FOR_VERIFICATION',
  'WAITING_FOR_NOTARIZATION',
] as const

/* eslint-disable camelcase */
type UserAgreement = {
  id: string,
  user_id: number,
  status: typeof ADOBE_STATUSES[number],
  external_id: string,
  agreement_type: string,
  adobe_agreement_id?: string,
  signing_url?: string
  signer_email?: string,
  locale?: string,
  created_date?: string,
}
/* eslint-enable camelcase */

type ConnectionParams = {
  agreementType: string
}

type UserAgreementConnection = {
  userAgreements?: UserAgreement[],
  updateUserAgreement: (agreementStatus: string, userAgreementId: string) => Promise<ApiAction>
  userAgreementIsUpdating: (userAgreementId: string) => Promise<Record<string, any>>,
  userAgreementUpdateFailed: (userAgreementId: string) => (state: ApiState) => ApiFailure | undefined,
  clearFailedUserAgreementUpdate: () => void,
}

type ModelState = {
  userAgreements?: UserAgreement[]
}

export const AGREEMENT_TYPES = {
  consentToTreat: 'consent_to_treat',
}

const defaultState: ModelState = {
  userAgreements: undefined,
}

const MODEL = new ApiModel('USER_AGREEMENTS', 'api/v1/user_agreements')
const USER_AGREEMENTS_READ_SUCCESS_TYPE = Action.READ.successType(MODEL)

const { modelStateSelector } = registerModelReducer<ModelState>('userAgreements', (state = defaultState, action) => {
  const { type } = action

  if (type === USER_AGREEMENTS_READ_SUCCESS_TYPE) {
    const { response } = action as ApiSuccessAction
    return {
      ...state,
      userAgreementsLoaded: true,
      userAgreements: response.user_agreements,
    }
  }

  return state
})

const load = (agreementType: string) => {
  if (agreementType != null) void MODEL.read({ query: { agreement_type: agreementType } })
}

const select = modelStateSelector(state => state.userAgreements)

const update = async (agreementStatus: string, userAgreementId: string) =>
  await MODEL.updateModify({ user_agreement: { status: agreementStatus } }, { id: userAgreementId })

const isUpdating = (userAgreementId: string) => awaitState((state: ApiState) =>
  MODEL.isUpdatingSelector({ query: { id: userAgreementId } })(state))

const updateFailed = (userAgreementId: string) => (state: ApiState) => {
  const url = MODEL.getUrl({ id: userAgreementId })
  const pending = Action.UPDATE_MODIFY.getPending(state, url)
  return Action.getError(pending)
}

const clearFailedUpdate = (userAgreementId?: string) => () => {
  if (userAgreementId == null) return

  const url = MODEL.getUrl({ id: userAgreementId })
  const action = Action.UPDATE_MODIFY.getClearPendingAction(MODEL, url)
  return ApiModel.dispatch(action)
}

const selectConnection = createSelector(
  [select],
  (userAgreements): UserAgreementConnection => ({
    userAgreements,
    userAgreementIsUpdating: isUpdating,
    updateUserAgreement: update,
    userAgreementUpdateFailed: updateFailed,
    clearFailedUserAgreementUpdate: clearFailedUpdate,
  }),
)

export const UserAgreement: {
  connection: Connection<UserAgreementConnection, ConnectionParams>,
} = {
  connection: {
    load: ({ userAgreements }, { agreementType }) => {
      if (userAgreements == null) load(agreementType)
    },

    isLoaded: ({ userAgreements }) => userAgreements != null,

    selector: selectConnection,
  },
}
