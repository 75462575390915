/*
  Params:
  useE2Mock[true,false]
  icScheduleResponse[success, treatmentBooked, chooseAnotherTime]
 */
export const getMockIcAppointmentMutation = (insuranceMemberId) => {
  // urlParams work here because our format is similar to a URL
  const urlParams = new URLSearchParams(insuranceMemberId)
  const providersParam = urlParams.get('icScheduleResponse')
  switch (providersParam) {
    case 'treatmentBooked':
      return {
        messages: 'This member has already been scheduled for an appointment',
        error_type: 'treatment_booked',
        errors: true,
      }
    case 'chooseAnotherTime':
      return {
        messages: 'All other errors are handled as choose_another_time',
        error_type: 'choose_another_time',
        errors: true,
      }
    default:
      return {}
  }
}
