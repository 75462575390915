/* global NativeApp */
/* global NativeiOSApp */
import i18n from 'i18next'
import { Dialog } from '@app/js/services/Dialog'
import { defaultLaunchPage } from '@app/js/components/dashboard/dashboardStates'
import { DIALOG_BANNER_TYPES } from './helpers'

// Constructs a contextual object used in translations to build modals appointment details content
export const parseAppointmentDetails = (period, providerDetails, modalPeriodDateFormat) => ({
  providerDetailsName: providerDetails?.name,
  providerDetailsLicense: providerDetails?.license,
  period: period?.begin.format(modalPeriodDateFormat),
  periodStart: period?.begin.format('h:mm a'),
  periodEnd: period?.end.format('h:mm a zz'),
})

export const showConfirmation = ({
  period,
  providerDetails,
  modalPeriodDateFormat,
  content,
  launchedFrom,
  showInfoBanner,
}) => {
  Dialog.RESCHEDULE_REQUEST.open({
    title: content.confirmation.consultationRescheduled,
    body: content.confirmation.body,
    iconType: 'CheckCircle',
    iconColor: 'teal400',
    infoBanner: showInfoBanner
      ? { copy: content.confirmation.infoBanner.showAllSessions }
      : null,
    buttonText: content.buttons.confirmReschedule,
    additionalContext: parseAppointmentDetails(period, providerDetails, modalPeriodDateFormat),
    submitDialog: () => {
      if (NativeApp.isReactNative()) {
        NativeApp.exitActivity()
      } else if (NativeiOSApp.isHosted()) {
        NativeiOSApp.exitActivity()
      } else {
        // HACK ALERT
        // This forces a full refresh of the DASHBOARD/HOME page which .go() will not.
        // TODO: Only Practitioner really needs to be refreshed, however, and this code should be reworked
        // to be handled by the dashboard context to conditionally clear the Practitioner cache
        // via additional props and be reverted to using .go().
        const launchedFromPath = launchedFrom != null ? `/${launchedFrom}` : defaultLaunchPage().path
        window.location.href = window.location.origin + launchedFromPath
      }
    },
  })
}

const getBannerContent = (infoBanner, content) => {
  if (infoBanner.type === DIALOG_BANNER_TYPES.MOVE_TO_NEXT_WEEK) {
    return {
      copy: content.submit.infoBanner.moveToNextWeek,
      backgroundColor: 'teal50',
      icon: {
        type: 'InformationCircle',
        color: 'gray900',
      },
    }
  }
  if (infoBanner.type === DIALOG_BANNER_TYPES.NEXT_MID_SESSION) {
    return {
      copy: content.submit.infoBanner.nextMidSession,
      additionalContext: infoBanner.additionalContext,
      backgroundColor: 'teal50',
      icon: {
        type: 'InformationCircle',
        color: 'gray900',
      },
    }
  }

  return null
}

export const openConfirmationDialog = ({
  period,
  providerDetails,
  modalPeriodDateFormat,
  confirmationDialogCallback,
  isScheduleIc,
  infoBanner,
  content,
  sessionType,
}) => {
  const dialogFooter = isScheduleIc ? content.phone_number.consent : content.submit.footer

  confirmationDialogCallback(
    i18n.t(content.phone_number.title, { sessionType }),
    content.confirmation.body,
    {
      confirm: { slug: 'confirm-consultation', text: content.buttons.confirmConsultation },
      cancel: { slug: 'choose-another-time', text: content.buttons.chooseAnotherTime },
    },
    dialogFooter,
    // iconName, seems to be deprecated
    null,
    // horizontalButtons
    false,
    parseAppointmentDetails(period, providerDetails, modalPeriodDateFormat),
    // hideCloseButton
    true,
    infoBanner != null ? getBannerContent(infoBanner, content) : null,
  )
}
