import get from 'lodash/get'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'

const MODEL = new ApiModel('BACKGROUND', 'admin/api/v1/background')

const SHAPE = PropTypes.shape({
  onboarding_background: PropTypes.string,
})

const backgroundOptions =
  userId => ({ query: { user_id: userId }, modelMapKey: ['user_id', userId] })

const selectBackground = (state, { userId }) =>
  get(state.modelMap, ['user_id', userId, 'background'])

export const Background = {
  connection: {
    load: ({ background }, { userId }) => {
      if (background == null) MODEL.read(backgroundOptions(userId))
    },

    isLoaded: ({ background }) => background != null,

    /**
     * Selector: (state, { userId }) => connectionShape
     */
    selector: createSelector([selectBackground], background => ({ background })),

    shape: {
      background: SHAPE,
    },
  },
}
