/* eslint-disable camelcase */
import ApiModel, { PRUNE_API_TREE } from '../ApiModel'
import PropTypes from 'prop-types'
import Action from '../Action'
import { registerModelReducer } from '../reducers'
import { createSelector } from 'reselect'

const MODEL = new ApiModel('RESCHEDULE_REQUEST', 'api/v1/reschedule_requests')

registerModelReducer('rescheduleRequest', (state = undefined, { type, response, error, pruneKeys }) => {
  if (type === Action.CREATE.successType(MODEL)) {
    return response.reschedule_request
  }
  if (type === Action.READ.successType(MODEL)) {
    return response.reschedule_request
  }
  if (
    type === PRUNE_API_TREE &&
    pruneKeys.find(key => key.length === 1 && key[0] === 'reschedule_requests') != null
  ) {
    return undefined
  }
  return state
})

const RESCHEDULE_REQUEST_SHAPE = PropTypes.shape({
  reschedule_request: {
    id: PropTypes.string,
    user_id: PropTypes.number,
    treatment_id: PropTypes.string,
    appointment_time: PropTypes.string,
    session_code: PropTypes.string,
    provider_name: PropTypes.string,
    reschedule_to_same_day: PropTypes.bool,
    preferred_days: PropTypes.string,
    preferred_time: PropTypes.string,
    reschedule_notes: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
  },
})

const selectConnection = createSelector(
  [
    state => state.models.rescheduleRequest,
    MODEL.isCreatingSelector(),
    state => MODEL.getCreateErrorMessage(state),
  ],
  (rescheduleRequest, isRescheduling, reschedulingErrorMessage) => ({
    rescheduleRequest,
    isRescheduling,
    reschedulingErrorMessage,
    clearRescheduleRequestState: () => {
      ApiModel.pruneCache('reschedule_requests')
      if (reschedulingErrorMessage != null) {
        ApiModel.dispatch(
          Action.CREATE.getClearPendingAction(MODEL, MODEL.getUrl()),
        )
      }
    },
    reschedule: (
      treatment_id,
      appointment_time,
      session_code,
      provider_name,
      reschedule_to_same_day,
      preferred_days,
      preferred_time,
      appointment_type,
      reschedule_notes,
      is_next_week,
    ) => {
      return MODEL.create({
        treatment_id,
        appointment_time,
        session_code,
        provider_name,
        reschedule_to_same_day,
        preferred_days,
        preferred_time,
        appointment_type,
        reschedule_notes,
        is_next_week,
      }, { noCache: true })
    },
    getAuditRescheduleRequest: (
      treatment_id,
      appointment_time,
      session_code,
    ) => {
      return MODEL.read({
        query: {
          treatment_id,
          appointment_time,
          session_code,
        },
        noCache: true,
      })
    },
  }),
)

export const RescheduleRequest = {
  RESCHEDULE_REQUEST_SHAPE,
  connection: {
    selector: selectConnection,
  },
  selector: selectConnection,
}
