import get from 'lodash/get'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel, { PRUNE_API_TREE } from '../ApiModel'
import Log from '../log'
import { deleteIn, registerModelReducer, setIn } from '../reducers'
import { selectorCache } from '../util/selectorCache'
import { parseQuery } from './utilities'
import cloneDeep from 'lodash/cloneDeep'

const MODEL = new ApiModel('COACH_AVAILABILITY', 'api/v1/coach_availability')
const ADMIN_MODEL = new ApiModel('ADMIN_COACH_AVAILABILITY', 'admin/api/v1/coach_availability')
const DEFAULT_AVAILABILITY_DAYS = 14

registerModelReducer('coachAvailability',
  (state = { }, { type, response, url, pruneAvailability }) => {
    if (type === Action.READ.successType(ADMIN_MODEL)) {
      const { user_id: userId, start_time: startTime } = parseQuery(url)
      return setIn(state, [userId, startTime], get(response, ['coach_availability', 'blocks']))
    } else if (type === PRUNE_API_TREE && pruneAvailability != null) {
      const { userId } = pruneAvailability
      return deleteIn(state, [userId])
    }

    return state
  },
)

const getDays = (
  blocks,
  timezone = 'America/Los_Angeles',
  startTime = timeInTimezone(moment(), timezone),
  isCoach = false,
) => {
  blocks = blocks.map(({ start_time: startTime, end_time: endTime }) => ({
    startTime: timeInTimezone(moment(startTime), timezone),
    endTime: timeInTimezone(moment(endTime), timezone),
  }))

  let result = blocks.reduce((days, block) => {
    if (days.length === 0) {
      days = [new DayAvailability(moment(startTime).tz(timezone))]
    }

    let availability = days[days.length - 1]
    // ignore blocks before our current availability
    if (availability.blockStartsBeforeToday(block)) return days

    while (!availability.blockStartsToday(block)) {
      days = [...days, availability = availability.createTomorrow()]
    }
    availability.addBlock(block)

    return days
  }, [])

  if (result.length < DEFAULT_AVAILABILITY_DAYS) {
    result = addSkeletonAvailability(result, startTime, timezone)
  }

  // only keep days that have blocks or don't have blocks but are weekdays (M-F)
  return result.filter(x => isCoach || (x.blocks.length || !(x.day.day() === 6 || x.day.day() === 0)))
}

const timeInTimezone = (time, timezone) => timezone == null ? time : time.tz(timezone)

const addSkeletonAvailability = (initialAvailability = [], startTime, timezone) => {
  const resultAvailability = cloneDeep(initialAvailability)
  for (let index = initialAvailability.length; index < DEFAULT_AVAILABILITY_DAYS; index++) {
    resultAvailability.push(new DayAvailability(moment(startTime).add(index, 'day').tz(timezone)))
  }
  return resultAvailability
}

class DayAvailability {
  constructor (day) {
    this.day = day.clone().startOf('day')
    this.dayEnd = this.day.clone().add(1, 'day')
    this.blocks = []
  }

  get dayName () {
    return this.day.format('dddd')
  }

  blockStartsToday = block =>
    !this.blockStartsBeforeToday(block) && block.startTime.isBefore(this.dayEnd)

  blockStartsBeforeToday = block => block.startTime.isBefore(this.day)

  addBlock (block) {
    if (!this.blockStartsToday(block)) {
      Log.warn('Asked to add a block that doesn’t start today', this, block)
      return
    }

    this.blocks.push(block)
  }

  createTomorrow () {
    return new DayAvailability(this.dayEnd)
  }
}

const selectClientConnection = selectorCache(
  ({ timezone }) => timezone,
  ({ timezone }) => {
    const reloadAvailability = () => ApiModel.pruneCache('coach_availability')
    if (timezone == null) return () => ({ availabilityDays: [], reloadAvailability })

    return createSelector(
      [state => state.api.coach_availability],
      availability => ({
        availabilityDays: availability && getDays(availability.blocks, timezone),
        reloadAvailability,
      }),
    )
  },
)

const selectCoachConnection = selectorCache(
  ({ startTime, userId, timezone }) => `${userId}|${startTime}|${timezone}`,
  ({ startTime, userId, timezone }) => {
    const selectBlocks = state => get(state.models.coachAvailability, [userId, startTime])
    return createSelector(
      [selectBlocks],
      blocks => ({ availabilityDays: blocks && getDays(blocks, timezone, startTime, true) }),
    )
  },
)

const pruneForClient = userId =>
  ApiModel.trimCache([], { additionalActionValues: { pruneAvailability: { userId } } })

const loadAdmin = (userId, startTime) =>
  ADMIN_MODEL.read({ query: { user_id: userId, start_time: startTime }, noCache: true })

export const CoachAvailability = {
  pruneForClient,

  /**
   * connection: { timezone }
   */
  connection: {
    load: ({ availabilityDays }) => {
      if (availabilityDays == null) MODEL.read()
    },

    isLoaded: ({ availabilityDays }, { timezone }) => availabilityDays != null || timezone == null,

    selector: selectClientConnection,

    shape: {
      availabilityDays: PropTypes.arrayOf(PropTypes.instanceOf(DayAvailability)),
      reloadAvailability: PropTypes.func.isRequired,
    },
  },

  /**
   * connection: { userId, startTime (ISO8601 string), timezone }
   */
  coachConnection: {
    load: ({ availabilityDays }, { userId, startTime }) => {
      if (availabilityDays == null) loadAdmin(userId, startTime)
    },

    isLoaded: ({ availabilityDays }) => availabilityDays != null,

    selector: selectCoachConnection,

    shape: {
      availabilityDays: PropTypes.arrayOf(PropTypes.instanceOf(DayAvailability)),
    },
  },
}
