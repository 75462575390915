import { Link, Redirect, Route, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { isString, isEmpty, isFunction, kebabCase } from 'lodash'
import i18next from 'i18next'
import { camelize } from 'humps'
import hoistStatics from 'hoist-non-react-statics'

import { Enum } from '@app/js/lib/enum'
import { isMobile } from '../lib/browserSize'
import analytics from '../services/analytics'
import { getState } from '../services/ReduxService'
import { ASYNC_PAGE_TITLE, E2_USER_ROLES } from '../constants'
import { selectLandingConfig } from '../store/ui'
import HistoryService from '@app/js/services/HistoryService'
import {
  useCommunityOnboardingRedirect,
  useProviderOnboardingRedirect,
} from './Activities'
import {
  allowExploreAccess, allowCPAccess, allowCommunities, allowMeAccess, communityModeratorOnly,
  allowSessionsAccess, allowSelfCareOnly,
  isGuest,
  allowCallSchedulingAccess,
} from '../components/dashboard/dashboardStates'

import { Icon } from '@ableto/component-library'
import JoyableHelmet from '../components/JoyableHelmet'
import JoyableButton from '../components/material/JoyableButton'
import DelayedPage from './DelayedPage'
import Requirement from './Requirement'

const loadLongBody = () => import('@app/js/components/pages/LongBodyOfText')
const loadActivityNamespace = () => import('../components/pages/ActivityNamespace')

const activityNamespace = rootPath => ({
  loadModule: loadActivityNamespace,
  path: `/${rootPath}/:activityPath`,
  redirect: rootPath === 'placement' ? useProviderOnboardingRedirect : null,
})

const SpotlightsRedirect = () => useCommunityOnboardingRedirect('community-journal-onboarding', 'community-spotlights')

export default class Pages extends Enum {
  static LOGIN = new Pages({
    exact: true,
    path: '/',
    loadModule: () => import('../components/pages/Login'),
    themeOverride: 'ableTo',
    label: () => i18next.t('buttons.logIn'),
  })

  static BLANK = new Pages({ loadModule: () => import('../components/pages/Blank') })

  static PARTNER_LANDING = new Pages({
    predicate: () => {
      const config = selectLandingConfig(getState())
      return !config.missingLandingConfig && config.landing_page != null
    },
    path: () => selectLandingConfig(getState())?.landing_page,
    label: () => selectLandingConfig(getState())?.title,
    loadModule: () => import('../components/pages/PartnerLanding'),
    redirect: useProviderOnboardingRedirect,
  })

  static CONNECT = new Pages({
    path: '/connect/:campaignPath?',
    loadModule: () => import('../components/pages/Connect'),
  })

  static COOKIES = new Pages({ loadModule: loadLongBody })

  static SUBVENDORS = new Pages({ loadModule: loadLongBody })

  static DASHBOARD = new Pages({
    // Users on mobile web can't access dashboard until chat onboarding is complete, because
    // they finish the onboarding flow on /coach-chat
    requirement: isMobile() ? Requirement.CHAT_ONBOARDING : Requirement.ONBOARDING,
    loadModule: () => import('../components/pages/Dashboard'),
    icon: { type: 'Home' },
  })

  static ACTIVITIES = new Pages(activityNamespace('activities'))

  static FUA = new Pages(activityNamespace('fua'))

  static LEVEL_UP = new Pages(activityNamespace('level-up'))

  static PLACEMENT = new Pages(activityNamespace('placement'))

  static ACTIVITY_RESULTS = new Pages({
    loadModule: () => import('../components/pages/ActivityResults'),
    allowedRoles: E2_USER_ROLES,
  })

  static PROGRAM_PROGRESS = new Pages({
    path: '/therapy/program-progress',
    loadModule: () => import('../components/pages/ProgramProgress'),
    allowedRoles: E2_USER_ROLES,
    themeOverride: 'ableTo',
  })

  static SUPPLEMENTAL_RESOURCES = new Pages({
    path: '/therapy/supplemental-resources',
    loadModule: () => import('../components/pages/ProgramProgress/SupplementalResources'),
    allowedRoles: E2_USER_ROLES,
    themeOverride: 'ableTo',
  })

  static PROGRAM_MESSAGE = new Pages({
    path: '/therapy/program-message',
    loadModule: () => import('../components/pages/ProgramMessage'),
    allowedRoles: E2_USER_ROLES,
  })

  static COACH_NOTES = new Pages({
    path: '/therapy/coach-notes',
    allowedRoles: ['therapist', 'program_advisor', 'therapy_reviewer', 'admin', 'tech_admin'],
    loadModule: () => import('../components/pages/CoachNotesPage'),
  })

  static PROVIDER_TOOLS = new Pages({
    loadModule: () => import('../components/pages/ProviderTools'),
    predicate: () => process.env.TOOLS_ENABLED,
    allowedRoles: E2_USER_ROLES,
  })

  static SESSIONS = new Pages({
    // Users on mobile web can't access sessions until chat onboarding is complete, because
    // they finish the onboarding flow on /coach-chat
    requirement: isMobile() ? Requirement.CHAT_ONBOARDING : Requirement.ONBOARDING,
    loadModule: () => import('../components/pages/Sessions'),
    icon: { type: 'Calendar' },
    exact: true,
    analyticsPath: 'sessions',
    predicate: () => allowSessionsAccess() || isGuest(),
  })

  static ALL_SESSIONS = new Pages({
    // Users on mobile web can't access sessions until chat onboarding is complete, because
    // they finish the onboarding flow on /coach-chat
    requirement: isMobile() ? Requirement.CHAT_ONBOARDING : Requirement.ONBOARDING,
    loadModule: () => import('../components/pages/AllSessions'),
    path: '/sessions/all-sessions',
    analyticsPath: 'sessions',
  })

  static SCHEDULE_SESSION = new Pages({
    requirement: isMobile() ? Requirement.ONBOARDING : Requirement.CHAT_ONBOARDING,
    loadModule: () => import('../components/pages/ScheduleSessionPage'),
    predicate: allowCallSchedulingAccess,
  })

  // D+/SelfCare Settings pages under /me // Start

  static ME = new Pages({
    loadModule: () => import('../components/pages/Me'),
    title: ASYNC_PAGE_TITLE,
    path: '/me',
    icon: { type: 'User' },
    exact: true,
    predicate: allowMeAccess,
    analyticsPath: 'me',
  })

  static ME_MOBILE_SETTINGS = new Pages({
    loadModule: () => import('../components/pages/Settings/MobileSettings'),
    label: () => i18next.t('pages.settings.label'),
    path: '/me/settings',
    exact: true,
    icon: { type: 'Cog' },
    predicate: allowMeAccess,
    analyticsPath: 'settings',
  })

  static ME_PROFILE = new Pages({
    requirement: Requirement.LOGIN,
    loadModule: () => import('../components/pages/Profile'),
    label: () => i18next.t('pages.yourProfile.label'),
    path: '/me/settings/profile',
    icon: { type: 'Cog' },
    predicate: allowMeAccess,
    analyticsPath: 'settings',
  })

  static ME_SWITCH_PROGRAM = new Pages({
    requirement: Requirement.LOGIN,
    loadModule: () => import('../components/pages/SwitchProgram'),
    path: '/me/settings/switch-program',
    renderLink: function () {
      let { label } = this
      const { path } = this
      let className = null

      if (arguments.length > 0) {
        className = arguments[0].className
        label = arguments[0].label
      }

      return (
        <Link
          to={path}
          className={className}
          onClick={() => { analytics.trackButtonClick(label) }}
        >
          {label}
        </Link>
      )
    },
    analyticsPath: 'settings',
  })

  static ME_ACCOUNT = new Pages({
    requirement: Requirement.LOGIN,
    loadModule: () => import('../components/pages/Account'),
    themeOverride: 'ableTo',
    path: '/me/settings/account',
    predicate: allowMeAccess,
    icon: { type: 'User' },
    label: () => i18next.t('pages.account.label'),
    analyticsPath: 'settings',
  })

  static ME_TERMS_OF_USE = new Pages({
    loadModule: () => import('../components/pages/Settings/TermsOfService'),
    path: '/me/settings/terms-of-use',
    predicate: allowMeAccess,
    label: () => i18next.t('pages.termsOfUse.label'),
    analyticsPath: 'settings',
  })

  static ME_APPOINTMENT_PREFERENCE = new Pages({
    requirement: Requirement.LOGIN,
    loadModule: () => import('../components/pages/Settings/communicationPreferences/AppointmentPreferencePage'),
    path: '/me/settings/notifications/appointment-preference',
    predicate: () => allowCPAccess() && allowMeAccess(),
    analyticsPath: 'settings',
  })

  static ME_COMMUNICATION_PREFERENCE = new Pages({
    requirement: Requirement.LOGIN,
    loadModule: () => import('../components/pages/Settings/communicationPreferences/CommunicationPreferencePage'),
    path: '/me/settings/notifications/:communicationPreferenceSlug',
    predicate: () => allowCPAccess() && allowMeAccess(),
    analyticsPath: 'settings',
  })

  static ME_COMMUNICATION_PREFERENCES = new Pages({
    requirement: Requirement.LOGIN,
    label: () => i18next.t('pages.communicationPreferences.label'),
    loadModule: () => import('../components/pages/Settings/communicationPreferences/index'),
    path: '/me/settings/notifications',
    predicate: () => allowCPAccess() && allowMeAccess(),
    analyticsPath: 'settings',
  })

  // TODO: remove this page later when we do a cleanup to remove all notification_settings instances and replace them
  // with communication_preferences; so this will remain as a fallback for now
  // it will be a ticket equivalent to JOY-13090
  static ME_NOTIFICATION_SETTINGS = new Pages({
    requirement: Requirement.LOGIN,
    label: () => i18next.t('pages.settings.label'),
    loadModule: () => import('../components/pages/NotificationSettings'),
    path: '/me/settings/notifications',
    predicate: allowMeAccess,
  })

  static ME_SUPPORT = new Pages({
    requirement: Requirement.LOGIN,
    loadModule: () => import('../components/pages/Settings/Support'),
    path: '/me/settings/support',
    predicate: allowMeAccess,
    label: () => i18next.t('pages.support.label'),
    analyticsPath: 'settings',
  })

  static ME_REQUEST_TO_DELETE_ACCOUNT = new Pages({
    requirement: Requirement.LOGIN,
    loadModule: () => import('../components/pages/Settings/RequestToDeleteAccount'),
    path: '/me/settings/request-to-delete-account',
    predicate: allowMeAccess,
    label: () => i18next.t('pages.requestToDeleteAccount.label'),
    analyticsPath: 'settings',
  })
  // D+/SelfCare Settings pages // End

  // Basic/Concierge Settings pages // Start

  // The Basic/Concierge Settings pages are absolutely the same as the ones for SC/C+
  // The only difference is that SC/C+ Settings pages are under the /me/settings page
  // and the Basic/Concierge Settings pages are under /settings
  // Escalated roles are also using these pages

  static ACCOUNT = new Pages({
    requirement: Requirement.LOGIN,
    loadModule: () => import('../components/pages/Account'),
    themeOverride: 'ableTo',
    path: '/settings/account',
    icon: { type: 'User' },
    predicate: (props) => !allowMeAccess(props),
    analyticsPath: 'settings',
  })

  static REPORTED_CONTENT = new Pages({
    requirement: Requirement.LOGIN,
    loadModule: () => import('../components/pages/CommunityModerator/ReportedContent/ReportedContent'),
    path: '/reported-content',
    icon: { type: 'XCircle' },
    predicate: communityModeratorOnly,
  })

  static SWITCH_PROGRAM = new Pages({
    requirement: Requirement.LOGIN,
    loadModule: () => import('../components/pages/SwitchProgram'),
    path: '/settings/switch-program',
    renderLink: this.ME_SWITCH_PROGRAM.renderLink,
    predicate: (props) => !allowMeAccess(props),
    analyticsPath: 'settings',
  })

  static TERMS_OF_USE = new Pages({
    loadModule: () => import('../components/pages/Settings/TermsOfService'),
    path: '/settings/terms-of-use',
    predicate: (props) => !allowMeAccess(props),
    analyticsPath: 'settings',
  })

  static APPOINTMENT_PREFERENCE = new Pages({
    requirement: Requirement.LOGIN,
    loadModule: () => import('../components/pages/Settings/communicationPreferences/AppointmentPreferencePage'),
    path: '/settings/notifications/appointment-preference',
    predicate: (props) => allowCPAccess(props) && !allowMeAccess(props),
    analyticsPath: 'settings',
  })

  static COMMUNICATION_PREFERENCE = new Pages({
    requirement: Requirement.LOGIN,
    loadModule: () => import('../components/pages/Settings/communicationPreferences/CommunicationPreferencePage'),
    path: '/settings/notifications/:communicationPreferenceSlug',
    predicate: (props) => allowCPAccess(props) && !allowMeAccess(props),
    analyticsPath: 'settings',
  })

  static PROFILE = new Pages({
    path: '/settings/profile',
    predicate: (props) => !allowMeAccess(props),
    requirement: Requirement.LOGIN,
    loadModule: () => import('../components/pages/Profile'),
    label: () => i18next.t('pages.settings.label'),
    icon: { type: 'Cog' },
    analyticsPath: 'settings',
  })

  static MOBILE_SETTINGS = new Pages({
    path: '/settings',
    predicate: (props) => !allowMeAccess(props),
    loadModule: () => import('../components/pages/Settings/MobileSettings'),
    label: () => i18next.t('pages.settings.label'),
    exact: true,
    icon: { type: 'Cog' },
    analyticsPath: 'settings',
  })

  static COMMUNICATION_PREFERENCES = new Pages({
    requirement: Requirement.LOGIN,
    label: () => i18next.t('pages.communicationPreferences.label'),
    loadModule: () => import('../components/pages/Settings/communicationPreferences/index'),
    path: '/settings/notifications',
    predicate: (props) => !allowMeAccess(props) && allowCPAccess,
    analyticsPath: 'settings',
  })

  static SUPPORT = new Pages({
    requirement: Requirement.LOGIN,
    loadModule: () => import('../components/pages/Settings/Support'),
    path: '/settings/support',
    predicate: (props) => !allowMeAccess(props),
    analyticsPath: 'settings',
  })

  // Basic/Concierge Settings pages // End

  // for mobile app webview use
  static USER_DELETION_REQUEST = new Pages({
    requirement: Requirement.LOGIN,
    loadModule: () => import('../components/dashboard/RequestToDeleteForm'),
  })

  static RIGHT_FOR_YOU = new Pages({ loadModule: () => import('../components/pages/RightForYou') })

  static COACH_PANEL = new Pages({
    requirement: Requirement.CHAT_ONBOARDING,
    loadModule: () => import('../components/pages/CoachPanelPage'),
  })

  static COACH_CHAT = new Pages({
    // Users on desktop can't access coach-chat until chat onboarding is complete, because
    // they finish the onboarding flow on /dashboard
    requirement: isMobile() ? Requirement.ONBOARDING : Requirement.CHAT_ONBOARDING,
    loadModule: () => import('../components/pages/CoachChat'),
  })

  static CALL_SCHEDULING = new Pages({
    requirement: Requirement.ONBOARDING,
    loadModule: () => import('../components/pages/CallSchedulingPage'),
    predicate: allowCallSchedulingAccess,
  })

  static REFERRALS = new Pages({
    requirement: Requirement.LOGIN,
    loadModule: () => import('../components/pages/ReferralScheduleSessionPage'),
    path: '/referrals/scheduling',
  })

  static SET_USER_PREFERENCES = new Pages({
    requirement: Requirement.ONBOARDING,
  })

  static COACHING = new Pages({
    loadModule: () => import('../components/pages/Coaching'),
    allowedRoles: ['coach', 'admin', 'tech_admin', 'escalations_reviewer', 'coach_advisor'],
    label: 'AbleTo Coach Tool',
    themeOverride: 'joyable',
  })

  static SATISFACTION_RESPONSE = new Pages({
    requirement: Requirement.LOGIN,
    loadModule: () => import('../components/pages/SatisfactionResponse'),
  })

  static RESOURCES_CALENDAR = new Pages({
    allowedRoles: ['admin', 'tech_admin'],
    loadModule: () => import('../components/coaching/calendar/ResourcesCalendarPage'),
    path: '/allocations/calendar',
    label: 'AbleTo Coach Resources',
    themeOverride: 'joyable',
  })

  static RESOURCES_COACHING_CALENDAR = new Pages({
    allowedRoles: ['admin', 'tech_admin'],
    loadModule: () => import('../components/coaching/calendar/ResourcesCoachingCalendarPage'),
    path: '/allocations/coaching/calendar',
    label: 'AbleTo Coach Resource Calendar',
    themeOverride: 'joyable',
  })

  static ALLOCATIONS = new Pages({
    allowedRoles: ['admin', 'tech_admin'],
    loadModule: () => import('../components/pages/AllocationsPage'),
    path: '/allocations',
    label: 'AbleTo Coach Allocations',
    themeOverride: 'joyable',
  })

  static ADMIN_TOOLS = new Pages({
    loadModule: () => import('../components/pages/AdminTools'),
    allowedRoles: ['admin', 'tech_admin', 'concierge_contractor'],
    label: 'AbleTo Admin Tools',
    themeOverride: 'joyable',
  })

  static TWILIO_SESSION = new Pages({
    requirement: Requirement.LOGIN,
    loadModule: () => import('../components/pages/TwilioSession'),
    path: '/twilio-session/:sessionId',
  })

  static RESCHEDULE_REQUEST = new Pages({
    requirement: Requirement.LOGIN,
    loadModule: () => import('../components/pages/Rescheduling'),
  })

  static ERROR = new Pages({ loadModule: () => import('../components/pages/Error') })

  // Dev tool pages
  static ACTIVITY_ILLUSTRATION_GALLERY = new Pages({
    predicate: () => process.env.TOOLS_ENABLED,
    label: 'Activity Illustration Gallery',
    loadModule: () => import('../components/pages/ActivityIllustrationGallery'),
  })

  static AVATAR_GALLERY = new Pages({
    predicate: () => process.env.TOOLS_ENABLED,
    label: 'Avatar Gallery',
    loadModule: () => import('../components/pages/AvatarGallery'),
  })

  static MY_COMMUNITY_RESPONSES = new Pages({
    path: '/community-spotlights/my-responses',
    loadModule: () => import('../components/pages/MyCommunitySpotlights'),
    predicate: allowCommunities,
  })

  static SAVED_COMMUNITY_RESPONSES = new Pages({
    path: '/community-spotlights/saved-responses',
    loadModule: () => import('../components/pages/SavedCommunitySpotlights'),
    predicate: allowCommunities,
  })

  static SINGLE_COMMUNITY_SPOTLIGHT = new Pages({
    path: '/community-spotlights/prompt/:id',
    loadModule: () => import('../components/pages/SingleCommunitySpotlight'),
    predicate: allowCommunities,
  })

  static COMMUNITY_SPOTLIGHTS = new Pages({
    path: '/community-spotlights',
    loadModule: () => import('../components/pages/CommunitySpotlights'),
    predicate: allowCommunities,
    redirect: SpotlightsRedirect,
  })

  static SINGLE_COMMUNITY_PROMPT = new Pages({
    path: '/prompts/:id',
    requirement: Requirement.LOGIN,
    loadModule: () => import('../components/pages/CommunityModerator/SingleCommunityPrompt'),
    allowedRoles: ['community_moderator'],
  })

  static COMMUNITY_PROMPTS = new Pages({
    label: 'Prompts',
    path: '/prompts',
    icon: { type: 'ViewGrid' },
    requirement: Requirement.LOGIN,
    loadModule: () => import('../components/pages/CommunityModerator/AllPrompts'),
    allowedRoles: ['community_moderator'],
  })

  static JOYCON_GALLERY = new Pages({
    predicate: () => process.env.TOOLS_ENABLED,
    label: 'Joycon Gallery',
    loadModule: () => import('../components/pages/JoyconGallery'),
  })

  static TEMPLATE_GALLERY = new Pages({
    predicate: () => process.env.TOOLS_ENABLED,
    label: 'Template Gallery',
    loadModule: () => import('../components/pages/TemplateGallery'),
  })

  static TEMPLATE_SAMPLES = new Pages({
    predicate: () => process.env.TOOLS_ENABLED,
    label: 'Template Samples Gallery',
    loadModule: () => import('../components/pages/TemplateSamples'),
  })

  static ACTIVITY_LOG = new Pages({
    allowedRoles: E2_USER_ROLES,
    loadModule: () => import('../components/pages/ActivityLog'),
  })

  static VERIFY_LOGIN = new Pages({
    loadModule: () => import('../components/pages/VerifyLogin'),
  })

  static WATCH_JOB = new Pages({
    loadModule: () => import('../components/pages/WatchJob'),
  })

  static WEB = new Pages({
    path: '/web',
    requirement: Requirement.LOGIN,
    loadModule: () => import('../components/pages/Web/index'),
  })

  static HOME = new Pages({
    // Users on mobile web can't access dashboard until chat onboarding is complete, because
    // they finish the onboarding flow on /coach-chat
    requirement: isMobile() ? Requirement.CHAT_ONBOARDING : Requirement.ONBOARDING,
    loadModule: () => import('../components/pages/Dashboard'),
    title: ASYNC_PAGE_TITLE,
    icon: { type: 'Home' },
    analyticsPath: 'home',
  })

  static CONSENT_TO_TREAT = new Pages({
    loadModule: () => import('../components/pages/ConsentToTreat'),
    title: 'Consent To Treat',
    path: '/consent-to-treat',
    exact: true,
  })

  static VIDEO_HELPER = new Pages({
    loadModule: () => import('../components/pages/VideoHelper'),
    title: 'Video Session Tips',
    path: '/video-helper',
    exact: true,
  })

  static EXPLORE = new Pages({
    loadModule: () => import('../components/pages/Explore'),
    title: ASYNC_PAGE_TITLE,
    icon: { type: 'Search' },
    predicate: allowExploreAccess,
    analyticsPath: 'explore',
  })

  static FIND_CARE = new Pages({
    loadModule: () => import('../components/pages/FindCare'),
    icon: { type: 'Clipboard' },
    title: ASYNC_PAGE_TITLE,
    analyticsPath: 'find-care',
    predicate: allowSelfCareOnly,
  })

  static FAVORITES = new Pages({
    loadModule: () => import('../components/pages/Favorites'),
    title: ASYNC_PAGE_TITLE,
  })

  static ARTICLES = new Pages({
    loadModule: () => import('../components/pages/Articles'),
    path: '/articles/:articleId',
    title: ASYNC_PAGE_TITLE,
  })

  static NOT_FOUND = new Pages({
    loadModule: () => import('../components/pages/NotFound'),
  })

  static GET_THE_APP = new Pages({
    loadModule: () => import('../components/pages/AppStoreRedirect'),
  })

  static _ = this.closeEnum()

  // Example: "all-sessions" => Pages.ALL_SESSIONS
  static getPageBySlug (slug) {
    const enumKey = slug.toUpperCase().replace(/-/g, '_')

    return Pages.enumValueOf(enumKey)
  }

  static getPageAtLocation ({ pathname }, exact = false, params) {
    const path = pathname.split('/')

    if (exact) {
      return Pages.enumValues.find(page => {
        if (!page.path) return null
        if (!page.path.includes(':')) return page.path === pathname

        return page.path.split('/')
          .map((slug) => {
            const slugWithoutColon = slug.replace(':', '')
            if (params && Object.prototype.isPrototypeOf.hasOwnProperty.call(params, slugWithoutColon)) {
              page._path = pathname
              return params[slugWithoutColon]
            }

            return slug
          })
          .join('/') === pathname
      })
    }

    return Pages.enumValues.find(page => page.path && page.path.split('/')[1] === path[1])
  }

  // TODO (NJC): Document, simplify, consolidate
  constructor ({
    allowedRoles,
    requirement,
    exact,
    path,
    loadModule,
    renderPage,
    renderLink,
    predicate,
    label,
    title,
    i18nKey,
    slug,
    redirect,
    themeOverride,
    icon,
    analyticsPath,
  }) {
    super()
    this._allowedRoles = allowedRoles
    this._requirement = requirement
    this._exact = exact
    this._path = path
    this._loadModule = loadModule
    this._renderPage = renderPage
    this._renderLink = renderLink
    this._predicate = predicate
    this._label = label
    this._title = title
    this._i18nKey = i18nKey
    this._slug = slug
    this._redirect = redirect
    this._themeOverride = themeOverride
    this._icon = icon
    this._analyticsPath = analyticsPath
  }

  get route () {
    if (this._predicate != null && !this._predicate()) return null
    return (
      <Route
        exact={this._exact}
        path={this.path}
        key={this.enumKey}
        render={this.renderDelayedPage}
      />
    )
  }

  get path () {
    return isFunction(this._path)
      ? this._path()
      : (this._path || `/${kebabCase(this.enumKey)}`)
  }

  get i18nKey () {
    return this._i18nKey || camelize(this.enumKey.toLowerCase())
  }

  get label () {
    if (isFunction(this._label)) return this._label()
    else if (!isEmpty(this._label)) return this._label
    return i18next.t(`pages.${this.i18nKey}.label`)
  }

  get title () {
    return isFunction(this._title)
      ? this._title()
      : this._title
  }

  get predicate () {
    return isFunction(this._predicate)
      ? this._predicate()
      : this._predicate
  }

  get metaDescription () {
    const key = `pages.${this.i18nKey}.meta`
    return i18next.exists(key) ? i18next.t(key) : null
  }

  get slug () {
    return this._slug
  }

  get themeOverride () {
    return this._themeOverride
  }

  get allowedRoles () {
    return this._allowedRoles
  }

  get requirement () {
    return this._requirement
  }

  get icon () {
    return this._icon
  }

  get analyticsPath () {
    return this._analyticsPath
  }

  buildPath = query => {
    if (query == null) return this.path
    let search = isString(query) ? query : queryString.stringify(query)
    if (!search.startsWith('?')) search = `?${search}`
    return `${this.path}${search}`
  }

  go = query => HistoryService.push(this.buildPath(query))

  redirect = (query, push = false) => <Redirect to={this.buildPath(query)} push={push} />

  renderDelayedPage = () => (
    <DelayedPage
      requirement={this._requirement}
      allowedRoles={this.allowedRoles}
      load={() => loadPage({
        redirect: this._redirect,
        loadModule: this._loadModule,
        renderPage: this._renderPage || (PageComponent => <PageComponent />),
      })}
    />
  )

  renderHelmet = () => <JoyableHelmet meta={this.metaDescription} />

  renderLink ({ className = '', label = this.label, key, role, render } = {}) {
    if (this._renderLink == null) {
      if (render != null) {
        return render({ path: this.path, role, label })
      }
      return <Link key={key} to={this.path} className={className} role={role}>{label}</Link>
    } else {
      return this._renderLink.apply(this, arguments)
    }
  }

  renderButton = ({
    className,
    size = 'regular',
    theme = 'black',
    label = this.label,
  } = {}) => (
    <Link
      to={this.path}
      onClick={() => { analytics.trackButtonClick(label) }}
      className={`${className}Wrapper`}
    >
      <JoyableButton {...{ className, size, theme, label }} />
    </Link>
  )

  renderIcon = (isActive) => {
    if (!this._icon) return null
    return (
      <Icon
        data-testid='nav-item-icon'
        type={this._icon.type}
        color={isActive ? 'primary600' : 'gray600'}
        size={this._icon.size || 22}
        outlined={!isActive}
        aria-hidden={true}
      />
    )
  }
}

// The shape required for Pages.getPageAtLocation
export const LOCATION_SHAPE = PropTypes.shape({
  pathname: PropTypes.string.isRequired,
})

export const PAGE_SHAPE = PropTypes.instanceOf(Pages)

// A convenience HOC to attach the current instance of the Pages enum to props.
export const withCurrentPage = Component => {
  // eslint-disable-next-line react/prop-types
  const C = props => <Component page={Pages.getPageAtLocation(props.location)} {...props} />
  Object.assign(C, {
    displayName: `withCurrentPage(${Component.displayName || Component.name})`,
    propTypes: { location: LOCATION_SHAPE },
    get WrappedComponent () {
      return Component.WrappedComponent || Component
    },
  })

  return withRouter(hoistStatics(C, Component))
}

async function loadPage ({ redirect, loadModule, renderPage }) {
  const [{ default: PageComponent }, redirectResult] = await Promise.all([
    loadModule(),
    redirect?.() || Promise.resolve(null),
  ])

  return redirectResult || renderPage(PageComponent)
}
