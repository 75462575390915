import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'

const MODEL = new ApiModel('ACCOUNT_PREP', 'api/v1/account_prep')

const SHAPE = PropTypes.shape({
  login_url: PropTypes.string.isRequired,
  prep_token: PropTypes.string.isRequired,
})

const createAccountPrep = ({ user, profile, program, promotion }) =>
  MODEL.create({ user, profile, program, promotion }, { ignoreFailure: true })

const clearPrepLoginURL = () => ApiModel.pruneCache('account_prep')

export const AccountPrep = {
  connection: {
    selector: createSelector(
      [
        state => state.api.account_prep,
        MODEL.isCreatingSelector(),
        state => MODEL.getCreateErrorMessage(state),
      ],
      (accountPrep, accountPrepCreating, createFailedMessage) => ({
        accountPrep,
        accountPrepCreating,
        createFailedMessage,

        createAccountPrep,
        clearPrepLoginURL,
      }),
    ),

    shape: {
      createAccountPrep: PropTypes.func.isRequired,
      clearPrepLoginURL: PropTypes.func.isRequired,

      accountPrep: SHAPE,
      accountPrepCreating: PropTypes.bool,
      createFailedMessage: PropTypes.string,
    },
  },
}
