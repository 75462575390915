import get from 'lodash/get'
import startCase from 'lodash/startCase'
import moment from 'moment-timezone'
import { CALL_MEDIUM_LABELS } from '../../../../app/js/constants'
import { createSelector } from 'reselect'
import { selectCallStatuses } from '../models/CallStatus'
import { selectMessages } from '../models/Message'
import { selectProfiles } from '../models/Profile'
import { selectQuickFacts } from '../models/QuickFacts'
import { selector as userSelector, selectUsers } from '../models/User'
import { selectCoaches } from '../models/Coach'

// Note: Creating selectors repeatedly for a given user doesn't get any memoization benefit. It is
// expected that the selectors created here will be encapsulated in selectors that are kept around in
// a cache of some kind. That is currently handled in Worklist.js's rowConnection.

const selectProfileForUser = userId => createSelector(
  [userSelector(userId), selectProfiles],
  (user, profiles) => user && profiles && profiles[user.profile_id],
)

const selectCallStatusForUser = userId => createSelector(
  [userSelector(userId), selectCallStatuses],
  (user, callStatuses) => user && callStatuses && callStatuses[user.call_status_id],
)

const selectLatestMessageForUser = userId => createSelector(
  [userSelector(userId), selectMessages],
  (user, messages) => user && user.latest_incoming_message_id &&
    messages && messages[user.latest_incoming_message_id],
)

const selectName = userId => createSelector(
  [selectProfileForUser(userId)],
  profile => profile && `${profile.first_name} ${profile.last_name}`,
)

const selectCalls = userId => createSelector(
  [userSelector(userId)],
  (user) => {
    if (user == null || user.calls_completed == null) return null
    return (
      <span className={(user.coaching_sessions_count - user.calls_completed <= 2) ? 'fadedOrange' : null}>
        {`${user.calls_completed}/${user.coaching_sessions_count} calls`}
      </span>
    )
  },
)

const selectId = userId => () => `${userId}`

const selectCallTimeToday = userId => createSelector(
  [selectCallStatusForUser(userId)],
  callStatus => {
    if (callStatus == null || callStatus.scheduled_for == null) return null

    const time = moment(callStatus.scheduled_for).tz(moment.tz.guess())
    if (time.format('mm') === '00') return time.format('h')
    else return time.format('h:mm')
  },
)

const selectCallType = userId => createSelector(
  [userSelector(userId), selectCallStatusForUser(userId)],
  (user, callStatus) => {
    if (user == null || callStatus == null) return null

    if (user.program_level === 'concierge') return 'Concierge'

    let callType = callStatus.call_type === 'kickoff' ? ' - KO' : ''
    if (user.escalated_at != null) callType = ' - ESC'
    return `${startCase(user.program_indication)}${callType}`
  },
)

const selectPartner = userId => createSelector(
  [userSelector(userId)],
  user => get(user, ['campaign_detail', 'company_name'], 'Consumer'),
)

// Gets the type of the scheduled session
const selectCallMedium = userId => createSelector(
  [selectCallStatusForUser(userId)],
  (callStatus) => CALL_MEDIUM_LABELS[callStatus?.medium] ?? 'Unknown',
)

const selectEscalatedAt = userId => createSelector(
  [userSelector(userId)],
  user => user?.escalated_at && formatFromNow(user.escalated_at, true),
)

const selectLastContactedAt = userId => createSelector(
  [userSelector(userId)],
  user => user?.last_contacted_at && formatFromNow(user.last_contacted_at, true),
)

const selectClaimStatus = userId => createSelector(
  [userSelector(userId), selectCoaches],
  (user, coaches) => {
    if (user.escalation_reviewer_id == null) return 'Unclaimed'

    return get(coaches, [user.escalation_reviewer_id, 'first_name'])
  },
)

const PROGRAM_ABBREVIATIONS = {
  concierge: 'Conc',
  social_anxiety: 'SA',
  depression: 'Dep',
  generalized_anxiety: 'GAD',
}

const programAbbreviation = user => {
  if (user == null) return null
  if (user.program_level === 'concierge') return PROGRAM_ABBREVIATIONS.concierge

  const abbreviation = PROGRAM_ABBREVIATIONS[user.program_indication]
  // a little future proofing so we don't have to remember this code when we add indications.
  return abbreviation || startCase(user.program_indication)
}
const selectProgram = userId => createSelector(
  [userSelector(userId)],
  user => programAbbreviation(user),
)

// Depend on this selector to force an update of another selector every 10 seconds (to update
// time info, for instance.
const selectMinutes = state => state.timeInfo.minutes

// Custom logic for our own "from now" formatting.
export const formatFromNow = (time, ago = false) => {
  const agoString = ago ? ' ago' : ''

  const parsed = moment(time)
  if (parsed.isBefore(moment().subtract(1, 'day'))) {
    return moment.duration(moment().diff(parsed)).humanize() + agoString
  } else if (parsed.isBefore(moment().subtract(12, 'hours'))) {
    return '12+ hr' + agoString
  } else if (parsed.isBefore(moment().subtract(6, 'hours'))) {
    return '6+ hr' + agoString
  } else if (parsed.isBefore(moment().subtract(3, 'hours'))) {
    return '3+ hr' + agoString
  } else if (parsed.isBefore(moment().subtract(2, 'hours'))) {
    return '2+ hr' + agoString
  } else if (parsed.isBefore(moment().subtract(1, 'hours'))) {
    return '1+ hr' + agoString
  } else if (parsed.isBefore(moment().subtract(30, 'minutes'))) {
    return '30+ min' + agoString
  } else if (parsed.isBefore(moment().subtract(10, 'minutes'))) {
    return '10+ min' + agoString
  } else {
    return 'Just now'
  }
}

const selectQuickFactsBody = userId => createSelector(
  [selectQuickFacts(userId)],
  quickFacts => quickFacts && quickFacts.body,
)

const selectLatestMessageTime = userId => createSelector(
  [selectMinutes, selectLatestMessageForUser(userId)],
  (minutes, message) => message && formatFromNow(message.sent_at),
)

const selectLatestMessageBody = userId => createSelector(
  [selectLatestMessageForUser(userId)],
  message => message && message.body,
)

const selectUserCoachName = userId => createSelector(
  [userSelector(userId), selectCoaches],
  (user, coaches) => user && get(coaches, [user.coach_id, 'first_name']),
)

const sortDataSelector = (
  modelSelector, modelField = null, modelUserIdField = 'user_id',
) => itemsSelector => createSelector(
  [itemsSelector, modelSelector],
  (worklistItems, models) => models == null || worklistItems == null
    ? { }
    : Object
      .values(models)
      // get the models for users that are in this worklist
      .filter(model =>
        worklistItems.find(({ user_id: userId }) => userId === model[modelUserIdField]) != null,
      )
      // reduce to a map of user_id -> value for sorting
      .reduce((values, model) => ({
        ...values,
        [model[modelUserIdField]]: modelField == null ? model : model[modelField],
      }), { }),
)

const idSortSelector = itemsSelector => createSelector(
  [itemsSelector],
  worklistItems => worklistItems.reduce((ids, { user_id: id }) => ({ ...ids, [id]: id }), { }),
)

const programAbbreviationSortSelector = itemsSelector => createSelector(
  [sortDataSelector(selectUsers, null, 'id')(itemsSelector)],
  users => Object.keys(users).reduce((programs, userId) => ({
    ...programs,
    [userId]: programAbbreviation(users[userId]),
  }), { }),
)

const coachSortSelector = itemsSelector => createSelector(
  [sortDataSelector(selectUsers, null, 'id')(itemsSelector), selectCoaches],
  (users, coaches) => Object.keys(users).reduce((coachNames, userId) => ({
    ...coachNames,
    [userId]: get(coaches, [users[userId].coach_id, 'first_name']),
  }), { }),
)

const claimStatusSortSelector = itemsSelector => createSelector(
  [sortDataSelector(selectUsers, null, 'id')(itemsSelector), selectCoaches],
  (users, coaches) => Object.keys(users).reduce((reviewerNames, userId) => ({
    ...reviewerNames,
    [userId]: get(coaches, [users[userId].escalation_reviewer_id, 'first_name'], 'Unclaimed'),
  }), { }),
)

const momentSortDataSelector = (
  modelSelector, modelField, modelUserIdField = 'user_id',
) => itemsSelector => createSelector(
  [sortDataSelector(modelSelector, modelField, modelUserIdField)(itemsSelector)],
  values => Object.keys(values).reduce((moments, userId) => ({
    ...moments,
    [userId]: values[userId] && moment(values[userId]),
  }), { }),
)

const latestMessageSentMomentSortDataSelector = itemsSelector => createSelector(
  [itemsSelector, selectUsers, selectMessages],
  (worklistItems, users, messages) =>
    users == null || messages == null || worklistItems == null
      ? { }
      : Object
        .values(messages)
        // Get just the latest messages
        .filter(({ id: messageId }) =>
          Object.values(users).find(({ latest_incoming_message_id: lastIncomingMessageId }) =>
            lastIncomingMessageId === messageId) != null,
        )
        // Get the latest messages for the users who are in this worklist
        .filter(({ user_id: messageUser }) =>
          worklistItems.find(({ user_id: userId }) => userId === messageUser) != null,
        )
        // reduce to a map of user_id -> sent_at moment for sorting
        .reduce((moments, { user_id: userId, sent_at: sentAt }) => ({
          ...moments,
          [userId]: sentAt && moment(sentAt),
        }), { }),
)

const dateCompare = (ascending, sortData) => ({ user_id: userA }, { user_id: userB }) => {
  const momentA = sortData[userA]
  const momentB = sortData[userB]

  let result
  if (momentA == null && momentB == null) result = 0
  else if (momentA == null) result = 1
  else if (momentB == null) result = -1
  else result = momentA.isBefore(momentB) ? -1 : (momentB.isBefore(momentA) ? 1 : 0)

  return ascending ? result : result * -1
}

/**
 * The structure for columns:
 * label <String>: A label for the column header row.
 * type <String>: A type indicator for this column. Typically used to adjust the space required
 *   in CSS.
 * createSelector: A method that, given a userId, will return a selector that can fill a cell for
 *   this column for that user.
 */
const nameColumn = { label: 'Name', type: 'name', createSelector: selectName }
const callMediumColumn = { label: 'Call Type', type: 'callMedium', createSelector: selectCallMedium }
const idColumn = { label: 'Id', type: 'id', createSelector: selectId }
const actionColumn =
  (label, action) => ({ label, type: 'todo', createSelector: () => () => action })
const callTimeTodayColumn =
  { label: 'Time', type: 'callTimeToday', createSelector: selectCallTimeToday }
const callTypeColumn = { label: 'Type', type: 'callType', createSelector: selectCallType }
const partnerColumn = { label: 'Partner', type: 'partner', createSelector: selectPartner }
const programColumn = { label: 'Program', type: 'program', createSelector: selectProgram }
const escalatedAtColumn = { label: 'Escalated At', type: 'escalatedAt', createSelector: selectEscalatedAt }
const quickFactsColumn =
  { label: 'Quick facts', type: 'quickFacts', createSelector: selectQuickFactsBody }
const latestMessageTimeColumn =
  { label: 'Time', type: 'latestMessageTime', createSelector: selectLatestMessageTime }
const latestMessageBodyColumn =
  { label: 'New message', type: 'latestMessageBody', createSelector: selectLatestMessageBody }
const coachNameColumn = { label: 'Coach', type: 'coachName', createSelector: selectUserCoachName }
const lastContactedAtColumn =
  { label: 'Last Contact', type: 'lastContactedAt', createSelector: selectLastContactedAt }
const claimStatusColumn =
  { label: 'Claim Status', type: 'claimStatus', createSelector: selectClaimStatus }
const callsColumn =
  { label: 'Calls', type: 'calls', createSelector: selectCalls }

/**
 * Make a column sortable
 *
 * @param column The base column definition
 * @param defaultSort When sorting a worklist by default sort, the first column found with
 * defaultSort = true will be used. If none is found, the defaultSort member on the worklist
 * definition is used. If that is null, the list is left unsorted
 * @param defaultAscending When using this column for default sort, this field controls the sort
 * direction. It also controls the first direction in use when a column header is clicked.
 * @param compare The comparator generator function, which returns a function suitable for
 * Array.sort that takes worklist items. Is passed the sort direction and sortData.
 * @param createSortDataSelector The function to generate a selector for this column's sort data.
 */
const sortable = (
  column,
  {
    defaultSort = false,
    defaultAscending = true,
    compare = null,
    createSortDataSelector,
  },
) => ({
  ...column,
  sortingEnabled: true,
  defaultSort,
  defaultAscending,
  compare,
  createSortDataSelector,
})

export const WorklistStructure = {
  escalations_review: {
    nav: 'Escalations Review',
    showUnreadIndicator: true,
    title: 'Resolve Escalations',
    columns: [
      sortable(nameColumn, {
        createSortDataSelector: sortDataSelector(selectProfiles, 'first_name'),
      }),
      sortable(idColumn, {
        createSortDataSelector: idSortSelector,
      }),
      sortable(programColumn, {
        createSortDataSelector: programAbbreviationSortSelector,
      }),
      sortable(escalatedAtColumn, {
        defaultSort: true,
        createSortDataSelector: momentSortDataSelector(selectUsers, 'escalated_at', 'id'),
        compare: dateCompare,
        defaultAscending: false,
      }),
      quickFactsColumn,
      sortable(coachNameColumn, {
        createSortDataSelector: coachSortSelector,
      }),
      sortable(lastContactedAtColumn, {
        createSortDataSelector: momentSortDataSelector(selectUsers, 'last_contacted_at', 'id'),
        compare: dateCompare,
        defaultAscending: false,
      }),
      sortable(claimStatusColumn, {
        createSortDataSelector: claimStatusSortSelector,
      }),
    ],
  },

  scheduled_calls: {
    nav: 'Today’s clients',
    showUnreadIndicator: true,
    icon: 'coachingPlant',
    includeToday: true,
    title: 'Coach your clients',
    columns: [
      callTimeTodayColumn,
      callMediumColumn,
      callsColumn,
      nameColumn,
      partnerColumn,
      callTypeColumn,
    ],
    defaultSort: {
      createSortDataSelector: momentSortDataSelector(selectCallStatuses, 'scheduled_for'),
      compare: dateCompare,
    },
  },

  escalations: {
    nav: 'Escalations',
    showUnreadIndicator: true,
    title: 'Resolve Escalations',
    columns: [
      nameColumn,
      programColumn,
      escalatedAtColumn,
      quickFactsColumn,
    ],
    defaultSort: {
      createSortDataSelector: momentSortDataSelector(selectUsers, 'escalated_at', 'id'),
      compare: dateCompare,
      ascending: false,
    },
  },

  messages: {
    nav: 'Messages',
    title: 'Reply to messages',
    columns: [
      actionColumn('Action', 'Reply'),
      latestMessageTimeColumn,
      nameColumn,
      latestMessageBodyColumn,
    ],
    defaultSort: {
      createSortDataSelector: latestMessageSentMomentSortDataSelector,
      compare: dateCompare,
      ascending: false,
    },
    actions: ['done'],
  },
}
