import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import { registerModelReducer } from '../reducers'
import ApiModel from '../ApiModel'
import { selectorCache } from '../util/selectorCache'
import { getUserModelId, parseQuery, selectModelStore } from './utilities'

const MODEL = new ApiModel('CONNECT_REPORT', 'api/v1/connect_reports')

registerModelReducer('connectReports', (state = { }, { type, url, response }) => {
  if (type === Action.READ.successType(MODEL) || (type === Action.READ.failureType(MODEL) && response.status === 404)) {
    const { user_id: userId } = parseQuery(url)
    return { ...state, [userId]: true }
  }

  return state
})

const SHAPE = PropTypes.shape({
  id: PropTypes.number,
  user_id: PropTypes.number,
  dplus_disqualifiers: PropTypes.object,
})

const selectAvailableConnection = selectorCache(
  ({ userId }) => userId,
  ({ userId }) => {
    if (userId == null) return () => ({ })
    return createSelector(
      [
        getUserModelId(userId, 'connect_reports'),
        selectModelStore('connect_reports'),
        state => state.models.connectReports[userId],
      ],
      (connectReportId, connectReports, loaded) => {
        if (connectReports == null || connectReportId == null) return { loaded }
        const connectReport = connectReports[connectReportId]
        return {
          connectReport,
          loaded,
        }
      },
    )
  },
)

export const ConnectReport = {
  SHAPE,
  connection: {
    load: ({ loaded, connectReport }, { userId }) => {
      if (!loaded || connectReport === null) {
        MODEL.read({ query: { user_id: userId }, modelMapKey: ['user_id'] })
      }
    },

    isLoaded: ({ loaded }) => loaded,

    selector: selectAvailableConnection,

    shape: {
      connectReport: PropTypes.object,
      loaded: PropTypes.bool,
    },
  },
}
