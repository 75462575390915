import { useCallback, useMemo } from 'react'
import { User } from 'joyable-js-api'
import { useConnection } from '@app/js/lib/useConnection'
import { getMockMultipleProvidersAvailabilityQuery } from './multipleProvidersAvailabilityQuery'
import { getMockIcAppointmentMutation } from './icAppointmentMutation'
import { formatProviderAvailabilities } from '../EnableToApi'
import { useMutation, useQuery } from 'react-query'

// The mock hook will be used in the EnableToApi.
// All functions that will be used later should be added and mocked here.
export const useEnableToMockApi = () => {
  const [, { me }] = useConnection(User.meConnection)
  const insuranceMemberId = me?.insurance_member_id

  return useMemo(() => {
    return {
      useMultipleProvidersAvailabilityQuery ({ slotMinutes, daysOut, timezone }, options = {}) {
        const select = useCallback(({ data }) => {
          return formatProviderAvailabilities(data?.attributes?.users, timezone)
        }, [timezone])
        return useQuery([slotMinutes, daysOut], async () => {
          return getMockMultipleProvidersAvailabilityQuery(daysOut, slotMinutes, timezone, insuranceMemberId)
        }, { select })
      },
      useIcAppointmentMutation (options = {}) {
        return useMutation(() => {
          return getMockIcAppointmentMutation(insuranceMemberId)
        }, options)
      },
      useProfile () {
        return useQuery('profile', async () => {
          return {
            first_name: 'First',
            last_name: 'Last',
          }
        })
      },
      useTreatmentSessionsQuery (treatmentId, options = {}) {
        return useQuery([treatmentId], async () => {
          return {}
        }, {})
      },
    }
  }, [insuranceMemberId])
}
