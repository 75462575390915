import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'
import { ProgramActivity } from './ProgramActivity'
import { loadMe as loadMeUser, User } from './User'
import { selectorCache } from '../util/selectorCache'
import { getUserModelId, selectModelStore } from './utilities'
import awaitState from '../awaitState'
import awaitConnection from '../util/awaitConnection'

const MODEL = new ApiModel('USER_PROGRAM', 'api/v1/user_programs')

const SHAPE = PropTypes.shape({
  id: PropTypes.number,
  slug: PropTypes.string,
  level: PropTypes.string,
  indication: PropTypes.string,
  choice: PropTypes.string,
  stage_choices: PropTypes.arrayOf(PropTypes.string),
  recommended_standard_program: PropTypes.string,
  recommended_standard_indication: PropTypes.string,
})

function recalculateProgram () {
  MODEL.create({}, { query: { user_id: 'me' } })
  ApiModel.pruneCache('user_programs')
  awaitState(state => !MODEL.isCreating(state, { query: { user_id: 'me' } }))
    .then(() => {
      ProgramActivity.invalidateCache()
      loadMeUser()
    })
}

function update (userProgram, userId = 'me') {
  MODEL.updateModify({ user_program: userProgram }, { query: { user_id: userId } })
  ApiModel.pruneCache('user_programs')
  awaitState(state => !MODEL.isUpdating(state, { query: { user_id: userId } }))
    .then(ProgramActivity.invalidateCache)
}

const updateWithRefresh = userProgram => {
  update(userProgram)
  awaitState(state => !MODEL.isUpdating(state, { query: { user_id: 'me' } }))
    .then(() => {
      loadMeUser()
    })
}

const updateProgramPeriod = (userProgram, newPeriod, userId = 'me') =>
  update({ ...userProgram, period: newPeriod }, userId)
const advanceProgram = userProgram =>
  updateProgramPeriod(userProgram, userProgram.period + 1)

const load = (userId = 'me') => MODEL.read({
  query: { user_id: userId },
  modelMapKey: ['user_id', userId],
})

const select = state => state.api.user_programs && Object.values(state.api.user_programs)[0]

const getUserId = ({ userId, user }) => user == null ? userId : user.id

const selectUserConnection = selectorCache(
  getUserId,
  props => {
    const userId = getUserId(props)
    if (userId == null) return () => ({ })

    return createSelector(
      [
        getUserModelId(userId, 'user_programs'),
        selectModelStore('user_programs'),
      ],
      (userProgramId, userPrograms) => {
        if (userPrograms == null || userProgramId == null) return {}
        const userProgram = userPrograms[userProgramId]
        return {
          userProgram,
          updateProgramPeriod: (period) => updateProgramPeriod(userProgram, period, userId),
        }
      },
    )
  },
)

const selectUserProgramByPractitionerConnection = selectorCache(
  getUserId,
  props => {
    const userId = getUserId(props)
    if (userId == null) return () => ({})

    return createSelector(
      [
        getUserModelId(userId, 'user_programs'),
        selectModelStore('user_programs'),
        state => MODEL.getReadErrorMessage(state, { query: { user_id: userId } }),
      ],
      (userProgramId, userPrograms, userProgramError) => {
        if (userProgramError) return { userProgramError }
        if (userPrograms == null || userProgramId == null) return {}
        const userProgram = userPrograms[userProgramId]
        return {
          userProgram,
          updateProgramPeriod: (period) => updateProgramPeriod(userProgram, period, userId),
        }
      },
    )
  },
)

const selectConnection = createSelector(
  [
    select,
    MODEL.isNotFoundSelector({ query: { user_id: 'me' } }),
    MODEL.isUpdatingSelector({ query: { user_id: 'me' } }),
    MODEL.isCreatingSelector({ query: { user_id: 'me' } }),
  ],
  (userProgram, userProgramIsNotFound, userProgramIsUpdating, userProgramIsCreating) => ({
    userProgram,
    userProgramIsNotFound,

    userProgramIsSaving: userProgramIsUpdating || userProgramIsCreating,

    updateUserProgramChoice: choice => update({ slug: userProgram.slug, choice }),
    recalculateProgram,
    switchProgram: ({ slug, level, indication }) => updateWithRefresh({ slug, level, indication }),
    advanceProgram: () => advanceProgram(userProgram),
  }),
)

async function awaitProgramChange () {
  await awaitConnection(User.meConnection, null, ({ meIsLoading }) => meIsLoading)
  await awaitConnection(User.meConnection, null, ({ meIsLoading }) => !meIsLoading)
}

export const UserProgram = {
  SHAPE,
  invalidateCache: () => ApiModel.pruneCache('user_programs'),

  // Program changes also reload the user, so for convenience this method is provided to wait
  // for the user reload. This is only provided because of the complex nature of user program
  // changes and should be considered an anti-pattern
  awaitProgramChange,

  connection: {
    load: ({ userProgram, userProgramIsNotFound, userProgramIsSaving }) => {
      if (userProgram == null && !userProgramIsNotFound && !userProgramIsSaving) load()
    },

    isLoaded: ({ userProgram, userProgramIsNotFound }) =>
      userProgram != null || userProgramIsNotFound,

    selector: selectConnection,

    shape: {
      userProgram: SHAPE,
      userProgramIsNotFound: PropTypes.bool.isRequired,
      userProgramIsSaving: PropTypes.bool.isRequired,

      updateUserProgramChoice: PropTypes.func.isRequired,
      recalculateProgram: PropTypes.func.isRequired,
      switchProgram: PropTypes.func.isRequired,
      advanceProgram: PropTypes.func.isRequired,
    },
  },

  coachConnection: {
    load: ({ userProgram }, ownProps) => {
      const userId = getUserId(ownProps)
      if (userProgram == null && userId != null) {
        load(userId)
      }
    },

    isLoaded: ({ userProgram }) => userProgram != null,

    selector: selectUserConnection,

    shape: {
      userProgram: SHAPE,
      updateProgramPeriod: PropTypes.func,
    },
  },
  practitionerConnection: {
    load: ({ userProgram }, ownProps) => {
      const userId = getUserId(ownProps)
      if (userProgram == null && userId != null) {
        load(userId)
      }
    },

    isLoaded: ({ userProgram, userProgramError }) => {
      return userProgram != null || userProgramError != null
    },

    selector: selectUserProgramByPractitionerConnection,

    shape: {
      userProgram: SHAPE,
      updateProgramPeriod: PropTypes.func,
    },
  },
}
