import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'
import { selectorCache } from '../util/selectorCache'

const MODEL = new ApiModel('ASSESSMENT_SCORES', 'api/v1/surveys')

const SHAPE = PropTypes.shape({
  activity_count: PropTypes.number,
  scores: PropTypes.arrayOf(PropTypes.shape({
    num_activities: PropTypes.number,
    score: PropTypes.number,
    min_score_factor: PropTypes.string,
    max_score_factor: PropTypes.string,
  })),
})

const load = (assessment, timeRange) => MODEL.read(options(assessment, timeRange))

const options = (assessment, timeRange) => ({ id: `${assessment}/scores`, query: { time_range: timeRange } })

const recordSnapshot = (assessment, userActivitySlug = null) => {
  return MODEL.create({ activity_slug: userActivitySlug }, { id: `${assessment}/scores` })
}

const select = assessment => state => state.api.scores && state.api.scores[assessment]

export const AssessmentScores = {
  /**
   * model connection { assessment, createSnapshot, activitySlug }
   *  - If createSnapshot is true, the connection will prune the store and ask the server to create
   *    a snapshot on mount. Defaults false.
   *  - If activitySlug is specified, it is passed to the server on the recordSnapshot call.
   */
  connection: {
    onMount: ({ assessment, createSnapshot, activitySlug }) => {
      ApiModel.pruneCache('scores')
      if (createSnapshot) {
        recordSnapshot(assessment, activitySlug)
        return false
      }
    },

    load: ({ assessmentScores }, { assessment, createSnapshot, timeRange }) => {
      if (assessmentScores == null && createSnapshot !== true) load(assessment, timeRange)
    },

    isLoaded: ({ assessmentScores }) => assessmentScores != null,

    selector: selectorCache(
      ({ assessment }) => assessment,
      ({ assessment }) => createSelector(
        [select(assessment)],
        assessmentScores => ({ assessmentScores }),
      ),
    ),

    shape: {
      assessmentScores: SHAPE,
    },
  },
}
