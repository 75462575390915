import moment from 'moment-timezone'

/*
  Params:
  useE2Mock[true,false]
  psrProviders[multiple, single, none]
  psrAvailabilityTimeSlots = number
 */
export const getMockMultipleProvidersAvailabilityQuery = (days, slotMinutes, timezone, insuranceMemberId) => {
  // urlParams work here because our format is similar to a URL
  const urlParams = new URLSearchParams(insuranceMemberId)
  const providersParam = urlParams.get('psrProviders')
  const slotsParam = urlParams.get('psrAvailabilityTimeSlots') ?? SLOTS_PER_DAY
  switch (providersParam) {
    case 'none':
      return {
        data: { attributes: { users: generateData(days, slotMinutes, timezone, slotsParam).slice(0, 0) } },
      }
    case 'single':
      return {
        data: { attributes: { users: generateData(days, slotMinutes, timezone, slotsParam).slice(0, 1) } },
      }
    default:
      return {
        data: { attributes: { users: generateData(days, slotMinutes, timezone, slotsParam) } },
      }
  }
}

const SLOTS_PER_DAY = 10
const SLOTS_FOR_DAYS = []
const PROVIDERS = [
  {
    id: 6939931,
    first_name: 'Anakin',
    last_name: 'Skywalker',
    license: 'LCSW',
    gender: 'M',
    languages: 'English, Spanish',
    experience: '10 years',
    photos: [
      {
        url: 'https://storage.googleapis.com/ableto-practitioner-photos-dev-approved/28ad5d7a-f2fd-4c87-bc1c-e8bc883769b3.png',
        active: true,
      },
    ],
    biography: {
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      active: true,
    },
  },
  {
    id: 2162283,
    first_name: 'Padmé',
    last_name: 'Amidala',
    license: 'LCPC',
    gender: 'F',
    languages: 'English',
    experience: '12 years',
    photos: [
      {
        url: 'https://storage.googleapis.com/ableto-practitioner-photos-dev-approved/28ad5d7a-f2fd-4c87-bc1c-e8bc883769b3.png',
        active: false,
      },
    ],
    biography: {
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      active: false,
    },
  },
  {
    id: 2162280,
    first_name: 'Obi-Wan',
    last_name: 'Kenobi',
    license: 'LCPC',
    gender: 'M',
    languages: 'English',
    experience: '20 years',
  },
  {
    id: 2162286,
    first_name: 'Ahsoka',
    last_name: 'Tano',
    license: 'LCSW',
    gender: 'F',
    languages: 'English',
    experience: '5 years',
    photos: [
      {
        url: 'https://storage.googleapis.com/ableto-practitioner-photos-dev-approved/28ad5d7a-f2fd-4c87-bc1c-e8bc883769b3.png',
        active: true,
      },
    ],
    biography: {
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      active: true,
    },
  },
  {
    id: 2162281,
    first_name: 'Qui-Gon',
    last_name: 'Jinn',
    license: 'LCSW',
    gender: 'M',
    languages: 'English, Spanish',
    experience: '30 years',
  },
  {
    id: 2162288,
    first_name: 'Mace',
    last_name: 'Windu',
    license: 'LCSW',
    gender: 'M',
    languages: 'English',
    experience: '22 years',
  },
  {
    id: 2162284,
    first_name: 'Leia',
    last_name: 'Skywalker',
    license: 'LCPC',
    gender: 'F',
    languages: 'English, Spanish',
    experience: '7 years',
  },
]

const generateData = (days, slotMinutes, timezone, slotsParam = SLOTS_PER_DAY) => {
  return PROVIDERS.map(provider => {
    return {
      ...provider,
      slots: generateSlots(days, slotMinutes, timezone, slotsParam),
    }
  })
}

const generateSlots = (days, slotMinutes, timezone, slotsParam) => {
  if (SLOTS_FOR_DAYS.length !== 0) { return SLOTS_FOR_DAYS }
  for (let day = 1; day <= days; day++) {
    const startTime = moment()
      .add(day, 'days')
      .set({ hours: 9, minutes: 0, second: 0, millisecond: 0 })
      .add(slotMinutes, 'minutes')
      .tz(timezone)
    const endTime = moment(startTime)
      .add(slotMinutes, 'minutes')
      .tz(timezone)

    for (let slotNumber = 0; slotNumber < slotsParam; slotNumber++) {
      SLOTS_FOR_DAYS.push(
        {
          period: {
            begin: startTime.toISOString(),
            end: endTime.toISOString(),
          },
        },
      )
      startTime.add(slotMinutes, 'minutes')
      endTime.add(slotMinutes, 'minutes')
    }
  }
  return SLOTS_FOR_DAYS
}
