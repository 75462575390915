import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel from '../ApiModel'

const MODEL = new ApiModel('SUPPORT_REQUEST', 'api/v1/support_requests',
  (json, response, fetch) => !(fetch.action === Action.CREATE && response.status === 201))

export const SupportRequest = {
  connection: {
    selector: createSelector(
      [
        MODEL.isCreatingSelector(),
        state => MODEL.getCreateErrorMessage(state),
      ],
      () => ({
        sendSupportRequest: async description =>
          await MODEL.create({ support_request: { request_description: description } }),
        clearSupportRequestFailure: () =>
          ApiModel.dispatch(Action.CREATE.getClearPendingAction(MODEL, MODEL.getUrl())),
      }),
    ),

    shape: {
      sendSupportRequest: PropTypes.func.isRequired,
      clearSupportRequestFailure: PropTypes.func.isRequired,
    },
  },
}
