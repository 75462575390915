import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel, { PRUNE_API_TREE } from '../ApiModel'
import { deleteIn, registerModelReducer } from '../reducers'
import { selectorCache } from '../util/selectorCache'
import { getSortedUserModelIds, invertPredicate, parseQuery, selectModelStore } from './utilities'
import awaitState from '../awaitState'

const MODEL = new ApiModel('TRANSACTION', 'admin/api/v1/transactions')

const SHAPE = PropTypes.shape({
  id: PropTypes.number.isRequired,
  amount: PropTypes.number,
  started_at: PropTypes.string,
  refunded_amount: PropTypes.number,
  braintree_transaction_id: PropTypes.string,
  status: PropTypes.string,
})

registerModelReducer('transactions', (state = { }, { type, url, pruneTransactions }) => {
  if (type === Action.READ.successType(MODEL)) {
    const { user_id: userId } = parseQuery(url)
    return { ...state, [userId]: true }
  } else if (type === PRUNE_API_TREE && pruneTransactions != null) {
    const { userId } = pruneTransactions
    return deleteIn(state, [userId])
  }

  return state
})

const refundTransaction = (transactionId, userId) => {
  const id = `${transactionId}/refund`
  MODEL.create({ }, { id, noCache: true })
  awaitState(invertPredicate(MODEL.isCreatingSelector({ id })))
    .then(() => {
      // trimming here will cause the connection to reload all transactions
      ApiModel.trimCache([], { additionalActionValues: { pruneTransactions: { userId } } })
    })
}

const selectConnection = selectorCache(
  ({ userId }) => userId,
  ({ userId }) => {
    if (userId == null) return () => ({ transactionLoaded: false })

    const modelIdsSelector = getSortedUserModelIds(userId, 'transactions')
    const selectTransactionsRefunding = createSelector(
      [modelIdsSelector, Action.CREATE.pendingSource],
      (ids, pending) => (ids && pending && ids
        .map(id => ({ id, url: MODEL.getUrl({ id: `${id}/refund` }) }))
        .filter(({ url }) => Action.isActing(pending[url]))
        .map(({ id }) => id)) || [],
    )

    return createSelector(
      [
        modelIdsSelector,
        selectModelStore('transactions'),
        selectTransactionsRefunding,
        state => state.models.transactions[userId],
      ],
      (ids, transactions, refundingTransactions, transactionsLoaded) => ({
        transactions: (ids && transactions && ids.map(id => transactions[id])) || [],
        transactionsLoaded: transactionsLoaded === true,
        refundingTransactions,

        refundTransaction: transactionId => refundTransaction(transactionId, userId),
      }),
    )
  },
)

export const Transaction = {
  SHAPE,

  /**
   * model connection { userId }
   */
  connection: {
    load: ({ transactionsLoaded }, { userId }) => {
      if (!transactionsLoaded && userId != null) {
        MODEL.read({ query: { user_id: userId }, modelMapKey: ['user_id', userId] })
      }
    },

    isLoaded: ({ transactionsLoaded }) => transactionsLoaded,

    selector: selectConnection,

    shape: {
      transactions: PropTypes.arrayOf(SHAPE),
      transactionsLoaded: PropTypes.bool.isRequired,
      refundingTransactions: PropTypes.arrayOf(PropTypes.number),

      refundTransaction: PropTypes.func,
    },
  },
}
