import get from 'lodash/get'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel from '../ApiModel'
import Log from '../log'
import { registerModelReducer } from '../reducers'
import { selectorCache } from '../util/selectorCache'
import { LOAD_SUCCESS_TYPE as USER_LOAD_SUCCESS_TYPE } from './User'

const MODEL = new ApiModel('SUBSCRIPTION', 'api/v1/subscription')
const ADMIN_MODEL = new ApiModel('ADMIN_SUBSCRIPTION', 'admin/api/v1/subscriptions')

registerModelReducer('subscription', (state = { }, { type, url, response }) => {
  if (type === USER_LOAD_SUCCESS_TYPE && url.endsWith('/me')) {
    return { ...state, mySubscriptionId: get(response.subscriptions, [0, 'id']) }
  } else if (type === Action.CREATE.successType(MODEL)) {
    return { ...state, mySubscriptionId: response.subscription.id }
  }

  return state
})

const SHAPE = PropTypes.shape({
  id: PropTypes.number,
  is_valid: PropTypes.bool,
  conversion_tracked: PropTypes.bool,
  plan_id: PropTypes.string,
  plan_type: PropTypes.string,
  plan_trial_days: PropTypes.number,
  status: PropTypes.string,
  next_billing_date: PropTypes.string,
  last_error_code: PropTypes.string,
  created_at: PropTypes.string,
  extended: PropTypes.bool,
  paused_until: PropTypes.string,
  expiring_soon: PropTypes.bool,
  trial_ends_at: PropTypes.string,
  is_upfront: PropTypes.bool,
})

export const selectSubscriptions = state => state.api.subscriptions

const select = createSelector(
  [state => state.models.subscription, selectSubscriptions],
  ({ mySubscriptionId }, subscriptions) =>
    (mySubscriptionId && subscriptions && subscriptions[mySubscriptionId]),
)

const cancel = () => MODEL.updateModify({ cancel: true })

const save = subscription => {
  MODEL[subscription.id ? 'updateModify' : 'create']({ subscription, use_jobs: true })
}

const isUpdating = MODEL.isUpdatingSelector()
const isCreating = MODEL.isCreatingSelector()
const isSaving = state => isUpdating(state) || isCreating(state)

const updateError = state => MODEL.getUpdateErrorMessage(state)
const createError = state => MODEL.getCreateErrorMessage(state)
const saveError = state => updateError(state) || createError(state)

const clearCreateFailed = () =>
  ApiModel.dispatch(Action.CREATE.getClearPendingAction(MODEL, MODEL.baseUrl))
const clearUpdateFailed = () =>
  ApiModel.dispatch(Action.UPDATE_MODIFY.getClearPendingAction(MODEL, MODEL.baseUrl))
const clearSaveFailed = () => {
  clearCreateFailed()
  clearUpdateFailed()
}

const loadSubscription = id => ADMIN_MODEL.read({ id })

// TODO (NJC): When we get rid of the admin subscriptions controller, this will likely follow
// the standard API pattern and the status update will need to be nested under `subscription`
const cancelSubscription = ({ id }) => ADMIN_MODEL.updateModify({ status: 'canceled' }, { id })

const selectUserConnection = selectorCache(
  ({ user, subscriptionId }) => subscriptionId || user.subscription_id,
  ({ user, subscriptionId }) => {
    const id = subscriptionId || user.subscription_id
    if (id == null) {
      Log.warn(`Asked to create a selector for subscription with no id [${user}]`)
      return () => ({ })
    }

    const selectSubscription = createSelector(
      [selectSubscriptions],
      subscriptions => subscriptions && subscriptions[id],
    )

    const pendingSelector = createSelector(
      [Action.UPDATE_MODIFY.pendingSource],
      pending => pending[ADMIN_MODEL.getUrl({ id })],
    )

    return createSelector(
      [selectSubscription, pendingSelector],
      (subscription, pending) => ({
        subscription,
        cancelSubscription: subscription && (() => cancelSubscription(subscription)),
        subscriptionIsUpdating: Action.isActing(pending),
      }),
    )
  },
)

export const Subscription = {
  SHAPE,

  connection: {
    selector: createSelector(
      [select, isSaving, saveError],
      (subscription, subscriptionIsSaving, saveError) => ({
        subscription,
        subscriptionIsSaving,
        saveError,
        saveSubscription: save,
        cancelSubscription: cancel,
        clearSaveSubscriptionFailed: clearSaveFailed,
      }),
    ),

    shape: {
      subscription: SHAPE,
      subscriptionIsSaving: PropTypes.bool.isRequired,
      saveError: PropTypes.string,
      saveSubscription: PropTypes.func.isRequired,
      cancelSubscription: PropTypes.func.isRequired,
      clearSaveSubscriptionFailed: PropTypes.func.isRequired,
    },
  },

  coachConnection: {
    load: ({ subscription }, { user, subscriptionId }) => {
      const id = subscriptionId || user.subscription_id
      if (subscription == null && id != null) loadSubscription(id)
    },

    isLoaded: ({ subscription }, { user, subscriptionId }) => {
      const id = subscriptionId || user.subscription_id
      return subscription != null || id == null
    },

    selector: selectUserConnection,

    shape: {
      subscription: SHAPE,
      cancelSubscription: PropTypes.func,
      subscriptionIsUpdating: PropTypes.bool,
    },
  },
}
