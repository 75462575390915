import { createSelector } from 'reselect'
import PropTypes from 'prop-types'
import { registerModelReducer } from '../reducers'
import { SUCCESS_TYPE, Read } from './Read'

const SHAPE = PropTypes.shape({
  shared_resource: PropTypes.arrayOf(PropTypes.number),
})

registerModelReducer('read', (state = { }, { type, response }) => {
  if (type === SUCCESS_TYPE) {
    const newState = { ...state }
    const { read: { type, id } } = response

    if (newState[type] != null) {
      newState[type].push(id)
    } else {
      newState[type] = [id]
    }
    return newState
  }
  return state
})

const selectModelRead = state => state.models.read
const selectConnection = createSelector([selectModelRead], (read) => ({
  read,
  markModelRead: Read.markModelRead,
}))

export const ReadConnection = {
  SHAPE,
  connection: {
    selector: selectConnection,
  },
}
