/* global fetch */
import { isFunction } from 'lodash'
import { node, string } from 'prop-types'
import { createContext, useContext, useMemo } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

const VERBS = ['DELETE', 'GET', 'PATCH', 'POST', 'PUT']

/**
 * Creates a URL based on API Gateway principles
 */
function createUrl (baseUrl, spec, version, path) {
  return `${
    baseUrl
  }${
    spec != null ? `/${spec}` : ''
  }${
    version != null ? `/v${version}` : ''
  }${
    path
  }`
}

async function getHeaders (gatewayHeaders, { headers }) {
  if (isFunction(gatewayHeaders)) gatewayHeaders = await gatewayHeaders()

  return { ...gatewayHeaders, ...headers }
}

/**
 * Creates a fetch abstraction that connects to a specific base URL
 */
const createGateway = (baseUrl, spec, version, headers = {}) =>
  VERBS.reduce((methods, verb) => ({
    ...methods,

    [verb.toLowerCase()]: async (path, init = {}) => fetch(
      createUrl(baseUrl, spec, version, path),
      {
        ...init,
        headers: await getHeaders(headers, init),
        method: verb,
      },
    ),
  }), {})

/**
 * Used to provide context to queries
 */
const ApiGateway = createContext(null)

/**
 * Provider which issues a gateway specific to a base URL
 * @param {*} props
 */
export const ApiGatewayProvider = ({ baseUrl, children }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      <ApiGateway.Provider value={baseUrl}>
        {children}
      </ApiGateway.Provider>
    </QueryClientProvider>
  )
}

ApiGatewayProvider.propTypes = {
  baseUrl: string,
  children: node,
}

/**
 * Convenience hook used to get access to the ApiGateway context
 * @param {*} spec Name of the API registered on the gateway (ex: 'schedules')
 * @param {*} version Version of the API to use on the gateway (ex: 1)
 */
export function useApiGateway (spec, version) {
  if (spec == null || version == null) {
    throw new Error(`Missing parameters: spec, and version are required [${spec}, ${version}]`)
  }

  const baseUrl = useContext(ApiGateway)
  return useMemo(
    () => createGateway(baseUrl, spec, version),
    [baseUrl, spec, version],
  )
}
