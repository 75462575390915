import get from 'lodash/get'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel from '../ApiModel'
import { registerModelReducer, setIn } from '../reducers'
import { selectorCache } from '../util/selectorCache'
import { parseQuery } from './utilities'

const MODEL = new ApiModel('CONCIERGE_STATUS', 'api/v1/concierge_status',
  (json, response, fetch) => !(fetch.action === Action.READ && response.status === 500))

const SHAPE = PropTypes.shape({
  status: PropTypes.string.isRequired,
  provider_id: PropTypes.number,
  user_activity_id: PropTypes.number,
  requested_type: PropTypes.string,
})

registerModelReducer('conciergeStatus', (state = {}, { type, url, response }) => {
  if (type === Action.READ.successType(MODEL)) {
    const { user_id: userId } = parseQuery(url)
    return setIn(state, [userId], response.concierge_status.id)
  }

  return state
})

const load = userId => MODEL.read({ query: { user_id: userId } })

const update = conciergeStatus => MODEL.updateModify(
  { concierge_status: conciergeStatus },
  { query: { user_id: 'me' } },
)

const select = userId => state => {
  const id = get(state.models.conciergeStatus, [userId])
  return id && state.api.concierge_statuses[id]
}

const selectChecklistConnection = selectorCache(
  ({ userId }) => userId,
  ({ userId, checklists }) => createSelector(
    [
      select(userId),
      ...checklists.map(
        list => MODEL.isUpdatingSelector(
          { id: `checklists/${list}`, query: { user_id: userId } },
        ),
      ),
    ],
    (status, ...listsUpdating) => ({
      conciergeChecklists: status && status.checklists,
      updateChecklistItem: (list, item, value) =>
        MODEL.updateModify(
          { checklist: { item, value } },
          { id: `checklists/${list}`, query: { user_id: userId } },
        ),
      checklistIsUpdating: listsUpdating.indexOf(true) >= 0,
    }),
  ),
)

export const ConciergeStatus = {
  SHAPE,

  checklistConnection: {
    load: ({ conciergeChecklists }, { userId }) => {
      if (conciergeChecklists == null) load(userId)
    },

    isLoaded: ({ conciergeChecklists }) => conciergeChecklists != null,

    selector: selectChecklistConnection,

    shape: {
      conciergeChecklists: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)).isRequired,
      updateChecklistItem: PropTypes.func.isRequired,
      checklistIsUpdating: PropTypes.bool.isRequired,
    },
  },

  connection: {
    load: ({ conciergeStatus }) => {
      if (conciergeStatus == null) load('me')
    },

    isLoaded: ({ conciergeStatus }) => conciergeStatus != null,

    selector: createSelector(
      [select('me'), MODEL.isUpdatingSelector({ query: { user_id: 'me' } })],
      (conciergeStatus, conciergeStatusIsUpdating) => ({
        conciergeStatus,
        conciergeStatusIsUpdating,
        updateConciergeStatus: update,
      }),
    ),

    shape: {
      conciergeStatus: SHAPE,
      conciergeStatusIsUpdating: PropTypes.bool.isRequired,
      updateConciergeStatus: PropTypes.func.isRequired,
    },
  },
}
