import PropTypes from 'prop-types'
import styled from 'styled-components'
import Loader from '@ableto/component-library/loader'
import { white } from '@ableto/component-library/theme/colors'
import { Heading, Body } from '@ableto/component-library'
import { size20, size64 } from '@ableto/component-library/theme/scale'
import { useEffect, useState } from 'react'
import { Transition } from 'react-transition-group'
import { useI18n } from '@app/js/lib/i18nNamespace'

const Page = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${props => props.background ?? white};
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 60;
  padding: ${size64} ${size20} ${size20};
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.centered &&
    `
      text-align: center;
      p {
        min-height: 1.5rem;
      }
      div {
        display: flex;
        align-items: center;
        div {
          order: -1;
          margin-bottom: 4px;
        }
      }
    `}
`

const FullPageLoader = ({ children, body, headline, background, centered, animated }) => {
  const [renderedBody, setRenderedBody] = useState()

  useEffect(() => {
    setRenderedBody(body)
  }, [body, renderedBody])

  return (
    <Page data-testid='page' background={background} centered={centered}>
      <div>
        {headline != null && <Heading variant='h3' marginBottom={true}>{headline}</Heading>}
        {body !== null &&
          <Transition
            in={(renderedBody === body)}
            timeout={400}
          >
            {(state) => (
              <Body
                marginBottom={true}
                color='gray700'
                style={{
                  opacity: (!animated || state === 'entered' || state === 'exiting') ? 1 : 0,
                  transition: state === 'entered' ? 'opacity 400ms ease-in' : '',
                }}
              >
                {renderedBody}
              </Body>
            )}
          </Transition>}
        <div>{children}</div>
      </div>
    </Page>
  )
}

FullPageLoader.propTypes = {
  children: PropTypes.node,
  headline: PropTypes.string,
  body: PropTypes.string,
  background: PropTypes.string,
  centered: PropTypes.bool,
  animated: PropTypes.bool,
}

const Wrapper = ({ children, fullPage, ...props }) =>
  fullPage ? <FullPageLoader data-testid='page' {...props}>{children}</FullPageLoader> : children

Wrapper.propTypes = {
  children: PropTypes.node,
  fullPage: PropTypes.bool,
  background: PropTypes.string,
}

function AbleToLoading (props) {
  const [renderedProps, setRenderedProps] = useState(props)
  const [i18nLoaded, { t }] = useI18n('common')

  useEffect(() => {
    let timer
    if (props.timeoutOptions) {
      timer = setTimeout(() => {
        setRenderedProps({ ...props, ...props.timeoutOptions.optionsAfterInterval })
      }, props.timeoutOptions.interval)
    }
    return () => { clearTimeout(timer) }
  })

  return (
    <Wrapper {...renderedProps}>
      <div>
        <Loader size={props.fullPage ? 'md' : 'sm'} color={props.color} ariaLabel={i18nLoaded && t('loadingPage')} />
      </div>
    </Wrapper>
  )
}

AbleToLoading.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  fullPage: PropTypes.bool,
  headline: PropTypes.string,
  body: PropTypes.string,
  background: PropTypes.string,
  centered: PropTypes.bool,
  timeoutOptions: PropTypes.shape({
    interval: PropTypes.number,
    optionsAfterInterval: PropTypes.shape({
      headline: PropTypes.string,
      body: PropTypes.string,
      background: PropTypes.string,
    }),
  }),
}

export default AbleToLoading
