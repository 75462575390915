import get from 'lodash/get'
import shuffle from 'lodash/shuffle'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel from '../ApiModel'
import { registerModelReducer } from '../reducers'
import { selectorCache } from '../util/selectorCache'

const reduceShuffledQuestions = (state, response) => ({
  ...state,
  [response.quiz.slug]: {
    shuffled_question_slugs: shuffleQuestionSlug(response.quiz),
  },
})
registerModelReducer('quiz', (state = { }, { type, response }) =>
  type === Action.READ.successType(MODEL) ? reduceShuffledQuestions(state, response) : state)

const MODEL = new ApiModel('QUIZ', 'api/v1/quizzes')

const QUESTION_SHAPE = PropTypes.shape({
  slug: PropTypes.string,
  question_type: PropTypes.string,
  question_text: PropTypes.string,
  difficulty: PropTypes.number,
  answer_feedback: PropTypes.string,
  incorrect_answer_feedback: PropTypes.string,
  prompt: PropTypes.string,
  prompt_description: PropTypes.string,
  prompt_image: PropTypes.string,
  correct_answer: PropTypes.number,
  hide_failure: PropTypes.bool,

  answers: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
  })),
})

const SHAPE = PropTypes.oneOfType([
  PropTypes.symbol, // for NOT_FOUND

  PropTypes.shape({
    slug: PropTypes.string,
    quiz_length: PropTypes.number,
    questions: PropTypes.arrayOf(QUESTION_SHAPE),
  }),
])

const NOT_FOUND = Symbol('Quiz not found')

const select = quizSlug => state =>
  MODEL.isNotFound(state, { id: quizSlug }) ? NOT_FOUND : get(state.api.quizzes, [quizSlug])

const selectShuffledQuestionSlugs = quizSlug => state =>
  get(state, `models.quiz.${quizSlug}.shuffled_question_slugs`)

const loadError = quizSlug => state => MODEL.getReadErrorMessage(state, { id: quizSlug })

/**
 * Returns a selection of quiz questions in random order. The number of questions returned matches
 * `quizLength`.
 */
// TODO (NJC): Support difficulty? I'm not sure we still care about that feature
function shuffleQuestionSlug ({ questions, quiz_length: quizLength }) {
  const shuffled = shuffle(questions.map(question => question.slug))
  if (shuffled.length > quizLength) shuffled.length = quizLength
  return shuffled
}

const selectConnection = selectorCache(
  ({ slug }) => slug,
  ({ slug }) => createSelector(
    [select(slug), selectShuffledQuestionSlugs(slug), loadError(slug)],
    (quiz, questionSlugs, quizLoadError) => {
      let shuffledQuestions = null
      if (quiz != null && quiz !== NOT_FOUND && questionSlugs != null) {
        shuffledQuestions = [...quiz.questions].sort(({ slug: slugA }, { slug: slugB }) =>
          questionSlugs.indexOf(slugA) - questionSlugs.indexOf(slugB))
      }

      return {
        quiz,
        shuffledQuestions,
        quizLoadError,
      }
    },
  ),
)

export const Quiz = {
  NOT_FOUND,
  QUESTION_SHAPE,

  connection: {
    load: ({ quiz, quizLoadError }, { slug }) => {
      if (quiz == null && quizLoadError == null) MODEL.read({ id: slug })
    },

    isLoaded: ({ quiz, quizLoadError }) => quiz != null || quizLoadError != null,

    /*
     * Selector: (state, { slug }) => connectionShape
     */
    selector: selectConnection,

    shape: {
      quiz: SHAPE,
      shuffledQuestions: PropTypes.arrayOf(QUESTION_SHAPE),
      quizLoadError: PropTypes.string,
    },
  },
}
