// This closure is here to delay loading of the very few dependencies this file has until/unless
// they are actually needed so that this file can be imported very early in the app bootstrapping
// process without slurping in a ton of dependencies.
const setDialogResult = (function () {
  let ui, reduxService

  return function (slug, result) {
    if (ui == null) ui = import('../store/ui')
    if (reduxService == null) reduxService = import('../services/ReduxService')

    Promise.all([ui, reduxService]).then(([
      { setDialogResult: uiSetDialogResult },
      { dispatch },
    ]) => dispatch(uiSetDialogResult(slug, result)))
  }
}())

class NativeAppBase {
  // Any code that attempts to use NativeApp should gate on `isHosted`,
  // and any hosting web view should be careful to override this value.
  isHosted = () => false

  // This flag will only be set to true by the React Native app
  // allowing us to execute special code in that application,
  // without breaking backwards compatibility with old
  // Native Android versions
  isReactNative = () => false

  setDialogResult = setDialogResult
  // These are not valid defaults and are only used to simulate the android
  // browser. Activities won't function correctly when isHosted is set to true
  getDeviceId = () => ''
  getDeviceType = () => ''
  showStepLoading = () => false
  getJwtToken = () => ''
  getIdpToken = () => ''
  getIdpUser = () => null
  showNavigationControls = () => false
  activityComplete = () => {}
  getJwtToken = () => null
  exitActivity = () => {}
}

export default function prepareNativeAppInterface () {
  const nativeAppBase = new NativeAppBase()

  if (typeof NativeApp === 'undefined' || typeof NativeiOSApp === 'undefined') {
    window.NativeApp = nativeAppBase
    window.NativeiOSApp = nativeAppBase
    return
  }

  // We can't just use Object.assign here, because java bridge functions can only be called on
  // the original injected object, so doing something like Object.assign(nativeAppBase, NativeApp)
  // doesn't actually work.
  Object.keys(nativeAppBase)
    // eslint-disable-next-line no-prototype-builtins
    .filter(key => !window.NativeApp.hasOwnProperty(key))
    .forEach(key => { window.NativeApp[key] = nativeAppBase[key] })
}
