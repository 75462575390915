import classnames from 'classnames'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Page = styled.div`
  height: 100vh;
  flex-direction: column;
  box-sizing: border-box;  
`

const Wrapper = ({ children, fullPage }) =>
  fullPage ? <Page>{children}</Page> : children

Wrapper.propTypes = {
  children: PropTypes.node,
  fullPage: PropTypes.bool,
}

const viewBox = direction => direction === 'row' ? '0 0 10 2' : '0 0 2 10'

const position = (direction, offset) => ({
  cx: direction === 'row' ? offset : 1,
  cy: direction === 'row' ? 1 : offset,
})

const Loading = ({ className, fullPage, direction, title }) =>
  <Wrapper {...{ fullPage }}>
    <svg
      className={classnames('loadingIcon', className, { centeredLoadingIcon: fullPage })}
      viewBox={viewBox(direction)}
    >
      <title>{title ?? 'Loading'}</title>
      {[1, 5, 9].map(offset =>
        <circle className='dot' key={offset} r='1' {...position(direction, offset)} />,
      )}
    </svg>
  </Wrapper>

Loading.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.oneOf(['row', 'column']),
  fullPage: PropTypes.bool,
  title: PropTypes.string,
}

Loading.defaultProps = {
  direction: 'row',
  fullPage: false,
}

export default Loading
