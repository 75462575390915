import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel from '../ApiModel'
import { registerModelReducer } from '../reducers'
import { selectorCache } from '../util/selectorCache'
import { getSortedUserModelIds, parseQuery, selectModelStore } from './utilities'

const MODEL = new ApiModel('COACH_CALLS', 'api/v1/coach_calls')

const SHAPE = PropTypes.shape({
  id: PropTypes.number.isRequired,
  user_id: PropTypes.number.isRequired,
  call_type: PropTypes.oneOf(['kickoff', 'checkin']),
  medium: PropTypes.oneOf(['call', 'chat', 'twilio_video']),
  called_at: PropTypes.string,
  completed: PropTypes.bool,
})

registerModelReducer('coachCalls', (state = { }, { type, url }) => {
  if (type === Action.READ.successType(MODEL)) {
    const { user_id: userId } = parseQuery(url)
    return { ...state, [userId]: true }
  }

  return state
})

const selectConnection = selectorCache(
  ({ user }) => user && user.id,
  ({ user }) => {
    const userId = user && user.id
    if (userId == null) return () => ({ coachCallsLoaded: false })

    return createSelector(
      [
        getSortedUserModelIds(userId, 'coach_calls'),
        selectModelStore('coach_calls'),
        state => state.models.coachCalls[userId],
      ],
      (ids, coachCalls, coachCallsLoaded) => ({
        coachCalls: (ids && coachCalls && ids.map(id => coachCalls[id])) || [],
        coachCallsLoaded,
      }),
    )
  },
)

export const CoachCalls = {
  /**
   * model connection { user: { id } }
   */
  connection: {
    load: ({ coachCallsLoaded }, { user }) => {
      const userId = user && user.id
      if (!coachCallsLoaded && userId != null) {
        MODEL.read({ query: { user_id: userId }, modelMapKey: ['user_id'] })
      }
    },

    isLoaded: ({ coachCallsLoaded }) => coachCallsLoaded,

    selector: selectConnection,

    shape: {
      coachCalls: PropTypes.arrayOf(SHAPE),
      coachCallsLoaded: PropTypes.bool.isRequired,
    },
  },
}
