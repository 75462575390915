import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'
import { selectorCache } from '../util/selectorCache'

const MODEL = new ApiModel('SURVEY', 'api/v1/surveys')

const NOT_FOUND = Symbol('Survey not found')

const QUESTION_SHAPE = PropTypes.shape({
  slug: PropTypes.string,
  prompt: PropTypes.string,
  category: PropTypes.string,
  prompt_image: PropTypes.string,
})

const SHAPE = PropTypes.oneOfType([
  PropTypes.symbol, // for NOT_FOUND

  PropTypes.shape({
    slug: PropTypes.string,
    prompt: PropTypes.string,
    answers: PropTypes.arrayOf(PropTypes.string),
    answer_type: PropTypes.string,
    minimum_displayed_categories: PropTypes.number,
    pad_categories: PropTypes.arrayOf(PropTypes.string),
    answer_images: PropTypes.arrayOf(PropTypes.string),

    categories: PropTypes.arrayOf(PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string,
      icon: PropTypes.string,
      image: PropTypes.string,
    })),

    questions: PropTypes.arrayOf(QUESTION_SHAPE),
  }),
])

const select = surveySlug => state => {
  if (MODEL.isNotFound(state, { id: surveySlug })) {
    return NOT_FOUND
  } else {
    return state.api.surveys && state.api.surveys[surveySlug]
  }
}

const loadError = surveySlug => state => MODEL.getReadErrorMessage(state, { id: surveySlug })

const selectConnection = selectorCache(
  ({ slug }) => slug,
  ({ slug }) => createSelector(
    [select(slug), loadError(slug)],
    (survey, surveyLoadError) => ({
      survey, surveyLoadError,
    }),
  ),
)

export const Survey = {
  QUESTION_SHAPE,
  NOT_FOUND,

  connection: {
    load: ({ survey, surveyLoadError }, { slug }) => {
      if (survey == null && surveyLoadError == null) MODEL.read({ id: slug })
    },

    isLoaded: ({ survey, surveyLoadError }) => survey != null || surveyLoadError != null,

    /**
     * Selector: (state, { slug }) => connectionShape
     */
    selector: selectConnection,

    shape: {
      survey: SHAPE,
      surveyLoadError: PropTypes.string,
    },
  },
}
