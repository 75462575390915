/// GENERATED with rake activities:get_schema[AbleToScheduleIc]
const SCHEMA = {
  options: {
    properties: {
      schedulingType: {
        type: 'string',
      },
      showPasswordToggle: {
        type: 'boolean',
        default: false,
      },
      showAllAvailableSlots: {
        type: 'boolean',
        default: false,
      },
      shouldGetSessions: {
        type: 'boolean',
        default: false,
      },
      shouldGetCoachSessions: {
        type: 'boolean',
        default: false,
      },
      shouldUseMidSession: {
        type: 'boolean',
        default: false,
      },
      partTimeProviderFilter: {
        type: 'boolean',
        default: false,
      },
      providerAvailability: {
        type: 'string',
        enum: ['allProviders', 'singleProvider'],
        default: 'allProviders',
      },
      daysOut: {
        type: 'number',
        default: 14,
      },
      availabilityRules: {
        type: 'object',
        properties: {
          daysBeforeAppointment: {
            type: 'number',
          },
          daysAfterAppointment: {
            type: 'number',
          },
          removeSlotsWithinHours: {
            type: 'number',
          },
        },
      },
      reschedulingRules: {
        type: 'object',
        properties: {
          maxExtendSession: {
            type: 'number',
          },
          maxExtendTotal: {
            type: 'number',
          },
        },
      },
      availabilityStartDate: {
        type: 'string',
        default: null,
      },
    },
  },
  content: {
    properties: {
      selection: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
          },
          body: {
            type: 'string',
          },
          nextAvailable: {
            type: 'string',
          },
          moveTo: {
            type: 'string',
          },
          experience: {
            type: 'string',
          },
          speaksSpanish: {
            type: 'string',
          },
          submitYourAvailability: {
            type: 'string',
          },
          footer: {
            type: 'string',
          },
          sessionTypeA: {
            type: 'string',
          },
          sessionTypeB: {
            type: 'string',
          },
          infoBanner: {
            type: 'object',
            properties: {
              sessionsMoved: {
                type: 'string',
              },
            },
          },
          infoMessage: {
            type: 'object',
            properties: {
              closeToLastAppointment: {
                type: 'object',
                properties: {
                  heading: {
                    type: 'string',
                  },
                  body: {
                    type: 'string',
                  },
                },
              },
              closeToNextAppointment: {
                type: 'object',
                properties: {
                  heading: {
                    type: 'string',
                  },
                  body: {
                    type: 'string',
                  },
                },
              },
              noAvailability: {
                type: 'object',
                properties: {
                  heading: {
                    type: 'string',
                  },
                  body: {
                    type: 'string',
                  },
                },
              },
              noAvailabilityForLastSession: {
                type: 'object',
                properties: {
                  heading: {
                    type: 'string',
                  },
                  body: {
                    type: 'string',
                  },
                },
              },
            },
          },
        },
      },
      submit: {
        type: 'object',
        properties: {
          infoBanner: {
            type: 'object',
            properties: {
              moveToNextWeek: {
                type: 'string',
              },
              nextMidSession: {
                type: 'string',
              },
            },
          },
          footer: {
            type: 'string',
          },
          footerTitle: {
            type: 'string',
          },
          footerBody: {
            type: 'string',
          },
        },
      },
      confirmation: {
        type: 'object',
        properties: {
          consultationRescheduled: {
            type: 'string',
          },
          body: {
            type: 'string',
          },
          infoBanner: {
            type: 'object',
            properties: {
              showAllSessions: {
                type: 'string',
              },
            },
          },
        },
      },
      phone_number: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
          },
          consent: {
            type: 'string',
          },
        },
      },
      password: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
          },
          cardHeading: {
            type: 'string',
          },
          cardBody: {
            type: 'string',
          },
          cardThankYou: {
            type: 'string',
          },
          cardContact: {
            type: 'string',
          },
        },
      },
      support: {
        type: 'object',
        properties: {
          headline: {
            type: 'string',
          },
          body: {
            type: 'string',
          },
        },
      },
      chooseAnotherTime: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
          },
          body: {
            type: 'string',
          },
        },
      },
      somethingWentWrong: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
          },
          body: {
            type: 'string',
          },
        },
      },
      treatmentBooked: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
          },
          body: {
            type: 'string',
          },
        },
      },
      form_labels: {
        type: 'object',
        properties: {
          phone: {
            type: 'string',
          },
          password: {
            type: 'string',
          },
          confirmPassword: {
            type: 'string',
          },
        },
      },
      form_hints: {
        type: 'object',
        properties: {
          phone: {
            type: 'string',
          },
        },
      },
      buttons: {
        type: 'object',
        properties: {
          chooseAnotherTime: {
            type: 'string',
          },
          treatmentBooked: {
            type: 'string',
          },
          somethingWentWrong: {
            type: 'string',
          },
          confirmConsultation: {
            type: 'string',
          },
          savePassword: {
            type: 'string',
          },
          confirmReschedule: {
            type: 'string',
          },
          show: {
            type: 'string',
          },
          hide: {
            type: 'string',
          },
          loadMore: {
            type: 'string',
          },
        },
      },
    },
  },
}
/// END GENERATED

export default SCHEMA
