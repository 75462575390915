import ApiModel from '../ApiModel'
import PropTypes from 'prop-types'
import Action from '../Action'
import { registerModelReducer } from '../reducers'
import { createSelector } from 'reselect'
import { selectorCache } from '../util/selectorCache'

const MODEL = new ApiModel('TWILIO_VIDEO_CREDENTIALS', 'api/v1/twilio/session_credentials')

registerModelReducer('twilioVideoCredentials', (state = { }, { type, response }) => {
  if (type === Action.READ.successType(MODEL)) {
    return response.session_credentials
  }
  return state
})

const TWILIO_VIDEO_CREDENTIALS_SHAPE = PropTypes.shape({
  room_name: PropTypes.string,
  token: PropTypes.string,
})

export const selectSessionCredentials = state => state.api.session_credentials
export const selectSessionCredentialsError = callId => state => MODEL.getReadErrorMessage(state,
  { query: { call_id: callId } })

// This contains translatable keys for the
// types of errors we're getting from the API
const translatedErrors = {
  Unauthorized: 'errors.unauthorized',
  'The selected call does not exist': 'errors.missingCall',
  'The selected call does not meet the requirenments for video session': 'errors.badCall',
}

const selectConnection = selectorCache(
  ({ callId }) => callId,
  ({ callId }) =>
    createSelector(
      [selectSessionCredentials, selectSessionCredentialsError(callId)],
      (twilioVideoCredentials, twilioVideoCredentialsError) => ({
        twilioVideoCredentials: twilioVideoCredentials
          ? twilioVideoCredentials.session_credentials
          : null,
        twilioVideoCredentialsError: twilioVideoCredentialsError
          ? translatedErrors[twilioVideoCredentialsError] ?? 'errors.generic'
          : null,
      }),
    ),
)

export const VideoSessionCredentials = {
  TWILIO_VIDEO_CREDENTIALS_SHAPE,
  connection: {
    load: ({ twilioVideoCredentials }, { callId }) => {
      if (twilioVideoCredentials == null) {
        MODEL.read({ query: { call_id: callId } })
      }
    },

    isLoaded: (
      {
        twilioVideoCredentials,
        twilioVideoCredentialsError,
      }) => twilioVideoCredentials != null || twilioVideoCredentialsError != null,

    selector: selectConnection,
    shape: {
      twilioVideoCredentials: TWILIO_VIDEO_CREDENTIALS_SHAPE,
      twilioVideoCredentialsError: PropTypes.string,
    },
  },
}
