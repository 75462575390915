import PropTypes from 'prop-types'
import { createSelector } from 'reselect'

import ApiModel from '../ApiModel'
import Action from '../Action'
import { registerModelReducer } from '../reducers'

const MODEL = new ApiModel('SESSION_RESCHEDULING_RECORDS', 'api/v1/session_rescheduling_records')

const READ_SUCCESS_TYPE = Action.READ.successType(MODEL)

registerModelReducer('sessionRescheduling', (state = { }, { type, response }) => {
  if (type === READ_SUCCESS_TYPE) {
    return {
      ...state,
      records: response.session_rescheduling_records,
      totalTreatmentExtendedTimes: response.total_treatment_extended_times,
    }
  }

  return state
})

const selectSessionRescheduleRecords = ({ models }) => models.sessionRescheduling.records

const selectTotalTreatmentExtendedTimes = ({ models }) => models.sessionRescheduling.totalTreatmentExtendedTimes

const createSessionReschedulingRecord = body => {
  ApiModel.pruneCache('sessionRescheduling')
  MODEL.create(body)
}

const selector = createSelector(
  [selectSessionRescheduleRecords, selectTotalTreatmentExtendedTimes],
  (sessionReschedulingRecords, totalTreatmentExtendedTimes) => ({
    sessionReschedulingRecords,
    totalTreatmentExtendedTimes,
    createSessionReschedulingRecord,
  }),
)

export const SessionReschedulingRecords = {
  connection: {
    load: ({ sessionReschedulingRecords }) => {
      if (sessionReschedulingRecords == null) MODEL.read()
    },

    isLoaded: ({ sessionReschedulingRecords, totalTreatmentExtendedTimes }) =>
      sessionReschedulingRecords != null || totalTreatmentExtendedTimes != null,

    selector,

    shape: {
      sessionReschedulingRecords: PropTypes.arrayOf(
        PropTypes.shape({
          session_code: PropTypes.string,
          treatment_extended_times: PropTypes.number,
          total_rescheduling_count: PropTypes.number,
        })),
      totalTreatmentExtendedTimes: PropTypes.number,
    },
  },
}
