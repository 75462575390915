import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'
import { registerModelReducer } from '../reducers'
import { JWT_ERROR } from './Authentication'
import get from 'lodash/get'
import { Firebase } from '../Firebase'

function logFailureFilter (json) {
  const isSmsRequired = get(json, 'error.reason') === 'sms_mfa_required'
  const isSetupRequired = get(json, 'error.reason') === 'mfa_setup_required'
  return !(isSmsRequired || isSetupRequired)
}

const MODEL = new ApiModel('SMS_MFA', 'api/v1/sms_mfa', logFailureFilter)
const SMS_ERROR = 'SMS_ERROR'
const CLEAR_SMS_MFA_ERRORS = 'CLEAR_SMS_MFA_ERRORS'

registerModelReducer('sms_mfa', (state = {}, { type, value }) => {
  if (type === JWT_ERROR || type === SMS_ERROR) {
    if (value.reason === 'sms_mfa_required') {
      return { ...state, obscuredMfaPhone: value.message, failureReason: value.reason }
    }
    return { ...state, failureReason: value.reason }
  }

  if (type === CLEAR_SMS_MFA_ERRORS) {
    return { ...state, failureReason: null }
  }

  return state
})

const setup = async ({ phone }) => {
  const idpToken = await Firebase.getIdpToken()
  const { error } = await MODEL.create({ phone, idp_token: idpToken }, { id: 'setup', ignoreFailure: true })

  if (error != null) {
    ApiModel.dispatch({ type: SMS_ERROR, value: error })
  }
}

const resend = async () => {
  const idpToken = await Firebase.getIdpToken()
  MODEL.create({ idp_token: idpToken }, { id: 'resend' })
}

const clearErrors = () => ApiModel.dispatch({ type: CLEAR_SMS_MFA_ERRORS })

export const SmsMfa = {
  setup: setup,
  resend: resend,
  clearErrors,
  connection: {
    selector: createSelector(
      [state => state.models.sms_mfa],
      (mfaState) => ({
        mfaState,
      }),
    ),
  },
}
