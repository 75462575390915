import { Store } from 'redux'
import Log from '@app/js/services/log'
import { fetchArticle, selectArticles } from '.'
import { Article as ArticleType } from './types'
import { ApiState } from 'joyable-js-api/src/types'

const ArticleEvents = {
  expireArticleCache: [
    'callScheduling',
    'activityCompletion',
    'treatmentUpdate',
    'consentToTreat',
  ] as const,
}

type ArticleEventObj = {
  type: keyof typeof ArticleEvents
  message: typeof ArticleEvents.expireArticleCache[number]
}
type SocketEvent = {
  event: ArticleEventObj
}

/**
 * @param eventMessage *expireArticleCache* event
 * @returns *predicate function*, which takes an article object and determines if that article
 * is subject of the *expireArticleCache* event
 */
const getSingleEventPredicate = (eventMessage: ArticleEventObj['message']) =>
  (article: ArticleType) => article.meta?.cacheInvalidationEvents?.includes(eventMessage)

/**
 * Checks if an article is subject of any of the *expireArticleCache* events
 */
const articleHasExpireEvent = (article: ArticleType) =>
  ArticleEvents.expireArticleCache.some(eventMessage => getSingleEventPredicate(eventMessage)(article))

/**
 * Re-fetch all stored articles that match the given predicate
 */
const updateStoredArticles = (store: Store, predicate: (article: ArticleType) => boolean | undefined) => {
  const articles = (() => {
    const state = store.getState() as ApiState
    const articles = selectArticles(state)
    if (articles == null) return

    return Object.values(articles)
  })()

  articles?.forEach(article => {
    if (predicate(article)) fetchArticle(article.id)
  })
}

export const socketDataReceiver = (store: Store, channel: string) => (data: SocketEvent) => {
  if (data.event == null) {
    Log.error(`${channel}: No event data received`, data)
    return
  }

  const { type: eventType, message: eventMessage } = data.event
  if (!ArticleEvents[eventType]?.includes(eventMessage)) {
    Log.error(`${channel}: Unexpected event data received`, data.event)
    return
  }

  updateStoredArticles(store, getSingleEventPredicate(eventMessage))
}

export const onSocketReconnect = (_longDisconnect: boolean, store: Store) => {
  updateStoredArticles(store, articleHasExpireEvent)
}
