// import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel from '../ApiModel'
import { registerModelReducer, setIn } from '../reducers'
import { selectorCache } from '../util/selectorCache'
import { parseQuery, userModelSelector } from './utilities'
import Log from '../log'

const MODEL = new ApiModel('PROVIDER_USER', 'api/v1/provider_users')
const NOT_FOUND = Symbol('ProviderUser not found')

registerModelReducer('provider_users', (state = { users: { } }, { type, url, response }) => {
  const isLoaded = type === Action.READ.successType(MODEL) ||
    (type === Action.READ.failureType(MODEL) && response.status === 404)
  if (isLoaded) {
    const { user_id: userId } = parseQuery(url)
    return setIn(state, ['users', userId], true)
  }

  return state
})

const selector = userId => state =>
  userModelSelector(userId, 'provider_users')(state)?.find(
    provider => provider.provider_type === 'Coach' && provider.status === 'active',
  )

const selfGuidedSetter = providerUser => selfGuided => {
  if (providerUser.is_self_guided === selfGuided) {
    Log.warn('Ignoring NOOP request', providerUser, selfGuided)
    return
  }

  const updatedModel = { ...providerUser, is_self_guided: selfGuided }
  MODEL.updateModify({ provider_user: updatedModel }, { id: providerUser.id })
}

const selectCoachConnection = selectorCache(
  ({ userId }) => ({ userId }),
  ({ userId }) => createSelector(
    [
      selector(userId),
      MODEL.isNotFoundSelector({ query: { user_id: userId }, modelMapKey: ['user_id', userId] }),
      Action.UPDATE_MODIFY.pendingSource,
    ],
    (providerUser, notFound, pending) => {
      if (notFound) {
        return {
          providerUser: NOT_FOUND,
          isUpdating: false,
          setSelfGuided: null,
        }
      }

      if (providerUser == null) {
        return {
          providerUser,
          isUpdating: false,
          setSelfGuided: null,
        }
      }

      return ({
        providerUser: providerUser,
        setSelfGuided: selfGuidedSetter(providerUser),
        isUpdating: Action.isActing(pending[MODEL.getUrl({ id: providerUser.id })]),
      })
    }),
)

export const ProviderUser = {
  NOT_FOUND,
  coachConnection: {
    load: ({ providerUser }, { userId }) => {
      if (providerUser == null) MODEL.read({ query: { user_id: userId }, modelMapKey: ['user_id', userId] })
    },

    isLoaded: ({ providerUser }) => providerUser != null,

    selector: selectCoachConnection,
  },
}
