import moment, { Moment } from 'moment-timezone'
import inflection from 'inflection'
import Log from './log'
import { SUCCESS_TYPE as READ_SUCCESS_TYPE } from './models/Read'
import { DELETE, EMPTY_RESULT } from './normalize'
import { SocketActions } from './socketActions'
import { Action } from 'redux'
import get from 'lodash/get'
import {
  ApiKey,
  ApiState,
  ApiSuccessAction,
  ApiValue,
  isApiAction,
  isClearPendingAction,
  isFailureAction,
  isHttpAction,
  isPendingAction,
  isPruneAction,
  isSuccessAction,
  isTypingAction,
  PruneAction,
} from './types'

// eslint-disable-next-line camelcase
type Updated = { updated_at: string }

type SetInOptions = {
  respectUpdatedAt?: boolean,
  mutate?: boolean,
}

type ModelStateReducer<S extends ApiState> =
  (state: S | undefined, action: Action<string>, globalState: ApiState) => ApiState
type ModelStateSelector<S extends ApiState, R extends ApiValue | ApiValue[]> = (state: S) => R

/**
 * Traverses an object tree deeply, making shallow clones at each step according to fullKey,
 * and truncates the tree at the last key. If any intermediate branches are missing, traversal is
 * halted. Finally, the resulting tree is returned, leaving the original object alone.
 *
 * This duplicates the deleteIn functionality from ImmutableJS's collections.
 */
export function deleteIn (obj: ApiState, fullKey: ApiKey[]): ApiState {
  const result = obj = { ...obj }
  for (let index = 0; index < fullKey.length; index++) {
    const key = fullKey[index]
    if (key !== '*' && obj[key] == null) break

    if (index === fullKey.length - 1) {
      if (Array.isArray(obj)) {
        if (!Number.isInteger(key) || key < 0 || key >= obj.length) {
          Log.warn('Asked to delete non index from array', obj, key, fullKey)
        } else {
          obj.splice(key as number, 1)
        }
      } else {
        delete obj[key]
      }
    } else if (key === '*') {
      // wildcard support in delete keys
      const subKey = fullKey.slice(index + 1)
      if (subKey.length > 0) {
        Object.entries(obj).forEach(([key, value]) => {
          obj[key] = deleteIn(value as Record<string, any>, subKey)
        })
      }
      break
    } else {
      if (Array.isArray(obj[key])) obj[key] = [...(obj[key] as ApiValue[])]
      else obj[key] = { ...(obj[key] as ApiState) }
      obj = obj[key] as ApiState
    }
  }
  return result
}

const isUpdated = (value?: unknown): value is Updated =>
  (value as Updated)?.updated_at != null

const mapUpdatedMoments = (...objects: Updated[]) =>
  objects.map(({ updated_at: updatedAt }): Moment => moment(updatedAt))

const compareUpdatedAt = (obj1: Updated, obj2: Updated) => {
  const [moment1, moment2] = mapUpdatedMoments(obj1, obj2)
  return moment1.isBefore(moment2) ? -1 : (moment2.isBefore(moment1) ? 1 : 0)
}

/**
 * Traverses an object tree deeply, making shallow clones at each step according to fullKey,
 * and sets the value at the tree for the last key. If any intermediate branches are missing,
 * an empty object is created. Finally, the resulting tree is returned, leaving the original
 * object alone.
 *
 * This duplicates the setIn functionality from ImmutableJS's collections.
 *
 * If respectUpdatedAt is true, and the value is replacing a value that already exists, and both
 * values have an `updated_at` field, then that field is expected to be a datestring that moment
 * can parse, and the newest value will win, which may mean that this method NOOPs.
 *
 * If mutate is true, shallow clones aren't created, and instead the object that's passed in is
 * mutated and returned.
 */
export function setIn (
  obj: ApiState,
  fullKey: ApiKey[],
  value: ApiValue,
  { respectUpdatedAt = false, mutate = false }: SetInOptions = { },
) {
  const result = obj = { ...obj }
  for (let index = 0; index < fullKey.length; index++) {
    const key = fullKey[index]
    const keyValue = obj[key]
    if (index === fullKey.length - 1) {
      // only set the value if we're ignoring the updated_at value, either the current value or
      // new value doesn't have an `updated_at` field, or the new value is newer.
      if (!respectUpdatedAt ||
        !isUpdated(keyValue) ||
        !isUpdated(value) ||
        compareUpdatedAt(keyValue, value) < 0
      ) {
        obj[key] = value
      }
    } else if (obj[key] == null) {
      obj = obj[key] = {}
    } else {
      if (!mutate) {
        if (Array.isArray(keyValue)) obj[key] = [...keyValue]
        else obj[key] = { ...(obj[key] as ApiState) }
      }
      obj = obj[key] as ApiState
    }
  }
  return result
}

export const mergeIn = (
  obj: ApiState,
  fullKey: ApiKey[],
  values: ApiState,
  options?: SetInOptions,
) => setIn(
  obj,
  fullKey,
  { ...get(obj, fullKey) as ApiState, ...values },
  options,
)

function api (state: ApiState = {}, action: Action<string>) {
  if (!isApiAction(action)) return state

  if (isSuccessAction(action)) {
    const { type, response, successData } = action
    if (type === READ_SUCCESS_TYPE) {
      const read = response.read as { type: string, id: ApiKey }
      const modelName = inflection.pluralize(read.type)
      const model = get(state, [modelName, read.id]) as ApiValue
      if (model == null) {
        Log.warn(`No model found for read update [${modelName}, ${read.id}]`, response)
      } else {
        state = setIn(state, [modelName, read.id, 'read'], true)
      }
    } else if (successData != null) {
      // Apply any automatically sussed out API response data.
      state = successData.values.reduce(
        (state, { key, value }) => value === DELETE
          ? deleteIn(state, key)
          : setIn(state, key, value as ApiState, { respectUpdatedAt: true }),
        state,
      )
    }
  }

  if (isPruneAction(action) || isSuccessAction(action)) state = prune(state, action)

  return state
}

function prune (state: ApiState, action: ApiSuccessAction | PruneAction) {
  const { pruneKeys, keepKeys = [] } = action
  if (pruneKeys == null) return state

  const successKeys = isSuccessAction(action) && action.successData != null
    ? action.successData.values.map(({ key }) => key)
    : []
  const keep = [...keepKeys, ...successKeys].map(key => ({ key, value: get(state, key) as ApiValue }))

  state = pruneKeys.reduce((state, key) => deleteIn(state, key), state)
  return keep.reduce((state, { key, value }) => {
    const joined = key.join('.')
    const wasPruned = pruneKeys.find(pruneKey => joined.startsWith(pruneKey.join('.'))) != null
    if (!wasPruned) return state

    // we can mutate `state` at this point because we know that we're re-inserting a value into a
    // tree that was pruned out of the redux store, so we're not mutating anything that's in the
    // previous version of the store.
    return setIn(state, key, value, { mutate: true })
  }, state)
}

// supported methods should match those in `Action` in ApiFetch.ts.
const DEFAULT_API_PENDING_STATE: ApiState = { GET: {}, POST: {}, PUT: {}, DELETE: {} }
function apiPending (
  state = DEFAULT_API_PENDING_STATE,
  action: Action<string>,
) {
  if (!isHttpAction(action)) return state

  const { url, options } = action
  const method = options?.method as string
  const isFailure = isFailureAction(action)
  const shouldRecordFailure = isFailure &&
    (action.recordFailure == null || action.recordFailure(action.response as Response))
  if (isPendingAction(action)) {
    state = setIn(state, [method, url], options, { respectUpdatedAt: false })
  } else if (shouldRecordFailure) {
    const { response, error } = action
    state = setIn(
      state,
      [method, url],
      { failed: { response, error } },
      { respectUpdatedAt: false },
    )
  } else if (isFailure || isSuccessAction(action) || isClearPendingAction(action)) {
    state = deleteIn(state, [method, url])
  }

  return state
}

function modelMap (state: ApiState = { }, action: Action<string>) {
  if (!isApiAction(action)) return state

  if (isPruneAction(action)) {
    const { modelMapPruneKeys } = action
    if (modelMapPruneKeys != null && modelMapPruneKeys.length > 0) {
      state = modelMapPruneKeys.reduce((state, key) => deleteIn(state, key), { ...state })
    }
  }

  if (!isSuccessAction(action) || action.successData == null) return state

  const { successData: { modelMap } } = action
  if (modelMap == null || modelMap.length === 0) return state

  // TODO (NJC): This wonky EMPTY_RESULT handling might be able to go away now that we're not
  // trying to silently ignore 404s.

  // this reducer is a little complicated but what it's doing is fairly straightforward:
  // For a given type of query param, it's either recording the id of the model associated, or
  // the model itself if it's a type that doesn't have an id. Examples include recording
  // coach_note ids by user_id, or recording user_activity ids by activity_slug
  // Note: We don't use Set because we have the requirement that lodash `get` be able to
  // traverse the full state tree and it doesn't know what to do with Sets.
  modelMap.forEach(({ key, value }) => {
    if (value === EMPTY_RESULT) {
      // make sure we have at least an empty set stored at this key
      if (get(state, key) == null) {
        state = setIn(state, key, [] as ApiValue)
      }
    } else if (value === DELETE) {
      const baseKey = key.slice(0, -1)
      const ids = get(state, baseKey) as ApiValue
      if (Array.isArray(ids)) {
        const index = ids.indexOf(key.slice(-1)[0])
        if (index >= 0) {
          state = deleteIn(state, [...baseKey, index])
        }
      } else if (get(state, key)) {
        state = deleteIn(state, key)
      }
    } else if (value != null && typeof value === 'object' && (value as ApiState).id != null) {
      const set = get(state, key) as ApiKey[] ?? []
      const id = (value as ApiState).id as ApiKey
      if (set.indexOf(id) < 0) {
        state = setIn(state, key, [...set, id] as ApiValue)
      }
    } else {
      state = setIn(state, key, value as ApiValue, { respectUpdatedAt: true })
    }
  })

  return state
}

const MODEL_REDUCERS = new Map<string, ModelStateReducer<any>>()

/**
 * Allows individual models to register a reducer that handles custom reducing.
 */
export function registerModelReducer<ModelStateType extends ApiState> (
  model: string,
  reducer: ModelStateReducer<ModelStateType>,
) {
  MODEL_REDUCERS.set(model, reducer)

  const selectModelState = (state: ApiState) => (state.models as ApiState)[model] as ModelStateType
  const modelStateSelector = <R extends ApiValue | ApiValue[]>(selector: ModelStateSelector<ModelStateType, R>) =>
    (state: ApiState) => selector(selectModelState(state))
  return { selectModelState, modelStateSelector }
}

function models (state: ApiState = { }, action: Action<string>) {
  MODEL_REDUCERS.forEach((reducer, key) => {
    const preState = state[key] as ApiState
    const postState = reducer(preState, action, state)
    if (preState !== postState) {
      // only create a new object if a model reducer produced a new state, to prevent thrashing
      // in this part of the tree for every action (many of which won't affect the model subtree).
      state = { ...state, [key]: postState }
    }
  })

  return state
}

const DEFAULT_SOCKET_STATE: {
  connectionState: string,
  typing: { [key: ApiKey]: boolean },
  disconnectedAt?: string | null
} = {
  connectionState: 'disconnected',
  typing: {},
  disconnectedAt: null,
}
function socket (
  state: typeof DEFAULT_SOCKET_STATE = DEFAULT_SOCKET_STATE,
  action: Action<string>,
): typeof DEFAULT_SOCKET_STATE {
  if (isTypingAction(action)) {
    return {
      ...state,
      typing: { ...state.typing, [action.user_id]: action.type === SocketActions.TYPING },
    }
  }

  switch (action.type) {
    case SocketActions.SOCKET_CONNECTING:
      return { ...state, connectionState: 'connecting' }

    case SocketActions.SOCKET_CONNECTED:
      return { ...state, connectionState: 'connected', disconnectedAt: null }

    case SocketActions.SOCKET_DISCONNECT:
      return { ...state, connectionState: 'disconnected', disconnectedAt: moment().format() }
  }

  return state
}

// Reducers that should be loaded into the app's reduce config.
export default { api, apiPending, modelMap, models, socket }
