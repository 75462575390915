import kebabCase from 'lodash/kebabCase'
import moment from 'moment-timezone'
import { lazy, Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'
import {
  User,
  Treatment,
  useEnableToApi,
  Profile,
  FirebaseToken,
  SessionReschedulingRecords,
  CallStatus,
  Practitioners,
  FeatureFlags,
} from 'joyable-js-api'
import { breakpoints } from '@ableto/component-library/theme/breakpoints'
import { useIsBreakpoint } from '@ableto/component-library/utils/hooks'
import { ActivityControllerContext } from '@app/js/contexts/ActivityControllerContext'
import { ContinueActivityContext } from '@app/js/contexts/ContinueActivityContext'
import { generatePropTypes } from '@app/js/activities/generatePropTypes'
import { useConnections } from '@app/js/lib/useConnection'
import { Enum } from '@app/js/lib/enum'
import { adjustBrazeQueryDate } from '@app/js/lib/helpers'
import { useConfirmationDialog, useReloadMe, useQueryParamsSnapshot } from '@app/js/lib/hooks'
import Pages from '@app/js/routing/Pages'
import Log from '@app/js/services/log'
import analytics from '@app/js/services/analytics'
import { Dialog } from '@app/js/services/Dialog'
import { AbleToInitialConsultContext } from './context'
import { showConfirmation, openConfirmationDialog } from './modals'
import SCHEMA from './schema'
import {
  normalizeDateAndTimezone,
  getCanExtendSession,
  getAvailabilityStartDate,
  DIALOG_BANNER_TYPES,
} from './helpers'
import trackAnalytics, { getAnalyticsTag } from './analyticsHelpers'
import { goToLogin } from '@app/js/routing/Activities'
import { isAuth0Enabled } from 'joyable-js-api/src/ApiFetch'

const PROVIDER_AVAILABILITY_RULES = {
  allProviders: 'useMultipleProvidersAvailabilityQuery',
  singleProvider: 'useSingleProviderAvailabilityQuery',
}

const SUBMIT_CALLBACKS_MAP = {
  scheduleIc: 'useIcAppointmentMutation',
  rescheduleIc: 'useIcAppointmentRescheduleMutation',
  rescheduleSession: 'useSessionRescheduleMutation',
  scheduleFua: 'useIcAppointmentMutation',
  rescheduleFua: 'useIcAppointmentRescheduleMutation',
}

export const SCHEDULE_IC_DIALOG_SLUG = 'openScheduleIc'

class Steps extends Enum {
  static SELECTION = new Steps(lazy(() => import('./steps/Selection')))
  static PASSWORD = new Steps(lazy(() => import('./steps/Password')))
  static SUPPORT = new Steps(lazy(() => import('./steps/Support')))
  static _ = this.closeEnum()

  constructor (component) {
    super()
    this.Component = component
  }
}

const getInitialStep = userActivity => {
  const hasIcScheduled = userActivity.content.ic_scheduled_for != null

  if (hasIcScheduled && FeatureFlags.current.auth0_login === true) return

  return hasIcScheduled ? Steps.PASSWORD : Steps.SELECTION
}

const validateQueryParams = queryParams => (
  queryParams.treatment_id != null &&
  queryParams.session_code != null &&
  queryParams.appointment_time != null
)

const getErrorSlug = (errorType) =>
  errorType === 'treatment_booked' ? 'treatmentBooked' : 'chooseAnotherTime'

const parseSchedulingTypeToBoolean = (schedulingType) => {
  let isScheduleIc = false
  let isRescheduleIc = false
  let isRescheduleSession = false
  let isScheduleFua = false
  let isRescheduleFua = false

  switch (schedulingType) {
    case 'scheduleIc':
      isScheduleIc = true
      break
    case 'rescheduleIc':
      isRescheduleIc = true
      break
    case 'rescheduleSession':
      isRescheduleSession = true
      break
    case 'scheduleFua':
      isScheduleFua = true
      break
    case 'rescheduleFua':
      isRescheduleFua = true
      break
  }

  return { isScheduleIc, isRescheduleIc, isRescheduleSession, isScheduleFua, isRescheduleFua }
}

const buildAppointmentBasedOnSchedulingType = (isScheduling, providerDetails, period) => {
  const { datetime, timezone } = normalizeDateAndTimezone(period?.begin, 'YYYY-MM-DD HH:mm:ss')

  if (isScheduling) {
    return {
      datetime,
      timezone,
      id_program: 43,
      chosen_cs: providerDetails?.id,
      selected_phone: 'primr',
      financial_hardship_status: 0,
    }
  }

  return {
    datetime,
    timezone,
    provider_id: providerDetails?.id,
  }
}

const showRescheduleErrorDialog = (history, content) => {
  Dialog.RESCHEDULE_REQUEST.open({
    title: content.somethingWentWrong.title,
    body: content.somethingWentWrong.body,
    buttonText: content.buttons.somethingWentWrong,
    submitDialog: () => {
      Pages.RESCHEDULE_REQUEST.go(history.location.search)
    },
  })
}

const showScheduleErrorDialog = (content) => {
  Dialog.INFO.open({
    title: content.somethingWentWrong.title,
    body: content.somethingWentWrong.body,
    buttonText: content.buttons.somethingWentWrong,
  })
}

function AbleToScheduleICTemplate ({ options, content }) {
  const {
    showAllAvailableSlots,
    providerAvailability,
    schedulingType,
    reschedulingRules,
    showPasswordToggle,
    availabilityStartDate: configAvailabilityStartDate,
  } = options
  const { componentMap } = useTheme()
  const { userActivity, setUserActivityContent } = useContext(ActivityControllerContext)
  const continueActivity = useContext(ContinueActivityContext)
  const [connectionsLoaded, [
    { me },
    { currentTreatment },
    { profile },
    { callStatus },
    { createCustomToken },
    {
      sessionReschedulingRecords,
      totalTreatmentExtendedTimes,
      createSessionReschedulingRecord,
    },
    { practitioners },
  ]] = useConnections([
    User.meConnection,
    Treatment.currentConnection,
    Profile.myProfileConnection,
    CallStatus.connection,
    FirebaseToken.connection,
    SessionReschedulingRecords.connection,
    Practitioners.connection,
  ])

  const isMobile = useIsBreakpoint(breakpoints.md)
  const meReloaded = useReloadMe()
  const history = useHistory()

  const [activeStep, setActiveStep] = useState(getInitialStep(userActivity))
  const [isConfirmationLoading, setIsConfirmationLoading] = useState(false)
  const [selectedTimeSlot, setSelectedTimeSlot] = useState()

  const selectStep = useCallback((value) => { setActiveStep(Steps[value]) }, [])
  const timezone = moment.tz.guess()
  const modalPeriodDateFormat = moment.locale() === 'en' ? 'dddd, MMMM Do' : 'dddd, MMMM D'
  const {
    isScheduleIc,
    isRescheduleIc,
    isRescheduleSession,
    isScheduleFua,
    isRescheduleFua,
  } = parseSchedulingTypeToBoolean(schedulingType)

  const queryParams = useQueryParamsSnapshot()
  const shouldUseQueryParams = isRescheduleSession && validateQueryParams(queryParams)
  const sessionProtocolCode = shouldUseQueryParams ? queryParams.session_code : currentTreatment?.session_protocol_code
  const sessionType = content.selection[`sessionType${sessionProtocolCode?.[1]}`]
  const isCoachSession = /^\d+B$/.test(sessionProtocolCode)
  const e2TreatmentId = shouldUseQueryParams ? queryParams.treatment_id : currentTreatment?.e2_treatment_id
  const queryAppointmentTime = adjustBrazeQueryDate(queryParams.appointment_time)
  const currentAppointmentTime = shouldUseQueryParams
    ? queryAppointmentTime
    : callStatus?.scheduled_for

  const availabilityStartDate = useMemo(() => {
    if (configAvailabilityStartDate) {
      return moment(configAvailabilityStartDate)
    }
    return getAvailabilityStartDate(isRescheduleSession, shouldUseQueryParams, queryAppointmentTime, timezone)
  }, [isRescheduleSession, shouldUseQueryParams, timezone, queryAppointmentTime, configAvailabilityStartDate])

  const canExtendSession = useMemo(() => {
    return getCanExtendSession({
      isRescheduleSession,
      reschedulingRules,
      sessionReschedulingRecords,
      totalTreatmentExtendedTimes,
      sessionProtocolCode,
    })
  }, [
    isRescheduleSession,
    reschedulingRules,
    sessionProtocolCode,
    sessionReschedulingRecords,
    totalTreatmentExtendedTimes,
  ])

  const openErrorDialog = useConfirmationDialog('openScheduleError', dialogResult => {
    if (dialogResult === 'choose-another-time') selectStep('SELECTION')
    if (dialogResult === 'treatment-booked') goToLogin()
  })

  const { mutateAsync: submitAppointment } = useEnableToApi()[SUBMIT_CALLBACKS_MAP[schedulingType]]()

  /** Serves to associate the analytics log with a particular flow */
  const analyticsTag = useMemo(() => {
    return getAnalyticsTag({ schedulingType, productLine: currentTreatment?.product_line, sessionProtocolCode })
  }, [schedulingType, currentTreatment?.product_line, sessionProtocolCode])

  const logToAnalytics = useCallback((messageKey, context) => {
    trackAnalytics({ schedulingType, messageKey, messageTag: analyticsTag, context })
  }, [schedulingType, analyticsTag])

  const submit = useCallback(async () => {
    setIsConfirmationLoading(true)
    const providerDetails = selectedTimeSlot?.providerDetails
    const period = selectedTimeSlot?.timeSlot.period

    const submitParams = {
      treatmentId: e2TreatmentId,
      leadId: me?.eligibility_match_id,
      sessionId: selectedTimeSlot?.sessionDetails?.id_session,
      appointment: buildAppointmentBasedOnSchedulingType(isScheduleIc || isScheduleFua, providerDetails, period),
      customIdpToken: null,
    }

    if (!isScheduleIc && !isScheduleFua && !isAuth0Enabled()) {
      submitParams.customIdpToken = await createCustomToken()
    }

    try {
      const appointment = await submitAppointment([submitParams])
      setIsConfirmationLoading(false)

      if (appointment.errors) {
        const error = getErrorSlug(appointment.error_type)

        openErrorDialog(
          content[error].title,
          content[error].body,
          { confirm: { slug: kebabCase(error), text: content.buttons[error] } },
          // footer
          null,
          // iconName
          null,
          // horizontalButtons
          false,
          // additionalContext, used for interpolation
          null,
          // hideCloseButton
          true,
        )

        Log.warn(appointment.messages)
        logToAnalytics(error, appointment)
      } else {
        analytics.setUserVars({ campaign: me?.campaign })
        if (isScheduleFua) {
          setUserActivityContent({
            fua_scheduled_for: period?.begin.toISOString(),
            fua_scheduled_for_timezone: timezone,
            provider_name: providerDetails.name,
            provider_license: providerDetails.license,
            photo_url: providerDetails.photoUrl,
          })
        } else if (isRescheduleFua) {
          setUserActivityContent({
            rescheduled_from: moment(currentAppointmentTime).toISOString(),
            rescheduled_to: period?.begin.toISOString(),
          })
        } else {
          setUserActivityContent({
            ic_scheduled_for: period?.begin.toISOString(),
            ic_scheduled_for_timezone: timezone,
            provider_name: providerDetails.name,
            provider_license: providerDetails.license,
            photo_url: providerDetails.photoUrl,
          })
        }

        logToAnalytics('successfulRequest', isScheduleIc || isScheduleFua ? appointment : null)

        if (isScheduleIc) {
          me.is_guest && FeatureFlags.current.auth0_login !== true
            ? selectStep('PASSWORD')
            : continueActivity()
        } else if (isScheduleFua) {
          continueActivity()
        } else {
          const rescheduleSessionRecordBody = {
            session_code: sessionProtocolCode,
            rescheduled_from: moment(currentAppointmentTime).toISOString(),
            rescheduled_to: period?.begin.toISOString(),
            treatment_extended: !!selectedTimeSlot.timeSlot.isExtendingTreatment,
          }

          createSessionReschedulingRecord(rescheduleSessionRecordBody)

          showConfirmation({
            period,
            providerDetails,
            modalPeriodDateFormat,
            content,
            launchedFrom: queryParams.launched_from,
            showInfoBanner: isRescheduleSession,
          })
        }
      }
    } catch (error) {
      setIsConfirmationLoading(false)
      logToAnalytics('failedRequest', error)

      if (!isScheduleIc && !isScheduleFua) {
        Log.error('Failed to reschedule appointment', error)
        showRescheduleErrorDialog(history, content)
      } else if (isScheduleFua) {
        Log.error('Failed to schedule IC/FUA appointment', error)
        showScheduleErrorDialog(content)
      } else {
        Log.error('Failed to schedule IC appointment', error)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    content,
    e2TreatmentId,
    sessionProtocolCode,
    currentAppointmentTime,
    me?.eligibility_match_id,
    me?.campaign,
    me?.is_guest,
    queryParams.launched_from,
    isScheduleIc,
    isRescheduleIc,
    isRescheduleSession,
    isScheduleFua,
    selectedTimeSlot,
    selectStep,
    logToAnalytics,
    openErrorDialog,
    setUserActivityContent,
    submitAppointment,
    timezone,
  ])

  const confirmationDialogCallback = useConfirmationDialog(SCHEDULE_IC_DIALOG_SLUG, dialogResult => {
    if (dialogResult === 'choose-another-time') selectStep('SELECTION')
    if (dialogResult === 'confirm-consultation') submit()
  })

  const submitForm = useCallback(({ period, infoBanner }, providerDetails) => {
    if (infoBanner?.type === DIALOG_BANNER_TYPES.NEXT_MID_SESSION) logToAnalytics('moveMidSession')
    logToAnalytics('confirmationDialog')

    const confirmationDialogParams = {
      isScheduleIc,
      period,
      providerDetails,
      modalPeriodDateFormat,
      confirmationDialogCallback,
      infoBanner,
      content,
      schedulingType,
      sessionType,
    }

    return openConfirmationDialog(confirmationDialogParams)
  }, [
    isScheduleIc,
    confirmationDialogCallback,
    content,
    schedulingType,
    modalPeriodDateFormat,
    sessionType,
    logToAnalytics,
  ])

  const selectSlot = useCallback((timeSlot, providerDetails, sessionDetails) => {
    setSelectedTimeSlot({ timeSlot, providerDetails, sessionDetails })
    submitForm(timeSlot, providerDetails)
  }, [submitForm])

  // If there is a misspelled providerAvailability value,
  // we would like to default to fetching all providers availability
  const providerAvailabilityQuery =
    PROVIDER_AVAILABILITY_RULES[providerAvailability] ?? PROVIDER_AVAILABILITY_RULES.allProviders

  const contextState = useMemo(() => ({
    me,
    profile,
    content,
    options,
    showAllAvailableSlots,
    canExtendSession,
    providerAvailabilityQuery,
    isMobile,
    selectStep,
    selectSlot,
    logToAnalytics,
    timezone,
    selectedTimeSlot,
    setSelectedTimeSlot,
    isConfirmationLoading,
    submitForm,
    isCoachSession,
    sessionProtocolCode,
    sessionType,
    e2TreatmentId,
    availabilityStartDate,
    showPasswordToggle,
    practitioners,
  }), [
    me,
    profile,
    content,
    options,
    showAllAvailableSlots,
    canExtendSession,
    providerAvailabilityQuery,
    timezone,
    isConfirmationLoading,
    isMobile,
    selectStep,
    selectSlot,
    logToAnalytics,
    selectedTimeSlot,
    submitForm,
    isCoachSession,
    sessionProtocolCode,
    sessionType,
    e2TreatmentId,
    availabilityStartDate,
    showPasswordToggle,
    practitioners,
  ])
  const loaded = connectionsLoaded && meReloaded

  useEffect(() => {
    if (activeStep == null) continueActivity()
  }, [activeStep, continueActivity])

  return loaded && activeStep != null && (
    <AbleToInitialConsultContext.Provider value={contextState}>
      <Suspense fallback={<componentMap.Loading fullPage={true} />}>
        <activeStep.Component
          isReschedule={isRescheduleIc || isRescheduleSession || isRescheduleFua}
          isScheduleFua={isScheduleFua}
          isRescheduleFua={isRescheduleFua}
        />
      </Suspense>
    </AbleToInitialConsultContext.Provider>
  )
}

AbleToScheduleICTemplate.propTypes = {
  options: generatePropTypes(SCHEMA.options),
  content: generatePropTypes(SCHEMA.content),
}

export default AbleToScheduleICTemplate
