import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'
import Action from '../Action'
import { ApiFailure, Connection } from './types'
import { ApiAction, ApiState, ApiSuccessAction } from '../types'
import { registerModelReducer } from '../reducers'

const MODEL = new ApiModel('NPS_SURVEYS', 'api/v1/nps_surveys')

export type NpsSurvey = {
  id?: number,
  score?: number,
  feedback?: string,
}

type NpsSurveyConnection = {
  npsSurvey?: NpsSurvey,
  npsSurveyUpdateFailed?: ApiFailure,
  npsSurveyIsUpdating: boolean,
  saveNpsSurvey: (npsSurvey?: NpsSurvey) => Promise<ApiAction>,
}

type ModelState = {
  npsSurvey?: NpsSurvey,
}

const defaultState: ModelState = {
}

const { modelStateSelector } = registerModelReducer<ModelState>(
  'npsSurveys',
  (state = defaultState, action) => {
    const { response } = action as ApiSuccessAction

    switch (action.type) {
      case Action.CREATE.successType(MODEL):
      case Action.UPDATE_MODIFY.successType(MODEL): {
        const npsSurvey = response.nps_survey as NpsSurvey
        return {
          ...state,
          npsSurvey: npsSurvey,
        }
      }
      default:
        return state
    }
  },
)

const selectNpsSurvey = modelStateSelector((state) => state.npsSurvey)

const userOptions = (userId = 'me') => {
  return { query: { user_id: userId }, modelMapKey: ['user_id', userId] }
}

const updateFailed = (userId = 'me') => (state: ApiState) => {
  const url = MODEL.getUrl(userOptions(userId))
  const pending = Action.UPDATE_MODIFY.getPending(state, url)
  return Action.getError(pending)
}

async function save (npsSurvey?: NpsSurvey) {
  if (npsSurvey === undefined || npsSurvey?.id == null) return await MODEL.create({ nps_survey: npsSurvey })
  return await MODEL.updateModify(
    {
      nps_survey: {
        score: npsSurvey.score,
        feedback: npsSurvey.feedback,
      },
    },
    {
      id: npsSurvey.id,
    },
  )
}

const selectConnection = createSelector(
  [
    selectNpsSurvey,
    updateFailed(),
    MODEL.isUpdatingSelector(userOptions()),
  ],
  (npsSurvey, npsSurveyUpdateFailed, npsSurveyIsUpdating): NpsSurveyConnection => ({
    npsSurvey,
    npsSurveyUpdateFailed,
    npsSurveyIsUpdating,
    saveNpsSurvey: async (npsSurvey?: NpsSurvey) => await save(npsSurvey),
  }),
)

export const NpsSurvey: {
  connection: Connection<NpsSurveyConnection>,
} = {
  connection: {
    selector: selectConnection,
  },
}
