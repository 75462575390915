/* global Response */
import { createSelector } from 'reselect'
import PropTypes from 'prop-types'
import Action from '../Action'
import ApiModel from '../ApiModel'

const MODEL = new ApiModel('LANDING', 'api/v1/landings/visit')
const CHANNEL_MODEL = new ApiModel('LANDING_CHANNEL', 'api/v1/landings/channel')
const UTM_MODEL = new ApiModel('LANDING_UTM', 'api/v1/landings/utm')
/**
 * @param campaign the String campaign name specified in campaigns.yml in the classic codebase
 * @param channel A boolean indicating if this is user is a channel user (through a business
 * partner)
 */
const recordLanding = (data, channel, ignoreFailure = false) =>
  MODEL.create({ ...data, channel }, { ignoreFailure })
const recordLandingCode = (accessCode, channel, ignoreFailure = false) =>
  MODEL.create({ access_code: accessCode, channel }, { ignoreFailure })
const recordLandingChannel = (data, ignoreFailure = false) =>
  CHANNEL_MODEL.create({ ...data })
const recordLandingUtm = (data, ignoreFailure = false) =>
  UTM_MODEL.create({ ...data })
const clearPendingCreate = () => {
  const url = MODEL.getUrl()
  ApiModel.dispatch(Action.CREATE.getClearPendingAction(MODEL, url))
}

const recordFailed = state => Action.CREATE.getPendingFailed(state, MODEL.baseUrl)
export const isRecording = MODEL.isCreatingSelector()

export const Landing = {
  connection: {
    selector: createSelector(
      [isRecording, recordFailed],
      (landingIsRecording, landingRecordFailed) => ({
        landingIsRecording,
        landingRecordFailed,
        recordLanding,
        recordLandingCode,
        recordLandingChannel,
        recordLandingUtm,
        clearPendingCreate,
      }),
    ),

    shape: {
      landingIsRecording: PropTypes.bool.isRequired,
      landingRecordFailed: PropTypes.shape({
        response: PropTypes.instanceOf(Response),
        error: PropTypes.shape({ message: PropTypes.string }),
      }),

      recordLanding: PropTypes.func.isRequired,
      recordLandingCode: PropTypes.func.isRequired,
      recordLandingChannel: PropTypes.func.isRequired,
      recordLandingUtm: PropTypes.func.isRequired,
    },
  },
}
