import get from 'lodash/get'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel from '../ApiModel'

const MODEL = new ApiModel('COUNSELOR_EMAIL', 'api/v1/counselor_emails')

const selectPreview = state => get(state.api.counselor_emails, 'preview')
const previewIsGetting = MODEL.isCreatingSelector({ id: 'preview' })
const previewError = state => MODEL.getCreateErrorMessage(state, { id: 'preview' })
const emailIsSending = MODEL.isCreatingSelector({ id: 'send_email' })
const emailSendError = state => MODEL.getCreateErrorMessage(state, { id: 'send_email' })

const getPreview = model => MODEL.create({ counselor_email: model }, { id: 'preview' })
const clearPreview = () => ApiModel.pruneCache('counselor_emails', 'preview')
const clearPreviewError = () =>
  ApiModel.dispatch(Action.CREATE.getClearPendingAction(MODEL, MODEL.getUrl({ id: 'preview' })))
const sendEmail = model => MODEL.create({ counselor_email: model }, { id: 'send_email' })
const clearSendEmailError = () =>
  ApiModel.dispatch(Action.CREATE.getClearPendingAction(MODEL, MODEL.getUrl({ id: 'send_email' })))
const reset = () => ApiModel.pruneCache('counselor_emails')

export const CounselorEmail = {
  connection: {
    selector: createSelector(
      [selectPreview, previewIsGetting, previewError, emailIsSending, emailSendError],
      (preview, previewIsGetting, previewError, emailIsSending, emailSendError) => ({
        preview,
        previewIsGetting,
        previewError,
        emailIsSending,
        emailSendError,

        getPreview,
        clearPreview,
        clearPreviewError,
        sendEmail,
        clearSendEmailError,
        reset,
      }),
    ),

    shape: {
      preview: PropTypes.shape({
        body: PropTypes.string.isRequired,
      }),

      previewIsGetting: PropTypes.bool.isRequired,
      previewError: PropTypes.string,

      emailIsSending: PropTypes.bool.isRequired,
      emailSendError: PropTypes.string,

      getPreview: PropTypes.func.isRequired,
      clearPreview: PropTypes.func.isRequired,
      clearPreviewError: PropTypes.func.isRequired,
      sendEmail: PropTypes.func.isRequired,
      clearSendEmailError: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
    },
  },
}
