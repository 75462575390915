/* global NativeApp */
/* global NativeiOSApp */
import { get, includes } from 'lodash'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { createSelector } from 'reselect'
import Action from '../Action'
import {
  clearDeviceID, getDeviceId, getXsrfToken, getAuth0Tokens,
  storeAuth0Tokens, storeJwt, clearAuth0Tokens,
} from '../ApiFetch'
import ApiModel from '../ApiModel'
import { Firebase } from '../Firebase'
import Log from '../log'
import { registerModelReducer } from '../reducers'
import { SessionStorage } from '../SessionStorage'
import { CallStatus } from './CallStatus'
import { Socket } from './Socket'
import Cookie from 'js-cookie'
import {
  awaitMeNotLoading as awaitMeUserNotLoading,
  loadMe as loadMeUser,
  meKeys,
  selectMe as selectMeUser,
} from './User'
import { sendWebViewAuthPayload } from './webViewUtil'
import { FeatureFlags } from 'joyable-js-api'
import { auth0Client } from 'joyable-js-api/src/ApiFetch'

export const JWT_ERROR = 'JWT_ERROR'
const INVALID_CREDS = 'INVALID_LOGIN_CREDENTIALS'
const INVALID_GOOGLE_CREDS = 'INVALID_GOOGLE_CREDS'
const ACKNOWLEDGE_LOGIN_ERROR = 'ACKNOWLEDGE_LOGIN_ERROR'
const INVALID_OTP_ATTEMPT = 'Api::V1::ApiController::InvalidOTPAttempt'
const IS_LOGGING_IN = 'IS_LOGGING_IN'

const INITIAL_STATE = { isLoggingIn: false }

const EXPECTED_ERRORS = [
  'otp_required',
  'idp_required',
  'sms_mfa_required',
  'mfa_setup_required',
  'mfa_invalid',
  'verification_invalid',
]
registerModelReducer('authentication', (state = INITIAL_STATE, { type, value }) => {
  if (type === INVALID_GOOGLE_CREDS) {
    return { ...state, googleLoginError: value }
  } else if (type === ACKNOWLEDGE_LOGIN_ERROR) {
    return { ...state, googleLoginError: null }
  } else if (type === IS_LOGGING_IN) {
    return { ...state, isLoggingIn: value }
  }
  return state
})

function logFailureFilter (json, response, fetch) {
  if (fetch.action === Action.READ && response.status === 401) return false

  const isCreate = fetch.action === Action.CREATE
  return !(isCreate && includes(EXPECTED_ERRORS, get(json, 'error.reason')))
}

const MODEL = new ApiModel('AUTHENTICATION', 'api/v1/jwt', logFailureFilter)
const CLEAR_SESSION_MODEL = new ApiModel('CLEAR_SESSION', 'api/v1/authentication')

/**
 * Returns true if the client is currently trying to log in via API call.
 */
const isLoggingIn = state => state.models.authentication.isLoggingIn

const isLoggingOut = MODEL.isDestroyingSelector()

/**
 * Returns the error message if login failed.
 */
const loginFailed = state => state.models.authentication.googleLoginError != null
  ? {
      message: state.models.authentication.googleLoginError,
    }
  : get(Action.CREATE.getPendingFailed(state, MODEL.baseUrl), ['error'])

function tokenReadError (state) {
  const failed = Action.READ.getPendingFailed(state, MODEL.baseUrl)
  return failed && failed.response.status === 401
}

function isLoggedIn (state, dependOnStoreOnly = false) {
  if (isLoggingIn(state)) return false

  // if we have a token in the store, we're definitely logged in.
  if (state.api.token != null) return true

  // if there is a token create or read failure in the store we're definitely not logged in
  if (loginFailed(state) != null) return false
  if (tokenReadError(state)) return false

  // We can guess based on the existence of the xsrf token
  return dependOnStoreOnly ? null : getXsrfToken() != null
}

async function loginWithCustomToken (customIdpToken) {
  setLoggingIn(true)

  const { error } = await MODEL.create({ custom_token: customIdpToken }, { ignoreFailure: true })

  if (error != null) {
    ApiModel.dispatch({ type: JWT_ERROR, value: error })
  }

  await reloadMe()

  setLoggingIn(false)
}

/**
 * Login user with google IDP
 */
async function loginWithGoogleToken (email, token, otp, redirectTo, smsOtp, deviceId) {
  setLoggingIn(true)
  const data = {
    email,
    idp_token: token,
    auth0_idp_token: null,
    otp_attempt: otp,
    redirect_to: redirectTo,
    sms_mfa_attempt: smsOtp,
    device_identifier: deviceId,
  }

  const { response, error } = await MODEL.create(data, { ignoreFailure: true })

  if (error != null) {
    ApiModel.dispatch({ type: JWT_ERROR, value: error })
  }

  if (!error) {
    await sendWebViewAuthPayload(token, email, response)
  }

  await reloadMe()

  setLoggingIn(false)
  return error
}

function isSignInWithEmailLink (url = globalThis.location.href) {
  return Firebase.auth.isSignInWithEmailLink(url)
}

/**
 * Sends an auth email (Firebase only)
 */
async function sendSignInEmail (email) {
  const settings = {
    url: `${window.location.protocol}//${window.location.host}/?email=${encodeURIComponent(email)}`,
    handleCodeInApp: true,
  }
  return Firebase.auth.sendSignInLinkToEmail(email, settings)
}

function setLoggingIn (value) {
  ApiModel.dispatch({ type: IS_LOGGING_IN, value })
}

async function loginFromEmailLink (url = globalThis.location.href) {
  if (!isSignInWithEmailLink(url)) throw new Error('No sign in link found')

  // When sending this email from firebase we can pull the email address from the parameters.
  // When sending from the monolith backend we must pull the email address from session storage.
  const { query: { continueUrl } } = queryString.parseUrl(url)
  const {
    query: {
      email = SessionStorage.getOnboardingEmail(),
    },
  } = queryString.parseUrl(continueUrl)

  setLoggingIn(true)
  try {
    const credential = await Firebase.signInWithEmailLink(email, url)
    const token = await credential.user.getIdToken(true)

    return await loginWithGoogleToken(email, token)
  } finally {
    setLoggingIn(false)
  }
}

async function updatePassword (password) {
  const currentUser = Firebase.auth.currentUser
  if (currentUser == null) {
    throw new Error('Not currently logged in')
  }

  try {
    return currentUser.updatePassword(password)
  } catch (error) {
    Log.warn('Update password failed, trying to reauthenticate', error)
    const credential = Firebase.getCredentialWithLink(currentUser.email, window.location.href)
    currentUser.reauthenticateWithCredential(credential).then(() => {
      Log.info('reauthentication successful')
      return currentUser.updatePassword(password)
    })
  }
}

async function reloadMe () {
  // regardless of result, re-fetch users/me
  ApiModel.trimCache(meKeys())
  loadMeUser()
  ApiModel.pruneCache('branches', 'redirect')

  if (!selectMeUser(await awaitMeUserNotLoading()).is_guest) {
    Socket.connectSocket()
    CallStatus.updateClientTimezoneIfNeeded()
  }
}

async function login (
  email,
  password,
  otp = null,
  redirectTo = null,
  eligibilityFailureUrl = null,
) {
  setLoggingIn(true)
  try {
    if (password != null) {
      // If user has provided email & password, validate them with Firebase
      try {
        const token = await Firebase.getGoogleToken(email, password)
        // Log user in with valid idp token
        return await loginWithGoogleToken(
          email, token, otp, redirectTo, null, window.localStorage.getItem('rememberDevice') ? getDeviceId() : null,
        )
      } catch (error) {
        ApiModel.dispatch({ type: INVALID_GOOGLE_CREDS, value: INVALID_CREDS })

        // Only log actual errors, not invalid credential responses
        if (error.code.match(/auth\//) == null) {
          Log.error('AuthError', error)
        }
        return error
      }
    }

    const data = {
      email,
      otp_attempt: otp,
      redirect_to: redirectTo,
      eligibility_failure_url: eligibilityFailureUrl,
    }

    // If a password hasn't been provided, we are attempting to validate credentials against the Consumer server
    const { error, response } = await MODEL.create(data, { ignoreFailure: true })

    if (error != null) {
      ApiModel.dispatch({ type: JWT_ERROR, value: error })
    }

    if (response?.jwt?.sso_redirect_url != null) {
      window.location = response.jwt.sso_redirect_url
      return null
    }

    await reloadMe()

    return error
  } finally {
    setLoggingIn(false)
  }
}

async function logout (auth0Logout, postLogoutLocation = '/') {
  const result = await MODEL.destroy({
    query: window.localStorage.getItem('rememberDevice') ? { device_identifier: getDeviceId() } : null,
    apiKeyOverride: ['token'],
    ignoreFailure: true,
  })
  if (result.type === Action.DESTROY.successType(MODEL)) {
    if (!FeatureFlags.current.auth0_login) {
      await Firebase.auth.signOut()
    } else {
      clearAuth0Tokens()
      auth0Logout()
    }

    if (NativeApp.isHosted()) NativeApp.signOut()

    // force a refresh on the joyable landing page so that our redux store gets all cleared
    // out of cached info about this client.
    // NOTE: If we stop doing a refresh here and instead clear out the redux store, make
    // sure we also call Socket.disconnectSocket()
    window.location = postLogoutLocation
  }
  Cookie.remove('preferredLocale')
  window.localStorage.removeItem('rememberDevice')
  return result
}

async function forgetDevice () {
  const result = await MODEL.destroy({
    query: window.localStorage.getItem('rememberDevice') ? { device_identifier: getDeviceId() } : null,
    apiKeyOverride: ['token'],
    ignoreFailure: true,
  })
  window.localStorage.removeItem('rememberDevice')
  return result
}

async function clearSession () {
  return await CLEAR_SESSION_MODEL.read({ id: 'clear_session' })
}

/**
 * Should be called at application start in order to populate the log in/log out button
 * state asap.
 */
const loadToken = ({ jwtToken = null, samlCampaign = null, signInMethod = null } = {}) => {
  const options = {}
  if (samlCampaign != null) {
    options.query = { saml_campaign: samlCampaign }

    if (process.env.TOOLS_ENABLED) {
      // In pre-prod environments, we can pass `id_data` query params to supply critical fields from our fake
      // SAML IDP
      const { url, query } = queryString.parseUrl(window.location.href)
      const redirectQuery = Object.entries(query).reduce((result, [key, value]) => {
        if (key.startsWith('id_data[')) {
          options.query[key] = value
          // Avoid including id_data[...] in the redirect_to URL
          return result
        } else {
          return { ...result, [key]: value }
        }
      }, {})
      options.query.redirect_to = `${url}?${queryString.stringify(redirectQuery)}`
    } else {
      options.query.redirect_to = window.location.href
    }
  }
  if (NativeApp.isHosted()) jwtToken = NativeApp.getJwtToken()
  if (NativeiOSApp.isHosted() && NativeiOSApp.getJwtToken) {
    jwtToken = NativeiOSApp.getJwtToken()
  }

  if (jwtToken != null) {
    if (signInMethod === 'email_auth_link') clearDeviceID()
    storeJwt(jwtToken)
  }

  return MODEL.read(options)
}

const loadAuth0Tokens = async () => {
  const { accessToken, idpToken } = getAuth0Tokens()
  if (accessToken && idpToken) return { accessToken, idpToken }

  try {
    const client = await auth0Client()
    const { id_token: auth0IdpToken, access_token: auth0AccessToken } = await client.getTokenSilently({
      detailedResponse: true,
      cacheMode: 'off',
    })

    if (auth0IdpToken && auth0AccessToken) {
      storeAuth0Tokens(auth0AccessToken, auth0IdpToken)
      return { idpToken: auth0IdpToken, accessToken: auth0AccessToken }
    }
  } catch (error) {
    return { error }
  }
}

const clearFailedLogin = () => {
  ApiModel.dispatch(Action.CREATE.getClearPendingAction(MODEL, MODEL.baseUrl))
  ApiModel.dispatch({ type: ACKNOWLEDGE_LOGIN_ERROR })
}

export const Authentication = {
  // A special case function that is used on app launch and cannot cleanly be migrated to a
  // connection.
  loadToken,
  loadAuth0Tokens,

  // Special case functions that are often called from components that don't actually watch
  // the connection state of authentication, and therefore don't need to be wrapped in a model
  // connection.
  login,
  logout,
  forgetDevice,
  clearFailedLogin,
  clearSession,
  loginWithGoogleToken,
  loginWithCustomToken,
  reloadMe,

  // Used for email authentication (firebase only)
  isSignInWithEmailLink,
  sendSignInEmail,
  loginFromEmailLink,
  updatePassword,

  INVALID_CREDS,
  INVALID_OTP_ATTEMPT,

  isLoggedInConnection: {
    selector: createSelector(
      [
        state => isLoggedIn(state),
        state => isLoggingIn(state) || isLoggingOut(state),
        loginFailed,
        state => get(state.api, 'jwt.sso_redirect_url'),
      ],
      (isLoggedIn, authenticationIsLoading, loginFailed, ssoUrl) => ({
        isLoggedIn,
        authenticationIsLoading,
        loginFailed,
        ssoUrl,
        login,
        clearFailedLogin,
      }),
    ),

    shape: {
      isLoggedIn: PropTypes.bool.isRequired,
      authenticationIsLoading: PropTypes.bool.isRequired,
      loginFailed: PropTypes.shape({
        message: PropTypes.string.isRequired,
        reason: PropTypes.string,
      }),
      ssoUrl: PropTypes.string,

      login: PropTypes.func,
      clearFailedLogin: PropTypes.func,
    },
  },
}
