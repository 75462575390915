import hoistStatics from 'hoist-non-react-statics'
import { createRef, Component, useEffect } from 'react'
import { makeCancelable } from '../../lib/makeCancelable'
import Log from '../../services/log'

export const safeSideEffect = (perform, callbackName = 'sideEffectComplete') =>
  safeSideEffects([{ perform, callbackName }])

/**
 * An hook to perform a side effect and only execute the callback if the component is still mounted
 * when the side effect promise completes.
 *
 * @param sideEffects an array with two members:
 * @param dependencies an array of dependencies
 * @param sideEffects.perform Function that returns a promise
 * @param sideEffects.callback The function to finish resolving the side effect
 */
export const useSafeSideEffect = (sideEffects, dependencies = []) => {
  useEffect(() => {
    const cancellors = sideEffects.reduce((cancellors, { perform, callback }) => {
      const { cancel, promise } = makeCancelable(perform())
      promise
        .then(callback)
        .catch(error => {
          if (error == null || error.isCanceled !== true) {
            Log.warn('Uncaught promise error in safeSideEffect', error)
          }
        })
      return [...cancellors, cancel]
    }, [])

    return () => {
      cancellors.forEach(cancel => cancel())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}

/**
 * An HOC to perform a side effect and only execute the callback if the component is still mounted
 * when the side effect promise completes.
 *
 * @param sideEffects an array with two members:
 * @param sideEffects.perform Function that returns a promise and receives the props
 * @param sideEffects.callbackName The function name to look for on the child component to finish resolving the
 * side effect
 */
export const safeSideEffects = sideEffects => ChildComponent => {
  class Initializable extends Component {
    static displayName = `initializable(${ChildComponent.displayName || ChildComponent.name})`

    childRef = createRef()

    componentDidMount () {
      this.cancellors = sideEffects.reduce((cancellors, { perform, callbackName }) => {
        const { cancel, promise } = makeCancelable(perform(this.props))
        promise
          .then((...args) => this.childRef.current[callbackName](...args))
          .catch(error => {
            if (error == null || error.isCanceled !== true) {
              Log.warn('Uncaught promise error in safeSideEffect', error)
            }
          })
        return [...cancellors, cancel]
      }, [])
    }

    componentWillUnmount () {
      this.cancellors.forEach(cancel => cancel())
    }

    render () {
      return <ChildComponent {...this.props} ref={this.childRef} />
    }
  }

  return hoistStatics(Initializable, ChildComponent)
}
