import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel from '../ApiModel'
import { registerModelReducer } from '../reducers'
import { invertPredicate } from './utilities'
import awaitState from '../awaitState'

const MODEL = new ApiModel('NOTIFICATION_SETTINGS', 'api/v1/notification_settings')

registerModelReducer('notificationSettings', (state = { }, { type, response }) => {
  if (type === Action.READ.successType(MODEL)) {
    return { ...state, myNotificationSettingsId: response.notification_settings.id }
  }

  return state
})

const REMINDER_TYPE = ['push', 'email', 'none']

const selectMyNotificationSettings = createSelector(
  [state => state.models.notificationSettings, state => state.api.notification_settings],
  ({ myNotificationSettingsId: myId }, models) => myId && models && models[myId],
)

const selectConnection = createSelector(
  [selectMyNotificationSettings, MODEL.isUpdatingSelector()],
  (notificationSettings, notificationSettingsUpdating) => ({
    notificationSettings,
    notificationSettingsUpdating,

    updateNotificationSettings: (updates) => {
      if (notificationSettings.notification_type !== updates.notification_type) {
        MODEL.updateModify({
          notification_settings: {
            ...notificationSettings,
            ...updates,
          },
        })
      }
    },
  }),
)

export const NotificationSettings = {
  REMINDER_TYPE,

  updateNotificationType: type =>
    MODEL.updateModify({ notification_settings: { notification_type: type } }),

  awaitNotUpdating: () => awaitState(invertPredicate(MODEL.isUpdatingSelector())),

  connection: {
    load: ({ notificationSettings }) => {
      if (notificationSettings == null) MODEL.read()
    },

    isLoaded: ({ notificationSettings }) => notificationSettings != null,

    selector: selectConnection,

    shape: {
      notificationSettings: PropTypes.shape({
        notification_type: PropTypes.oneOf(REMINDER_TYPE),
        weekly_reminder_type: PropTypes.oneOf(REMINDER_TYPE),
      }).isRequired,
      notificationSettingsUpdating: PropTypes.bool.isRequired,

      updateNotificationSettings: PropTypes.func.isRequired,
    },
  },
}
