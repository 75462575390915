import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel from '../ApiModel'
import { registerModelReducer } from '../reducers'
import { userModelSelector } from './utilities'
import { selectorCache } from '../util/selectorCache'

const MODEL = new ApiModel('USER_PREFERENCES', 'api/v1/user_preferences')

registerModelReducer('userPreferences', (state = { }, { type }) => {
  if (type === Action.READ.successType(MODEL)) {
    return { ...state }
  }

  return state
})

const availableTopics = ['appointment_type']

const selectConnection = createSelector(
  [state => state.api.user_preferences, MODEL.isUpdatingSelector()],
  (userPreferences, userPreferencesUpdating) => ({
    userPreferences,
    userPreferencesUpdating,

    updatePreferencesSettings: (newPreferences, isExpectedValue = false) => {
      if (isExpectedValue) {
        return MODEL.updateModify({ user_preferences: [...newPreferences] })
      }

      const allPreferences = Object.values(userPreferences)
      const preferencesToUpdate = Object.keys(newPreferences).filter((pref) => pref.includes(availableTopics))
      const updatedPreferences = preferencesToUpdate.filter((pref) => {
        const preference = allPreferences.find((prefInAll) => prefInAll.topic === pref)
        return newPreferences[pref] !== preference.preference
      }).map((pref) => {
        const preference = allPreferences.find((prefInAll) => prefInAll.topic === pref)
        return { preference: newPreferences[pref], topic: preference.topic }
      })

      if (updatedPreferences.length > 0) {
        MODEL.updateModify({ user_preferences: [...updatedPreferences] })
      }
    },
  }),
)

const loadForUser = userId => MODEL.read({ id: userId, modelMapKey: ['user_id', userId] })

const selectCoachConnection = selectorCache(
  ({ user }) => user,
  ({ user }) => createSelector(
    [userModelSelector(user.id, 'user_preferences')],
    (userPreferences) => ({ userPreferences }),
  ),
)

export const UserPreferences = {
  connection: {
    load: ({ userPreferences }) => {
      if (userPreferences == null) {
        MODEL.read()
      }
    },

    isLoaded: ({ userPreferences }) => userPreferences != null,

    selector: selectConnection,
  },

  coachConnection: {
    load: ({ userPreferences }, { user }) => {
      if (userPreferences == null) {
        loadForUser(user.id)
      }
    },

    isLoaded: ({ userPreferences }) => userPreferences != null,

    selector: selectCoachConnection,
  },
}
