import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'

const MODEL = new ApiModel('ADMIN_TOOL', 'api/v1/admin_tools')

const SHAPE = PropTypes.shape({
  slug: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.array,
  arguments: PropTypes.array,
})

export const AdminTool = {
  SHAPE,
  connection: {
    load: ({ adminTools }) => {
      if (adminTools == null) MODEL.read()
    },

    isLoaded: ({ adminTools }) => adminTools != null,

    selector: createSelector(
      [state => state.api.admin_tools],
      adminTools => ({ adminTools }),
    ),

    shape: {
      adminTools: PropTypes.arrayOf(SHAPE),
    },
  },
}
