/* global Response */
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel from '../ApiModel'
import {
  registerModelReducer,
} from '../reducers'
import { selectorCache } from '../util/selectorCache'
import { invertPredicate } from './utilities'
import { sendWebViewAuthPayload } from './webViewUtil'
import { Firebase } from '../Firebase'
import { FeatureFlags } from './FeatureFlags'
import Log from '../log'
import awaitState from '../awaitState'
import { ApiFailure, Connection, FailedApiResolution } from './types'
import { Action as ActionType, AnyAction } from 'redux'
import {
  ApiAction,
  ApiFailureAction,
  ApiKey,
  ApiState,
  ApiSuccessAction,
  isFailureAction,
} from '../types'

type DeviceType = 'ios' | 'android' | 'browser'
type ProgramLevel = 'basic' | 'standard' | 'concierge' | 'tplus' | 'pbh' | 't360' | 'self_care'
/* eslint-disable camelcase */
export type User = {
  id: number,
  email?: string,
  role: string,
  is_guest: boolean,
  onboarding_complete: boolean,
  program_slug?: string,
  program_level?: ProgramLevel,
  program_indication?: string,
  experiments: Record<string, string>,

  escalated_at?: string,
  is_escalated_role: boolean,
  is_provider_account: boolean,
  channel?: boolean,
  connect_channel?: string,
  can_select_payer: boolean,
  campaign?: string,
  campaign_detail?: Record<string, any>,
  program_period?: number,
  suicidality_answer?: number,
  coach_tool_avatar?: {
    icon: string,
    theme: string,
    skin_tone: string,
  },
  current_assessment?: {
    activity_slug: string,
    program_activity_id: string,
    monthly_activity_slug?: string,
    monthly_program_activity_id?: string,
  }
  subscription_id?: number,
  profile_id?: number,
  call_status_id?: number,
  coach_call_completed_at?: string,
  last_contacted_at?: string,
  last_scheduled_call_at?: string,
  latest_incoming_message_id?: number,
  is_self_guided: boolean,
  is_auto_greeted: boolean,
  coach_id?: number,
  device_types: DeviceType[],
  notification_preference?: 'push' | 'sms' | 'email' | 'none',
  has_chat: boolean,
  insurance_member_id?: string,
  eligibility_match_id?: string,
  account_deletion_requested: boolean,
  calls_completed: number,
  coaching_sessions_count: number,
  is_auto_escalated: boolean,
  is_video_only: boolean,
  is_post_program: boolean,

  re_entering_connect_in_progress?: boolean,
}
/* eslint-enable camelcase */

type MeConnection = {
  me?: User,
  loadMe: () => Promise<ApiAction>,
  meIsLoaded: boolean,
  meIsLoading: boolean,
}

type EditMeConnection = {
  loadMe: () => Promise<ApiAction>,
  me?: User,
  meIsLoaded: boolean,
  meIsLoading: boolean,

  createUser: (email: string, password: string) => Promise<ApiAction>,
  clearUserCreateFailed: () => void,
  userIsCreating: boolean,
  userCreateError?: string,
  userCreateFailedDetails?: Record<string, any>,

  saveUser: (user: User) => Promise<ApiAction>,
  clearUserSaveFailed: () => void,
  userIsSaving: boolean,
  userSaveError?: string,
  userSaveFailedReason?: string,
  userSaveFailedDetails?: Record<string, any>,
}

type DeletionRequestConnection = {
  sendDeletionRequest: (reason: string) => Promise<void>,
  deletionRequestInProgress?: boolean,
  deletionRequestFailed?: ApiFailure,
  deletionRequestSuccess?: boolean,
}

type PasswordResetConnectionProps = {
  resetToken: string,
  isIdP: boolean,
}

type PasswordResetConnection = {
  requestPasswordReset: (email: string) => void,
  clearFailedPasswordReset: () => void,
  passwordResetInProgress: boolean,
  passwordResetFailed?: ApiFailure,
  passwordResetSuccess?: boolean,

  setPassword: (password: string) => void,
  clearFailedPasswordSet: () => void,
  setPasswordInProgress: boolean,
  setPasswordFailed?: ApiFailure,
  passwordResetEmail?: string,
}

type CoachConnectionProps = {
  userId: number,
}

type CoachConnection = {
  user?: User,
  userLoadFailed?: FailedApiResolution,
  userIsUpdating: boolean,

  updateUserEscalation: (escalated: boolean) => void,
  updateEscalationReviewer: (escalationReviewerId: number) => void,
}

type ModelState = Partial<{
  resetPasswordEmail: string,
  creationInProgress: boolean,
  creationError: ApiFailure,
  passwordResetInProgress: boolean,
  passwordResetError: ApiFailure,
  passwordFinalizeInProgress: boolean,
  passwordFinalizeError: ApiFailure,
  passwordResetSuccess: boolean,
  deletionRequestInProgress: boolean,
  deletionRequestError: ApiFailure,
  deletionRequestSuccess: boolean
}>

type InProgressAction = ActionType<string> & {
  inProgress: boolean,
  error?: Error
}

const isInProgressAction = (action: AnyAction): action is InProgressAction =>
  typeof action.inProgress === 'boolean'

const USER_CREATION = 'USER_CREATION'
const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST'
const PASSWORD_RESET_FINALIZE = 'PASSWORD_RESET_FINALIZE'
const USER_DELETION_REQUEST = 'USER_DELETION_REQUEST'

const { modelStateSelector } = registerModelReducer<ModelState>(
  'user',
  (state = {}, action): ModelState => {
    const { type } = action
    if (type === 'USER_SINGULAR_UPDATE_MODIFY_SUCCESS') {
      const { response } = action as ApiSuccessAction
      return { ...state, resetPasswordEmail: (response.user as User).email }
    }

    if (!isInProgressAction(action)) return state

    const { inProgress, error } = action
    if (type === USER_CREATION) {
      return { ...state, creationInProgress: inProgress, creationError: error }
    } else if (type === PASSWORD_RESET_REQUEST) {
      return { ...state, passwordResetInProgress: inProgress, passwordResetError: error }
    } else if (type === PASSWORD_RESET_FINALIZE) {
      return {
        ...state,
        passwordFinalizeInProgress: inProgress,
        passwordFinalizeError: error,
        passwordResetSuccess: !inProgress && error == null,
      }
    } else if (type === USER_DELETION_REQUEST) {
      return {
        ...state,
        deletionRequestInProgress: inProgress,
        deletionRequestError: error,
        deletionRequestSuccess: !inProgress && error == null,
      }
    }

    return state
  },
)

const MODEL = new ApiModel('USER', 'api/v1/users')
export const LOAD_SUCCESS_TYPE = Action.READ.successType(MODEL)

/**
 * @deprecated When the components that import this are switched to TS, this should no longer be
 * needed
 */
export const SHAPE = PropTypes.shape({
  id: PropTypes.number,
  email: PropTypes.string,
  role: PropTypes.string,
  is_guest: PropTypes.bool,
  onboarding_complete: PropTypes.bool,
  program_slug: PropTypes.string,
  program_level: PropTypes.string,
  program_indication: PropTypes.string,
  experiments: PropTypes.objectOf(PropTypes.string),

  escalated_at: PropTypes.string,
  is_escalated_role: PropTypes.bool,
  channel: PropTypes.bool,
  connect_channel: PropTypes.string,
  can_select_payer: PropTypes.bool,
  campaign: PropTypes.string,
  campaign_detail: PropTypes.object,
  program_period: PropTypes.number,
  suicidality_answer: PropTypes.number,
  coach_tool_avatar: PropTypes.objectOf(PropTypes.string),
  subscription_id: PropTypes.number,
  profile_id: PropTypes.number,
  call_status_id: PropTypes.number,
  coach_call_completed_at: PropTypes.string,
  last_contacted_at: PropTypes.string,
  last_scheduled_call_at: PropTypes.string,
  latest_incoming_message_id: PropTypes.number,
  is_self_guided: PropTypes.bool,
  is_auto_greeted: PropTypes.bool,
  coach_id: PropTypes.number,
  device_types: PropTypes.arrayOf(PropTypes.string),
  notification_preference: PropTypes.oneOf(['push', 'sms', 'email', 'none']),
  has_chat: PropTypes.bool,
  insurance_member_id: PropTypes.string,
  eligibility_match_id: PropTypes.string,
  account_deletion_requested: PropTypes.bool,
  calls_completed: PropTypes.number,
  coaching_sessions_count: PropTypes.number,
  is_auto_escalated: PropTypes.bool,
})

async function createUser (email: string, password: string): Promise<ApiAction> {
  ApiModel.dispatch({ type: USER_CREATION, inProgress: true })

  try {
    const result = await MODEL.create({ user: { email } })
    if (isFailureAction(result)) {
      ApiModel.dispatch({ type: USER_CREATION, inProgress: false, error: result.error })
      return result
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (FeatureFlags.current.auth0_login !== true) {
      const idpToken = await Firebase.createUserInIdp(email, password)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (FeatureFlags.current.sms_mfa === true) await sendWebViewAuthPayload(idpToken)
    }
    ApiModel.dispatch({ type: USER_CREATION, inProgress: false })
    return result
  } catch (error) {
    Log.error('Error occurred creating account', error)
    ApiModel.dispatch({ type: USER_CREATION, inProgress: false, error })
    return { error } as ApiFailureAction
  }
}

/**
 * Returns true if the client is currently trying to create a new user
 */
const isCreating = modelStateSelector(state => state.creationInProgress ?? false)

/**
 * Returns the error message if user creation failed.
 */
const creationError = modelStateSelector(state => state.creationError?.message)
const createFailed = (state: ApiState) => MODEL.getCreateErrorMessage(state) ?? creationError(state)

const createFailedDetails = (state: ApiState) => MODEL.getCreateErrorDetails(state)

const clearCreateFailed = () => {
  ApiModel.dispatch(Action.CREATE.getClearPendingAction(MODEL, MODEL.baseUrl))
  ApiModel.dispatch({ type: USER_CREATION, inProgress: false, error: null })
}

export const selectMeId = (state: ApiState) => get(state, 'api.users.me') as ApiKey

/**
 * If available, returns the `me` User object
 */
export function selectMe (state: ApiState): User | undefined {
  const id = selectMeId(state)
  const users = selectUsers(state)
  return id == null || users == null ? undefined : selectUsers(state)[id]
}

export function loadMe () {
  ApiModel.trimCache([['coaches'], ['coach_availability']])
  return MODEL.read({ id: 'me' })
}

const isLoading = MODEL.isReadingSelector({ id: 'me' })
const isLoaded = (state: ApiState) => selectMeId(state) != null

export const awaitMeNotLoading =
  () => awaitState(invertPredicate(MODEL.isReadingSelector({ id: 'me' })))

const save = (user: User) => MODEL.updateModify({ user }, { id: user.id })

function isSaving (state: ApiState) {
  const id = selectMeId(state)
  return id != null && MODEL.isUpdating(state, { id })
}

function saveFailed (state: ApiState) {
  const id = selectMeId(state)
  return id == null ? undefined : MODEL.getUpdateErrorMessage(state, { id })
}

const saveFailedReason = (state: ApiState) => {
  const id = selectMeId(state)
  return MODEL.getUpdateErrorReason(state, { id })
}

const saveFailedDetails = (state: ApiState) => {
  const id = selectMeId(state)
  return MODEL.getUpdateErrorDetails(state, { id })
}

function clearSaveFailed () {
  const id = selectMeId(ApiModel.reduxState)
  ApiModel.dispatch(Action.UPDATE_MODIFY.getClearPendingAction(MODEL, MODEL.getUrl({ id })))
}

const resetOptions = { id: 'reset_password' }
const resetUrl = MODEL.getUrl(resetOptions)

async function requestPasswordReset (email: string) {
  ApiModel.dispatch({ type: PASSWORD_RESET_REQUEST, inProgress: true })
  try {
    const settings = {
      // Send the user to the dashboard page after resetting password and logging them in
      // with the provided password. Implemented as a hard coded string here
      // to avoid importing Pages from the app code in library code.
      url: `${window.location.protocol}//${window.location.host}/dashboard?email=${encodeURIComponent(email)}`,
    }
    // TODO: can be removed after Firebase is converted to TS
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    await Firebase.auth.sendPasswordResetEmail(email, settings)

    ApiModel.dispatch({ type: PASSWORD_RESET_REQUEST, inProgress: false })
  } catch (error) {
    Log.warn('Error occurred during password reset', error)
    ApiModel.dispatch({ type: PASSWORD_RESET_REQUEST, inProgress: false })
  }
}

const passwordIsResetting =
  modelStateSelector(state => state.passwordFinalizeInProgress ?? false)
const passwordResetFailed = modelStateSelector(state => state.passwordResetError)
const passwordResetSuccess = modelStateSelector(state => state.passwordResetSuccess)
const clearFailedPasswordReset = () => {
  ApiModel.dispatch(Action.CREATE.getClearPendingAction(MODEL, resetUrl))
  ApiModel.dispatch({ type: PASSWORD_RESET_REQUEST, inProgress: false, error: null })
}

const deletionRequestOptions = { id: 'deletion_request' }

async function sendDeletionRequest (reason: string) {
  ApiModel.dispatch({ type: USER_DELETION_REQUEST, inProgress: true })
  try {
    const result = await MODEL.create({ audit_deletion_request: { reason } }, deletionRequestOptions)
    if (isFailureAction(result)) {
      ApiModel.dispatch({ type: USER_DELETION_REQUEST, inProgress: false, error: result.error })
      return Promise.reject(result.error)
    }

    ApiModel.dispatch({ type: USER_DELETION_REQUEST, inProgress: false })
  } catch (error) {
    Log.error('Error occurred during user deletion request', error)
    ApiModel.dispatch({ type: USER_DELETION_REQUEST, inProgress: false, error })
    return Promise.reject(error)
  }
}

const deletionRequestInProgress =
  modelStateSelector(state => state.deletionRequestInProgress ?? false)
const deletionRequestFailed = modelStateSelector(state => state.deletionRequestError)
const deletionRequestSuccess = modelStateSelector(state => state.deletionRequestSuccess)

// These methods use a singular `user` endpoint instead of `users`
const WITH_TOKEN_MODEL = new ApiModel('USER_SINGULAR', 'api/v1/user')

const setPasswordOptions = (token: string) => ({ query: { reset_password_token: token } })
const setPasswordUrl = (token: string) => WITH_TOKEN_MODEL.getUrl(setPasswordOptions(token))

async function setPassword (resetToken: string, password: string, isIdP: boolean) {
  ApiModel.dispatch({ type: PASSWORD_RESET_FINALIZE, inProgress: true })
  try {
    if (isIdP) {
      // TODO This can be removed when Firebase is converted to TS
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      await Firebase.auth.confirmPasswordReset(resetToken, password)
    } else {
      const result =
        await WITH_TOKEN_MODEL.updateModify({ user: { password } }, setPasswordOptions(resetToken))
      if (isFailureAction(result)) {
        ApiModel.dispatch({ type: PASSWORD_RESET_FINALIZE, inProgress: false, error: result.error })
        return
      }
    }

    ApiModel.dispatch({ type: PASSWORD_RESET_FINALIZE, inProgress: false })
  } catch (error) {
    Log.error('Error occurred during password reset finalization', error)
    ApiModel.dispatch({ type: PASSWORD_RESET_FINALIZE, inProgress: false, error })
  }
}

const setPasswordInProgress = modelStateSelector(state => state.passwordFinalizeInProgress ?? false)
const setPasswordFailed = modelStateSelector(state => state.passwordFinalizeError)
const clearFailedPasswordSet = (resetToken: string, isIdP: boolean) => {
  ApiModel.dispatch({ type: PASSWORD_RESET_FINALIZE, inProgress: false, error: null })
  if (!isIdP) {
    ApiModel.dispatch(
      Action.UPDATE_MODIFY.getClearPendingAction(WITH_TOKEN_MODEL, setPasswordUrl(resetToken)),
    )
  }
}

const selectPasswordResetEmail = modelStateSelector((state) => state.resetPasswordEmail)

export const selector = (userId?: number) => (state: ApiState) =>
  userId == null ? undefined : (selectUsers(state) ?? {})[userId]

const userLoadFailedSelector = (userId?: number) => (state: ApiState) => {
  if (userId == null) return undefined
  return Action.getFailed(Action.READ.getPending(state, MODEL.getUrl({ id: userId })))
}

export const selectUsers = (state: ApiState) => (state.api as ApiState).users as { [key: ApiKey]: User }

const selectCoachConnection = selectorCache<CoachConnection, CoachConnectionProps>(
  ({ userId }) => userId,
  ({ userId }) => {
    const pendingSelector = createSelector(
      [Action.UPDATE_MODIFY.pendingSource],
      pending => pending[MODEL.getUrl({ id: userId })],
    )

    return createSelector(
      [selector(userId), userLoadFailedSelector(userId), pendingSelector],
      (user, userLoadFailed, pending): CoachConnection => ({
        user,
        userLoadFailed,
        userIsUpdating: Action.isActing(pending),

        updateUserEscalation: escalated => {
          void MODEL.updateModify({ user: { escalated } }, { id: userId })
        },

        updateEscalationReviewer: escalationReviewerId => {
          void MODEL.updateModify(
            { user: { escalation_reviewer_id: escalationReviewerId } },
            { id: userId },
          )
        },
      }),
    )
  },
)

// Returns an array of keys that are related to the "me" user object.
export const meKeys = () => {
  const meId = selectMeId(ApiModel.reduxState)
  return meId == null
    ? []
    : [
        ['users', 'me'],
        ['users', meId],
      ]
}

const selectPasswordResetConnection = selectorCache<PasswordResetConnection, PasswordResetConnectionProps>(
  ({ resetToken, isIdP }) => `${resetToken}|${isIdP}`,
  ({ resetToken, isIdP }) => createSelector(
    [
      passwordIsResetting,
      passwordResetFailed,
      passwordResetSuccess,
      setPasswordInProgress,
      setPasswordFailed,
      selectPasswordResetEmail,
    ],
    (
      passwordResetInProgress,
      passwordResetFailed,
      passwordResetSuccess,
      setPasswordInProgress,
      setPasswordFailed,
      passwordResetEmail,
    ): PasswordResetConnection => ({
      requestPasswordReset: (email: string) => void requestPasswordReset(email),
      clearFailedPasswordReset,
      passwordResetInProgress,
      passwordResetFailed,
      passwordResetSuccess,
      setPassword: (password: string) => void setPassword(resetToken, password, isIdP),
      setPasswordInProgress,
      clearFailedPasswordSet: () => clearFailedPasswordSet(resetToken, isIdP),
      setPasswordFailed,
      passwordResetEmail,
    }),
  ),
)

const selectDeletionRequestConnection = createSelector(
  [
    deletionRequestInProgress,
    deletionRequestFailed,
    deletionRequestSuccess,
  ],
  (
    deletionRequestInProgress,
    deletionRequestFailed,
    deletionRequestSuccess,
  ): DeletionRequestConnection => ({
    sendDeletionRequest,
    deletionRequestInProgress,
    deletionRequestFailed,
    deletionRequestSuccess,
  }),
)

const selectEditMeConnection = createSelector(
  [
    selectMe,
    isLoaded,
    isLoading,

    isCreating,
    createFailed,
    createFailedDetails,

    isSaving,
    saveFailed,
    saveFailedReason,
    saveFailedDetails,
  ],
  (
    me,
    meIsLoaded,
    meIsLoading,
    isCreating,
    createFailed,
    createFailedDetails,
    isSaving,
    saveFailed,
    saveFailedReason,
    saveFailedDetails,
  ): EditMeConnection => ({
    loadMe,
    me,
    meIsLoaded,
    meIsLoading,

    createUser,
    clearUserCreateFailed: clearCreateFailed,
    userIsCreating: isCreating,
    userCreateError: createFailed,
    userCreateFailedDetails: createFailedDetails,

    saveUser: save,
    clearUserSaveFailed: clearSaveFailed,
    userIsSaving: isSaving,
    userSaveError: saveFailed,
    userSaveFailedReason: saveFailedReason,
    userSaveFailedDetails: saveFailedDetails,
  }),
)

export const User: {
  meConnection: Connection<MeConnection>
  editMeConnection: Connection<EditMeConnection>,
  deletionRequestConnection: Connection<DeletionRequestConnection>,
  passwordResetConnection: Connection<PasswordResetConnection, PasswordResetConnectionProps>,
  coachConnection: Connection<CoachConnection, CoachConnectionProps>,
} = {
  meConnection: {
    selector: createSelector(
      [selectMe, isLoaded, isLoading],
      (me, meIsLoaded, meIsLoading) => ({
        me,
        meIsLoaded,
        meIsLoading,
        loadMe,
      }),
    ),
  },

  coachConnection: {
    selector: selectCoachConnection,

    load: ({ user }, { userId }) => {
      if (user == null && userId != null) void MODEL.read({ id: userId })
    },

    isLoaded: ({ user, userLoadFailed }) => userLoadFailed != null || user != null,
  },

  editMeConnection: { selector: selectEditMeConnection },
  deletionRequestConnection: { selector: selectDeletionRequestConnection },
  passwordResetConnection: { selector: selectPasswordResetConnection },
}
