import get from 'lodash/get'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel from '../ApiModel'
import { registerModelReducer } from '../reducers'
import { selectorCache } from '../util/selectorCache'
import { LOAD_SUCCESS_TYPE as USER_LOAD_SUCCESS_TYPE } from './User'
import { convertDateToUTC } from './utilities'

const MODEL = new ApiModel('PROFILE', 'api/v1/profile', logFailureFilter)
const ADMIN_MODEL = new ApiModel('ADMIN_PROFILE', 'admin/api/v1/profiles')

registerModelReducer('profile', (state = { }, { type, url, response }) => {
  if (type === USER_LOAD_SUCCESS_TYPE && url.endsWith('/me')) {
    return { ...state, myProfileId: get(response.profiles, [0, 'id']) }
  } else if (type === Action.CREATE.successType(MODEL)) {
    return { ...state, myProfileId: response.profile.id }
  }

  return state
})

function logFailureFilter (json, response, fetch) {
  const isWrite = fetch.action === Action.CREATE || fetch.action === Action.UPDATE_MODIFY
  return !(isWrite && get(json, 'error.reason') === 'eligibility_mismatch')
}

const ADMIN_SHAPE = PropTypes.shape({
  id: PropTypes.number.isRequired,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  city: PropTypes.string,
  age: PropTypes.number,
  gender: PropTypes.string,
  phone: PropTypes.string,
  phone_E164: PropTypes.string,
})

const SHAPE = PropTypes.shape({
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  dob: PropTypes.string,
  age: PropTypes.number,
  gender: PropTypes.string,
  zip: PropTypes.string,
  accepts_coach_notice_and_terms: PropTypes.bool,
  accepts_data_storage: PropTypes.bool,
  accepts_health_data_processing: PropTypes.bool,
  accepts_community_guidelines: PropTypes.bool,
  accepts_data_sharing_authorization: PropTypes.bool,
  community_nickname: PropTypes.string,
  avatar_color: PropTypes.oneOf(['teal600', 'yellow400', 'blue600', 'green600', 'red400']),
  comms_opt_in: PropTypes.bool,
  skipped_jiff: PropTypes.bool,
  country: PropTypes.string,
  pending_jiff_fix: PropTypes.bool,
  state: PropTypes.string,
  is_video_only: PropTypes.bool,
})

/**
 * Returns true if the client is currently trying to create or save a profile.
 */
const isSaving = state => MODEL.isCreatingSelector()(state) || MODEL.isUpdatingSelector()(state)

function clearSaveFailed () {
  ApiModel.dispatch(Action.CREATE.getClearPendingAction(MODEL, MODEL.getUrl()))
  ApiModel.dispatch(Action.UPDATE_MODIFY.getClearPendingAction(MODEL, MODEL.getUrl()))
}

/**
 * Returns the error message if profile creation or saving failed.
 */
const saveFailed = state =>
  MODEL.getCreateErrorMessage(state) || MODEL.getUpdateErrorMessage(state)

/**
 * Returns the error reason if profile creation or saving failed.
 */
const saveFailedReason = state =>
  MODEL.getCreateErrorReason(state) || MODEL.getUpdateErrorReason(state)

const saveFailedDetails = state =>
  MODEL.getCreateErrorDetails(state) || MODEL.getUpdateErrorDetails(state)

const isAbleToTarget = profile => {
  return profile.benefit_product_target && profile.benefit_product_target.includes('ableto')
}

const isInsuranceHdhp = profile => {
  return profile.benefit_payment_responsibility && profile.benefit_payment_responsibility.includes('ableto')
}

export const selectProfiles = state => state.api.profiles

const selectMyProfile = createSelector(
  [state => state.models.profile, selectProfiles],
  // It's an antipattern in our API to return something other than null for a missing model, but
  // by the time that was established, we had a lot of code sprinkled about that expected
  // "my profile" to always return an object of some kind.
  ({ myProfileId }, profiles) => (myProfileId && profiles && profiles[myProfileId]) || {},
)

async function save (profile, additional = {}) {
  // Update DOB field without timezone
  if (profile.dob != null) profile = { ...profile, dob: convertDateToUTC(profile.dob) }

  if (profile.complete == null) {
    // if the supplied profile is sans complete field, explicitly set it false because the server
    // sets it to true by default.
    profile = { ...profile, complete: false }
  }
  if (profile.id == null) {
    return await MODEL.create({ profile, ...additional })
  } else {
    return await MODEL.updateModify({ profile, ...additional })
  }
}

const loadMyProfile = () => MODEL.read()
const loadProfile = id => ADMIN_MODEL.read({ id })

const selectUserConnection = selectorCache(
  ({ user }) => user && user.id,
  ({ user }) => {
    if (user == null) return () => ({ })

    return createSelector(
      [selectProfiles],
      profiles => ({ profile: profiles && profiles[user.profile_id] }),
    )
  },
)

// Returns an array of keys in the api store that are related to my profile object
export const meKeys = () => {
  const { myProfileId } = ApiModel.reduxState.models.profile || {}
  return myProfileId == null ? [] : [['profiles', myProfileId]]
}

const selectMyProfileConnection = createSelector(
  [
    selectMyProfile,
    isSaving,
    saveFailed,
    saveFailedReason,
    saveFailedDetails,
  ],
  (profile, profileIsSaving, profileSaveError, profileSaveFailedReason, profileSaveFailedDetails) => ({
    profile,
    profileIsSaving,
    profileSaveError,
    profileSaveFailedReason,
    profileSaveFailedDetails,
    isAbleToTarget: profile && isAbleToTarget(profile),
    isInsuranceHdhp: profile && isInsuranceHdhp(profile),
    saveProfile: save,
    updateProfile: async (updates = {}, additional = {}) => await save({ ...profile, ...updates }, additional),
    clearProfileSaveFailed: clearSaveFailed,
    loadProfile: loadMyProfile,
  }),
)

export const Profile = {
  SHAPE,

  myProfileConnection: {
    selector: selectMyProfileConnection,

    shape: {
      profile: SHAPE,
      profileIsSaving: PropTypes.bool.isRequired,
      profileSaveError: PropTypes.string,
      profileSaveFailedReason: PropTypes.string,
      profileSaveFailedDetails: PropTypes.object,
      saveProfile: PropTypes.func.isRequired,
      updateProfile: PropTypes.func.isRequired,
      clearProfileSaveFailed: PropTypes.func.isRequired,
      loadProfile: PropTypes.func.isRequired,
      isAbleToTarget: PropTypes.bool,
      isInsuranceHdhp: PropTypes.bool,
    },
  },

  // admin
  coachConnection: {
    load: ({ profile }, { user }) => {
      if (profile == null && user != null && user.profile_id != null) {
        loadProfile(user.profile_id)
      }
    },

    isLoaded: ({ profile }) => profile != null,

    selector: selectUserConnection,

    shape: {
      profile: ADMIN_SHAPE,
    },
  },
}
