import { Selector } from '../models/types'
import { ApiKey, ApiState } from '../types'

type StateOnlySelector<SelectedType> = (state: ApiState) => SelectedType | Record<any, never>

/**
 * Selectors created by createSelector are basically a 1-deep cache. As long as the results of their
 * input _selectors_ (not the (state, options) arguments) don't change, they don't recompute their
 * output value. We frequently want to get computed state from the redux store depends on both the
 * current state and some inputs (model id, endpoint URL, etc). When those secondary arguments
 * change, it also changes the output of the selectors at the bottom of the tree, causing the whole
 * change of selectors to recompute their output. That in turn makes a selector that gets
 * reused by several components at the same time with different arguments (think user rows in
 * a table, for instance) worse than useless.
 *
 * This cache is used to create and maintain a mapping of selectors that only depend on state,
 * having the other arguments bound to the context of the selector.
 *
 * @param keyFactory Function of the form (options) => <map key>
 * @param selectorFactory Function of the form (options) => <selector that takes state only>
 */
export const selectorCache =
  <SelectedType, PropsType extends Record<string, any>>
  (
    keyFactory: (props: PropsType) => ApiKey,
    selectorFactory: (props: PropsType) => (state: any) => SelectedType,
  ): Selector<SelectedType, PropsType> => {
    // TODO (NJC): It would probably be smart at some point to replace this with an expiring cache
    // of some flavor
    const selectors = new Map<ApiKey, StateOnlySelector<SelectedType>>()

    return (state, props) => {
      const key = keyFactory(props)
      let selector = selectors.get(key)
      if (selector == null) {
        selectors.set(key, selector = selectorFactory(props))
      }
      return selector(state)
    }
  }
