/* global FormData */
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import { selectorCache } from '../util/selectorCache'
import ApiModel from '../ApiModel'
import get from 'lodash/get'

const MODEL = new ApiModel('ADMIN_TOOL_RUN', 'api/v1/admin_tools/run')

const isRunning = MODEL.isCreatingSelector()
const creationFailed = state => MODEL.getCreateErrorMessage(state)

const runAdminTool = (slug, argMap) => {
  const formData = new FormData()
  formData.append('slug', slug)
  Object.entries(argMap).map(([name, value]) => formData.append(name, value))
  return MODEL.create(
    formData,
    // Ignore server-thrown exceptions, since we explicitly handle them.
    { modelMapKey: ['adminToolRuns', slug], ignoreFailure: true },
  )
}

const selectLastRun = slug => ({ modelMap }) => get(modelMap, ['adminToolRuns', slug, 'runs'])

const selectConnection = selectorCache(
  ({ tool: { slug } }) => slug,
  ({ tool: { slug } }) => createSelector(
    [isRunning, creationFailed, selectLastRun(slug)],
    (runInProgress, runErrored, lastRun) => ({
      runInProgress,
      runAdminTool: argMap => runAdminTool(slug, argMap),
      lastRun,
      runErrored,
    }),
  ),
)

export const AdminToolRun = {
  connection: {
    selector: selectConnection,
    shape: {
      runAdminTool: PropTypes.func.isRequired,
      runInProgress: PropTypes.bool.isRequired,
      runErrored: PropTypes.string,
    },
  },
}
