import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import { INSURANCE_PAYER_TYPE } from '../apiConstants'
import ApiModel from '../ApiModel'
import { registerModelReducer } from '../reducers'
import { isRecording as landingIsRecording } from './Landing'
import { LOAD_SUCCESS_TYPE as USER_LOAD_SUCCESS_TYPE } from './User'

const MODEL = new ApiModel('PROMOTION', 'api/v1/promotion',
  (json, response, fetch) => !(fetch.action === Action.READ && response.status === 404))

registerModelReducer('promotion', (state = { }, { type }) => {
  if (type === USER_LOAD_SUCCESS_TYPE) {
    // once Me is loaded, if there's no promotion in the store we can assume it's because we have
    // no promotion since it would have been sideloaded if we did.
    return { ...state, meLoaded: true }
  }

  return state
})

const SHAPE = PropTypes.shape({
  campaign: PropTypes.string,
  force_coach_id: PropTypes.number,
  company_name: PropTypes.string,
  plan_set: PropTypes.string,
  available_levels: PropTypes.arrayOf(PropTypes.string),
  available_indications: PropTypes.arrayOf(PropTypes.string),
  landing_page: PropTypes.string,
  landing_page_type: PropTypes.string,
  landing_cta_destination: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string),
  requires_eligibility_check: PropTypes.bool,
  eligibility_identifier: PropTypes.shape({
    name: PropTypes.string,
    dialog: PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.string,
    }),
  }),
  forced_locale: PropTypes.string,
  member_id_rules: PropTypes.string,
  initial_activity: PropTypes.string,
})

const loadPromotion = () => MODEL.read()

const isNotFound = createSelector(
  [MODEL.isNotFoundSelector(), state => state.models.promotion.meLoaded],
  (isNotFound, meLoaded) => isNotFound || meLoaded,
)

const select = state => state.api.promotion

const isTrialDemo = ({ campaign }) => campaign === 'demo-20171229'

const isPayerInsurance = ({ payer_type: type }) => type === INSURANCE_PAYER_TYPE

const isBranded = ({ features }) => Array.isArray(features) && features.includes('branded_promotion')

const loadError = state => MODEL.getReadErrorMessage(state)

const clearError = () => ApiModel.dispatch(Action.READ.getClearPendingAction(MODEL, MODEL.getUrl()))

export const Promotion = {
  SHAPE,

  connection: {
    load: ({ promotion, promotionIsNotFound, isRecordingLanding }) => {
      if (promotion == null && !promotionIsNotFound && !isRecordingLanding) loadPromotion()
    },

    isLoaded: ({ promotion, promotionIsNotFound }) => promotion != null || promotionIsNotFound,

    selector: createSelector(
      [select, isNotFound, landingIsRecording, loadError],
      (promotion, promotionIsNotFound, isRecordingLanding, loadError) => ({
        promotion,
        promotionIsTrialDemo: promotion && isTrialDemo(promotion),
        promotionIsNotFound,
        isRecordingLanding,
        isPayerInsurance: promotion && isPayerInsurance(promotion),
        isBranded: promotion && isBranded(promotion),
        loadPromotion,
        loadError,
        clearError,
      }),
    ),

    shape: {
      promotion: SHAPE,
      promotionIsTrialDemo: PropTypes.bool,
      promotionIsNotFound: PropTypes.bool,
      // needed to avoid loading promotion while a landing is current recording.
      isRecordingLanding: PropTypes.bool,
      isPayerInsurance: PropTypes.bool,
      loadPromotion: PropTypes.func,
      loadError: PropTypes.string,
      clearError: PropTypes.func,
    },
  },

  isQuartet: promotion => promotion.campaign.indexOf('quartet') >= 0,
}
