import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'

const MODEL = new ApiModel('SATISFACTION', 'api/v1/satisfactions')

const SHAPE = PropTypes.shape({
  id: PropTypes.number,
  satisfied_joyable: PropTypes.string,
  satisfied_concierge_match: PropTypes.string,
  tell_us_why: PropTypes.string,
  satisfied_coach_call: PropTypes.string,
})

function select (state) {
  if (state.api.satisfactions == null || state.api.satisfactions.length === 0) return null
  return Object.values(state.api.satisfactions)[0]
}

function save (satisfaction, externalId) {
  if (satisfaction.id == null) return MODEL.create({ satisfaction, external_id: externalId })
  return MODEL.updateModify({ satisfaction, external_id: externalId }, { id: satisfaction.id })
}

const isSaving = (state) => {
  const { id } = select(state) ?? {}
  return id == null ? MODEL.isCreating(state) : MODEL.isUpdating(state, { id })
}

const saveError = (state) => {
  const { id } = select(state) ?? {}
  return id == null ? MODEL.getCreateErrorMessage(state) : MODEL.getUpdateErrorMessage(state, { id })
}

const selectConnection = createSelector(
  [select, isSaving, saveError],
  (satisfaction, satisfactionIsSaving, satisfactionSaveError) => ({
    satisfaction,
    satisfactionIsSaving,
    satisfactionSaveError,

    saveSatisfaction: save,
  }),
)

export const Satisfaction = {
  connection: {
    selector: selectConnection,

    shape: {
      satisfaction: SHAPE,
      satisfactionIsSaving: PropTypes.bool.isRequired,
      satisfactionSaveError: PropTypes.string,
      saveSatisfaction: PropTypes.func.isRequired,
    },
  },
}
