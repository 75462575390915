import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'

const MODEL = new ApiModel('INDICATOR_SET', 'api/v1/indicator_sets')

export const IndicatorSet = {
  connection: {
    load: ({ indicatorSet }) => indicatorSet == null && MODEL.read(),

    isLoaded: ({ indicatorSet }) => indicatorSet != null,

    selector: createSelector(
      [state => state.api.indicator_set],
      indicatorSet => ({ indicatorSet }),
    ),

    shape: {
      indicatorSet: PropTypes.object,
    },
  },
}
