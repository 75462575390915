/* eslint-disable camelcase */
import { createSelector } from 'reselect'
import { registerModelReducer } from '../reducers'
import ApiModel from '../ApiModel'
import Action from '../Action'
import { ApiFailureAction, ApiSuccessAction } from '../types'
import { Connection } from './types'
import {
  Prompt, RESET_COMMUNITY_PROMPT,
  ModelState as CommunityPromptModelState,
} from './CommunityPrompt'
import { ColorType } from '@ableto/component-library/icon'
import { parseId } from './utilities'
import {
  ResponseAction,
  applyActionToCommunityResponse,
  RESPONSE_ACTION_CREATE_FAILURE_TYPE,
  RESPONSE_ACTION_CREATE_REQUEST_TYPE,
  RESPONSE_ACTION_DESTROY_REQUEST_TYPE,
  RESPONSE_ACTION_DESTROY_FAILURE_TYPE,
  RESPONSE_ACTION_CREATE_SUCCESS_TYPE,
  ResponseActionType,
} from './ResponseActions'

export const MODEL = new ApiModel('COMMUNITY_RESPONSE', 'api/v1/community_responses')

export type CommunityResponse = {
  id: number
  community_prompt_id: number
  user_id: number
  response: string
  status: string
  created_at: string
  total_likes: number
  total_bookmarks: number
  total_reports: number
  prompt_id?: Prompt['id']
  avatar_color?: ColorType
  prompt_text?: string
  is_liked?: boolean
  is_bookmarked?: boolean
  nickname?: string
  emergency_email_sent?: boolean
}

type UpdateResponseType = {
  community_response: CommunityResponse
}

export type ModeratorCommunityResponse = {
  id: number
  user_id: number
  response: string
  status: string
  created_at: string
  flagged: boolean
  community_prompt_id: number
  emergency_email_sent?: boolean
  reviewed?: boolean
  total_bookmarks?: number
  total_likes?: number
  user: {
    id: number
    email: string
    created_at: string
    community_nickname: string
    product_identifier: string
    total_user_featured_responses: number
  }
}

type SortedByProps = {
  field: string
  order: string
}

export type ModelState = {
  userResponses: CommunityResponse[]
  savedResponses: CommunityResponse[]
  userResponsesLoaded: boolean
  savedResponsesLoaded: boolean
  reportedResponsesLoaded: boolean
  responsesTotalCount: number
  responsesCurrentPage: number
  responsesSortedBy: SortedByProps
}

export type CommunityModeratorModelState = {
  promptResponses: {
    responses: ModeratorCommunityResponse[],
    loaded: boolean,
    totalCount: number,
    currentPage: number,
    sortedBy: SortedByProps,
  },
  promptMetrics: {
    reviewedResponsesPercentage: number,
    disapprovedResponsesPercentage: number,
    sentEmergencyResourcesPercentage: number,
  },
}

export type PageQuery = {
  page: number
  field?: string
  order?: string
}

type ConnectionProps = PageQuery & {
  promptId: number
}

const defaultState: ModelState & CommunityModeratorModelState = {
  userResponses: [],
  savedResponses: [],
  userResponsesLoaded: false,
  savedResponsesLoaded: false,
  reportedResponsesLoaded: false,
  responsesTotalCount: 0,
  responsesCurrentPage: 1,
  responsesSortedBy: {
    field: '',
    order: '',
  },
  promptResponses: {
    responses: [],
    loaded: false,
    totalCount: 0,
    currentPage: 1,
    sortedBy: {
      field: '',
      order: '',
    },

  },
  promptMetrics: {
    reviewedResponsesPercentage: 0,
    disapprovedResponsesPercentage: 0,
    sentEmergencyResourcesPercentage: 0,
  },
}

export const RESET_COMMUNITY_RESPONSES = 'RESET_COMMUNITY_RESPONSES'

const { modelStateSelector } = registerModelReducer<ModelState & CommunityModeratorModelState>(
  'communityResponses',
  (state = defaultState, action, globalState) => {
    const { type } = action
    const { response, url } = action as ApiSuccessAction

    switch (type) {
      case Action.READ.successType(MODEL): {
        if (parseId(MODEL, url) === 'saved') {
          return {
            ...state,
            savedResponsesLoaded: true,
            savedResponses: response.community_responses,
          }
        }

        if (parseId(MODEL, url) === 'reported') {
          return {
            ...state,
            responsesTotalCount: response.total_count,
            responsesCurrentPage: response.current_page,
            reportedResponsesLoaded: true,
            userResponses: response.community_responses,
            responsesSortedBy: response.sorted_by,
          }
        }

        if (parseId(MODEL, url) === 'all') {
          return {
            ...state,
            promptResponses: {
              ...state.promptResponses,
              loaded: true,
              totalCount: response.total_count,
              currentPage: response.current_page,
              responses: response.responses,
              sortedBy: response.sorted_by,
            },
            promptMetrics: {
              reviewedResponsesPercentage: response.reviewed_responses_percentage,
              disapprovedResponsesPercentage: response.disapproved_responses_percentage,
              sentEmergencyResourcesPercentage: response.sent_emergency_resources_percentage,
            },
          }
        }

        return {
          ...state,
          userResponsesLoaded: true,
          userResponses: response.community_responses,
        }
      }

      case Action.UPDATE_MODIFY.successType(MODEL): {
        if (parseId(MODEL, url) === 'moderator_update') {
          const promptResponses = state.promptResponses.responses.map((promptResponse: ModeratorCommunityResponse) => {
            const communityResponse = (response as UpdateResponseType).community_response

            if (promptResponse.id === communityResponse.id) {
              const { status } = communityResponse

              return { ...promptResponse, status }
            }

            return promptResponse
          })

          return {
            ...state,
            promptResponses: {
              ...state.promptResponses,
              responses: promptResponses,
            },
          }
        } else if (parseId(MODEL, url) === 'batch_status_updates') {
          return {
            ...state,
            promptResponses: {
              responses: response.responses,
            },
          }
        } else {
          const userResponseId = (response as UpdateResponseType).community_response?.id
          const userResponses = state.userResponses.filter((response) => response?.id !== userResponseId)
          const savedResponses = state.savedResponses.filter((response) => response?.id !== userResponseId)

          return {
            ...state,
            userResponses,
            savedResponses,
          }
        }
      }
      case Action.CREATE.successType(MODEL):
        return {
          ...state,
          userResponses: [
            response.community_response as CommunityResponse,
            ...state.userResponses,
          ],
        }
      case RESPONSE_ACTION_CREATE_FAILURE_TYPE:
      case RESPONSE_ACTION_CREATE_REQUEST_TYPE:
      case RESPONSE_ACTION_DESTROY_REQUEST_TYPE:
      case RESPONSE_ACTION_DESTROY_FAILURE_TYPE: {
        return applyActionToCommunityResponse(action, state) as ModelState
      }
      case RESPONSE_ACTION_CREATE_SUCCESS_TYPE: {
        const { type, community_response_id } = response.response_action as ResponseAction

        if (type === ResponseActionType.REPORT || type === ResponseActionType.HIDE) {
          return applyActionToCommunityResponse(action, state) as ModelState
        }

        if (type !== ResponseActionType.BOOKMARK) return state

        const prompt = (globalState?.communityPrompts as CommunityPromptModelState).prompts?.find(
          ({ community_responses }) => community_responses.find(response => response.id === community_response_id),
        )
        const communityResponse = prompt?.community_responses?.find(({ id }) => id === community_response_id)

        if (!prompt || !communityResponse) return state

        return {
          ...state,
          savedResponses: [
            { ...communityResponse, is_bookmarked: true, prompt_text: prompt.prompt_text },
            ...state.savedResponses,
          ],
        }
      }
      case RESET_COMMUNITY_RESPONSES: {
        return defaultState
      }
      default:
        return state
    }
  },
)

const selectUserCommunityResponses = modelStateSelector(state => state.userResponses)
const selectUserCommunityResponsesLoaded = modelStateSelector(state => state.userResponsesLoaded)
const selectSavedCommunityResponses = modelStateSelector(state => state.savedResponses)
const selectSavedCommunityResponsesLoaded = modelStateSelector(state => state.savedResponsesLoaded)
const selectReportedCommunityResponsesLoaded = modelStateSelector((state) => state.reportedResponsesLoaded)
const selectResponsesTotalCount = modelStateSelector((state) => state.responsesTotalCount)
const selectResponsesCurrentPage = modelStateSelector((state) => state.responsesCurrentPage)
const selectResponsesSortedBy = modelStateSelector((state) => state.responsesSortedBy)

const selectPromptResponses = modelStateSelector(state => state.promptResponses.responses)
const selectPromptResponsesTotalCount = modelStateSelector(state => state.promptResponses.totalCount)
const selectPromptResponsesLoaded = modelStateSelector(state => state.promptResponses.loaded)
const selectPromptResponsesCurrentPage = modelStateSelector(state => state.promptResponses.currentPage)
const selectPromptResponsesSortedBy = modelStateSelector(state => state.promptResponses.sortedBy)
const selectPromptMetrics = modelStateSelector(state => state.promptMetrics)

const createCommunityResponse = async (activePromptId: number, content: string, status: string) =>
  await MODEL.create({
    community_response: {
      community_prompt_id: activePromptId,
      response: content,
      status,
    },
  })

export const fetchCommunityResponses = ({ id, query }: { id?: string, query?: PageQuery } = {}) =>
  void MODEL.read({ id, query })

const deleteUserResponse = async (id: number) => {
  const res = await MODEL.updateModify({ community_response: { status: 'deleted' } }, { id })
  ApiModel.dispatch({ type: RESET_COMMUNITY_PROMPT })
  return res
}

export const markReportedResponseAsOK = async (id: number) => {
  return await MODEL.updateModify(
    { community_response: { flagged: false } },
    { id: 'moderator_update', query: { id } },
  )
}

export const getResponsesByPromptId = ({ promptId, page, field, order }: ConnectionProps) =>
  MODEL.read({ id: 'all', query: { promptId, page, field, order } })

const sortResponses = async ({ order, field }: { order: string, field: string }) => {
  await MODEL.read({ id: 'reported', query: { page: 1, order, field } })
}

export const unpublishCommunityResponse = async (id: number) => {
  return await MODEL.updateModify(
    { community_response: { status: 'disapproved', flagged: false } },
    { id: 'moderator_update', query: { id } })
}

export const sendEmergencyResourceEmail = async (id: number) => {
  return await MODEL.updateModify(
    {},
    { id: 'send_emergency_resources_email', query: { id } })
}

export const updateCommunityResponseStatus = async (id: number, status: string, newStatus: string) => {
  return await MODEL.updateModify({ community_response: { status: newStatus } },
    { id: 'moderator_update', query: { id }, ignoreFailure: true }) as ApiFailureAction
}
export const batchUpdateCommunityResponseStatus = async (id: number, featured: number[],
  disapproved: number[], pending: number[]) => {
  await MODEL.updateModify({ community_response: { featured, disapproved, pending } },
    { id: 'batch_status_updates', query: { id } })
}

type CommunityResponsesConnection = ModelState & {
  createCommunityResponse: typeof createCommunityResponse,
  deleteUserResponse: typeof deleteUserResponse,
  sortResponses: typeof sortResponses,
  fetchCommunityResponses: typeof fetchCommunityResponses,
  unpublishCommunityResponse: typeof unpublishCommunityResponse,
  sendEmergencyResourceEmail: typeof sendEmergencyResourceEmail,
  markReportedResponseAsOK: typeof markReportedResponseAsOK,
}

type CommunityModeratorConnection = CommunityModeratorModelState & {
  getResponsesByPromptId: typeof getResponsesByPromptId,
  updateCommunityResponseStatus: typeof updateCommunityResponseStatus,
  batchUpdateCommunityResponseStatus: typeof batchUpdateCommunityResponseStatus
}

const selectAvailableConnection = createSelector(
  [
    selectUserCommunityResponses,
    selectUserCommunityResponsesLoaded,
    selectSavedCommunityResponses,
    selectSavedCommunityResponsesLoaded,
    selectReportedCommunityResponsesLoaded,
    selectResponsesTotalCount,
    selectResponsesCurrentPage,
    selectResponsesSortedBy,
  ],
  (userResponses, userResponsesLoaded, savedResponses, savedResponsesLoaded,
    reportedResponsesLoaded, responsesTotalCount, responsesCurrentPage, responsesSortedBy) => ({
    userResponses,
    userResponsesLoaded,
    savedResponses,
    savedResponsesLoaded,
    reportedResponsesLoaded,
    responsesTotalCount,
    responsesCurrentPage,
    responsesSortedBy,
    deleteUserResponse,
    createCommunityResponse,
    sortResponses,
    unpublishCommunityResponse,
    sendEmergencyResourceEmail,
    fetchCommunityResponses,
    markReportedResponseAsOK,
  }),
)

const selectCommunityModeratorConnection = createSelector(
  [
    selectPromptResponses,
    selectPromptResponsesLoaded,
    selectPromptResponsesCurrentPage,
    selectPromptResponsesTotalCount,
    selectPromptResponsesSortedBy,
    selectPromptMetrics,
  ],
  (responses,
    loaded,
    currentPage,
    totalCount,
    sortedBy,
    promptMetrics,
  ) => ({
    promptResponses: {
      responses,
      totalCount,
      currentPage,
      loaded,
      sortedBy,
    },
    promptMetrics,
    updateCommunityResponseStatus,
    getResponsesByPromptId,
    batchUpdateCommunityResponseStatus,
  }),
)

export const CommunityResponse: {
  userResponsesConnection: Connection<CommunityResponsesConnection>,
  savedResponsesConnection: Connection<CommunityResponsesConnection>,
  reportedResponsesConnection: Connection<CommunityResponsesConnection>,
  promptResponsesConnection: Connection<CommunityModeratorConnection, ConnectionProps>,
} = {
  userResponsesConnection: {
    load: ({ userResponsesLoaded }) => {
      if (!userResponsesLoaded) void fetchCommunityResponses()
    },

    isLoaded: ({ userResponsesLoaded }) => userResponsesLoaded,

    selector: selectAvailableConnection,
  },

  savedResponsesConnection: {
    load: ({ savedResponsesLoaded }) => {
      if (!savedResponsesLoaded) void fetchCommunityResponses({ id: 'saved' })
    },

    isLoaded: ({ savedResponsesLoaded }) => savedResponsesLoaded,

    selector: selectAvailableConnection,

    onMount: () => void ApiModel.dispatch({ type: RESET_COMMUNITY_RESPONSES }),
  },
  reportedResponsesConnection: {
    load: ({ reportedResponsesLoaded }) => {
      if (!reportedResponsesLoaded) void fetchCommunityResponses({ id: 'reported', query: { page: 1 } })
    },

    isLoaded: ({ reportedResponsesLoaded }) => reportedResponsesLoaded,

    selector: selectAvailableConnection,

    onMount: () => void ApiModel.dispatch({ type: RESET_COMMUNITY_RESPONSES }),
  },

  promptResponsesConnection: {
    load: ({ promptResponses }, { promptId, page, field, order }) => {
      if (!promptResponses.loaded) void getResponsesByPromptId({ promptId, page, field, order })
    },

    isLoaded: ({ promptResponses }) => promptResponses.loaded,

    selector: selectCommunityModeratorConnection,

    onMount: () => void ApiModel.dispatch({ type: RESET_COMMUNITY_RESPONSES }),
  },
}
