/* eslint-disable camelcase */
import flow from 'lodash/flow'
import { Action as ReduxAction } from 'redux'
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'
import Action from '../Action'
import { Connection } from './types'
import {
  CommunityResponse, ModelState as CommunityResponseModelState,
  ModeratorCommunityResponse, CommunityModeratorModelState,
} from './CommunityResponse'
import { User } from './User'
import { HTTPAction, isSuccessAction } from '../types'
import { parseQuery } from './utilities'
import { ModelState as CommunityPromptModelState, Prompt } from './CommunityPrompt'

const MODEL = new ApiModel('RESPONSE_ACTION', 'api/v1/response_actions')

export const RESPONSE_ACTION_CREATE_FAILURE_TYPE = Action.CREATE.failureType(MODEL)
export const RESPONSE_ACTION_CREATE_REQUEST_TYPE = Action.CREATE.requestType(MODEL)
export const RESPONSE_ACTION_CREATE_SUCCESS_TYPE = Action.CREATE.successType(MODEL)

export const RESPONSE_ACTION_DESTROY_FAILURE_TYPE = Action.DESTROY.failureType(MODEL)
export const RESPONSE_ACTION_DESTROY_REQUEST_TYPE = Action.DESTROY.requestType(MODEL)

export enum ResponseActionType {
  LIKE = 'ResponseLike',
  UNLIKE = 'ResponseUnLike',
  BOOKMARK = 'ResponseBookmark',
  UNBOOKMARK = 'ResponseUnBookmark',
  HIDE = 'ResponseHide',
  REPORT = 'ResponseReport',
  REVIEW = 'ResponseReview',
  UNREVIEW = 'ResponseUnreview',
}

enum OperationType {
  APPLY,
  UNAPPLY,
}

export type ResponseAction = {
  id?: number
  user_id?: User['id']
  type: ResponseActionType
  community_response_id: CommunityResponse['id']
}

const extractResponseActionData = (action: HTTPAction): ResponseAction | null => {
  const { type: actionType, community_response_id } = parseQuery(action.url)

  if (!actionType || !community_response_id) {
    return null
  }

  return {
    type: actionType as ResponseActionType,
    community_response_id: parseInt(community_response_id),
  }
}

const applyActionChangesToResponse = (
  actionType: ResponseActionType, response: CommunityResponse | ModeratorCommunityResponse, operation?: OperationType,
) => {
  // Operation is null when the actionType is HIDE or REPORT since these actions are not
  // modifying the communityResponse but they are removing it entirely from the state.
  if (operation == null) return response

  let responseChanges = {}
  const total_likes = response?.total_likes as number
  const total_bookmarks = response?.total_bookmarks as number

  switch (actionType) {
    case ResponseActionType.LIKE:
      responseChanges = {
        total_likes: operation === OperationType.APPLY
          ? total_likes + 1
          : total_likes - 1,
        is_liked: operation === OperationType.APPLY,
      }
      break
    case ResponseActionType.BOOKMARK:
      responseChanges = {
        total_bookmarks: operation === OperationType.APPLY
          ? total_bookmarks + 1
          : total_bookmarks - 1,
        is_bookmarked: operation === OperationType.APPLY,
      }
      break
    case ResponseActionType.REVIEW:
      responseChanges = {
        reviewed: true,
      }
      break
    case ResponseActionType.UNREVIEW:
      responseChanges = {
        reviewed: false,
      }
      break
  }

  return { ...response, ...responseChanges }
}

const extractReduxAction = (reduxAction: HTTPAction) => {
  /*
    General information:
    - HIDE/REPORT       - State changes are applied ONLY after the API call succeeds.
                          (RESPONSE_ACTION_CREATE_SUCCESS_TYPE)
    - LIKE/BOOKMARK     - State changes are applied immediately on button click before the API
                          call is completed. (RESPONSE_ACTION_CREATE_REQUEST_TYPE)
                          In case of API failure, additional logic for reverting the state changes
                          is performed. (RESPONSE_ACTION_CREATE_FAILURE_TYPE)
    - UNLIKE/UNBOOKMARK - State changes are applied immediately. (RESPONSE_ACTION_DESTROY_REQUEST_TYPE)
                          In case of API failure, state changes are reverted. (RESPONSE_ACTION_DESTROY_FAILURE_TYPE)
  */

  const { type } = parseQuery(reduxAction.url)
  const isHideOrReportAction = type === ResponseActionType.HIDE || type === ResponseActionType.REPORT

  switch (reduxAction.type) {
    case RESPONSE_ACTION_CREATE_SUCCESS_TYPE: {
      return isHideOrReportAction ? OperationType.APPLY : null
    }
    case RESPONSE_ACTION_DESTROY_FAILURE_TYPE:
    case RESPONSE_ACTION_CREATE_REQUEST_TYPE:
      return isHideOrReportAction ? null : OperationType.APPLY
    case RESPONSE_ACTION_DESTROY_REQUEST_TYPE:
    case RESPONSE_ACTION_CREATE_FAILURE_TYPE:
      return OperationType.UNAPPLY
  }
}

const responseActionReducer = (
  state: CommunityResponseModelState | CommunityPromptModelState,
  changes: {
    responseAction: ResponseAction | null
    operation: OperationType
  },
  action: ReduxAction<string>,
) => {
  if (!changes.responseAction) return state

  const { operation, responseAction: { type, community_response_id } } = changes

  if (!type || !community_response_id) return state

  const communityPromptsState = state as CommunityPromptModelState
  const communityResponsesState = state as CommunityResponseModelState & CommunityModeratorModelState

  const updateCommunityResponse = (response: CommunityResponse | ModeratorCommunityResponse) =>
    response.id === community_response_id
      ? applyActionChangesToResponse(type, response, operation)
      : response

  // In case when we are performing state changes in the communityPrompts model
  if ('prompts' in communityPromptsState) {
    if (shouldFilterOutResponse(operation, type, action)) {
      return {
        ...communityPromptsState,
        prompts: filterOutPromptsAndResponses(communityPromptsState.prompts, community_response_id),
        singlePrompt: removeResponseFromSinglePrompt(communityPromptsState.singlePrompt, community_response_id),
      }
    }

    return {
      ...communityPromptsState,
      prompts: communityPromptsState.prompts?.map(prompt => ({
        ...prompt,
        community_responses: prompt.community_responses?.map(updateCommunityResponse),
      })),
      singlePrompt: {
        ...communityPromptsState.singlePrompt,
        community_responses: communityPromptsState.singlePrompt?.community_responses?.map(updateCommunityResponse),
      },
    }
  }

  const shouldFilterOutSavedResponse = (operation === OperationType.UNAPPLY && type === ResponseActionType.BOOKMARK) ||
    shouldFilterOutResponse(operation, type, action)

  // In case when we are performing state changes in the communityResponses model
  return {
    ...communityResponsesState,
    promptResponses: {
      ...communityResponsesState.promptResponses,
      responses: communityResponsesState.promptResponses?.responses?.map(updateCommunityResponse),
    },
    userResponses: communityResponsesState.userResponses.map(updateCommunityResponse),
    savedResponses: shouldFilterOutSavedResponse
      ? communityResponsesState.savedResponses.filter((response) => response.id !== community_response_id)
      : communityResponsesState.savedResponses.map(updateCommunityResponse),
  }
}

const filterOutPromptsAndResponses = (prompts: Prompt[], communityResponseId: number) => {
  return prompts.map(prompt => ({
    ...prompt,
    community_responses: prompt.community_responses?.filter(response => response.id !== communityResponseId),
  }))
}

const removeResponseFromSinglePrompt = (singlePrompt: Prompt | null, communityResponseId: number) => {
  if (singlePrompt == null) return singlePrompt
  return {
    ...singlePrompt,
    community_responses: singlePrompt.community_responses?.filter(response => response.id !== communityResponseId),
  }
}

const shouldFilterOutResponse = (operation: OperationType, type: ResponseActionType, action: ReduxAction<string>) => {
  if (type !== ResponseActionType.REPORT && type !== ResponseActionType.HIDE) return false

  return isSuccessAction(action) && operation === OperationType.APPLY
}

export function applyActionToCommunityResponse (
  action: ReduxAction<string>,
  state: CommunityResponseModelState | CommunityPromptModelState,
) {
  return flow([
    (action: HTTPAction) => ({
      responseAction: extractResponseActionData(action),
      operation: extractReduxAction(action),
    }),
    (changes: {
      responseAction: ResponseAction | null
      operation: OperationType
    }) => responseActionReducer(state, changes, action),
  ])(action) as CommunityResponseModelState | CommunityPromptModelState
}

async function applyAction (
  actionType: ResponseActionType,
  communityResponse: CommunityResponse | ModeratorCommunityResponse) {
  if (!communityResponse) return

  return await MODEL.create(
    {},
    {
      query: { community_response_id: communityResponse.id, type: actionType } as ResponseAction,
      ignoreFailure: true,
    },
  )
}

async function unapplyAction (
  actionType: ResponseActionType,
  communityResponse: CommunityResponse) {
  if (!communityResponse) return

  return await MODEL.destroy({
    query: { type: actionType, community_response_id: communityResponse.id } as ResponseAction,
    ignoreFailure: true,
  })
}

export type ToggleActionType = {
  actionType: ResponseActionType,
  response: CommunityResponse,
  discriminator: boolean,
}

function toggleAction ({ actionType, response, discriminator }: ToggleActionType) {
  return discriminator
    ? unapplyAction(actionType, response)
    : applyAction(actionType, response)
}

type ResponseActionConnection = {
  applyAction: typeof applyAction
  toggleAction: typeof toggleAction
  unapplyAction: typeof unapplyAction
}

const selectAvailableConnection = createSelector(
  [],
  (): ResponseActionConnection => ({
    applyAction,
    toggleAction,
    unapplyAction,
  }),
)

export const ResponseAction: {
  connection: Connection<ResponseActionConnection>
} = {
  connection: {
    selector: selectAvailableConnection,
  },
}
