import { createSelector } from 'reselect'
import Action from '../../Action'
import ApiModel, { FailureLoggingFilter } from '../../ApiModel'
import { selectorCache } from '../../util/selectorCache'
import { registerModelReducer } from '../../reducers'
import { Article as ArticleType } from './types'
import { Connection } from '../types'
import { ApiState, isSuccessAction } from '../../types'
import ApiFetch from '../../ApiFetch'

const failureFilter: FailureLoggingFilter =
  (json, response: Response, fetch: ApiFetch) => !(fetch.action === Action.READ && response.status === 404)
const MODEL = new ApiModel('ARTICLE', 'api/v1/articles', failureFilter)

const NOT_FOUND = Symbol('Article not found')

// These can cause looping when using the back button
const NON_TRACKABLE_ARTICLE_IDS = ['my-responses', 'saved-responses']

export type ArticleConnection = {
  article?: ArticleType | symbol
  nestedArticleIds: string[],
  expiresAt: string | undefined
}

type ArticleConnectionProps = {
  id: string,
}

type ModelState = {
  nestedArticleIds: string[]
}

const { modelStateSelector } = registerModelReducer<ModelState>(
  'article',
  (state = { nestedArticleIds: [] }, action) => {
    if (!isSuccessAction(action)) return state

    const { type, response } = action
    if (type === Action.READ.successType(MODEL)) {
      const { id } = response.article as ArticleType

      if (NON_TRACKABLE_ARTICLE_IDS.includes(id)) return state

      if (state.nestedArticleIds[state.nestedArticleIds.length - 1] !== id) {
        const articleIds = [...state.nestedArticleIds, id]
        return { ...state, nestedArticleIds: articleIds }
      }
    }
    return state
  },
)

export const fetchArticle = (id: string) => void MODEL.read({ id })

export const selectArticles = (state: ApiState) =>
  (state.api as ApiState).articles as undefined | Record<string, ArticleType>

const selectArticle = (articleId: string) => (state: ApiState): ArticleType | undefined =>
  (selectArticles(state) ?? {})[articleId]

const selectNestedArticleIds = modelStateSelector(state => state.nestedArticleIds)

const selectConnection = selectorCache<ArticleConnection, ArticleConnectionProps>(
  ({ id }) => id,
  ({ id }) => {
    return createSelector(
      [
        selectArticle(id),
        selectNestedArticleIds,
        MODEL.isNotFoundSelector({ id }),
      ],
      (
        article,
        nestedArticleIds,
        notFound,
      ): ArticleConnection => ({
        article: notFound ? NOT_FOUND : article,
        nestedArticleIds,
        expiresAt: article?.meta?.expiresAt,
      }),
    )
  },
)

export const Article: {
  NOT_FOUND: symbol,
  connection: Connection<ArticleConnection, ArticleConnectionProps>,
} = {
  NOT_FOUND,

  // Connection takes a single "id" argument that can either be a slug (like 'explore'), or a
  // Contentful article ID.
  connection: {
    load: ({ article }, { id }) => {
      if (article == null) void MODEL.read({ id })
    },

    isLoaded: ({ article }) => article != null,

    selector: selectConnection,

    onMount: () => ApiModel.pruneCache('articles'),
  },
}

export const ICONS = {
  listen: 'audio',
  read: 'article',
  handout: 'article',
  collection: 'collection',
  practice: 'journal',
  track: 'tracker',
  worksheet: 'tracker',
  breathe: 'breathe',
  meditate: 'meditate',
  checkIn: 'checkIn',
  assessment: 'checkIn',
  story: 'story',
  safetyPlan: 'safetyPlan',
  watch: 'video',
  video: 'video',
  website: 'website',
  relax: 'collection',
  reflect: 'collection',
  learn: 'series',
  practiceInteractive: 'collection',
}
