import moment from 'moment-timezone'

export default (ascending, fieldName) => (objA, objB) => {
  const momentA = objA[fieldName] == null ? null : moment(objA[fieldName])
  const momentB = objB[fieldName] == null ? null : moment(objB[fieldName])

  const result = (function () {
    if (momentA == null && momentB == null) return 0
    else if (momentA == null) return 1
    else if (momentB == null) return -1
    return momentA.isBefore(momentB) ? -1 : (momentB.isBefore(momentA) ? 1 : 0)
  })()

  return ascending ? result : result * -1
}
