const Requirement = (name, {
  slug = name.toLowerCase(),
} = {}) => ({
  name,

  // Find the redirect route for a page with this requirement, or null if the client may access this
  // page. It is assumed that the requirements are given in priority order so that if the
  // requirement matching this slug is null, but a later one is not, then that later one should
  // be honored.
  redirectSlug: ({ requirements }, fromDeepLink) => {
    if (name === 'NONE' && !fromDeepLink) return null

    for (const { slug: requirement, destination } of requirements) {
      if (destination != null) return destination.split('/')[0]
      if (slug === requirement) return null
    }
    return null
  },

  getRedirectPath: ({ requirements }) => {
    for (const { destination } of requirements) {
      if (destination != null && destination.includes('/')) {
        return destination.split('/').slice(1, destination.length).join('/')
      }
    }
    return '/'
  },
})

Requirement.values = Object.entries({
  COMMUNITY_ONBOARDING: { slug: 'community_onboarding_complete' },
  CHAT_ONBOARDING: { slug: 'chat_onboarding_complete' },
  ONBOARDING: { slug: 'onboarding_complete' },
  LOGIN: { slug: 'logged_in' },
  NONE: {},
}).map(([name, options]) => {
  Requirement[name] = Requirement(name, options)
  return Requirement[name]
})

export default Requirement
