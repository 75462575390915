import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import { WorklistStructure } from '..'
import Action from '../Action'
import ApiModel, { isPruneOf } from '../ApiModel'
import { meKeys as profileMeKeys } from '../models/Profile'
import { meKeys as userMeKeys } from './User'
import { registerModelReducer } from '../reducers'
import { SocketActions } from '../socketActions'
import { selectorCache } from '../util/selectorCache'

const ROLE_TO_WORKLIST_GROUP_MAP = {
  coach: 'coach',
  coach_advisor: 'coach',
  escalations_reviewer: 'escalations_review',
}
const MODEL = new ApiModel('WORKLIST', 'api/v1/worklist_items',
  // Updates are expected to return 200 with an empty body.
  (json, response, fetch) => fetch.action !== Action.UPDATE_MODIFY || response.status !== 200)

const findSuccessValue = rootKey => successData =>
  (successData.values.find(({ key }) => key.length === 1 && key[0] === rootKey) || {}).value

registerModelReducer('worklist', (state = { }, { type, successData, pruneKeys }) => {
  if (type === SocketActions.CHANNEL_RECEIVED || type === Action.READ.successType(MODEL)) {
    const generatedAt = findSuccessValue('generated_at')(successData)
    let items = findSuccessValue('worklist_items')(successData)
    if (items == null && type === Action.READ.successType(MODEL) && generatedAt != null) {
      // If the server has no worklist items for us (worklist 0!), normalizeModels will end up
      // sending us null here, which causes us to issue the request to the server again. Instead,
      // stash an empty array so it's known that there are no worklist items for us to display.
      items = []
    }
    if (generatedAt != null && items != null) {
      const previousGeneratedAt = state.generatedAt && moment(state.generatedAt)
      if (previousGeneratedAt == null || previousGeneratedAt.isBefore(generatedAt)) {
        return { ...state, generatedAt, items }
      }
    }
  } else if (isPruneOf(['worklist_items'])({ type, pruneKeys })) {
    return { ...state, generatedAt: null, items: null }
  }

  return state
})

const SHAPE = PropTypes.shape({
  worklist: PropTypes.string.isRequired,
  user_id: PropTypes.number.isRequired,
})

const selectWorklistItems = state => state.models.worklist.items

const updateStatus = data => MODEL.updateModify({ worklist_item: data })

const defaultCompare = (ascending, sortData) => ({ user_id: userA }, { user_id: userB }) => {
  const sortA = sortData[userA]
  const sortB = sortData[userB]

  function compare () {
    // if both are null, they are equivalent
    if (sortA == null && sortB == null) return 0

    // nulls sort to the end of the list.
    if (sortA == null) return 1
    if (sortB == null) return -1

    // Let javascript decide what less than/greater than means for this unknown type. This will
    // do the default array.sort() thing for strings.
    return sortA < sortB ? -1 : (sortB < sortA ? 1 : 0)
  }
  return ascending ? compare() : compare() * -1
}

function findSort (worklist, sort, worklistItemsSelector) {
  const structure = WorklistStructure[worklist]
  if (sort != null) {
    const column = structure.columns.find(({ label }) => label === sort.column)
    if (column != null) {
      return {
        sortDataSelector: column.createSortDataSelector(worklistItemsSelector),
        compare: column.compare,
        ascending: sort.ascending,
      }
    }
  }

  // either there is no sort passed in, or the sort we got didn't make sense
  const column = structure.columns.find(({ defaultSort }) => defaultSort === true)
  if (column != null) {
    return {
      sortDataSelector: column.createSortDataSelector(worklistItemsSelector),
      compare: column.compare,
      ascending: column.defaultAscending,
    }
  }

  // look for a default sort definition on the structure
  if (structure.defaultSort != null) {
    return {
      sortDataSelector: structure.defaultSort.createSortDataSelector(worklistItemsSelector),
      compare: structure.defaultSort.compare,
      ascending: structure.defaultSort.ascending !== false,
    }
  }

  // no sorting
  return { }
}

const selectWorklistConnection = selectorCache(
  ({ worklist, sort }) => `${worklist}|${sort?.column}|${sort?.ascending}`,
  ({ worklist, sort, me }) => {
    const group = me && ROLE_TO_WORKLIST_GROUP_MAP[me.role]
    if (worklist == null || group == null) return () => ({ worklistItems: [] })

    const selectItemsForWorklist = createSelector(
      [selectWorklistItems],
      worklistItems => worklistItems && worklistItems
        .filter(({ worklist: itemWorklist }) => itemWorklist === worklist),
    )

    const { sortDataSelector, compare, ascending } = findSort(worklist, sort, selectItemsForWorklist)
    if (sortDataSelector == null) {
      // no sorting
      return createSelector([selectItemsForWorklist], worklistItems => ({ worklistItems }))
    }

    return createSelector(
      [selectItemsForWorklist, sortDataSelector],
      (worklistItems, sortData) => worklistItems == null || sortData == null
        ? ({ worklistItems })
        : ({ worklistItems: [...worklistItems].sort((compare || defaultCompare)(ascending, sortData)) }),
    )
  },
)

const selectWorklistRowConnection = selectorCache(
  ({ worklist, userId }) => `${worklist}|${userId}`,
  ({ worklist, userId }) => {
    const structure = WorklistStructure[worklist]
    const columns = createSelector(
      structure.columns.map(({ createSelector }) => createSelector(userId)),
      (...results) => results.map((label, index) => ({
        label,
        type: structure.columns[index].type,
      })),
    )

    return createSelector(
      [columns],
      columns => ({
        columns,

        markWithStatus: status => updateStatus({ type: status, worklist, user_id: userId }),
      }),
    )
  },
)

const socketRefreshPruneOptions = () => ({
  // In order to clear out worklists, remove all worklist items and the models that get sideloaded
  // with them.
  pruneKeys: [
    ['worklist_items'],
    ['call_statuses'],
    ['profiles'],
    ['quick_facts'],
    ['subscriptions'],
    ['users'],
  ],

  // Our own user and profile are included in the pruned keys above, keep them in the store.
  keepKeys: [
    ...userMeKeys(),
    ...profileMeKeys(),
  ],
})

// On a short socket disconnect, worklists are refetched without clearing the store first.
const forceRefresh = () => MODEL.read({
  successActionMutator: action => ({ ...action, ...socketRefreshPruneOptions() }),
})

// On a long socket disconnect, the worklist (and associated model) cache is cleared out, which
// will cause the active connections in the react tree to re-fetch everything.
const clearCache = () => {
  const { pruneKeys, keepKeys } = socketRefreshPruneOptions()
  ApiModel.trimCache(pruneKeys, { keepKeys })
}

export const onSocketReconnect = longDisconnect => {
  if (longDisconnect) clearCache()
  else forceRefresh()
}

export const Worklist = {
  /**
   * Connection for a given worklist. All worklist connections fetch the entire set of worklists
   * from the server, but a `worklist` parameter is expected on the options argument for the
   * specific worklist to bind items from on this instance.
   */
  connection: {
    selector: selectWorklistConnection,

    load: ({ worklistItems }, { me }) => {
      const group = me && ROLE_TO_WORKLIST_GROUP_MAP[me.role]
      if (worklistItems != null || group == null) return
      MODEL.read({ query: { group } })
    },

    isLoaded: ({ worklistItems }) => worklistItems != null,

    shape: {
      worklistItems: PropTypes.arrayOf(SHAPE).isRequired,
    },
  },

  rowConnection: {
    /**
     * Selector (state, { worklist, userId })
     */
    selector: selectWorklistRowConnection,

    shape: {
      columns: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        type: PropTypes.string,
      })).isRequired,

      /**
       * (status) => null
       */
      markWithStatus: PropTypes.func.isRequired,
    },
  },
}
