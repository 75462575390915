import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel from '../ApiModel'
import { registerModelReducer } from '../reducers'
import { selectorCache } from '../util/selectorCache'
import { parseQuery, userModelSelector } from './utilities'

const MODEL = new ApiModel('PROGRAM_MESSAGE', 'api/v1/program_messages')

const SHAPE = PropTypes.shape({
  id: PropTypes.number,
  user_id: PropTypes.number,
  provider_id: PropTypes.number,
  slug: PropTypes.string,
  body: PropTypes.string,
  read: PropTypes.bool,
})

const NOT_FOUND = Symbol('ProgramMessage not found')
const PRUNE_PROGRAM_MESSAGES_MODEL = 'PRUNE_PROGRAM_MESSAGES_MODEL'

registerModelReducer('programMessages', (state = {}, { type, url, userId }) => {
  if (type === Action.READ.successType(MODEL)) {
    return { ...state, [parseQuery(url).user_id]: true }
  }

  if (type === PRUNE_PROGRAM_MESSAGES_MODEL) {
    return { ...state, [userId]: false }
  }

  return state
})

const indexForUser = userId => MODEL.read({
  query: { user_id: userId },
  modelMapKey: ['user_id'],
})

const createProgramMessage = (userId, slug, body, e2TreatmentId) => MODEL.create(
  { program_message: { slug, body, e2_treatment_id: e2TreatmentId } },
  { query: { user_id: userId }, modelMapKey: ['user_id'], ignoreFailure: true },
)

const updateProgramMessage = programMessage => MODEL.updateModify(
  { program_message: programMessage },
  { id: programMessage.id, query: { user_id: programMessage.user_id }, ignoreFailure: true },
)

const selectTreatmentMessage = (
  { program_messages: programMessages = {} },
  { programMessages: loaded },
  userId,
  slug,
  e2TreatmentId,
) => {
  if (loaded[userId] !== true || e2TreatmentId == null) return null

  const currentMessages = selectProgramMessagesByTreatment(e2TreatmentId, Object.values(programMessages))

  const message = currentMessages.find(m => m.slug === slug)
  return message || NOT_FOUND
}

const selectProgramMessageBySlug = (
  { program_messages: programMessages = {} },
  { programMessages: loaded },
  userId,
  slug,
) => {
  if (loaded[userId] !== true) return null

  const message = Object.values(programMessages).find(m => m.slug === slug)
  return message ?? NOT_FOUND
}

const selectProgramMessagesByTreatment = (e2TreatmentId, stateMessages) =>
  stateMessages?.filter(message => message.e2_treatment_id === e2TreatmentId) ?? []

const selectProviderConnection = selectorCache(
  ({ userId, e2TreatmentId }) => `${userId}|${e2TreatmentId}`,
  ({ userId, e2TreatmentId }) => {
    if (userId == null) return () => ({ })

    const createOptions = { query: { user_id: userId } }

    const saveStateSelector = createSelector(
      [
        MODEL.isCreatingSelector(createOptions),
        state => MODEL.getCreateErrorMessage(state, createOptions),
        Action.UPDATE_MODIFY.pendingSource,
      ],
      (isCreating, createError, pendingHash) => {
        const pendings = Object.entries(pendingHash)
        const pendingMatches = predicate => ([url, pending]) =>
          predicate(pending) &&
          url.startsWith(MODEL.baseUrl) &&
          // eslint-disable-next-line camelcase
          parseQuery(url)?.user_id === `${userId}`

        const isSaving = isCreating || pendings.find(pendingMatches(Action.isActing)) != null

        function findUpdateError () {
          const pendingEntry = pendings.find(pendingMatches(Action.hasFailed))
          return pendingEntry && Action.getErrorMessage(pendingEntry[1])
        }

        function clearFailure () {
          if (createError != null) {
            ApiModel.dispatch(
              Action.CREATE.getClearPendingAction(MODEL, MODEL.getUrl(createOptions)),
            )
          }
          pendings.filter(pendingMatches(Action.hasFailed)).forEach(([url]) => {
            ApiModel.dispatch(Action.UPDATE_MODIFY.getClearPendingAction(MODEL, url))
          })
        }

        return {
          isSaving,
          saveError: createError || findUpdateError(),
          clearFailure,
        }
      },
    )

    return createSelector(
      [
        userModelSelector(userId, 'program_messages'),
        state => state.models.programMessages,
        saveStateSelector,
      ],
      (stateMessages, loaded, { isSaving, saveError, clearFailure }) => {
        const programMessages = loaded[userId] !== true
          ? null
          : selectProgramMessagesByTreatment(e2TreatmentId, stateMessages).reduce((messages, message) => ({
            ...messages,
            [message.slug]: message,
          }), {})

        function saveMessage (slug, body) {
          if (programMessages[slug] == null) {
            createProgramMessage(userId, slug, body, e2TreatmentId)
          } else {
            updateProgramMessage({ ...programMessages[slug], body })
          }
        }

        return { programMessages, saveMessage, isSaving, saveError, clearFailure }
      },
    )
  },
)

export const ProgramMessage = {
  providerConnection: {
    load: ({ programMessages }, { userId }) => {
      if (programMessages == null && userId != null) indexForUser(userId)
    },

    isLoaded: ({ programMessages }, { userId }) => userId == null || programMessages != null,

    /**
     * selector: (state, { userId }) => connectionShape
     */
    selector: selectProviderConnection,

    shape: {
      programMessages: PropTypes.arrayOf(SHAPE),
      saveMessage: PropTypes.func.isRequired,
      // True if any message for this user is creating or updating at the moment.
      isSaving: PropTypes.func.isRequired,
      saveError: PropTypes.string,
      clearFailure: PropTypes.func.isRequired,
    },
  },

  chapterConnection: {
    load: ({ programMessage }, { userId }) => {
      if (programMessage == null && userId != null) indexForUser(userId)
    },

    isLoaded: ({ programMessage }) => programMessage != null,

    selector: selectorCache(
      ({ userId, slug }) => `${userId}|${slug}`,
      ({ userId, slug }) => createSelector([
        ({ api, models }) => selectProgramMessageBySlug(api, models, userId, slug),
      ],
      programMessage => ({ programMessage })),
    ),

    // This is ensuring that users will see additions or updates to their messages
    // whenever the component mounts, instead of relying on cached information
    onMount: ({ userId }) => ApiModel.dispatch({ type: PRUNE_PROGRAM_MESSAGES_MODEL, userId }),

    shape: SHAPE,
  },

  treatmentConnection: {
    load: ({ programMessage }, { userId }) => {
      if (programMessage == null && userId != null) indexForUser(userId)
    },

    isLoaded: ({ programMessage }) => programMessage != null,

    selector: selectorCache(
      ({ userId, slug, e2TreatmentId }) => `${userId}|${slug}|${e2TreatmentId}`,
      ({ userId, slug, e2TreatmentId }) => createSelector([
        ({ api, models }) => selectTreatmentMessage(api, models, userId, slug, e2TreatmentId),
      ],
      programMessage => ({ programMessage })),
    ),

    // This is ensuring that users will see additions or updates to their messages
    // whenever the component mounts, instead of relying on cached information
    onMount: ({ userId }) => ApiModel.dispatch({ type: PRUNE_PROGRAM_MESSAGES_MODEL, userId }),

    shape: SHAPE,
  },
}
