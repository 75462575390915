import get from 'lodash/get'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel from '../ApiModel'
import { selectUsers } from './User'
import { selectProfiles } from './Profile'
import { mergeIn, registerModelReducer, setIn } from '../reducers'
import { selectorCache } from '../util/selectorCache'
import { parseQuery } from './utilities'
import { ACTIVE_STATUSES, INACTIVE_STATUSES } from './Treatment.d.ts'
import { FeatureFlags } from './FeatureFlags'

const MODEL = new ApiModel('USER_SUMMARY', 'api/v1/user_summaries')
const MODEL_KEY = 'userSummaries'

registerModelReducer(MODEL_KEY, (state, { type, url, response }) => {
  if (type === Action.READ.successType(MODEL)) {
    const { page_token: pageToken, self_guided: selfGuided, inactive } = parseQuery(url)
    return setIn(state, [summaryKey(selfGuided, inactive)], {
      currentPageToken: pageToken,
      nextPageToken: '' + response.next_page_token,
      requestComplete: true,
    })
  } else if (type === Action.READ.requestType(MODEL)) {
    const { self_guided: selfGuided, inactive } = parseQuery(url)
    return mergeIn(state, [summaryKey(selfGuided, inactive)], { requestComplete: false })
  }

  return state
})

const SHAPE = PropTypes.shape({
  id: PropTypes.number.isRequired,
  is_self_guided: PropTypes.bool.isRequired,
  current_treatment_status: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  program_indication: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  coach_call_completed_at: PropTypes.string,
  company_name: PropTypes.string,
  coach_call_scheduled_for: PropTypes.string,
  last_completed_user_activity: PropTypes.string,
  calls_completed: PropTypes.number,
  coaching_sessions_count: PropTypes.number,
  is_soft_excluded: PropTypes.bool,
})

const summaryKey = (selfGuided, inactive) =>
  selfGuided ? 'selfGuided' : inactive ? 'inactive' : 'active'

const userSummariesOptions = ({ selfGuided, limit, pageToken, inactive }) => ({
  query: { self_guided: selfGuided, limit, page_token: pageToken, inactive },
})

const statusMatch = (inactive, status) => {
  return inactive ? INACTIVE_STATUSES.includes(status) : ACTIVE_STATUSES.includes(status)
}

const selectUserSummaries = (selfGuided, inactive) => createSelector(
  [
    state => state.api.user_summaries,
    state => get(state.models, [MODEL_KEY, summaryKey(selfGuided, inactive), 'requestComplete'], false),
    selectUsers,
    selectProfiles,
  ],
  (summaries, requestComplete, users, profiles) => {
    if (summaries == null) return requestComplete ? [] : null
    const me = users.me
    const newUsers = Object.values(users)
      .filter(({ id, is_self_guided: isSelfGuided, current_treatment_status: status }) => {
        if (FeatureFlags.current.cplus_treatment_statuses) {
          return me !== id && summaries[id] == null && statusMatch(inactive, status)
        } else {
          return me !== id && summaries[id] == null && isSelfGuided === Boolean(selfGuided)
        }
      })
      .map(user => {
        const profile = profiles && profiles[user.profile_id]

        return {
          ...user,
          first_name: profile && profile.first_name,
          last_name: profile && profile.last_name,
          company_name: profile && get(profile, ['campaign_detail', 'company_name']),
        }
      })
    // User summaries can be out of date. If we have a more recent
    // user/profile record for this user defer to the values there.
    return [...newUsers, ...Object.values(summaries)
      .filter(({ is_self_guided: isSelfGuided, updated_at: updatedAt, id, current_treatment_status: status }) => {
        const user = users && users[id]
        if (FeatureFlags.current.cplus_treatment_statuses) {
          if (user != null && moment(updatedAt).isBefore(moment(user.updated_at))) {
            return statusMatch(inactive, user.current_treatment_status)
          }
          return statusMatch(inactive, status)
        } else {
          if (user != null && moment(updatedAt).isBefore(moment(user.updated_at))) {
            return user.is_self_guided === Boolean(selfGuided)
          }
          return isSelfGuided === Boolean(selfGuided)
        }
      })
      .map(summary => {
        const user = users && users[summary.id]
        const profile = profiles && profiles[summary.profile_id]

        const selectFirstUpdated = key => {
          // always get soft exclude from user summary
          if (key === 'is_soft_excluded') return summary[key]

          const otherObject = key === 'first_name' || key === 'last_name' ? profile : user

          if (otherObject != null && moment(summary.updated_at).isBefore(otherObject.updated_at)) {
            if (key === 'company_name') return get(otherObject, ['campaign_detail', key])
            return otherObject[key]
          }

          return summary[key]
        }

        return Object.keys(summary).reduce((response, key) =>
          ({ ...response, [key]: selectFirstUpdated(key) }), { })
      })]
  })

const selectNextPageToken = (selfGuided, inactive) => state =>
  get(state.models, [MODEL_KEY, summaryKey(selfGuided, inactive), 'nextPageToken'])

const selectCurrentPageToken = (selfGuided, inactive) => state =>
  get(state.models, [MODEL_KEY, summaryKey(selfGuided, inactive), 'currentPageToken'])

const selectConnection = selectorCache(
  ({ selfGuided, pageSize, inactive }) => `${selfGuided}|${inactive}|${pageSize}`,
  ({ selfGuided, pageSize, inactive }) => createSelector(
    [
      selectUserSummaries(selfGuided, inactive),
      selectNextPageToken(selfGuided, inactive),
      selectCurrentPageToken(selfGuided, inactive),
    ],
    (summaries, nextPageToken, currentPageToken) => {
      const hasMoreSummaries = nextPageToken == null || currentPageToken !== nextPageToken
      return {
        summaries,
        hasMoreSummaries,
        loadSummaries: () =>
          MODEL.read(userSummariesOptions({ selfGuided, limit: pageSize, inactive })),
        loadMoreSummaries: () =>
          hasMoreSummaries &&
          MODEL.read(userSummariesOptions({ selfGuided, limit: pageSize, pageToken: nextPageToken, inactive })),
      }
    }))

export const selectUserSummary = state => state.api.user_summaries

export const UserSummary = {
  SHAPE,

  connection: {
    selector: selectConnection,

    load: ({ summaries }, { selfGuided, pageSize, inactive }) => {
      if (summaries == null) {
        MODEL.read(userSummariesOptions({ selfGuided, limit: pageSize, inactive }))
      }
    },

    isLoaded: ({ summaries }) => summaries != null,

    shape: {
      summaries: PropTypes.arrayOf(SHAPE).isRequired,
      loadMoreSummaries: PropTypes.func.isRequired,
      hasMoreSummaries: PropTypes.bool.isRequired,
    },
  },
}
