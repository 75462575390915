import get from 'lodash/get'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'

const MODEL = new ApiModel('CLIENT_TOKEN', 'api/v1/subscription/client_token')

export const ClientToken = {
  connection: {
    load: ({ clientToken }) => {
      if (clientToken == null) MODEL.read()
    },

    isLoaded: ({ clientToken }) => clientToken != null,

    selector: createSelector(
      [state => get(state.api.client_tokens, ['client_token'])],
      clientToken => ({ clientToken }),
    ),

    shape: {
      clientToken: PropTypes.shape({ token: PropTypes.string.isRequired }).isRequired,
    },
  },
}
