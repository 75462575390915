/**
 * Actions that are dispatched as part of socket activity. They are specified in this separate file
 * in order to get around a problem with circular dependency declarations in webpack.
 */
export const SocketActions = {
  SOCKET_CONNECTING: 'SOCKET_CONNECTING',
  SOCKET_CONNECTED: 'SOCKET_CONNECTED',
  SOCKET_DISCONNECT: 'SOCKET_DISCONNECT',

  CHANNEL_RECEIVED: 'CHANNEL_RECEIVED',

  TYPING: 'MESSAGE_TYPING',
  CLEAR_TYPING: 'MESSAGE_TYPING_CLEAR',

  CHAT_EVENT: 'CHAT_EVENT',
}
