import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'
import { selectorCache } from '../util/selectorCache'

const MODEL = new ApiModel('PARTICIPANT', 'api/v1/participants')
const NOT_FOUND = Symbol('User not found')

const SHAPE = PropTypes.shape({
  participant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    user_id: PropTypes.number.isRequired,
  }),
  participantError: PropTypes.object,
})

const options = (leadId, isTherapist) => ({
  id: leadId,
  query: { is_therapist_for_participant: isTherapist },
})

const selectParticipants = state => state.api.participants

const selectParticipant = (leadId, isTherapist) => {
  const queryOptions = options(leadId, isTherapist)
  return createSelector(
    [
      selectParticipants,
      MODEL.isNotFoundSelector(queryOptions),
      state => MODEL.getReadErrorMessage(state, queryOptions),
      state => MODEL.getReadErrorReason(state, queryOptions),
    ],
    (participants, notFound, readErrorMessage, readErrorReason) => {
      const readFailed = readErrorReason ?? readErrorMessage

      return ({
        participantError: notFound ? 'participant_not_found' : readFailed,
        participant: notFound ? NOT_FOUND : participants?.[leadId],
      })
    },
  )
}

const loadParticipant = (leadId, isTherapist) => {
  MODEL.read(options(leadId, isTherapist))
}

export const Participant = {
  SHAPE,
  NOT_FOUND,
  connection: {
    load: ({ participant }, { leadId, isTherapist }) => {
      if (participant == null) {
        loadParticipant(leadId, isTherapist)
      }
    },

    isLoaded: ({ participant, participantError }, { leadId }) => {
      if (leadId == null) return true
      return participant != null || participantError != null
    },

    selector: selectorCache(
      ({ leadId }) => leadId,
      ({ leadId, isTherapist }) => createSelector([
        selectParticipant(leadId, isTherapist),
      ],
      ({ participant, participantError }) => ({
        participant,
        participantError,
      })),
    ),

    shape: {
      participant: PropTypes.shape({
        id: PropTypes.string,
        user_id: PropTypes.string,
      }),
      participantError: PropTypes.string,
    },
  },
}
