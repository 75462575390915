/* eslint-disable camelcase */
import { ApiAction } from '../types'
import { Connection } from './types'

export const ACTIVE_STATUSES = ['ic_fua', 'missed_ic_fua', 'in_treatment', 'kickoff']
export const INACTIVE_STATUSES = ['inactive', 'cancelled', 'finished']
export const ALL_STATUSES = [...ACTIVE_STATUSES, ...INACTIVE_STATUSES] as const

type Provider = {
  email: string,
  full_name: string,
  years_experience: number,
  // Filled in on the client, at the selector level from full name and license depending on the
  // provider type
  displayName: string,
  gender?: string,
  license?: string,
}

export type Treatment = {
  status: typeof ALL_STATUSES[number],
  product_line: string,
  program: string,
  is_self_guided: bool,
  is_graduated: bool,
  session_protocol_code: string,
  behavioral_coach?: Provider,
  therapist?: Provider,
  previous_status: typeof ALL_STATUSES[number],
  e2_treatment_id: string,
}

type DefaultConnection = { activeTreatment: Treatment }

type CurrentTreatmentConnection = { currentTreatment: Treatment }

type E2TreatmentIdConnection = { treatment: Treatment }
type E2TreatmentIdConnectionProps = { userId: number }

type CurrentForUserConnection = {
  currentTreatmentForUser: Treatment,
  updateTreatment: (treatment: Treatment) => Promise<ApiAction>,
}
type CurrentForUserConnectionProps = { userId: number }

export declare namespace Treatment {
  const connection: Connection<DefaultConnection>
  const currentConnection: Connection<CurrentTreatmentConnection>
  const e2TreatmentIdConnection: Connection<E2TreatmentIdConnection, E2TreatmentIdConnectionProps>
  const currentForUserConnection: Connection<CurrentForUserConnection, CurrentForUserConnectionProps>
}
