import i18next from 'i18next'
import { Connection, selectorCache } from 'joyable-js-api'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { createSelector } from 'reselect'
import { HOCConnection } from '../components/modelConnect'
import Log from '../services/log'
import { dispatch } from '../services/ReduxService'
import { useConnection } from '@app/js/lib/useConnection'
import { Action, Store } from 'redux'
import { Dispatch } from 'react'

const LOAD_NAMESPACE = Symbol('Load Namespace')
const NAMESPACE_STATE = 'NAMESPACE_STATE'
const NAMESPACE_LOADING = Symbol('Namespace Loading')
const NAMESPACE_LOADED = Symbol('Namespace Loaded')
const NAMESPACE_LOAD_ERROR = Symbol('Namespace Load Error')

type Bundle = {
  [key: string]: string | Bundle
}

type SelectedI18n = {
  t: (key: string, options?: Record<string, any>) => string
  tExists: (key: string) => boolean,
  getTBundle: () => Bundle,
}

type I18nProps = {
  namespace: string,
  prefix?: string,
}

type I18nState = { [key: string]: symbol }

type I18nStore = {
  i18n: I18nState
}

type I18nAction = {
  type: typeof NAMESPACE_STATE,
  state: symbol,
  namespace: string,
}

const isi18nAction = (action: Action<string>): action is I18nAction => action.type === NAMESPACE_STATE

const getNamespaceBundle = (namespace: string) => {
  const language = i18next.language.split('-')[0]
  return i18next.getResourceBundle(language, namespace) as Bundle
}

const loadState = (namespace: string) => (state: I18nStore) => state.i18n[namespace]

export const i18nMiddleware = (store: Store) => (next: Dispatch<any>) =>
  (action: Record<keyof any, unknown>) => {
    const namespace = action[LOAD_NAMESPACE] as string | undefined
    if (namespace == null) return next(action)

    if (loadState(namespace)(store.getState() as I18nStore) != null) return null

    next({ type: NAMESPACE_STATE, state: NAMESPACE_LOADING, namespace })

    i18next.loadNamespaces(namespace).then(
      () => next({ type: NAMESPACE_STATE, state: NAMESPACE_LOADED, namespace }),
      error => {
        Log.error('Encountered error loading namespace', error)
        next({ type: NAMESPACE_STATE, state: NAMESPACE_LOAD_ERROR, namespace })
      },
    )
  }

export const i18nReducer = (state = {}, action: Action<string>) =>
  isi18nAction(action) ? { ...state, [action.namespace]: action.state } : state

const selectConnection = selectorCache<SelectedI18n | Record<any, never>, I18nProps>(
  ({ namespace, prefix = '' }) => `${namespace}|${prefix}`,
  ({ namespace, prefix = '' }) => {
    const isMobile = namespace.endsWith('Mobile')
    const t = (key: string, options?: Record<string, any>) => isMobile
      ? i18next.t(
          `${namespace}:${prefix}${key}`,
          options?.sprintf == null ? options : ({ postProcess: 'sprintf', ...options }),
        )
      : i18next.t(`${namespace}:${prefix}${key}`, options)
    const tExists = (key: string) => i18next.exists(`${namespace}:${prefix}${key}`)
    const getTBundle = () => {
      const bundle = getNamespaceBundle(namespace)
      return isEmpty(prefix)
        ? bundle
        : get(bundle, prefix.replace(/\.$/, '')) as Bundle
    }

    return createSelector(
      [loadState(namespace)],
      (state): SelectedI18n | Record<any, never> =>
        state === NAMESPACE_LOADED ? { t, tExists, getTBundle } : {},
    )
  },
)

export const i18nWithNamespace = (
  namespace: string,
  { prefix = '', useConnectionNamespace = false } = {},
): HOCConnection<SelectedI18n | Record<any, never>, I18nProps> => ({
  ...i18nConnection,
  normalizeProps: () => ({ namespace, prefix }),
  ...(useConnectionNamespace ? { namespace } : null),
})

export const i18nConnection: Connection<SelectedI18n | Record<any, never>, I18nProps> = {
  selector: selectConnection,

  load: ({ t }, { namespace }) => {
    if (t == null) dispatch({ [LOAD_NAMESPACE]: namespace })
  },

  isLoaded: ({ t }) => t != null,
}

export const useI18n = (namespace: string, prefix = '') =>
  useConnection(i18nConnection, { namespace, prefix } as I18nProps)
