import get from 'lodash/get'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel from '../ApiModel'
import { registerModelReducer } from '../reducers'
import { selectorCache } from '../util/selectorCache'
import { getSortedUserModelIds, parseQuery, selectModelStore } from './utilities'

const NOT_FOUND = Symbol('NOT_FOUND')
const MODEL = new ApiModel('EXERCISE_SNAPSHOTS', 'api/v1/exercise_snapshots')

registerModelReducer('exerciseSnapshots', (state = { }, { type, url }) => {
  if (type === Action.READ.successType(MODEL)) {
    const { user_id: userId, exercise_name: exerciseName } = parseQuery(url)
    return {
      ...state,
      loaded: {
        ...state.loaded,
        [userId]: [
          ...(state.loaded?.[userId] || []),
          exerciseName,
        ],
      },
    }
  }

  return state
})

const SHAPE = PropTypes.shape({
  id: PropTypes.number.isRequired,
  exercise_name: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  answers: PropTypes.arrayOf(PropTypes.number).isRequired,
  score: PropTypes.number,
  suicidality_answer: PropTypes.number,
})

const INDICATION_TO_ASSESSMENT = {
  depression: { exercise: 'phq9', name: 'PHQ-9' },
  generalized_anxiety: { exercise: 'gad7', name: 'GAD-7' },
  social_anxiety: { exercise: 'spin', name: 'SPIN' },
}

const getExercise = ({ program_indication: indication }) =>
  get(INDICATION_TO_ASSESSMENT[indication], ['exercise'])

const getAssessmentName = ({ program_indication: indication }) =>
  get(INDICATION_TO_ASSESSMENT[indication], ['name'])

const selectAllConnection = selectorCache(
  ({ user }) => user && user.id,
  ({ user }) => {
    if (user == null) return () => ({ })

    return createSelector(
      [
        getSortedUserModelIds(user.id, 'exercise_snapshots'),
        selectModelStore('exercise_snapshots'),
      ],
      (ids, snapshots) => ({
        exerciseSnapshots: ids &&
          (ids.length === 0 ? [] : snapshots && ids.map(id => snapshots[id])),
        assessmentName: getAssessmentName(user),
      }),
    )
  },
)

const selectLatestConnection = selectorCache(
  ({ userId, exerciseName }) => `${userId}|${exerciseName}`,
  ({ userId, exerciseName }) => createSelector(
    [
      getSortedUserModelIds(userId, 'exercise_snapshots'),
      selectModelStore('exercise_snapshots'),
      state => state.models.exerciseSnapshots,
    ],
    (ids, snapshots, { loaded }) => {
      // If we have any snapshots of this type, we can assume we've either loaded them all already,
      // or the latest snapshot creation was captured in this store, so we don't need to load fresh
      const snapshot = ids && ids
        .map(id => snapshots[id])
        .filter(({ exercise_name: name }) => name === exerciseName.toUpperCase())
        .pop()
      if (snapshot != null) return { snapshot }

      // If we haven't tried to load this type yet, load it now
      if (loaded?.[userId] == null || !loaded[userId].includes(exerciseName)) return { }

      // We've tried to load this type, and there just aren't any.
      return { snapshot: NOT_FOUND }
    },
  ),
)

const createQuery = (userId, activitySlug) =>
  ({ user_id: userId || 'me', activity_slug: activitySlug })

function recordSnapshot (exerciseName, activitySlug, userId, uaId) {
  MODEL.create(
    { exercise_snapshot: { exercise_name: exerciseName }, user_activity_id: uaId },
    { modelMapKey: ['user_id'], query: createQuery(userId, activitySlug) },
  )
}

const selectCreateConnection = selectorCache(
  ({ exerciseName, activitySlug, userId }) => `${exerciseName}|${activitySlug}|${userId}`,
  ({ exerciseName, activitySlug, userId }) => createSelector(
    [
      getSortedUserModelIds(userId || 'me', 'exercise_snapshots'),
      selectModelStore('exercise_snapshots'),
      Action.CREATE.pendingSource,
    ],
    (ids, snapshots, pending) => {
      // If we ever have a situation where multiple snapshots can be created at once, this will
      // not be sufficient to differentiate it for this connection.
      const isCreating = pending?.[MODEL.getUrl({ query: createQuery(userId, activitySlug) })] != null
      if (isCreating) return ({ isCreating })

      const snapshot = ids && ids
        .map(id => snapshots[id])
        .filter(({ exercise_name: name }) => name === exerciseName.toUpperCase())
        .pop()

      return { isCreating, snapshot }
    },
  ),
)

export const ExerciseSnapshots = {
  NOT_FOUND,

  /**
   * modelConnect { user: { id, program_indication } }
   */
  allConnection: {
    load: ({ exerciseSnapshots }, { user }) => {
      if (exerciseSnapshots == null && user != null) {
        const exercise = getExercise(user)
        if (exercise != null) {
          MODEL.read({
            query: { user_id: user.id, exercise_name: exercise },
            modelMapKey: ['user_id', user.id],
          })
        }
      }
    },

    isLoaded: ({ assessmentName, exerciseSnapshots }, { user }) =>
      (user != null && assessmentName == null) || exerciseSnapshots != null,

    selector: selectAllConnection,

    shape: {
      exerciseSnapshots: PropTypes.arrayOf(SHAPE),
      assessmentName: PropTypes.string,
    },
  },

  /**
   * ModelConnection { exerciseName, userId }
   *   If userId is null, the current user is used
   *   This connection assumes that if there are snapshots in the redux store for this exercise,
   *   then the latest is already cached and it is returned.
   */
  latestConnection: {
    load: ({ snapshot }, { exerciseName, userId }) => {
      if (snapshot == null) {
        MODEL.read({
          query: { user_id: userId || 'me', exercise_name: exerciseName },
          modelMapKey: ['user_id'],
        })
      }
    },

    isLoaded: ({ snapshot }) => snapshot != null,

    selector: selectLatestConnection,

    shape: {
      snapshot: PropTypes.oneOfType([SHAPE, PropTypes.symbol]),
    },
  },

  /**
   * modelConnect { exerciseName, activitySlug, userId }
   *  if userId is null, the current user is used.
   */
  createSnapshotConnection: {
    onMount: ({ exerciseName, activitySlug, userId, uaId }) => {
      recordSnapshot(exerciseName, activitySlug, userId, uaId)
    },

    isLoaded: ({ snapshot, isCreating }) => snapshot != null && !isCreating,

    selector: selectCreateConnection,

    shape: {
      snapshot: SHAPE,
      isCreating: PropTypes.bool,
    },
  },
}
