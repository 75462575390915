import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import keyBy from 'lodash/keyBy'

import Action from '../Action'
import ApiModel from '../ApiModel'
import { registerModelReducer } from '../reducers'

// Sponsors model
const MODEL = new ApiModel('SPONSORS', 'api/v1/sponsors')
const SPONSORS_READ_SUCCESS_TYPE = Action.READ.successType(MODEL)

const SHAPE = PropTypes.shape({
  name: PropTypes.string,
  displayName: PropTypes.string,
  sponsorSlug: PropTypes.string,
})

const mapSponsorList = (sponsors) =>
  sponsors.map(({ name, display_name: displayName, sponsor_slug: sponsorSlug }) =>
    ({ name, displayName, sponsorSlug }))

registerModelReducer('sponsors', (state = null, { type, response }) => {
  if (type === SPONSORS_READ_SUCCESS_TYPE) {
    return { sponsors: mapSponsorList(response.sponsors) }
  }
  return state
})

const selectSponsors = createSelector(
  [
    state => state.models.sponsors,
  ],
  (sponsors) => (sponsors),
)

// Admin Sponsors model
const ADMIN_MODEL = new ApiModel('ADMIN_SPONSORS', 'admin/api/v1/sponsors')
const ADMIN_SPONSORS_READ_SUCCESS_TYPE = Action.READ.successType(ADMIN_MODEL)

const ADMIN_SPONSOR_SHAPE = PropTypes.shape({
  id: PropTypes.number,
  displayName: PropTypes.string,
  name: PropTypes.string,
  sponsorSlug: PropTypes.string,
  contactEffectiveAt: PropTypes.string,
  createdAt: PropTypes.string,
  accessCodeIsMemberId: PropTypes.string,
})

const ADMIN_SHAPE = PropTypes.arrayOf(ADMIN_SPONSOR_SHAPE)

registerModelReducer('adminSponsors', (state = null, { type, response }) => {
  if (type === ADMIN_SPONSORS_READ_SUCCESS_TYPE) {
    const sponsors = mapAdminSponsorList(response)
    return {
      sponsors,
      sponsorsMap: convertSponsorListToMap(sponsors),
    }
  }
  return state
})

// Utilities
const mapAdminSponsorList = ({ sponsors }) => sponsors.map((sponsor) => ({
  id: sponsor.id,
  displayName: sponsor.display_name,
  name: sponsor.name,
  sponsorSlug: sponsor.sponsor_slug,
  contactEffectiveAt: getFormattedDate(sponsor.contract_effective_at),
  createdAt: getFormattedDate(sponsor.created_at),
  accessCodeIsMemberId: sponsor.access_code_is_member_id?.toString(),
  defaultAccessCode: sponsor.default_access_code,
}))

const convertSponsorListToMap = (sponsors) => keyBy(sponsors, 'id')
const getFormattedDate = (isoDate) => isoDate != null && isoDate.split('T')[0]

// Selectors
const adminSponsorsSelector = ({ models: { adminSponsors } }) => adminSponsors != null ? adminSponsors : null

const selectAdminSponsors = createSelector(
  [adminSponsorsSelector],
  adminSponsors => adminSponsors && adminSponsors.sponsors,
)

const selectAdminSponsorsMap = createSelector(
  [adminSponsorsSelector],
  adminSponsors => adminSponsors && adminSponsors.sponsorsMap,
)

const selectAdminConnection = createSelector(
  [
    selectAdminSponsors,
    selectAdminSponsorsMap,
  ],
  (adminSponsors, adminSponsorsMap) => ({
    adminSponsors,
    adminSponsorsMap,
    getSponsors: () => ADMIN_MODEL.read(),
    deleteSponsor: id => ADMIN_MODEL.destroy({ id }),
    clearDeleteError: (id) => {
      ApiModel.dispatch(
        Action.DESTROY.getClearPendingAction(ADMIN_MODEL, ADMIN_MODEL.getUrl({ id })),
      )
    },
  }),
)

export const Sponsor = {
  connection: {
    load: (sponsors) => sponsors == null && MODEL.read(),
    isSponsorLoaded: (sponsors) => sponsors != null,
    selector: selectSponsors,
    shape: SHAPE,
  },

  adminConnection: {
    load: ({ adminSponsors }) => adminSponsors == null && ADMIN_MODEL.read(),
    isSponsorLoaded: (adminSponsors) => adminSponsors != null,
    selector: selectAdminConnection,
    shape: ADMIN_SHAPE,
  },
}
