import Log from './log'
import ApiModel from './ApiModel'

/**
 * Will call `predicate` once immediately, and then every time the store updates until `predicate`
 * returns true, at which point the store subscription will be cleaned up and the promise will
 * resolve.
 *
 * Warning! If a dispatch is triggered in `predicate`, it can cause an infinite loop in redux.
 * If a dispatch is required as a result of the store being ready, either call it when the promise
 * resolves, or use `setTimeout(..., 0)` to delay the dispatch by a frame.
 */
export default function awaitState (
  predicate: (state: Record<string, any>) => boolean,
): Promise<Record<string, any>> {
  return new Promise<Record<string, any>>((resolve, reject) => {
    try {
      // no need to subscribe if the store already satisfies our predicate
      const state = ApiModel.reduxState
      if (predicate(state)) {
        resolve(state)
        return
      }

      const unsubscribe = ApiModel.store.subscribe(() => {
        const state = ApiModel.reduxState
        if (predicate(state)) {
          unsubscribe()
          resolve(state)
        }
      })
    } catch (error) {
      Log.error('Store observer predicate threw an error', error)
      reject(error)
    }
  })
}
