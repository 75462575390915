import dateCompare from '@app/js/lib/dateCompare'
import findFirst from '@app/js/lib/findFirst'
import { get, isBoolean, isInteger, isObject, isString } from 'lodash'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { createSelector, OutputSelector } from 'reselect'
import Action from '../Action'
import ApiFetch, { ApiFetchOptions } from '../ApiFetch'
import ApiModel, { FailureLoggingFilter } from '../ApiModel'
import Log from '../log'
import { registerModelReducer } from '../reducers'
import { selectorCache } from '../util/selectorCache'
import { ActivityConfig } from './ActivityConfig'
import { parseId, parseQuery } from './utilities'
import { Connection } from './types'
import { ApiAction, ApiKey, ApiState, isHttpAction } from '../types'

type CompletionContentItem = {
  title: string,
  body: string | any[],
}

/* eslint-disable camelcase */
type UserActivity = {
  id: number,
  state: string,
  activity_slug: string,
  schema_version: number,
  device_type: string,
  content: Record<string, any>
  last_step_completed?: string,
  completion_content?: Array<CompletionContentItem>,
  sort_slug?: string,
  program_activity_id?: string,
}
/* eslint-enable camelcase */

type ModelState = {
  allCompleteLoaded?: string[],
  lastCompletedLoaded?: string[],
}

type SaveReturnType = Promise<ApiAction> | undefined

type SingleUA = UserActivity | symbol | null
type MultiUA = UserActivity[] | symbol | null
type UserActivityPicker<T extends SingleUA | MultiUA> = (
  userActivities: UserActivity[],
  activitySlug: string,
  modelState: ModelState,
  programActivityId: string | undefined,
) => T

type UserActivityConnectionProps = {
  id: number,
}

type UserActivityConnection = {
  userActivity: SingleUA,
  userActivityIsSaving: boolean,
  userActivitySaveError?: string,
  saveUserActivity: (updatedUserActivity: UserActivity) => SaveReturnType,
  saveUserActivityContent: (contentUpdates: Record<string, any>) => SaveReturnType,
}

type UserIdConnectionProps = { userId: number }

type ActivitySlugConnectionProps = { activitySlug: string, programActivityId: string }

type StartActivityConnectionProps = ActivitySlugConnectionProps & {
  programActivityId?: string,
  userId?: number,
  inheritedContent?: Record<string, any>,
}

type StartActivityConnection = {
  userActivity: SingleUA,
  userActivityIsCreating: boolean,
}

type MultiUAConnection = {
  userActivities: MultiUA,
  isAllCompletedLoading?: boolean,
  refetchAllCompleteUAs?: (activitySlug: string, programActivityId: string, userId: number) => void,
}

type SingleUAConnection = {
  userActivity: SingleUA,
  isLastCompletedStep: (stepSlug: string) => boolean,
}

type UASelector<T> = OutputSelector<Record<string, any>, T, any>

const MOCK_ACTIVITIES = ['template_gallery', 'template_solo', 'mock_activity']

const failureFilter: FailureLoggingFilter = (json, response, fetch) =>
  !(fetch.action === Action.READ && response.status === 404)
const MODEL = new ApiModel('USER_ACTIVITY', 'api/v1/user_activities', failureFilter)

const { selectModelState } = registerModelReducer<ModelState>(
  'userActivities',
  (state = {}, action) => {
    if (!isHttpAction(action)) return state

    const { type, url } = action
    if (type === Action.READ.successType(MODEL) && parseId(MODEL, url) === 'all_complete') {
      return {
        ...state,
        allCompleteLoaded: [
          ...(state.allCompleteLoaded ?? []),
          parseQuery(url).activity_slug,
        ],
      }
    }

    if (type === Action.READ.successType(MODEL) && parseId(MODEL, url) === 'last_complete') {
      return {
        ...state,
        lastCompletedLoaded: [
          ...(state.lastCompletedLoaded || []),
          parseQuery(url).activity_slug,
        ],
      }
    }

    return state
  },
)

const NOT_FOUND = Symbol('UserActivity not found')

/**
 * @deprecated Once all components that use these connections are converted to TS, this will
 * no longer be necessary
 */
export const SHAPE = PropTypes.oneOfType([
  PropTypes.symbol, // for NOT_FOUND

  PropTypes.shape({
    id: PropTypes.number,
    state: PropTypes.string,
    activity_slug: PropTypes.string,
    schema_version: PropTypes.number,
    device_type: PropTypes.string,
    content: PropTypes.object,
    last_step_completed: PropTypes.string,
    completion_content: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      sort_slug: PropTypes.string,
    })),
  }),
])

export const selectUserActivities = (state: ApiState) =>
  (state.api as ApiState).user_activities as { [key: ApiKey]: UserActivity } ?? {}

const mappedUserActivitySelector = (activitySlug: string) =>
  (state: ApiState): UserActivity[] | null => {
    const ids = get(state.modelMap, ['activitySlug', activitySlug, 'user_activities']) as ApiKey[]
    if (!Array.isArray(ids)) return null

    const userActivities = selectUserActivities(state)
    return ids.map(id => userActivities[id]).filter(ua => ua != null)
  }

const typeLoader = (type: string) => (activitySlug: string, userId: number, programActivityId?: string) => {
  const key = programActivityId != null ? programActivityId : activitySlug
  void MODEL.read({
    id: type,
    query: getQuery({ activitySlug, userId, programActivityId }),
    modelMapKey: ['activitySlug', key],
  })
}

const loadLatest = (activitySlug: string, programActivityId: string, userId?: number) => {
  const query = getQuery({ activitySlug, userId, programActivityId })
  const options: ApiFetchOptions =
    { id: 'latest', query, modelMapKey: ['activitySlug', programActivityId ?? activitySlug] }
  // TODO (NJC): Refactor template_gallery usages to use the more general use template_solo system
  if (MOCK_ACTIVITIES.includes(activitySlug)) {
    // template gallery data gets stubbed out and not persisted.
    const fetch = new ApiFetch(MODEL, Action.READ, options)
    const action = fetch.apiSuccess({
      user_activity: {
        schema_version: 1,
        activity_slug: activitySlug,
        id: -1,
        content: { },
        state: 'started',
      },
    })
    if (action != null) ApiModel.dispatch(action)
    return
  }

  return MODEL.read(options)
}

const clearPendingRead = (type: string, activitySlug: string) => {
  const url = MODEL.getUrl({ id: type, query: getQuery({ activitySlug, programActivityId: activitySlug }) })
  ApiModel.dispatch(Action.READ.getClearPendingAction(MODEL, url))
}

const selectMultiUALoading = (type: string, activitySlug: string, userId: number, programActivityId?: string) =>
  (state: ApiState) => {
    return MODEL.isReadingSelector({
      id: type,
      query: getQuery({ activitySlug, userId, programActivityId }),
    })(state)
  }

const refetchMultiUAs = (type: string, activitySlug: string, programActivityId: string, userId: number) => {
  ApiModel.pruneCache('user_activities')

  const key = programActivityId ?? activitySlug
  void MODEL.read({
    id: type,
    query: getQuery({ activitySlug, userId, programActivityId }),
    modelMapKey: ['activitySlug', key],
  })
}

function typeSelector (type: string, pickUa: UserActivityPicker<SingleUA>):
    (activitySlug: string, userId?: number, programActivityId?: string) => UASelector<SingleUA>
function typeSelector (type: string, pickUA: UserActivityPicker<MultiUA>):
    (activitySlug: string, userId?: number, programActivityId?: string) => UASelector<MultiUA>
function typeSelector (type: string, pickUa: UserActivityPicker<SingleUA | MultiUA>) {
  return (activitySlug: string, userId?: number, programActivityId?: string): UASelector<SingleUA | MultiUA> => {
    const pai = programActivityId != null ? programActivityId : activitySlug
    const query = getQuery({ activitySlug, userId, programActivityId: pai })
    const selectNotFound = createSelector(
      [Action.READ.pendingSource],
      pending => {
        const url = MODEL.getUrl({ id: type, query })
        return Action.isNotFound(pending[url])
      },
    )
    return createSelector(
      [mappedUserActivitySelector(pai), selectModelState, selectNotFound],
      (userActivities, modelState, notFound) => {
        if (notFound) {
          return NOT_FOUND
        } else if (Array.isArray(userActivities)) {
          return pickUa(userActivities, activitySlug, modelState, pai) ?? null
        } else {
          return null
        }
      },
    )
  }
}

const anyLatestSelector: UserActivityPicker<SingleUA> = (userActivities) =>
  userActivities[userActivities.length - 1] ?? null
const latestSelector: UserActivityPicker<SingleUA> = (userActivities) =>
  userActivities.find(({ id, state }) => id == null || state === 'started') ?? null

const lastCompleteSelector: UserActivityPicker<SingleUA> = (userActivities, activitySlug, modelState) => {
  const loaded = modelState.lastCompletedLoaded?.includes(activitySlug)
  const completedActivities = userActivities.filter(({ state }) => state === 'completed')

  if (completedActivities.length === 0 && !loaded) return null
  return findFirst(
    completedActivities,
    dateCompare(false, 'completed_at'),
  ) as UserActivity
}

const allCompleteSelector: UserActivityPicker<MultiUA> = (userActivities, activitySlug, modelState) =>
  // Make sure we've loaded "all complete" for this activity from the server once. After that,
  // any further completed activities for this slug will get into the store automatically so we can
  // consider the state valid. If they leave a browser session open and then complete an activity
  // on another device or browser and come back to the first browser, this assumption breaks, but
  // that corner case doesn't feel worth coding around.
  modelState.allCompleteLoaded?.includes(activitySlug)
    ? userActivities.filter(({ state }) => state === 'completed')
    : null

const allForUserSelector = (userId: number) => (state: ApiState): UserActivity[] | null => {
  const ids = get(state.modelMap, ['user_id', userId, 'user_activities']) as ApiKey[]
  if (ids == null) return null

  const userActivities = selectUserActivities(state)
  return ids.map(id => userActivities[id]).filter(ua => ua != null)
}

const getQuery = ({ activitySlug, userId, programActivityId }:
                      { activitySlug?: string, userId?: number, programActivityId?: string }) => {
  // A couple of things are important here for the eventual consumption by `queryString.stringify`:
  // * If there are no params, query should be null
  // * If a given param has a null value, it should not exist as a key on the query object
  const query: Record<string, any> = {}
  if (activitySlug != null) query.activity_slug = activitySlug
  if (userId != null) query.user_id = userId
  query.program_activity_id = programActivityId != null ? programActivityId : activitySlug
  return Object.keys(query).length === 0 ? undefined : query
}

function startActivity (
  activitySlug: string, programActivityId?: string, userId?: number, inheritedContent?: Record<string, any>) {
  const data: Record<string, any> = {
    user_activity: {
      schema_version: 1,
      activity_slug: activitySlug,
      program_activity_id: programActivityId ?? activitySlug,
      content: inheritedContent,
    },
  }
  if (userId != null) data.user_id = userId
  const options = { modelMapKey: ['activitySlug', programActivityId ?? activitySlug] }

  // usually startActivity is called after a latest read returns a 404
  const query = getQuery({ activitySlug, userId, programActivityId })
  void MODEL.create(data, options)
  ApiModel.dispatch(Action.READ.getClearPendingAction(
    MODEL,
    MODEL.getUrl({ id: 'latest', query }),
  ))
}

function save (userActivity: UserActivity, userId?: number): SaveReturnType {
  // template gallery/template solo data does not persist
  if (MOCK_ACTIVITIES.includes(userActivity.activity_slug)) return

  const data = { user_activity: userActivity }
  const options: ApiFetchOptions = {
    id: userActivity.id,
    modelMapKey: ['activitySlug', userActivity.program_activity_id ?? userActivity.activity_slug],
    query: getQuery({ userId }),
  }
  return MODEL.updateModify(data, options)
}

const selectIsSaving = (id: number, userId?: number) =>
  MODEL.isUpdatingSelector({ id, query: getQuery({ userId }) })
const selectSaveFailed = (id: number, userId?: number) => (state: ApiState) =>
  MODEL.getUpdateErrorMessage(state, { id, query: getQuery({ userId }) })

const isLastCompletedStep = (userActivity: UserActivity, stepSlug: string) => {
  return userActivity.last_step_completed === stepSlug
}

const setContent = (userActivity: UserActivity, contentUpdates: Record<string, any>) => ({
  ...userActivity,
  content: { ...userActivity.content, ...contentUpdates },
})

/**
 * Saves a copy of the userActivity with the given content changes.
 */
const saveContent = (
  userActivity: UserActivity,
  contentUpdates: Record<string, any>,
): SaveReturnType =>
  save(setContent(userActivity, contentUpdates))

/**
 * Surfaces an activity's completion_content for interpolation,
 * keying the content on the index if the content is an array.
 */
function userFriendlyCompletionContent (userActivity?: UserActivity): any {
  if (userActivity == null) return {}
  const { completion_content: completionContent } = userActivity

  if (Array.isArray(completionContent)) {
    return completionContent.reduce((result, content, idx) => {
      result[idx.toString()] = content
      return result
    }, {})
  }

  return completionContent
}

/**
 * Computes the user friendly content for the user activity, based on the
 * provided activity config's step content.
 */
function userFriendlyContentJS (userActivity: UserActivity, activityConfig: ActivityConfig) {
  if (userActivity == null) return {}

  const userFriendlyContent: Record<string, string> = {}
  Object.entries(userActivity.content).forEach(([modelKey, selectedContent]) => {
    const optionTitles: (string | undefined)[] = []
    if (selectedContent == null) return

    // Find the config for this user activity content.  ASSUMPTION: we'll never
    // have an activity where two steps have the same model key.
    const stepConfig = activityConfig.steps.find((step) =>
      step.options != null && step.options.modelKey === modelKey,
    )

    // Multiple Choice
    if (Array.isArray(selectedContent)) {
      selectedContent.forEach((option: { value: string }) => {
        optionTitles.push(ActivityConfig.getTitleForSlug(activityConfig, modelKey, option.value))
      })

    // Radio, no write your own OR text entry OR date picker
    } else if (isString(selectedContent)) {
      const time = moment(selectedContent, moment.ISO_8601)
      if (time.isValid()) {
        optionTitles.push(time.calendar())
      } else {
        optionTitles.push(
          ActivityConfig.getTitleForSlug(activityConfig, modelKey, selectedContent))
      }

    // Ranking
    } else if (isInteger(selectedContent)) {
      const index = selectedContent as number
      if (get(stepConfig, 'options.mode') === 'continuous') {
        optionTitles.push(`${index}/10`)
      } else {
        optionTitles.push(ActivityConfig.getLabelAtIndex(activityConfig, modelKey, index))
      }

    // Radio + write your own
    } else if ((selectedContent as { value: string }).value != null) {
      optionTitles.push(ActivityConfig.getTitleForSlug(
        activityConfig,
        modelKey,
        (selectedContent as { value: string }).value,
      ))
    } else if (isObject(selectedContent)) {
      optionTitles.push(JSON.stringify(selectedContent))
    } else if (isBoolean(selectedContent)) {
      // NOOP
    } else {
      Log.error(String(selectedContent) + ' not of a recognizable type')
    }

    userFriendlyContent[modelKey] = optionTitles.join('\n')
  })
  return userFriendlyContent
}

const selectUserActivityConnection = selectorCache<UserActivityConnection, UserActivityConnectionProps>(
  ({ id }) => id,
  ({ id }) => createSelector([
    selectUserActivities,
    selectIsSaving(id),
    selectSaveFailed(id),
  ], (
    userActivities,
    userActivityIsSaving,
    userActivitySaveError,
  ): UserActivityConnection => {
    const userActivity = userActivities[id]
    return {
      userActivity,
      userActivityIsSaving,
      userActivitySaveError,
      saveUserActivity: (updatedUserActivity: UserActivity) => save(updatedUserActivity),
      saveUserActivityContent: contentUpdates => saveContent(userActivity, contentUpdates),
    }
  }),
)

const selectStartActivityConnection = selectorCache<StartActivityConnection, StartActivityConnectionProps>(
  ({ activitySlug, programActivityId }) => programActivityId || activitySlug,
  ({ activitySlug, userId, programActivityId }) => createSelector([
    typeSelector('latest', latestSelector)(activitySlug, userId, programActivityId),
    MODEL.isCreatingSelector(),
  ], (
    userActivity,
    userActivityIsCreating,
  ): StartActivityConnection => ({ userActivity, userActivityIsCreating })),
)

const simpleConnectionLoaded = (content: SingleUAConnection | MultiUAConnection, multi) =>
  multi
    ? (content as MultiUAConnection).userActivities != null
    : (content as SingleUAConnection).userActivity != null

const singleUAConnection = (type: string, selector: UserActivityPicker<SingleUA>):
  Connection<SingleUAConnection, UserIdConnectionProps & ActivitySlugConnectionProps> => ({
  load: (content, { activitySlug, userId, programActivityId }) => {
    if (!simpleConnectionLoaded(content, false)) typeLoader(type)(activitySlug, userId, programActivityId)
  },

  isLoaded: content => simpleConnectionLoaded(content, false),

  selector: selectorCache(
    ({ activitySlug, userId }) => `${activitySlug}|${type}|${userId}`,
    ({ activitySlug, userId }) => createSelector(
      [typeSelector(type, selector)(activitySlug, userId)],
      (userActivity): SingleUAConnection => ({
        userActivity,
        isLastCompletedStep: stepSlug =>
          userActivity != null &&
          typeof userActivity !== 'symbol' &&
          isLastCompletedStep(userActivity, stepSlug),
      }),
    ),
  ),
})

const multiUAConnection = (type: string, selector: UserActivityPicker<MultiUA>):
  Connection<MultiUAConnection, UserIdConnectionProps & ActivitySlugConnectionProps> => ({
  load: (content, { activitySlug, userId }) => {
    if (!simpleConnectionLoaded(content, true)) typeLoader(type)(activitySlug, userId)
  },

  isLoaded: content => simpleConnectionLoaded(content, true),

  selector: selectorCache(
    ({ activitySlug, userId }) => `${activitySlug}|${type}|${userId}`,
    ({ activitySlug, userId }) => createSelector(
      [
        typeSelector(type, selector)(activitySlug, userId),
        selectMultiUALoading(type, activitySlug, userId),
      ],
      (userActivities, isAllCompletedLoading): MultiUAConnection => ({
        userActivities,
        isAllCompletedLoading,
        refetchAllCompleteUAs: (activitySlug: string, programActivityId: string, userId: number) => {
          refetchMultiUAs(type, activitySlug, programActivityId, userId)
        },
      }),
    ),
  ),
})

export const UserActivity: {
  userFriendlyContentJS: typeof userFriendlyContentJS,
  userFriendlyCompletionContent: typeof userFriendlyCompletionContent,
  NOT_FOUND: symbol,
  clearLastCompletedCache: (activitySlug: string) => void,
  clearAllCompletedCache: (activitySlug: string) => void,
  connection: Connection<UserActivityConnection, UserActivityConnectionProps>,
  startActivityConnection: Connection<StartActivityConnection, StartActivityConnectionProps>,
  allForUserConnection: Connection<MultiUAConnection, UserIdConnectionProps>,
  anyLatestConnection: Connection<SingleUAConnection, UserIdConnectionProps & ActivitySlugConnectionProps>
  lastCompleteConnection: Connection<SingleUAConnection, UserIdConnectionProps & ActivitySlugConnectionProps>
  allCompleteConnection: Connection<MultiUAConnection, UserIdConnectionProps & ActivitySlugConnectionProps>
} = {
  userFriendlyContentJS,
  userFriendlyCompletionContent,

  NOT_FOUND,

  clearLastCompletedCache: activitySlug => clearPendingRead('last_complete', activitySlug),
  clearAllCompletedCache: activitySlug => clearPendingRead('all_complete', activitySlug),

  connection: {
    load: ({ userActivity }, { id }) => {
      if (userActivity == null) void MODEL.read({ id })
    },

    isLoaded: ({ userActivity }, { id }) => {
      if (id == null) {
        Log.error('No UA id supplied to UserActivity.connection')
        // prevents an infinite loop of attempting to load while instead rapidly asking the server
        // for all of our user activities
        return true
      }

      return userActivity != null
    },

    selector: selectUserActivityConnection,
  },

  startActivityConnection: {
    load: (
      { userActivity, userActivityIsCreating },
      { activitySlug, programActivityId, userId, inheritedContent },
    ) => {
      if (userActivity == null && !userActivityIsCreating) void loadLatest(activitySlug, programActivityId, userId)
      else if (userActivity === NOT_FOUND) startActivity(activitySlug, programActivityId, userId, inheritedContent)
    },

    isLoaded: ({ userActivity }) => userActivity != null && userActivity !== NOT_FOUND,

    selector: selectStartActivityConnection,
  },

  allForUserConnection: {
    load: ({ userActivities }, { userId }) => {
      if (userActivities == null) {
        void MODEL.read({
          query: getQuery({ userId }),
          modelMapKey: ['user_id', userId],
        })
      }
    },
    isLoaded: ({ userActivities }) => userActivities != null,
    selector: selectorCache(
      ({ userId }) => userId,
      ({ userId }) => createSelector(
        [allForUserSelector(userId)],
        (userActivities): MultiUAConnection => ({ userActivities }),
      ),
    ),
  },

  anyLatestConnection: singleUAConnection('any_latest', anyLatestSelector),
  lastCompleteConnection: singleUAConnection('last_complete', lastCompleteSelector),
  allCompleteConnection: multiUAConnection('all_complete', allCompleteSelector),
}
