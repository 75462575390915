import get from 'lodash/get'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel from '../ApiModel'
import { registerModelReducer, setIn } from '../reducers'
import { selectorCache } from '../util/selectorCache'
import { SUCCESS_TYPE as NEXT_ACTIVITY_SUCCESS_TYPE } from './NextActivity'
import { parseId, parseQuery } from './utilities'
import { Connection } from './types'
import { set } from 'lodash'
import { ApiKey, ApiState, isSuccessAction } from '../types'

type Step = {
  slug: string,
  template: string,
  options?: Record<string, any>,
  content?: Record<string, any>,
}

export type ActivityConfig = {
  slug: string,
  controller?: string,
  name: string,
  options: {
    startUserActivity: boolean,
    hasFeedback: boolean,
  }
  steps: Array<Step>
  reloadsNeeded?: Array<string>
}

type StepWithRoute = Step & {
  route: string,
}

type ActivityConfigConnection = {
  activityConfig?: ActivityConfig
  getReadErrorMessage?: string
}

type ActivityConfigConnectionProps = {
  activitySlug: string,
  programActivityId?: string,
  userId?: number,
}

const MODEL = new ApiModel('ACTIVITY_CONFIG', 'api/v1/activity_configs')

export const RESTRICTED_ACTIVITY_ERROR = 'ActivityConfigService::ActivityRequirementsFailed'

const { selectModelState } = registerModelReducer<Record<string, any>>(
  'activityConfigs',
  (state = { }, action) => {
    if (!isSuccessAction(action)) return state

    const { type, url, response } = action
    if (type === Action.READ.successType(MODEL)) {
      const activitySlug = parseId(MODEL, url) as string
      const { program_activity_id: programActivityId, user_id: userId } = parseQuery(url)
      return setIn(
        state,
        [activitySlug, programActivityId, userId],
        response.activity_config as ActivityConfig)
    } else if (type === NEXT_ACTIVITY_SUCCESS_TYPE) {
      // For now, it appears that activities from the NextActivity API never have custom PA IDs. This
      // will need updating if that changes.
      // TODO when NextActivity is converted to TS, this can be as NextActivity, and then the
      // type of next_activity_slug will be defined
      const activitySlug = (response.next_activity as ApiState).next_activity_slug as string
      // This is a bit of a hack, but lodash set does accept undefined as a key even though the
      // type claims it doesn't. This should likely be refactored to be a little cleaner.
      const undefinedKey = undefined as unknown as string
      return set(state, [activitySlug, undefinedKey, undefinedKey], response.activity_config)
    }

    return state
  },
)

const getQuery = (programActivityId?: string, userId?: number) => {
  const query: Record<string, ApiKey> = {}
  if (programActivityId != null) query.program_activity_id = programActivityId
  if (userId != null) query.user_id = userId
  return Object.keys(query).length === 0 ? undefined : query
}

const select = (state: ApiState, activitySlug: string, programActivityId?: string, userId?: number) =>
  get(
    selectModelState(state),
    [activitySlug, programActivityId as string, userId as number],
  ) as ActivityConfig | undefined

function load (activitySlug: string, programActivityId?: string, userId?: number) {
  const query = getQuery(programActivityId, userId)
  return MODEL.read({ id: activitySlug, query, noCache: true })
}

const getRoute = (step: Step) => step.slug == null ? '' : `/${step.slug.replace(/_/g, '-')}`

/**
 * Returns an Array of step objects with the config for each step, adding a 'route' member to
 * each one.
 */
const getSteps = (steps: Array<Step>, activityPath: string): Array<StepWithRoute> =>
  steps.map(step => ({ ...step, route: activityPath + getRoute(step) }))

/**
 * Returns the user friendly title for a selection template answer.
 */
function getTitleForSlug (config: ActivityConfig, modelKey: string, slug: string): string {
  const step = config.steps.find(step => step.options?.modelKey === modelKey)

  if (step?.content?.options != null) {
    const options = step.content.options as { slug: string, title: string }[]
    const option = options.find(option => option.slug === slug)
    if (option != null) return option.title
  }
  return slug
}

/**
 * Returns the user friendly label for a ranking template answer.
 */
function getLabelAtIndex (config: ActivityConfig, modelKey: string, index: number) {
  const step = config.steps.find(({ options }) => options?.modelKey === modelKey)
  const labels = step?.content?.labels as string[] | undefined
  return labels?.[index]
}

const selectConnection = selectorCache<ActivityConfigConnection, ActivityConfigConnectionProps>(
  ({ activitySlug, programActivityId, userId }) => `${activitySlug}|${programActivityId}|${userId}`,
  ({ activitySlug, programActivityId, userId }) => createSelector(
    [
      (state: ApiState) => {
        return select(state, activitySlug, programActivityId, userId)
      },
      (state: ApiState) => MODEL.getReadErrorMessage(state, { id: activitySlug }),
    ],
    (activityConfig, getReadErrorMessage): ActivityConfigConnection => ({ activityConfig, getReadErrorMessage }),

  ),
)

export const ActivityConfig: {
  getSteps: typeof getSteps,
  getTitleForSlug: typeof getTitleForSlug,
  getLabelAtIndex: typeof getLabelAtIndex,
  connection: Connection<ActivityConfigConnection, ActivityConfigConnectionProps>
} = {
  getSteps,
  getTitleForSlug,
  getLabelAtIndex,

  connection: {
    load: ({ activityConfig }, { activitySlug, programActivityId, userId }) => {
      if (activityConfig == null) void load(activitySlug, programActivityId, userId)
    },

    isLoaded: ({ activityConfig, getReadErrorMessage }) => activityConfig != null || getReadErrorMessage != null,

    selector: selectConnection,
  },
}
