import { createContext, useContext } from 'react'

export const AbleToInitialConsultContext = createContext()

export const useInitialConsultContext = () => {
  const context = useContext(AbleToInitialConsultContext)
  if (context == null) {
    throw new Error('useInitialConsultContext must be used inside a AbleToInitialConsultContextProvider')
  }
  return context
}
