/* global FormData */
import get from 'lodash/get'
import { parse } from 'query-string'
import { createSelector, Selector } from 'reselect'
import moment from 'moment'
import set from 'lodash/set'
import snakeCase from 'lodash/snakeCase'

import Log, { Logger } from '../log'
import { selectMeId as selectMeUserId } from './User'
import ApiModel from '../ApiModel'
import { ApiFailureAction, ApiKey, ApiState, ApiSuccessAction, ApiValue } from '../types'
import Action from '../Action'

export const getUserModelIds = (userId: ApiKey, model: string) => (state: ApiState): ApiKey[] => {
  if (userId === 'me' || userId == null) userId = selectMeUserId(state)
  return get(state.modelMap, ['user_id', userId, model]) as ApiKey[]
}

export const getUserModelId = (userId: ApiKey, model: string) => (state: ApiState) =>
  (getUserModelIds(userId, model)(state) || [])[0]

const sortNumbersAscending = (a, b) => a > b ? 1 : (b > a ? -1 : 0)

export const getSortedUserModelIds = (userId: ApiKey, model: string) => createSelector(
  [getUserModelIds(userId, model)],
  modelIds => modelIds && [...modelIds].sort(sortNumbersAscending),
)

export const selectModelStore = (model: string) => (state: ApiState) =>
  (state.api as ApiState)[model] as ApiState[]

let USER_MODEL_SELECTORS = {}
export const userModelSelector = (
  userId: ApiKey,
  model: string,
  { singleResult = false } = { },
) => {
  const selectorKey = [userId, model, String(singleResult)]
  let selector = get(USER_MODEL_SELECTORS, selectorKey) as Selector<ApiState, ApiValue | ApiValue[]>

  if (selector == null) {
    selector = createSelector(
      [getSortedUserModelIds(userId, model), selectModelStore(model)],
      (ids, models): ApiValue | ApiValue[] | null => {
        if (ids == null || models == null) return undefined
        return singleResult ? models[ids[0]] as ApiState : ids.map(id => models[id] as ApiState)
      },
    )

    USER_MODEL_SELECTORS = set(USER_MODEL_SELECTORS, selectorKey, selector)
  }

  return selector
}

export const parseQuery = (url: string) => parse(url.split(/\?/)[1]) as Record<string, string>

export const parseId = (model: ApiModel, url: string) => url.length === model.baseUrl.length
  ? null
  : url.split(/\?/)[0].substring(model.baseUrl.length + 1)

export const extractFormData = (item: ApiState, name: string) =>
  Object.keys(item).reduce((formData, key) => {
    if (item[key] != null) formData.append(name ? `${name}[${key}]` : key, item[key] as string)
    return formData
  }, new FormData())

export const invertPredicate = <T extends any[]>(predicate: (...args: T) => boolean) =>
  (...args: T) => !predicate(...args)

export const countRead = (numRead: number, { read }) => numRead + (read === false ? 1 : 0)

export const convertDateToUTC = (date: string) => moment(date).utcOffset(0, true).format()

/**
 * A FP utility for tracing values passing through a given pure function. Especially useful for
 * debugging selectors. The example below will trace the inputs and outputs to both selector
 * dependencies.
 *
 * createSelector(
 *   [trace('user', selectUser), trace('profile', selectProfile)],
 *   (user, profile) => ({ user, profile })
 * )
 *
 * @param label A label used in the console log.
 * @param target The function to wrap.
 * @param logLevel If provided, sets the log level to log at. Default is 'info'
 * @returns {function(...[*]=): *} A wrapped version of the target function that logs inputs and
 *   outputs.
 */
export const trace = (
  label: string,
  target: (...unknown) => unknown,
  { logLevel }: { logLevel: keyof Logger } = { logLevel: 'info' },
) => (...args: unknown[]) => {
  const result = target(...args)
  if (process.env.SENTRY_DEBUG === 'true') Log[logLevel](`trace [${label}]`, args, result)
  return result
}

export const parseResponse = (response: ApiSuccessAction | ApiFailureAction, MODEL: ApiModel, key: string) => {
  const isSuccess = response?.type === Action.CREATE.successType(MODEL)

  if (isSuccess) {
    return { isSuccess, [key]: (response as ApiSuccessAction).response[snakeCase(key)] }
  }

  return { isSuccess, error: (response as ApiFailureAction)?.error?.message }
}
