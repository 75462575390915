import { Connection, OptionalProps } from 'joyable-js-api'
import Log from '@app/js/services/log'
import { useCallback, useDebugValue, useEffect, useRef, useState } from 'react'
import ApiModel from 'joyable-js-api/src/ApiModel'

type Connected<SelectedType> = readonly [boolean, SelectedType | Record<any, never>]
type MultipleConnected = readonly [boolean, unknown[]]

/**
 * A utility to provide props in a guaranteed order so that they can be provided as a list of
 * dependencies to hooks.
 */
const propsInOrder = (props?: OptionalProps) => props == null
  ? []
  : Object
    .entries(props)
    .sort(([key1], [key2]) => key1 < key2 ? -1 : 1)
    .map(([, value]) => value)

/**
 * A hook for utilizing a connection
 *
 * For future component construction, we should be favoring hooks, so the modelConnect HOC is
 * now deprecated.
 *
 * @returns [isLoaded, selectedShape]. If isLoaded is false, selectedShape is always an empty
 * object, simply returned to make destructuring code cleaner.
 */
export function useConnection <SelectedType, PropsType extends OptionalProps = undefined> (
  connection: Connection<SelectedType, PropsType>,
  props: PropsType | Record<any, never> = {},
  traceLabel: string | null = null,
): Connected<SelectedType> {
  const log = (...args: unknown[]) => {
    if (traceLabel != null) Log.info(`[useConnection ${traceLabel}] ${args[0]}`, ...args.slice(1))
  }

  const { selector, onMount, isLoaded, load } = connection

  const getState = useCallback(() => {
    const selected = selector(ApiModel.reduxState, props)
    const loadingDone = isLoaded == null || isLoaded(selected, props)
    return { loadingDone, selected }
  }, [isLoaded, props, selector])

  const calculateInitialState = useCallback(() => {
    // we have to let the onMount process first, if it's specified
    if (onMount != null) return null

    const { loadingDone, selected } = getState()
    return loadingDone ? selected : null
  }, [getState, onMount])

  const [selected, setSelected] = useState(calculateInitialState)

  // if the connection or props changes, it counts as a fresh "mount". We need to return an unloaded
  // state immediately in this case (rather than waiting for the useEffect() to process and update
  // our saved state).
  const hookDeps = [connection, ...propsInOrder(props)]
  // This is very close to the implementation of usePrevious, but we can't import lib/hooks here,
  // or it sets up a circular import reference problem in webhook.
  const previousDepsRef = useRef<any[]>()
  const previousDeps = previousDepsRef.current
  const depsChanged = (() => {
    if (previousDeps == null) return false
    if (previousDeps.length !== hookDeps.length) return true
    return hookDeps.some((dep, index) => dep !== previousDeps[index])
  })()

  useEffect(
    () => {
      log('Mounting')
      previousDepsRef.current = hookDeps
      if (onMount != null) onMount(props)

      function checkState () {
        const { loadingDone, selected: currentSelected } = getState()
        log('checkState', loadingDone, currentSelected)
        if (load != null) load(currentSelected, props)
        if (loadingDone) {
          setSelected(currentSelected)
        } else {
          // In case something was loaded and then got unloaded via a redux store clear
          if (selected != null) setSelected(null)
        }
      }

      // order is important here. checkState() may call load() on the connection, and in some rare
      // cases, that may synchronously update the store such that the connection is now loaded. In
      // that case, we must already be subscribed so that we catch the state change, or the
      // component using this hook will have to wait for another redux action to dispatch before
      // it learns about the state change.
      log('subscribing')
      const subscription = ApiModel.store.subscribe(checkState)
      checkState()
      return subscription
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    hookDeps,
  )

  // TODO (NJC): When dev tools 4.0 lands, remove the callback, or possibly this useDebugValue
  // entirely.
  useDebugValue(selected, () => {
    Log.info('Inspected connection', selected)
    return selected == null ? 'loading' : 'loaded'
  })

  const state = depsChanged ? calculateInitialState() : selected
  log('returning value for render', state)
  return [state != null, state ?? {}]
}

export function useConnections<S1, P1 extends OptionalProps, S2, P2 extends OptionalProps>(
  connections: [Connection<S1, P1>, Connection<S2, P2>],
  props?: P1 & P2 | Record<any, never>,
): readonly [boolean, [S1, S2]]
export function useConnections<
  S1, P1 extends OptionalProps,
  S2, P2 extends OptionalProps,
  S3, P3 extends OptionalProps
>(
  connections: [Connection<S1, P1>, Connection<S2, P2>, Connection<S3, P3>],
  props?: (P1 & P2 & P3) | Record<any, never>,
): readonly [boolean, [S1, S2, S3]]
export function useConnections<
  S1, P1 extends OptionalProps,
  S2, P2 extends OptionalProps,
  S3, P3 extends OptionalProps,
  S4, P4 extends OptionalProps
>(
  connections: [Connection<S1, P1>, Connection<S2, P2>, Connection<S3, P3>, Connection<S4, P4>],
  props?: (P1 & P2 & P3 & P4) | Record<any, never>,
): readonly [boolean, [S1, S2, S3, S4]]
export function useConnections<
  S1, P1 extends OptionalProps,
  S2, P2 extends OptionalProps,
  S3, P3 extends OptionalProps,
  S4, P4 extends OptionalProps,
  S5, P5 extends OptionalProps
>(
  connections: [
    Connection<S1, P1>,
    Connection<S2, P2>,
    Connection<S3, P3>,
    Connection<S4, P4>,
    Connection<S5, P5>
  ],
  props?: (P1 & P2 & P3 & P4 & P5) | Record<any, never>,
): readonly [boolean, [S1, S2, S3, S4, S5]]

/**
 * A shortcut for when a component needs multiple connections and wants to combine all the loaded
 * flags into a single flag to simplify the loading logic of that component. To make this method
 * simple, a single props object is accepted that applies to all the connections and the
 * traceLabel feature is not supported
 */
export function useConnections (
  connections: Connection<unknown, any>[],
  props :Record<string, unknown> = {},
): MultipleConnected {
  // snapshot the connections we're given on mount so that we don't accidentally invalidate our React
  // hook state
  const connectionsOnMount = useRef(connections)

  // This check could potentially be isolated to dev environments, but this would be really bad
  // in production, and should be fast so let's leave it in.
  const connectionsChanged = connections.reduce((changed, connection, idx) => {
    return changed || connection !== connectionsOnMount.current[idx]
  }, false)
  if (connectionsChanged) {
    Log.error(
      'Connections passed to useConnections has changed - ignoring to avoid invalid hook state!',
      { onMount: connectionsOnMount, current: connections },
    )
  }

  return connectionsOnMount.current.reduce(
    ([loaded, selectedStates], connection) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [connectionLoaded, selected] = useConnection(connection, props)
      return [loaded && connectionLoaded, [...selectedStates, selected]]
    },
    [true, []] as MultipleConnected,
  )
}
