import queryString from 'query-string'
import { Enum } from '@app/js/lib/enum'
import ActionCable from 'actioncable'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import moment from 'moment-timezone'
import Cookies from 'js-cookie'
import Log from './log'
import { onSocketReconnect as messagesSocketReconnect } from './models/Message'
import { selectMe as selectMeUser } from './models/User'
import { onSocketReconnect as worklistSocketReconnect } from './models/Worklist'
import {
  onSocketReconnect as articlesSocketReconnect,
  socketDataReceiver as articlesDataReceiver,
} from './models/Article/socketMiddleware'
import { getNormalizedModels } from './normalize'
import { SocketActions } from './socketActions'
import { getJwt, isAuth0Enabled, getAuth0Tokens } from './ApiFetch'

let _cable = null
let _typingSubscription = null
let _chatEventSubscription = null

export const SOCKET_ACTION = Symbol('Socket Action')

const SHORT_DISCONNECT_THRESHOLD = 3000 // in ms

const findNormalizedValueUpdates = (state, data, modelMapKey = ['user_id']) => {
  const { normalized, modelMap } = getNormalizedModels(data, { modelMapKey })
  // Do a deep comparison with the store before dispatching. We seem to get a fairly frequent
  // set of updates with the same data in some circumstances, and it causes a bunch of spurious
  // store/prop updates in component land. Ignore modelMap because if the normalized values
  // themselves are the same, we should already have mapped any models that would be mapped by
  // this update.
  const values = normalized.filter(({ key, value }) =>
    typeof value === 'symbol' || !isEqual(get(state.api, key, null), value))
  return { values, modelMap }
}

const defaultDataReceiver = (store, channel) => data => {
  const { values, modelMap } = findNormalizedValueUpdates(store.getState(), data)
  if (values.length > 0) {
    store.dispatch({
      type: SocketActions.CHANNEL_RECEIVED,
      successData: { values, modelMap },
      socketData: data,
      socketChannel: channel,
    })
  }
}

const userDataReceiver = (store, channel) => data => {
  const state = store.getState()
  const { values: initialValues, modelMap } = findNormalizedValueUpdates(state, data)
  if (initialValues.length === 0) return

  const { values, pruneKeys } = initialValues.reduce(({ values, pruneKeys }, valueUpdate) => {
    const { key, value } = valueUpdate
    if (key[0] === 'users' && Object.keys(value).length === 1 && value.id != null) {
      // When we receive a user object that has nothing but an id, it means that user is no
      // longer coached by us as a result of a program switch or graduation
      return { values, pruneKeys: [...pruneKeys, ['users', value.id], ['user_summaries', value.id]] }
    } else {
      return { pruneKeys, values: [...values, valueUpdate] }
    }
  }, { values: [], pruneKeys: [] })

  if (values.length > 0 || pruneKeys.length > 0) {
    store.dispatch({
      type: SocketActions.CHANNEL_RECEIVED,
      successData: { values, modelMap },
      pruneKeys: pruneKeys,
      socketData: data,
      socketChannel: channel,
    })
  }
}

const practitionersDataReceiver = (store, channel) => data => {
  const { values, modelMap } = findNormalizedValueUpdates(store.getState(), data)
  const { api: { practitioners } } = store.getState()
  const practitionerForRemove = Object.values(practitioners).find(p => p.type === data.practitioner.type)
  const pruneKeys = practitionerForRemove ? [['practitioners', practitionerForRemove.id]] : []

  if (values.length > 0) {
    store.dispatch({
      type: SocketActions.CHANNEL_RECEIVED,
      successData: { values, modelMap },
      pruneKeys,
      socketData: data,
      socketChannel: channel,
    })
  }
}

const coachNoteDataReceiver = (store, channel) => data => {
  const { values, modelMap } = findNormalizedValueUpdates(store.getState(), data)
  const { models: { coachNotes } } = store.getState()
  const userId = data.coach_notes.find(note => note.user_id != null).user_id
  const incomingNoteIds = data.coach_notes ? data.coach_notes.map(note => note.id) : []
  const shouldPrune = (coachNotes[userId]?.notes ?? []).some(note => incomingNoteIds.includes(note.id))
  // We are using the pruneKeys to override the existing state data
  // from the coach note model reducer.
  const pruneKeys = shouldPrune ? [[userId]] : []

  if (values.length > 0) {
    store.dispatch({
      type: SocketActions.CHANNEL_RECEIVED,
      successData: { values, modelMap },
      pruneKeys,
      socketData: data,
      socketChannel: channel,
    })
  }
}

const authorizedHashDataReceiver = (store, channel) => data => {
  // eslint-disable-next-line camelcase
  const { user_id, ...authorizedHashData } = data

  const { values } = findNormalizedValueUpdates(
    store.getState(),
    authorizedHashData,
    null,
  )

  if (values.length > 0) {
    store.dispatch({
      type: SocketActions.CHANNEL_RECEIVED,
      successData: { values },
      socketData: data,
      socketChannel: channel,
    })
  }
}

// Channels to subscribe to, by role. Admins/moderators don't get any channels because they don't
// have their own clients and sometimes don't have a coach either, so they have no need for
// channels.

const CHANNELS = {
  coach: [
    { channel: 'WorklistsChannel', onReconnect: worklistSocketReconnect },

    // reconnect cache clearing handled by worklists for call statuses, profiles and users
    { channel: 'CallStatusesChannel' },
    { channel: 'CoachNotesChannel', dataReceiver: coachNoteDataReceiver },
    { channel: 'ProfilesChannel' },
    { channel: 'ConciergeStatusesChannel' },
    { channel: 'UsersChannel', dataReceiver: userDataReceiver },

    { channel: 'MessagesChannel', onReconnect: messagesSocketReconnect },

    // TODO (NJC): Implement when fears have been merged.
    { channel: 'FearsChannel' },
  ],

  // TODO (NJC): Implement and test robust socket reconnect behavior for clients. Not urgent at the
  // moment, and the intended behavior may need to be different than for coaches.
  client: [
    { channel: 'ChatChannel' },
    { channel: 'ProfilesChannel' },
    { channel: 'CallStatusesChannel' },
    { channel: 'NotificationSettingsChannel' },
    { channel: 'TreatmentsChannel' },
    { channel: 'PractitionersChannel', dataReceiver: practitionersDataReceiver },
    { channel: 'IndicatorSetChannel', dataReceiver: authorizedHashDataReceiver },
    {
      channel: 'ArticlesChannel',
      dataReceiver: articlesDataReceiver,
      onReconnect: articlesSocketReconnect,
    },
  ],
}

export class SocketActionTypes extends Enum {
  static CONNECT = new SocketActionTypes(store => {
    // the socket is good at reconnecting on its own
    if (_cable != null) return

    const { id, is_guest: isGuest, role, is_provider_account: isProviderAccount } =
      selectMeUser(store.getState()) ?? {}
    if (role == null || isGuest) {
      Log.error('Asked to connect when not logged in', id, isGuest, role)
      return
    }

    // Prevent sockets from connecting when we're in an iFrame on E2. This is in case a coach
    // opens an iframe on E2, and they also happen to do C+ coaching - we don't want them to receive
    // model updates for users on the C+ coach tool while in the E2 iframe.
    const leadId = queryString.parse(window.location.search).lead_id
    const providerChannels = leadId == null ? (CHANNELS[role] ?? CHANNELS.coach) : null
    const channels = isProviderAccount ? providerChannels : CHANNELS[role]
    if (channels == null || channels.length === 0) return

    const state = store.getState()

    let accessToken = null
    let jwt = null
    if (isAuth0Enabled()) {
      accessToken = getAuth0Tokens().accessToken
      if (accessToken == null) {
        Log.error('Cannot connect, no valid auth credentials', accessToken)
        return
      }
    } else {
      jwt = getJwt()
      if (jwt == null) {
        Log.error('Cannot connect, no valid auth credentials', jwt)
        return
      }
    }

    const connectionState = get(state, ['socket', 'connectionState'])
    if (connectionState === 'connecting') {
      // Error because setting _cable should prevent this
      Log.error('Asked to connect while still connecting')
      return
    }

    if (connectionState === 'connected') {
      // Error because setting _cable should prevent this
      Log.error('Asked to connect when we already have an open connection')
      return
    }

    let cableUrl = null
    store.dispatch({ type: SocketActions.SOCKET_CONNECTING })
    if (isAuth0Enabled()) {
      Cookies.set('auth0_access_token', accessToken, {
        domain: process.env.WEBSOCKET_ROOT ? '.ableto.com' : '',
        secure: true,
        expires: new Date(new Date().getTime() + 3 * 1000),
      })
      cableUrl = `${process.env.WEBSOCKET_ROOT}/cable`
    } else {
      cableUrl = `${process.env.WEBSOCKET_ROOT}/cable?token=${jwt}`
    }
    _cable = ActionCable.createConsumer(cableUrl)

    channels.forEach(({ channel, dataReceiver = defaultDataReceiver }, index) => {
      const options = { received: dataReceiver(store, channel) }

      if (index === 0) {
        // All channels share the same connected/disconnected state, so let's just
        // track that on the first channel subscription we've got.
        options.connected = () => {
          const { disconnectedAt } = store.getState().socket
          if (disconnectedAt != null) {
            const disconnectedFor = moment().diff(disconnectedAt)
            channels.forEach(({ onReconnect }) => {
              if (onReconnect != null) onReconnect(disconnectedFor > SHORT_DISCONNECT_THRESHOLD, store)
            })
          }

          store.dispatch({ type: SocketActions.SOCKET_CONNECTED })
        }

        options.disconnected = () => store.dispatch({ type: SocketActions.SOCKET_DISCONNECT })
      }

      _cable.subscriptions.create({ channel }, options)
    })

    // everybody gets a typing channel
    _typingSubscription = _cable.subscriptions.create({ channel: 'TypingChannel' }, {
      received: data => {
        // we don't know our coach's user id, so ignore it and use a special key.
        const userId = role === 'client' ? 'coach' : data.user_id
        store.dispatch({ type: SocketActions.TYPING, user_id: userId })
      },
    })

    _chatEventSubscription = _cable.subscriptions.create({ channel: 'ChatEventChannel' })
  })

  static DISCONNECT = new SocketActionTypes(() => {
    if (_cable == null) return

    _cable.disconnect()
    _cable = null
  })

  static TYPING = new SocketActionTypes((store, { user_id: userId }) => {
    if (_typingSubscription != null) _typingSubscription.perform('typing', { user_id: userId })
  })

  static CHAT_EVENT = new SocketActionTypes((store, { action, payload }) => {
    if (_chatEventSubscription != null) _chatEventSubscription.perform(action, payload)
  })

  static _ = this.closeEnum()

  processAction

  constructor (processAction) {
    super()
    this.processAction = processAction
  }
}

export const socketMiddleware = store => next => action => {
  const socketAction = action[SOCKET_ACTION]
  if (typeof socketAction === 'undefined') return next(action)

  socketAction.type.processAction(store, socketAction)
}
