import { isEmpty } from 'lodash'
import Log from '@app/js/services/log'

const URL = process.env.PLATFORM_ANALYTICS_URL
const ENABLED = URL != null
const BATCH_COLLECTION_TIMEOUT = 5000
const MAXIMUM_BATCH_SIZE = 20
const APP_VERSION = isEmpty(process.env.RELEASE) ? 'local-development' : process.env.RELEASE
const API_KEY = process.env.PLATFORM_ANALYTICS_KEY

let superProperties = {}
const setSuperProperties = props => {
  superProperties = { ...superProperties, ...props }
}

function init () {
  if (!ENABLED) return

  window.addEventListener('beforeunload', sendBatch)
}

let batchTimeout = null
let batch = []
async function sendBatch () {
  const events = [...batch]
  batch = []
  batchTimeout = null

  if (!ENABLED || events.length === 0) return

  let url = URL
  const props = {
    method: 'POST',
    // The keepalive prop allows the fetch to outlive the page.
    // https://developer.mozilla.org/en-US/docs/Web/API/fetch#parameters
    keepalive: true,
    headers: {
      'Content-Type': 'application/json',
      'AbleTo-App-Version': APP_VERSION,
      'AbleTo-App-Platform': 'browser',
      'AbleTo-API-Key': API_KEY,
    },
  }
  if (events.length === 1) {
    props.body = JSON.stringify(events[0])
  } else {
    props.body = JSON.stringify(events)
    url = `${url}/batch`
  }

  try {
    const response = await window.fetch(url, props)

    if (!response.ok) {
      const { status } = response
      const text = await response.text()
      Log.error('Received non-OK response from analytics endpoint', { status, text, events: JSON.stringify(events) })
    }
  } catch (error) {
    Log.error('Error recording analytics event', error)
  }
}

function track (event, properties) {
  if (!ENABLED) return

  batch.push({
    event,
    properties: {
      ...superProperties,
      ...properties,
      eventTime: new Date().toISOString(),
    },
  })

  if (batch.length >= MAXIMUM_BATCH_SIZE) {
    clearTimeout(batchTimeout)
    batchTimeout = null
    sendBatch()
  } else if (batchTimeout == null) {
    batchTimeout = setTimeout(sendBatch, BATCH_COLLECTION_TIMEOUT)
  }
}

export const PlatformAnalytics = {
  init,
  setSuperProperties,
  track,
}
