import { createSelector } from 'reselect'

const displayName = ({ full_name: fullName, license }, providerType) => providerType === 'therapist'
  ? `${fullName}, ${license}`
  : fullName

const withDisplayName = (practitioner, providerType) => practitioner == null
  ? null
  : ({
      ...practitioner,
      displayName: displayName(practitioner, providerType),
    })

const selectTherapist = practitioners =>
  withDisplayName(practitioners.find(p => p.type === 'therapist'), 'therapist')
const selectBehavioralCoach = practitioners =>
  withDisplayName(practitioners.find(p => p.type === 'behavioral_coach'), 'behavioral_coach')

const select = ({ api: { practitioners, previous_therapist: previousTherapist } }) => ({
  therapist: practitioners && selectTherapist(Object.values(practitioners)),
  behavioralCoach: practitioners && selectBehavioralCoach(Object.values(practitioners)),
  previousTherapist: previousTherapist && withDisplayName(previousTherapist, 'therapist'),
})

export const Practitioners = {
  connection: {
    selector: createSelector([select], practitioners => ({ practitioners })),
  },
}
