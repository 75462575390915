import PropTypes from 'prop-types'
import Log from '../log'
import { messagesWithUserSelector, sortMessagesOldestFirst } from './Message'
import Action from '../Action'
import ApiModel from '../ApiModel'
import { createSelector } from 'reselect'

const MODEL = new ApiModel('BRANCH', 'api/v1/branches',
  (json, response, fetch) => !(fetch.action === Action.READ && response.status === 404))

const NOT_FOUND = Symbol('Branch Not Found')

const SHAPE = PropTypes.oneOfType([
  PropTypes.shape({
    slug: PropTypes.string.isRequired,
    // other props are slug-specific.
  }),

  // symbol for NOT_FOUND
  PropTypes.symbol,
])

const selector = slug => state => {
  if (MODEL.isNotFound(state, { id: slug })) return NOT_FOUND
  else return state.api.branches && state.api.branches[slug]
}

const selectChatOnboardingComplete = createSelector(
  [
    messagesWithUserSelector('me', sortMessagesOldestFirst),
    selector('redirect'),
  ],
  (messages, redirectBranch) => {
    if (redirectBranch === NOT_FOUND) {
      Log.error('Redirect branch not found!')
      // Prevent this code path from generating a never ending loop of requests to the branches
      // API.
      return false
    }
    if (redirectBranch == null) return null
    for (const { destination } of redirectBranch.requirements) {
      if (destination === 'chat') {
        // Check to see if a message exited us from the chat flow, so we don't have
        // to refetch the branching service to see if this state has ended.
        // TODO: Could send branch changes via websocket, but since the branch is a bunch
        // of derived state it's a bit complicated to setup at this point.
        if (messages == null) return false
        for (const { button_action: action } of messages) {
          if (action === 'startFirstActivity') return true
        }
        return false
      }
    }
    return true
  })

function reload (slug) {
  ApiModel.pruneCache('branches', slug)
  clearFailed(slug)
  return MODEL.read({ id: slug })
}

const clearFailed = slug =>
  ApiModel.dispatch(Action.READ.getClearPendingAction(MODEL, MODEL.getUrl({ id: slug })))

export const Branch = {
  loadRedirect: refresh => refresh ? reload('redirect') : MODEL.read({ id: 'redirect' }),

  redirectBranchConnection: {
    load: ({ redirectBranch }) => {
      if (redirectBranch == null) MODEL.read({ id: 'redirect' })
    },

    isLoaded: ({ redirectBranch }) => redirectBranch != null,

    selector: createSelector([selector('redirect')], redirectBranch => ({
      redirectBranch,
      clearRedirectContext: () => ApiModel.trimCache([['branches', 'redirect', 'requirements', '*', 'context']]),
    })),

    shape: {
      redirectBranch: SHAPE,
      clearRedirectContext: PropTypes.func,
    },
  },

  chatOnboardingConnection: {
    load: ({ chatOnboardingComplete }) => {
      if (chatOnboardingComplete == null) MODEL.read({ id: 'redirect' })
    },

    isLoaded: ({ chatOnboardingComplete }) => chatOnboardingComplete != null,

    selector: createSelector(
      [selectChatOnboardingComplete],
      chatOnboardingComplete => ({ chatOnboardingComplete }),
    ),

    shape: {
      chatOnboardingComplete: PropTypes.bool.isRequired,
    },
  },
}
