import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'
import Action from '../Action'

const MODEL = new ApiModel('HEALTH_HABIT_SNAPSHOTS', 'api/v1/health_habit_snapshots',
  (json, response, fetch) => fetch.action !== Action.CREATE || response.status !== 201)

const recordSnapshot = (snapshot) => {
  return MODEL.create({ snapshot })
}

const selector = createSelector(() => ({ recordSnapshot }))

export const HealthHabitSnapshots = {
  connection: {
    selector,

    shape: {
      recordSnapshot: PropTypes.func.isRequired,
    },
  },
}
