/* eslint-disable camelcase */
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'
import Action from '../Action'
import { Connection } from './types'
import { ApiKey, ApiState } from '../types'
import { selectorCache } from '../util/selectorCache'

const MODEL = new ApiModel('BENEFITS_PROVIDERS', 'api/v1/benefits_providers')
const ADMIN_MODEL = new ApiModel('ADMIN_BENEFITS_PROVIDERS', 'admin/api/v1/benefits_providers')

export type BenefitsProviderType = {
  id: number
  payer: string | null
  sponsor_slug: string | null
  sponsorDisplayName: string | null
  payerDisplayName: string
  url: string | null
  external: boolean
  campaign: string | null
  requireMemberId: boolean
  requireAccessCode: boolean
}

export type BenefitsProviderListType = {
  label: string
  value: BenefitsProviderType
}

type AdminBenefitsProvider = {
  id: number,
  payer: string | null
  sponsorDisplayName: string | null
  payerDisplayName: string
  campaign: string | null
  groups: Array<string>
  requireMemberId: boolean
  requireAccessCode: boolean
  url: string | null
  external: boolean
}

type BenefitsProviderConnection = {
  benefitsProviders: Array<BenefitsProviderListType> | Array<AdminBenefitsProvider>
  benefitsProviderError?: string
}

type BenefitsProvidersConnectionParams = {
  group: string
}

type CreateData = {
  payer_id: number,
  sponsor_id: number,
  display_name: string,
  campaign: string,
  member_id_required: string,
  access_code_required: string,
  groups: Array<string>,
  url: string,
  external_url: string,
  sort: string,
}

const selectConnection = selectorCache(
  ({ group }) => group as ApiKey, ({ group }: BenefitsProvidersConnectionParams) =>
    createSelector(
      [
        (state: ApiState) => (state.api as ApiState).benefits_providers as Array<BenefitsProviderListType>,
        (state: ApiState) => MODEL.getReadErrorMessage(state, { query: { group } }),
      ],
      (benefitsProviders, benefitsProviderError) => ({ benefitsProviders, benefitsProviderError }),
    ),
)

const adminConnection = createSelector(
  [
    (state: ApiState) => {
      const benefits_providers = (state.api as ApiState).benefits_providers as { [key: string]: AdminBenefitsProvider }
      return (benefits_providers && Object.values(benefits_providers)) || null
    },
    (state: ApiState) => ADMIN_MODEL.getReadErrorMessage(state),
  ],
  (benefitsProviders, benefitsProviderError) => ({
    benefitsProviders,
    benefitsProviderError,
    refetchBenefitsProviders: async () => {
      ApiModel.dispatch(Action.READ.getClearPendingAction(ADMIN_MODEL, ADMIN_MODEL.getUrl()))
      await ADMIN_MODEL.read()
    },
    createBenefitsProvider: (data: CreateData) => {
      ApiModel.dispatch(Action.CREATE.getClearPendingAction(ADMIN_MODEL, ADMIN_MODEL.getUrl()))
      return ADMIN_MODEL.create(data)
    },
    updateBenefitsProvider: (id: number, data: CreateData) => {
      ApiModel.dispatch(Action.UPDATE_MODIFY.getClearPendingAction(ADMIN_MODEL, ADMIN_MODEL.getUrl({ id })))
      return ADMIN_MODEL.updateModify({ ...data }, { id })
    },
    removeBenefitsProvider: async (id: number) => {
      ApiModel.dispatch(Action.DESTROY.getClearPendingAction(ADMIN_MODEL, ADMIN_MODEL.getUrl({ id })))
      await ADMIN_MODEL.destroy({ id })
    },
  }),
)

export const BenefitsProvider: {
  connection: Connection<BenefitsProviderConnection, BenefitsProvidersConnectionParams>,
  adminConnection: Connection<BenefitsProviderConnection, BenefitsProvidersConnectionParams>,
} = {
  connection: {
    load: ({ benefitsProviders }, { group }) => {
      if (!benefitsProviders) void MODEL.read({ query: { group } })
    },

    isLoaded: ({ benefitsProviders, benefitsProviderError }) => {
      return !!benefitsProviders || !!benefitsProviderError
    },

    selector: selectConnection,
  },

  adminConnection: {
    load: ({ benefitsProviders }) => {
      if (!benefitsProviders) void ADMIN_MODEL.read()
    },

    isLoaded: ({ benefitsProviders, benefitsProviderError }) => {
      return !!benefitsProviders || !!benefitsProviderError
    },

    selector: adminConnection,
  },
}
