import { IS_DEBUG, IS_PROD, IS_TEST } from '../constants'

const getConfigureFunction = () => {
  if (IS_TEST) return import('./configureStore.test')
  if (IS_PROD && !IS_DEBUG) return import('./configureStore.prod')
  return import('./configureStore.dev')
}

const configureStore = async () => (await getConfigureFunction()).default()

export default configureStore
