/* eslint-disable camelcase */
import { createSelector } from 'reselect'
import get from 'lodash/get'
import Action from '../../Action'
import ApiModel from '../../ApiModel'
import { registerModelReducer } from '../../reducers'
import { selectorCache } from '../../util/selectorCache'
import { parseQuery, parseResponse } from '../utilities'
import { ApiFailureAction, ApiState, ApiSuccessAction } from '../../types'
import { Connection } from '../types'
import { CoachNotesConnection, CoachNotesConnectionProps, ModelState, Note } from './types'
import { SocketActions } from '../../socketActions'

const ADDITIONAL_ENDPOINTS = { submit: 'submit' }

const MODEL = new ApiModel('COACH_NOTE', 'admin/api/v1/coach_notes')

const DEFAULT_STATE: ModelState = {}

registerModelReducer<ModelState>('coachNotes', (state = DEFAULT_STATE, action) => {
  const { response, type, pruneKeys, socketData = {}, url = '' } = action as ApiSuccessAction

  if (type === Action.READ.successType(MODEL)) {
    const { user_id: userId } = parseQuery(url)

    return {
      ...state,
      [userId]: {
        notes: response.coach_notes,
        isLoaded: true,
      },
    }
  } else if (type === Action.CREATE.successType(MODEL)) {
    const { user_id: userId } = parseQuery(url)

    const notes = get(state, [userId, 'notes']) as Note[]
    const updatedNotes = notes.map((note) =>
      note.id === (response.coach_note as Note).id
        ? response.coach_note
        : note,
    ) as Note[]

    return {
      ...state,
      [userId]: {
        notes: updatedNotes,
        isLoaded: true,
      },
    }
  } else if (type === Action.READ.failureType(MODEL) && response.status === 404) {
    const { user_id: userId } = parseQuery(url)

    return {
      ...state,
      [userId]: {
        isLoaded: true,
      },
    }
  } else if (type === SocketActions.CHANNEL_RECEIVED && pruneKeys != null && pruneKeys.length > 0) {
    const userId = pruneKeys[0][0]

    return {
      ...state,
      [userId]: {
        notes: socketData?.coach_notes,
        isLoaded: true,
      },
    }
  }

  return state
})

const submitNote = async (id: string, userId: number, body: string) => {
  const apiAction = await MODEL.create(
    { coach_note: { id, body } },
    { id: ADDITIONAL_ENDPOINTS.submit, query: { user_id: userId } },
  ) as ApiSuccessAction | ApiFailureAction

  return parseResponse(apiAction, MODEL, 'coachNote')
}

const selectSubmittedNotes = (userId: number) => (state: ApiState) => {
  const coachNotesForCurrentUser = get(state.models, ['coachNotes', userId, 'notes']) as Note[] | undefined

  if (coachNotesForCurrentUser != null) {
    const submittedNotes = coachNotesForCurrentUser
      .reduce((acc: Note[], note) => {
        if (note.status === 'submitted' && note.user_id === userId) {
          acc.push(note)
        }
        return acc
      }, [])
      .sort((noteA, noteB) => new Date(noteA.submitted_at) > new Date(noteB.submitted_at) ? 1 : -1)

    return submittedNotes
  }
}

const selectLatestPendingCoachNote = (userId: number) => (state: ApiState) => {
  const coachNotesForCurrentUser = get(state.models, ['coachNotes', userId, 'notes']) as Note[] | undefined

  if (coachNotesForCurrentUser != null) {
    const pendingNote = coachNotesForCurrentUser.find((note) => note.status === 'pending')

    return pendingNote
  }
}

const fetchAllCoachNotes = async (userId: number) =>
  await MODEL.read({ query: { user_id: userId }, modelMapKey: ['user_id', userId] })

const updateCoachNote = async (body: string, userId: number, noteId?: number) => {
  const query = {
    user_id: userId,
    note_id: noteId,
  }

  const apiAction = await MODEL.create(
    { coach_note: { body } },
    { query, modelMapKey: ['user_id', userId] },
  ) as ApiSuccessAction | ApiFailureAction

  return parseResponse(apiAction, MODEL, 'coachNote')
}

const selectIsSaving = (state: ApiState) => (userId: number, noteId?: number) => {
  const pending = Action.CREATE.pendingSource(state)

  const query = {
    user_id: userId,
    note_id: noteId,
  }

  return Action.isActing(pending[MODEL.getUrl({ query })])
}

const selectSaveError = (state: ApiState) => (userId: number, noteId?: number) => {
  const pending = Action.CREATE.pendingSource(state)

  const query = {
    user_id: userId,
    note_id: noteId,
  }

  return Action.getFailed(pending[MODEL.getUrl({ query })])
}

const selectConnection = selectorCache(
  ({ userId }: { userId: number }) => userId,
  ({ userId }: { userId: number }) => {
    return createSelector(
      [selectLatestPendingCoachNote(userId), selectSubmittedNotes(userId), selectIsSaving, selectSaveError],
      (latestPendingCoachNote, submittedCoachNotes, isNoteSaving, getNoteSaveError) => ({
        latestPendingCoachNote,
        submittedCoachNotes,
        fetchAllCoachNotes: async () => await fetchAllCoachNotes(userId),
        coachNoteSaving: (noteId: number) => isNoteSaving(userId, noteId),
        coachNoteSaveError: (noteId: number) => getNoteSaveError(userId, noteId),
        updateCoachNote: async (body: string, noteId?: number) =>
          await updateCoachNote(body, userId, noteId),
        submitCoachNote: async (id: string, body: string) => await submitNote(id, userId, body),
        clearSubmitCoachNoteFailure: () =>
          ApiModel.dispatch(
            Action.CREATE.getClearPendingAction(
              MODEL,
              MODEL.getUrl({
                id: ADDITIONAL_ENDPOINTS.submit,
                query: { user_id: userId },
              }),
            ),
          ),
        clearUpdateCoachNoteFailure: (noteId: number) =>
          ApiModel.dispatch(
            Action.CREATE.getClearPendingAction(MODEL, MODEL.getUrl({ query: { user_id: userId, note_id: noteId } })),
          ),
      }),
    )
  },
)

export const CoachNote: {
  connection: Connection<CoachNotesConnection, CoachNotesConnectionProps>
} = {
  /**
   * model connection: { userId }
   */
  connection: {
    load: ({ latestPendingCoachNote }, { userId }) => {
      if (latestPendingCoachNote == null && userId != null) {
        void fetchAllCoachNotes(userId)
      }
    },

    isLoaded: ({ latestPendingCoachNote }) => latestPendingCoachNote != null,

    selector: selectConnection,
  },
}
