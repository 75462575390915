import get from 'lodash/get'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel from '../ApiModel'
import { registerModelReducer } from '../reducers'
import { selectorCache } from '../util/selectorCache'

registerModelReducer('quiz_results', (state = { }, { type, options }) => {
  if (type === Action.CREATE.successType(MODEL)) {
    const quizResult = get(JSON.parse(options.body), 'quiz_result')
    const results = quizResult.results.reduce((results, { question_slug: questionSlug, score }) =>
      ({ ...results, [questionSlug]: score }), { })
    return { ...state, [quizResult.quiz_slug]: results }
  }

  return state
})

const MODEL = new ApiModel('QUIZ_RESULTS', 'api/v1/quiz_results')

const createErrorSelector = state => MODEL.getCreateErrorMessage(state)
const resultsSelector = quizSlug => state => get(state, `models.quiz_results.${quizSlug}`)

const selectConnection = selectorCache(
  ({ slug }) => slug,
  ({ slug }) => createSelector([
    resultsSelector(slug),
    MODEL.isCreatingSelector(),
    createErrorSelector,
  ], (previousResults, resultsAreRecording, resultsRecordError) => ({
    previousResults,
    resultsAreRecording,
    resultsRecordError,

    recordResults: results => MODEL.create({ quiz_result: { quiz_slug: slug, results } }),
  })),
)

export const QuizResults = {
  connection: {
    /**
     * modelConnect selector { slug (quiz slug) }
     */
    selector: selectConnection,

    shape: {
      previousResults: PropTypes.objectOf(PropTypes.number),
      resultsAreRecording: PropTypes.bool.isRequired,
      resultsRecordError: PropTypes.string,
      recordResults: PropTypes.func.isRequired,
    },
  },
}
