import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel from '../ApiModel'
import { registerModelReducer } from '../reducers'

const MODEL = new ApiModel('PROGRAM', 'api/v1/programs')

registerModelReducer('programs', (state = {}, { type }) => {
  if (type === Action.READ.successType(MODEL)) {
    return { ...state, loadedAvailablePrograms: true }
  }

  return state
})

export const SHAPE = PropTypes.shape({
  slug: PropTypes.string,
  indication: PropTypes.string,
  level: PropTypes.string,
  next_period_start: PropTypes.string,
  number_of_periods: PropTypes.number,
  rollout_period: PropTypes.string,
  max_reached_period: PropTypes.number,
  is_tools: PropTypes.bool,
})

export const loadAvailable = () => MODEL.read()

const selectAvailable = state => state.api.programs
const selectAvailableProgramsLoaded = state => state.models.programs.loadedAvailablePrograms

const selectAvailableConnection = createSelector(
  [selectAvailable, selectAvailableProgramsLoaded],
  (availablePrograms, loaded) => ({
    availablePrograms: availablePrograms ?? (loaded ? [] : null),
  }),
)

const PROGRAM_SELECTORS = new Map()
const selectProgramConnection = (state, { programSlug }) => {
  let selector = PROGRAM_SELECTORS.get(programSlug)
  if (selector == null) {
    const selectProgram = state => state.api.programs?.find(program => program.slug === programSlug)

    PROGRAM_SELECTORS.set(
      programSlug,
      selector = createSelector([selectProgram], program => ({ program })),
    )
  }
  return selector(state)
}

export const Program = {
  SHAPE,

  availableConnection: {
    load: ({ availablePrograms }) => {
      if (availablePrograms == null) loadAvailable()
    },

    isLoaded: ({ availablePrograms }) => availablePrograms != null,

    selector: selectAvailableConnection,

    shape: alwaysAvailable => ({
      availablePrograms: alwaysAvailable
        ? PropTypes.arrayOf(SHAPE)
        : PropTypes.arrayOf(SHAPE).isRequired,
    }),
  },

  programConnection: {
    /**
     * Selector: (state, { programSlug }) => connectionShape
     *
     * @param (String) programSlug The slug of the expected program
     */
    selector: selectProgramConnection,

    shape: {
      program: SHAPE.isRequired,
    },
  },
}
