import get from 'lodash/get'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'
import { SocketActions } from '../socketActions'
import { SOCKET_ACTION, SocketActionTypes } from '../socketMiddleware'

const connectSocket = () =>
  ApiModel.dispatch({ [SOCKET_ACTION]: { type: SocketActionTypes.CONNECT } })

const disconnectSocket = () =>
  ApiModel.dispatch({ [SOCKET_ACTION]: { type: SocketActionTypes.DISCONNECT } })

const sendTypingNotification = userId => ApiModel.dispatch({
  [SOCKET_ACTION]: { type: SocketActionTypes.TYPING, user_id: userId },
})

const clearTypingNotification = userId => ApiModel.dispatch({
  type: SocketActions.CLEAR_TYPING,
  user_id: userId,
})

const sendChatEvent = (action, payload) => ApiModel.dispatch({
  [SOCKET_ACTION]: { type: SocketActionTypes.CHAT_EVENT, action, payload },
})

const connectionStateSelector = state => state.socket.connectionState

const isTypingSelector = (state, { otherUserId }) => get(state.socket.typing, [otherUserId], false)

export const Socket = {
  connectSocket,
  disconnectSocket,
  sendTypingNotification,
  clearTypingNotification,
  sendChatEvent,

  statusConnection: {
    selector: createSelector([connectionStateSelector], connectionState => ({ connectionState })),

    shape: {
      connectionState: PropTypes.oneOf(['connecting', 'connected', 'disconnected']).isRequired,
    },
  },

  typingConnection: {
    selector: createSelector([isTypingSelector], isTyping => ({ isTyping })),

    shape: {
      isTyping: PropTypes.bool.isRequired,
    },
  },
}
