import PropTypes from 'prop-types'
import Log from '../services/log'

/**
 * Generates PropTypes for a component from the SCHEMA generated by the activities:get_schema
 * rake tool in the joyable codebase from the YML schema for a given template.
 */
export function generatePropTypes (schema, isRequired = true) {
  // PropTypes aren't checked in production builds, so don't bother to generate them either.
  if (process.env.NODE_ENV === 'production') return undefined

  if (schema == null) return PropTypes.shape({})
  if (schema.properties == null) return generatePropType(schema, isRequired)

  const props = {}
  for (const prop of Object.keys(schema.properties)) {
    props[prop] = generatePropType(schema.properties[prop], (schema.required || []).includes(prop))
  }

  return isRequired ? PropTypes.shape(props).isRequired : PropTypes.shape(props)
}

const PRIMITIVES = {
  string: PropTypes.string,
  boolean: PropTypes.bool,
  integer: PropTypes.number,
  number: PropTypes.number,
}

function generatePropType (schema, required) {
  let type
  if (schema.enum != null) {
    type = PropTypes.oneOf(schema.enum)
  } else if (schema.type === 'array') {
    type = PropTypes.arrayOf(generatePropTypes(schema.items, required))
  } else if (schema.properties != null) {
    // isRequired will be handled in the return from this method.
    type = generatePropTypes(schema, false)
  } else {
    type = PRIMITIVES[schema.type]
  }

  if (type == null) {
    if (Object.keys(schema).length > 0) {
      Log.error('Unable to generate prop type', schema)
    }
    type = PropTypes.shape({})
  }

  // schemas that have a default on the server may be marked required on the client because
  // they'll always have a value.
  return required || type.default ? type.isRequired : type
}
