import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'
import { registerModelReducer } from '../reducers'
import Action from '../Action'

const MODEL = new ApiModel(
  'COMMUNICATION_PREFERENCES',
  'api/v1/communication_preferences',
)

const getUpdatedState = (state, response) =>
  state.map((p) =>
    p.slug === response.communication_preference.slug
      ? response.communication_preference
      : p
    ,
  )

const cleanNullValues = (preferences) =>
  preferences.map((preference) => ({
    ...preference,
    modes: preference.modes || [],
    user_selected_timing: preference.user_selected_timing || [],
  }))

registerModelReducer(
  'communicationPreferences',
  (state = [], { type, response }) => {
    if (type === Action.READ.successType(MODEL)) {
      return cleanNullValues(response.communication_preferences)
    } else if (type === Action.READ.failureType(MODEL)) {
      return state
    } else if (type === Action.UPDATE_MODIFY.successType(MODEL)) {
      return getUpdatedState(state, response)
    } else if (type === Action.UPDATE_MODIFY.failureType(MODEL)) {
      return state
    }
    return state
  },
)

export const SHAPE = PropTypes.shape({
  slug: PropTypes.string,
  modes: PropTypes.array,
  selectable_modes: PropTypes.array,
  timing_supported: PropTypes.bool,
  user_selected_timing: PropTypes.array,
  user_selected_weekdays: PropTypes.array,
})

const select = (state) => state.models.communicationPreferences

export const updateCommunicationPreference = (updates, slug) => {
  if (updates == null) return
  return MODEL.updateModify(
    { communication_preference: updates },
    { id: slug, ignoreFailure: true },
  )
}

const selectAvailableConnection = createSelector(
  [select],
  (communicationPreferences) => ({
    communicationPreferences,
    updateCommunicationPreference: (updates, slug) => updateCommunicationPreference(updates, slug),
  }),
)

export const CommunicationPreferences = {
  SHAPE,
  connection: {
    load: ({ communicationPreferences }) => {
      if (!communicationPreferences.length) MODEL.read()
    },

    isLoaded: ({ communicationPreferences }) => communicationPreferences.length,

    selector: selectAvailableConnection,

    shape: {
      communicationPreferences: PropTypes.arrayOf(SHAPE),
      updateCommunicationPreference: PropTypes.func.isRequired,
    },
  },
}
