import Action from '../Action'
import ApiModel from '../ApiModel'
import { registerModelReducer } from '../reducers'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import { selectorCache } from '../util/selectorCache'
import { formatModelTimestamps } from '../../../../app/js/components/admin/utils'

registerModelReducer('admins', (state = { }, { type, response }) => {
  switch (type) {
    case ADMIN_ADMINS_READ_SUCCESS_TYPE:
      if (response.is_active) {
        return {
          ...state,
          activeAdmins: formatModelTimestamps(response.admins, ['created_at', 'last_sign_in_at']),
          activeAdminsTotalCount: response.total_count,
          activeAdminsCurrentPage: response.current_page,
          isActive: response.is_active,
        }
      } else {
        return {
          ...state,
          deactivatedAdmins: formatModelTimestamps(response.admins,
            ['created_at', 'deactivated_at', 'last_sign_in_at']),
          deactivatedAdminsTotalCount: response.total_count,
          deactivatedAdminsCurrentPage: response.current_page,
          isActive: response.is_active,
        }
      }
    default:
      return state
  }
})

const ADMIN_ADMINS_MODEL = new ApiModel('ADMINS', 'admin/api/v1/admins')
const ADMIN_ADMINS_READ_SUCCESS_TYPE = Action.READ.successType(ADMIN_ADMINS_MODEL)

const ADMINS_SHAPE = PropTypes.shape({
  id: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  last_logged_in: PropTypes.string.isRequired,
  deactivated_at: PropTypes.string.isRequired,
})

const selectAdminConnection = selectorCache(
  ({ adminId }) => adminId,
  ({ adminId }) => createSelector(
    [
      state => state.models.admins.activeAdmins,
      state => state.models.admins.activeAdminsTotalCount,
      state => state.models.admins.activeAdminsCurrentPage,
      state => state.models.admins.deactivatedAdmins,
      state => state.models.admins.deactivatedAdminsTotalCount,
      state => state.models.admins.deactivatedAdminsCurrentPage,
      state => state.models.admins.isActive,
      state => ADMIN_ADMINS_MODEL.getUpdateErrorMessage(state, { adminId }),
    ],
    (activeAdmins,
      activeAdminsTotalCount,
      activeAdminsCurrentPage,
      deactivatedAdmins,
      deactivatedAdminsTotalCount,
      deactivatedAdminsCurrentPage,
      isActive) => ({
      activeAdmins,
      activeAdminsTotalCount,
      activeAdminsCurrentPage,
      deactivatedAdmins,
      deactivatedAdminsTotalCount,
      deactivatedAdminsCurrentPage,
      isActive,
      getAdmins: ({ page, field, order, active }) => {
        if (!(field !== 'last_logged_in')) {
          field = 'last_sign_in_at'
        }
        ADMIN_ADMINS_MODEL.read({ query: { page, order, field, active } })
      },
      sortAdmins: ({ order, field, active }) => {
        if (!(field !== 'last_logged_in')) {
          field = 'last_sign_in_at'
        }
        ADMIN_ADMINS_MODEL.read({ query: { page: 1, order, field, active } })
      },
      deleteAdmin: async id => await ADMIN_ADMINS_MODEL.destroy({ id }),
      clearDeleteError: (id) => {
        ApiModel.dispatch(
          Action.DESTROY.getClearPendingAction(ADMIN_ADMINS_MODEL, ADMIN_ADMINS_MODEL.getUrl({ id })),
        )
      },
    }),
  ),
)

export const Admin = {
  ADMINS_SHAPE,

  /**
   * Connection used in "Manage Admins" Admin Tool to view all admins
   */
  adminConnection: {
    load: ({ active, activeAdmins, deactivatedAdmins }) => {
      if ((active && activeAdmins == null && active != null) ||
      (!active && deactivatedAdmins == null && active != null)) {
        ADMIN_ADMINS_MODEL.read({ query: { page: 1, active } })
      }
    },

    isLoaded: ({ isActive, activeAdmins, deactivatedAdmins }) => {
      return ((activeAdmins !== null && isActive) ||
      (deactivatedAdmins !== null && !isActive))
    },

    selector: selectAdminConnection,

    shape: {
      admins: PropTypes.arrayOf(ADMINS_SHAPE).isRequired,
      totalCount: PropTypes.number,
      currentPage: PropTypes.number,
      getActiveAdmins: PropTypes.func.isRequired,
      isActive: PropTypes.bool,
    },
  },
}
