import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel, { PRUNE_API_TREE } from '../ApiModel'
import Log from '../log'
import { mergeIn, registerModelReducer } from '../reducers'
import { SocketActions } from '../socketActions'
import { selectorCache } from '../util/selectorCache'
import { countRead, getUserModelIds, parseId, parseQuery, selectModelStore, trace } from './utilities'

const MODEL = new ApiModel('MESSAGE', 'api/v1/messages')

const DEFAULT_STATE = {
  users: { },
  threads: { },
  clientChat: { messagesLoaded: false, readUpTo: -1 },
}
registerModelReducer('messaging', (
  state = DEFAULT_STATE,
  { type, url, response, pruneKeys, socketData, socketChannel },
) => {
  if (type === Action.READ.successType(MODEL)) {
    const id = parseId(MODEL, url)
    if (isEmpty(id)) {
      const { user_id: userId, page_token: pageToken } = parseQuery(url)
      return mergeIn(state, ['users', userId], {
        currentPageToken: pageToken,
        nextPageToken: '' + response.next_page_token,
      })
    } else if (id === 'thread') {
      const { thread_id: threadId, page_token: pageToken } = parseQuery(url)
      return mergeIn(state, ['threads', threadId], {
        currentPageToken: pageToken,
        nextPageToken: '' + response.next_page_token,
      })
    } else if (id === 'chat') {
      const { page_token: pageToken } = parseQuery(url)
      return mergeIn(state, ['clientChat'], {
        messagesLoaded: true,
        currentPageToken: pageToken,
        nextPageToken: '' + response.next_page_token,
        totalMessageCount: response.total_message_count,
        totalUnreadMessageCount: response.total_unread_message_count,
      })
    }
  } else if (type === SocketActions.CHANNEL_RECEIVED && socketChannel === 'ChatChannel') {
    return mergeIn(state, ['clientChat'], {
      totalMessageCount: socketData.total_message_count,
      totalUnreadMessageCount: socketData.total_unread_message_count,
    })
  } else if (
    type === PRUNE_API_TREE &&
    pruneKeys.find(key => key.length === 1 && key[0] === 'messages') != null
  ) {
    return DEFAULT_STATE
  } else if (type === Action.UPDATE_MODIFY.successType(MODEL)) {
    const id = parseId(MODEL, url)
    if (id != null && id.startsWith('chat/')) {
      const modelId = parseInt(id.split('/')[1])
      return mergeIn(state, ['clientChat'], {
        readUpTo: modelId,
        totalMessageCount: response.total_message_count,
        totalUnreadMessageCount: response.total_unread_message_count,
      })
    }
  }

  return state
})

const CONTENT_SHAPES = {
  Message: {
    body: PropTypes.string.isRequired,
  },

  MessageWithButton: {
    body: PropTypes.string.isRequired,
    button_title: PropTypes.string.isRequired,
    button_action: PropTypes.string.isRequired,
    button_action_argument: PropTypes.string,
  },
}

const SUGGESTION_SHAPE = PropTypes.shape({
  slug: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  message_type: PropTypes.string,
})

const SHAPE = PropTypes.shape({
  id: PropTypes.number.isRequired,
  sent_at: PropTypes.string.isRequired,
  to_coach: PropTypes.bool.isRequired,
  user_id: PropTypes.number.isRequired,
  template: PropTypes.oneOf(Object.keys(CONTENT_SHAPES)),
  content: PropTypes.oneOfType(Object.values(CONTENT_SHAPES).map(shape => PropTypes.shape(shape))),
  suggestions: PropTypes.arrayOf(SUGGESTION_SHAPE),
  request_slug: PropTypes.string,
})

const messagesWithUserQuery = (userId, { pageToken = null, limit = 10 }) => ({
  user_id: userId, page_token: pageToken, limit,
})

const threadOptions = (userId, threadId, { pageToken = null, limit = 10 }) => ({
  query: { user_id: userId, thread_id: threadId, page_token: pageToken, limit },
  id: 'thread',
  modelMapKey: ['user_id'],
})

const loadMessagesWithUser = (userId, { pageToken = null, limit = 10 }) => MODEL.read({
  query: messagesWithUserQuery(userId, { pageToken, limit }),
  modelMapKey: ['user_id'],
})

const loadThread = (userId, threadId, { pageToken = null, limit = 10 }) =>
  MODEL.read(threadOptions(userId, threadId, { pageToken, limit }))

const sendChatOptions = userId => ({ id: 'chat', query: { user_id: userId } })

export const sendChat = (userId, message) => MODEL.create(
  { message },
  {
    ...sendChatOptions(userId),
    ignoreFailure: true,
    modelMapKey: ['user_id'],
  },
)

export const clearChatSendFailure = userId => ApiModel.dispatch(
  Action.CREATE.getClearPendingAction(
    MODEL,
    MODEL.getUrl(sendChatOptions(userId)),
  ),
)

const sendTextOptions = userId => ({ query: { user_id: userId } })

export const sendText = (userId, message) => MODEL.create(
  { message }, {
    ...sendTextOptions(userId),
    ignoreFailure: true,
    modelMapKey: ['user_id'],
  },
)

export const clearTextSendFailure = userId => ApiModel.dispatch(
  Action.CREATE.getClearPendingAction(
    MODEL,
    MODEL.getUrl(sendTextOptions(userId)),
  ),
)

export const selectChatIsSending = (state, { user }) =>
  user != null && MODEL.isCreatingSelector(sendChatOptions(user.id))(state)

export const selectChatHasFailed = (state, { user }) => user != null && Action.hasFailed(
  Action.CREATE.getPending(
    state,
    MODEL.getUrl(sendChatOptions(user.id)),
  ),
)

export const selectTextIsSending = (state, { user }) =>
  user != null && MODEL.isCreatingSelector(sendTextOptions(user.id))(state)

export const selectTextHasFailed = (state, { user }) => user != null && Action.hasFailed(
  Action.CREATE.getPending(
    state,
    MODEL.getUrl(sendTextOptions(user.id)),
  ),
)

const clientChatOptions = ({ pageToken = null, limit = 10 }) => ({
  id: 'chat',
  query: { page_token: pageToken, limit },
})

const loadClientChat = ({ pageToken = null, limit = 10 }) =>
  MODEL.read({ ...clientChatOptions({ pageToken, limit }), modelMapKey: ['user_id'] })

const markAllReadUpTo = id => MODEL.updateModify({ message: { read: true } }, { id: `chat/${id}` })

const momentsForSort = sort => ({ id: id1, sent_at: date1 }, { id: id2, sent_at: date2 }) => sort(
  { parsed: date1 && moment(date1), id: id1 },
  { parsed: date2 && moment(date2), id: id2 },
)

const sortMessagesYoungestFirst =
  momentsForSort(({ parsed: moment1, id: id1 }, { parsed: moment2, id: id2 }) => {
    if (moment1 == null && moment2 == null) return 0
    // a null date is older than a specified date
    if (moment1 == null) return -1
    if (moment2 == null) return 1

    if (moment1.isBefore(moment2)) return 1
    else if (moment2.isBefore(moment1)) return -1
    else return id1 < id2 ? 1 : (id2 < id1 ? -1 : 0)
  })

export const sortMessagesOldestFirst =
  momentsForSort(({ parsed: moment1, id: id1 }, { parsed: moment2, id: id2 }) => {
    if (moment1 == null && moment2 == null) return 0
    // a null date is older than a specified date
    if (moment1 == null) return 1
    if (moment2 == null) return -1

    if (moment1.isBefore(moment2)) return -1
    else if (moment2.isBefore(moment1)) return 1
    else return id1 < id2 ? -1 : (id2 < id1 ? 1 : 0)
  })

export const selectMessages = state => state.api.messages

// TODO: remove tracing when "Message from props is null!" bug is fixed
export const messagesWithUserSelector = (userId, sort = sortMessagesYoungestFirst) =>
  createSelector(
    [
      trace('getUserModelIds', getUserModelIds(userId, 'messages'), { logLevel: 'warn' }),
      selectModelStore('messages'),
    ],
    (messageIds, messages) => {
      if (messageIds == null || messages == null) return null
      if (!isArray(messageIds)) {
        // We're getting an occasional error in prod from coach tool declaring that
        // "e.map is not a function". This is meant to help track that down.
        Log.error('messageIds is not null, but also not an array', messageIds)
        return null
      }
      return messageIds.map(id => messages[id]).sort(sort)
    },
  )

const messagesInThreadSelector = (userId, threadId) => createSelector(
  [messagesWithUserSelector(userId)],
  messages => messages && messages.filter(
    ({ thread_id: messageThreadId }) => threadId === messageThreadId,
  ),
)

const messagesLoadedSelector = userId => state =>
  get(state.models.messaging, ['users', userId]) != null

const currentPageTokenSelector = userId => state =>
  get(state.models.messaging, ['users', userId, 'currentPageToken'])

const nextPageTokenSelector = userId => state =>
  get(state.models.messaging, ['users', userId, 'nextPageToken'])

const clientTotalUnreadMessageCountSelector = state =>
  get(state.models.messaging, ['clientChat', 'totalUnreadMessageCount'])

const currentThreadPageTokenSelector = threadId => state =>
  get(state.models.messaging, ['threads', threadId, 'currentPageToken'])

const threadLoadedSelector = threadId => state =>
  get(state.models.messaging, ['threads', threadId]) != null

const selectClientChatConnection = (() => {
  const currentPageTokenSelector = state => state.models.messaging.clientChat.currentPageToken
  const nextPageTokenSelector = state => state.models.messaging.clientChat.nextPageToken
  const messagesLoadedSelector = state => state.models.messaging.clientChat.messagesLoaded

  const currentPageEndpointSelector = createSelector(
    [currentPageTokenSelector],
    pageToken => MODEL.getUrl(clientChatOptions({ pageToken })),
  )

  const nextPageEndpointSelector = createSelector(
    [nextPageTokenSelector],
    pageToken => MODEL.getUrl(clientChatOptions({ pageToken })),
  )

  const pendingSelector = createSelector(
    [currentPageEndpointSelector, nextPageEndpointSelector, Action.READ.pendingSource],
    (currentPageEndpoint, nextPageEndpoint, pending) => ({
      current: pending[currentPageEndpoint],
      next: pending[nextPageEndpoint],
    }),
  )

  const statusSelector = createSelector(
    [pendingSelector, messagesLoadedSelector],
    ({ current, next }, isLoaded) => ({
      isLoaded,
      isLoading: Action.isActing(current),
      hasError: Action.hasFailed(current),
      isLoadingMore: Action.isActing(next),
      hasErrorMore: Action.hasFailed(next),
    }),
  )

  return createSelector(
    [
      messagesWithUserSelector('me', sortMessagesOldestFirst),
      currentPageTokenSelector,
      nextPageTokenSelector,
      statusSelector,
      state => selectChatIsSending(state, { user: { id: null } }),
      state => state.models.messaging.clientChat.readUpTo,
    ],
    (messages, currentPageToken, nextPageToken, status, chatIsSending, readUpTo) => ({
      messages,
      latestMessage: messages != null ? last(messages) : null,
      messagesStatus: {
        ...status,
        hasMore: nextPageToken != null && currentPageToken !== nextPageToken,
      },
      retryMessages: () => loadClientChat({ pageToken: currentPageToken }),
      loadMoreMessages: () => loadClientChat({ pageToken: nextPageToken }),
      sendChat: body => sendChat(null, { body }),
      chatIsSending,
      markAllRead: () => {
        if (messages == null) return
        const lastMessageFromCoach = [...messages].reverse().find(
          ({ to_coach: toCoach }) => !toCoach,
        )
        if (
          lastMessageFromCoach != null &&
          !lastMessageFromCoach.read &&
          lastMessageFromCoach.id > readUpTo
        ) {
          markAllReadUpTo(lastMessageFromCoach.id)
        }
      },
    }),
  )
})()

/**
 * selector: (state, { user })
 */
const selectCoachConnection = selectorCache(({ user }) => user.id, ({ user }) => {
  const endpointSelector = createSelector(
    [currentPageTokenSelector(user.id)],
    pageToken => MODEL.getUrl({ query: messagesWithUserQuery(user.id, { pageToken }) }),
  )

  // Implementation note: The status selector could be implemented in this one selector. However,
  // state.apiPending.READ changes on any GET request status change, so this pending selector
  // gets re-evaluated frequently. If the status object from statusSelector were regenerated
  // on every one of those re-evaluations, it would cause a re-render of the component tree
  // that depends on this connection every time. Instead, this pendingSelector just references
  // the correct part of the apiPending state, and the resulting object from it will only change
  // when there is an update for the particular request we care about. As a result, the other
  // selectors that depend on it will only re-evaluate when a relevant change happens, saving
  // all those re-renders. It's important to think about the input and output from selectors
  // in terms of object equality and how changes in object equality (even if the contents are the
  // same) will cascade through selectors and into the React component tree.
  const pendingSelector = createSelector(
    [endpointSelector, Action.READ.pendingSource],
    (endpoint, pending) => pending[endpoint],
  )

  const statusSelector = createSelector(
    [pendingSelector, messagesLoadedSelector(user.id)],
    (pending, isLoaded) => ({
      isLoaded,
      isLoading: Action.isActing(pending),
      hasError: Action.hasFailed(pending),
    }),
  )

  // TODO: remove tracing when "Message from props is null!" bug is fixed
  return createSelector(
    [
      trace('messagesWithUserSelector', messagesWithUserSelector(user.id), { logLevel: 'warn' }),
      currentPageTokenSelector(user.id),
      nextPageTokenSelector(user.id),
      statusSelector,
    ],
    (messages, currentPageToken, nextPageToken, status) => ({
      messages,
      messagesStatus: {
        ...status,
        hasMore: nextPageToken != null && currentPageToken !== nextPageToken,
      },
      retryMessages: () => loadMessagesWithUser(user.id, { pageToken: currentPageToken }),
      loadMoreMessages: () => loadMessagesWithUser(user.id, { pageToken: nextPageToken }),
    }),
  )
})

/**
 * selector: (state, { userId, threadId })
 */
const selectThreadConnection = selectorCache(
  ({ threadId }) => threadId,
  ({ threadId, userId }) => {
    if (threadId == null) {
      return () => ({
        threadLoaded: false,
        threadLoading: false,
        loadThread: () => null,
      })
    }

    const endpointSelector = createSelector(
      [currentThreadPageTokenSelector(threadId)],
      pageToken => MODEL.getUrl(threadOptions(userId, threadId, { pageToken })),
    )

    const loadingSelector = createSelector(
      [endpointSelector, Action.READ.pendingSource],
      (endpoint, pending) => Action.isActing(pending[endpoint]),
    )

    return createSelector(
      [
        messagesInThreadSelector(userId, threadId),
        threadLoadedSelector(threadId),
        loadingSelector,
        currentThreadPageTokenSelector(threadId),
      ],
      (messages, threadLoaded, threadLoading, currentPageToken) => ({
        thread: messages,
        threadLoaded,
        threadLoading,
        loadThread: () => loadThread(userId, threadId, { pageToken: currentPageToken }),
      }),
    )
  })

const selectClientUnreadConnection = createSelector(
  [Action.READ.pendingSource, clientTotalUnreadMessageCountSelector],
  (pending, unreadCount) => ({
    load: () => loadClientChat({}),
    isLoaded: unreadCount != null,
    isLoading: Action.isActing(pending[MODEL.getUrl(clientChatOptions({}))]),
    numUnreadMessages: unreadCount,
  }))

const selectUnreadConnection = selectorCache(
  ({ userId }) => userId,
  ({ userId }) => userId == null
    ? () => ({ })
    : createSelector(
      [messagesWithUserSelector(userId)],
      // For now, this is only used by coaches. If that changes, this will need to get a little
      // smarter
      messages => ({
        numUnreadMessages: (messages || [])
          .filter(message => message != null && message.to_coach)
          .reduce(countRead, 0),
      }),
    ),
)

export const onSocketReconnect = longDisconnect => {
  // do nothing on a short disconnect. The risk is low and the most recent message will come in
  // on the worklist refresh.
  if (longDisconnect) {
    ApiModel.trimCache([['messages']], { modelMapPruneKeys: [['user_id', '*', 'messages']] })
  }
}

export const Message = {
  SHAPE,
  CONTENT_SHAPES,

  clientChatConnection: {
    selector: selectClientChatConnection,

    load: ({ messagesStatus: { isLoading, isLoaded }, retryMessages }) => {
      // use the retry function for the initial load
      if (!isLoading && !isLoaded) retryMessages()
    },

    isLoaded: ({ messagesStatus: { isLoaded } }) => isLoaded,

    shape: {
      messages: PropTypes.arrayOf(SHAPE),
      latestMessage: SHAPE,
      messagesStatus: PropTypes.shape({
        isLoading: PropTypes.bool.isRequired,
        isLoaded: PropTypes.bool.isRequired,
        hasError: PropTypes.bool.isRequired,
        hasMore: PropTypes.bool.isRequired,
        isLoadingMore: PropTypes.bool.isRequired,
        hasErrorMore: PropTypes.bool.isRequired,
      }),
      retryMessages: PropTypes.func.isRequired,
      loadMoreMessages: PropTypes.func.isRequired,
      sendChat: PropTypes.func.isRequired,
      chatIsSending: PropTypes.bool.isRequired,
      markAllRead: PropTypes.func.isRequired,
    },
  },

  clientUnreadConnection: {
    selector: selectClientUnreadConnection,

    load: ({ isLoading, isLoaded, load }) => {
      // use the retry function for the initial load
      if (!isLoading && !isLoaded) load()
    },

    shape: {
      load: PropTypes.func.isRequired,
      isLoaded: PropTypes.bool.isRequired,
      isLoading: PropTypes.bool.isRequired,
      numUnreadMessages: PropTypes.number,
    },
  },

  /**
   * model connection: { userId }
   */
  unreadConnection: {
    selector: selectUnreadConnection,

    shape: {
      numUnreadMessages: PropTypes.number,
    },
  },

  coachConnection: {
    selector: selectCoachConnection,

    load: ({ messagesStatus: { isLoading, isLoaded }, retryMessages }) => {
      // Use the connected retry function for the initial load.
      if (!isLoading && !isLoaded) retryMessages()
    },

    isLoaded: ({ messagesStatus: { isLoaded } }) => isLoaded,

    shape: {
      messages: PropTypes.arrayOf(SHAPE),
      messagesStatus: PropTypes.shape({
        isLoading: PropTypes.bool.isRequired,
        isLoaded: PropTypes.bool.isRequired,
        hasError: PropTypes.bool.isRequired,
        hasMore: PropTypes.bool.isRequired,
      }),
      retryMessages: PropTypes.func.isRequired,
      loadMoreMessages: PropTypes.func.isRequired,
    },
  },

  threadConnection: {
    selector: selectThreadConnection,

    load: ({ threadLoaded, threadLoading, loadThread }) => {
      if (!threadLoaded && !threadLoading) loadThread()
    },

    isLoaded: ({ threadLoaded }) => threadLoaded,

    shape: {
      thread: PropTypes.arrayOf(SHAPE).isRequired,
      threadLoaded: PropTypes.bool.isRequired,
      threadLoading: PropTypes.bool.isRequired,
      loadThread: PropTypes.func.isRequired,
      // TODO (NJC) Add support for loading more pages of a thread? There is not currently
      // any UI for it, so may not be necessary. Also, the server currently ignores `limit`, so it
      // isn't correctly supporting pagination for threads either.
    },
  },
}
