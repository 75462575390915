import { isAuth0Enabled } from 'joyable-js-api/src/ApiFetch'
import { IN_IFRAME } from '../constants'
import { isMobile } from '../lib/browserSize'
import Activities, {
  activityPath,
  activityPathWithProgramActivityID,
  deepLinkRedirectActivityPath,
} from './Activities'
import Pages from './Pages'
import queryString from 'query-string'

// Pages that have a Requirement.LOGIN requirement but that handle checkout and should therefore
// be allowed to load if 'checkout' was returned from the branching service for the login
// requirement. Presented as a predicate instead of constant array to get around a circular
// dependency problem.
const hasCheckout = path => [
  Pages.ME_ACCOUNT.path,
  Activities.FIT_RESULTS.path,
].includes(path)

const redirector = pathGetter => ({
  getPath: pathGetter,

  redirect: (props, fromDeepLink) => {
    const path = pathGetter(props)
    if (path != null) props.history.push(path)

    return path == null || fromDeepLink ? StateChanges.PAGE_ALLOWED : StateChanges.REDIRECTING
  },
})

export const StateChanges = {
  PAGE_ALLOWED: { isLoading: false, isRedirecting: false },
  REDIRECTING: { isLoading: false, isRedirecting: true },
  LOADING: { isLoading: true, isRedirecting: false },
}

export const Redirect = {
  checkout: redirector(({ match: { path } = { } }) => {
    if (hasCheckout(path)) return null
    return Pages.ACCOUNT.path
  }),

  // redirector takes a method instead of the path directly in order to get around a circular
  // dependency problem.
  fit: redirector(() => Activities.FIT.path),
  fit_results: redirector(() => Activities.FIT_RESULTS.path),
  insight: redirector(() => Activities.INSIGHT.path),
  insight_results: redirector(() => Activities.INSIGHT_RESULTS.path),
  terapia: redirector(() => Activities.TERAPIA.path),
  terapia_results: redirector(() => Activities.TERAPIA_RESULTS.path),
  finalize_account_prep: redirector(() => Activities.FINALIZE_ACCOUNT_PREP.path),
  triage: redirector(() => Activities.TRIAGE.path),
  concierge_preferences: redirector(() => Activities.CONCIERGE_PREFERENCES.path),
  setup: redirector((props) => deepLinkRedirectActivityPath(props, 'setup')),
  partner_setup: redirector(() => Activities.PARTNER_SETUP.path),
  referrals_welcome_to_ableto: redirector(() => Activities.REFERRALS_WELCOME_TO_ABLETO.path),
  empathia_intake: redirector(() => Activities.EMPATHIA_INTAKE.path),
  warm_up: redirector(() => activityPath('warm_up', Pages.PLACEMENT.path)),
  warm_up_eap: redirector(() => activityPath('warm_up_eap', Pages.PLACEMENT.path)),
  get_started: redirector(() => activityPath('get_started', Pages.PLACEMENT.path)),
  ftue: redirector((props) => deepLinkRedirectActivityPath(props, 'ftue')),
  checking_in: redirector(() =>
    activityPathWithProgramActivityID('checking_in', 'ft_checking_in')),
  path: redirector(() => activityPath('path', Pages.PLACEMENT.path)),
  product_highlight: redirector(() => activityPath('product_highlight', Pages.PLACEMENT.path)),
  connect_re_entry: redirector(({ requirement, redirectBranch }) => {
    const redirectPath = requirement.getRedirectPath(redirectBranch)
    return activityPath(redirectPath, Pages.PLACEMENT.path)
  }),
  download_the_app: redirector(() => activityPath('download_the_app')),

  // if we're on mobile, we need to show the full screen chat view.
  // otherwise, we can just send them to chat on the dashboard.
  chat: redirector(() => isMobile() ? Pages.COACH_CHAT.path : Pages.DASHBOARD.path),

  root: redirector(() => '/'),

  unauthorized: {
    redirect: () => {
      if (window.parent !== window) {
        Pages.ERROR.go({ message_key: 'unauthorized', link_help: true })
        return StateChanges.REDIRECTING
      } else {
        Pages.LOGIN.go()
        return StateChanges.REDIRECTING
      }
    },
  },

  account: {
    redirect: () => {
      Pages.ME_ACCOUNT.go()
      return StateChanges.REDIRECTING
    },
  },

  login: {
    getPath: () => '/',
    redirect: () => {
      if (IN_IFRAME) {
        Pages.VERIFY_LOGIN.go(window.location.search)
        return StateChanges.REDIRECTING
      } else {
        if (isAuth0Enabled()) {
          return
        }
        const { location: { pathname, search } } = globalThis
        const query = queryString.stringify({ login_redirect: `${pathname}${search}` })

        // force a refresh on the landing page so that our redux store gets all cleared
        // out of cached info about this client.
        // NOTE: If we stop doing a refresh here and instead clear out the redux store, make
        // sure we also call Socket.disconnectSocket()
        window.location = `/?${query}`
        return StateChanges.REDIRECTING
      }
    },
  },
}
