import Action from '../Action'
import ApiModel from '../ApiModel'

const MODEL = new ApiModel('READ', 'api/v1/read')
export const SUCCESS_TYPE = Action.UPDATE_MODIFY.successType(MODEL)

const markModelRead = (type, id) => MODEL.updateModify({ read: { type, id } })

export const Read = {
  markModelRead,
}
