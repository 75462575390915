/* global mixpanel, NativeApp, FS */
import { Treatment, User } from 'joyable-js-api'
import isFunction from 'lodash/isFunction'
import { connectionContext } from '../components/modelConnect'
import Pages from '@app/js/routing/Pages'
import Log from './log'
import { PlatformAnalytics } from './PlatformAnalytics'
import queryString from 'query-string'

const INIT_TIMEOUT = 6000 // in milliseconds

const ANALYTICS_EVENTS = {
  VIEW: 'View',
  CLICKED: 'Clicked',
  LAUNCHED_ACTIVITY: 'LaunchedActivity',
  EXITED_ACTIVITY: 'ExitedActivity',
  FOLLOWED_LINK: 'FollowedLink',
}

const meContext = connectionContext(User.meConnection)
const currentTreatmentContext = connectionContext(Treatment.currentConnection)

class Analytics {
  constructor () {
    this.brazeToken = process.env.BRAZE_TOKEN
    this.mixpanelToken = process.env.MIXPANEL_TOKEN
    this.fullStoryOrg = process.env.FULLSTORY_ORG
  }

  get brazeEnabled () {
    return this.brazeToken != null
  }

  get mixpanelEnabled () {
    return this.mixpanelToken != null
  }

  get fullStoryEnabled () {
    return this.fullStoryOrg != null
  }

  init () {
    // Initialize all analytics services and get the user, then identify that user with Mixpanel & Braze.
    if (this.mixpanelEnabled) this._mixpanelInit = this.initializeMixpanel()
    if (this.fullStoryEnabled) this._fullStoryInit = this.initializeFullStory()
    PlatformAnalytics.init()
    this.identify()
  }

  initMixPanelSuperProperties () {
    const { me } = meContext.select()
    let props = {}

    if (me != null && !me.is_guest) {
      props = { ...props, program_level: me.program_level, program_slug: me.program_slug }
    }

    mixpanel.register(props)
  }

  initializeMixpanel () {
    return new Promise((resolve, reject) => {
      /* eslint-disable */
      (function(e,a){if(!a.__SV){var b=window;try{var c,l,i,j=b.location,g=j.hash;c=function(a,b){return(l=a.match(RegExp(b+"=([^&]*)")))?l[1]:null};g&&c(g,"state")&&(i=JSON.parse(decodeURIComponent(c(g,"state"))),"mpeditor"===i.action&&(b.sessionStorage.setItem("_mpcehash",g),history.replaceState(i.desiredHash||"",e.title,j.pathname+j.search)))}catch(m){}var k,h;window.mixpanel=a;a._i=[];a.init=function(b,c,f){function e(b,a){var c=a.split(".");2==c.length&&(b=b[c[0]],a=c[1]);b[a]=function(){b.push([a].concat(Array.prototype.slice.call(arguments,
        0)))}}var d=a;"undefined"!==typeof f?d=a[f]=[]:f="mixpanel";d.people=d.people||[];d.toString=function(b){var a="mixpanel";"mixpanel"!==f&&(a+="."+f);b||(a+=" (stub)");return a};d.people.toString=function(){return d.toString(1)+".people (stub)"};k="disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config reset people.set people.set_once people.increment people.append people.union people.track_charge people.clear_charges people.delete_user".split(" ");
        for(h=0;h<k.length;h++)e(d,k[h]);a._i.push([b,c,f])};a.__SV=1.2;b=e.createElement("script");b.type="text/javascript";b.async=!0;b.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?MIXPANEL_CUSTOM_LIB_URL:"file:"===e.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\/\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";c=e.getElementsByTagName("script")[0];c.parentNode.insertBefore(b,c)}})(document,window.mixpanel||[]);
      /* eslint-enable */

      let complete = false
      mixpanel.init(this.mixpanelToken, {
        loaded: () => {
          mixpanel.set_config({ cross_subdomain_cookie: false })
          if (!complete) {
            complete = true
            resolve()
          }
        },
      })

      setTimeout(() => {
        if (!complete) {
          complete = true
          // Log the timeout once
          Log.error('Mixpanel init timed out')
          reject(new Error('Mixpanel init timed out'))
        }
      }, INIT_TIMEOUT)
    })
  }

  async initializeBraze () {
    const { default: appboy } = await import('appboy-web-sdk')

    appboy.initialize(this.brazeToken, { baseUrl: 'https://sdk.iad-02.braze.com' })

    const externalId = meContext.select().me.external_id
    if (externalId != null) appboy.changeUser(externalId)

    appboy.openSession()
  }

  initializeFullStory () {
    const timeout = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error('FullStory init timed out'))
      }, INIT_TIMEOUT)
    })

    const fs = new Promise(resolve => {
      window._fs_debug = false
      window._fs_host = 'fullstory.com'
      window._fs_script = 'edge.fullstory.com/s/fs.js'
      window._fs_org = this.fullStoryOrg
      window._fs_namespace = 'FS'
      window._fs_run_in_iframe = true
      window._fs_ready = resolve;
      /* eslint-disable */
      (function(m,n,e,t,l,o,g,y){
          if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
          g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
          o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
          y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
          g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
          g.anonymize=function(){g.identify(!!0)};
          g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
          g.log = function(a,b){g("log",[a,b])};
          g.consent=function(a){g("consent",!arguments.length||a)};
          g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
          g.clearUserCookie=function(){};
          g.setVars=function(n, p){g('setVars',[n,p]);};
          g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
          if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
          g._v="1.3.0";
      })(window,document,window['_fs_namespace'],'script','user');
      /* eslint-enable */
    })

    return Promise.race([timeout, fs]).catch(error => {
      Log.error(`Fullstory init error [${error.message}]`)
    })
  }

  identify () {
    const {
      me: {
        id,
        email,
        is_guest: isGuest,
        program_level: programLevel,
        program_slug: programSlug,
      },
    } = meContext.select()

    if (this.mixpanelEnabled) {
      this._mixpanelInit.then(() => {
        mixpanel.identify(id)
        this.initMixPanelSuperProperties()
      })
    }
    if (this.brazeEnabled && isGuest === false) this.initializeBraze()
    if (this.fullStoryEnabled) this._fullStoryInit.then(() => FS.identify(id))

    PlatformAnalytics.setSuperProperties({ id, email, program_level: programLevel, program_slug: programSlug })

    if (process.env.SERVER_HOST !== 'localhost') {
      import('@sentry/browser').then(Sentry => {
        Sentry.configureScope(scope => {
          scope.setUser({ email, id })
        })
      })
    }

    if (!isGuest) {
      const { currentTreatment } = currentTreatmentContext.select()
      const { product_line: productLine, program } = currentTreatment ?? {}
      this.setUserVars({ productLine, program })
    }

    // if user gets reloaded (usually due to a login), re-identify and re-evaluate the super props
    meContext
      .awaitFlagOff('meIsLoaded')
      .then(() => meContext.awaitFlagOn('meIsLoaded').then(() => {
        this.identify()
      }))
  }

  async trackMixPanel (eventName, properties) {
    if (!this.mixpanelEnabled) return

    try {
      await this._mixpanelInit
      await new Promise(resolve => mixpanel.track(eventName, properties, resolve))
    } catch (error) {
      // swallow error
    }
  }

  async trackFullStory (eventName, properties) {
    await this._fullStoryInit
    if (this.fullStoryEnabled) FS.event(eventName, properties)
  }

  async setUserVarsInFullStory (properties) {
    await this._fullStoryInit
    if (this.fullStoryEnabled) FS.setUserVars(properties)
  }

  setUserVars (properties) {
    Log.info(`Set user vars in ${JSON.stringify(properties)}`)
    this.setUserVarsInFullStory(properties)
      .catch(error => { Log.error('Error setting FS user vars', error) })
    PlatformAnalytics.setSuperProperties(properties)
  }

  track (eventName, properties, callback) {
    properties = { ...properties, isHosted: NativeApp.isHosted() }

    if (NativeApp.isHosted()) {
      properties = { ...properties, hostedDeviceType: NativeApp.getDeviceType() }
    }

    Log.info(`Track analytics [${eventName}, ${JSON.stringify(properties)}]`)

    PlatformAnalytics.track(eventName, properties)
    Promise.all([
      this.trackMixPanel(eventName, properties),
      this.trackFullStory(eventName, properties),
    ]).finally(() => {
      if (isFunction(callback)) {
        callback()
      }
    })
  }

  trackPageView = () => {
    const {
      deep_link: deepLinkFlag,
    } = queryString.parse(window.location.search)

    const { href, pathname } = window.location
    const page = Pages.getPageAtLocation({ pathname }, true)

    if (deepLinkFlag) {
      // don't send FOLLOWED_LINK for activities
      if ((page?.predicate == null || page?.predicate) && Pages.getPageAtLocation({ pathname }) !== Pages.ACTIVITIES) {
        this.track(ANALYTICS_EVENTS.FOLLOWED_LINK, { from: 'deep_link', from_URL: href })
      }
    } else {
      this.track(ANALYTICS_EVENTS.VIEW, {
        url: href,
        ...(page?.analyticsPath && { path: page.analyticsPath }),
      })
    }
  }

  formatFromProperty = (from) => from.replace(/(activities|articles)\//, '').replaceAll('-', '_')

  trackLaunchedActivity = ({ slug, programActivityId, launchedFrom }) => {
    const {
      deep_link: deepLinkFlag,
    } = queryString.parse(window.location.search)
    const { href } = window.location
    const payload = {
      slug, programActivityId, from: this.formatFromProperty(launchedFrom),
    }
    if (deepLinkFlag) {
      payload.from = 'deep_link'
      payload.from_URL = href
    }
    this.track(
      ANALYTICS_EVENTS.LAUNCHED_ACTIVITY,
      payload,
    )
  }

  trackExitedActivity = ({ activityName, slug, stepSlug, completed, programActivityId, ...props }) => {
    this.track(
      ANALYTICS_EVENTS.EXITED_ACTIVITY,
      { activityName, slug, stepSlug, completed, programActivityId, ...props },
    )
  }

  trackButtonClick = (buttonName, callback, properties = {}) => {
    if (!buttonName) {
      Log.error('A button name is required when sending a button click event.')
      return
    }

    this.track(ANALYTICS_EVENTS.CLICKED, {
      url: window.location.href,
      buttonName: buttonName,
      ...properties,
    }, callback)
  }

  trackCheckEmployerSubmit = (props) => {
    this.track(ANALYTICS_EVENTS.CLICKED, props)
  }
}

export default new Analytics()
