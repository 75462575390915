import sortBy from 'lodash/sortBy'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel from '../ApiModel'
import { registerModelReducer } from '../reducers'
import { selectorCache } from '../util/selectorCache'
import { getUserModelIds, parseId, selectModelStore, userModelSelector } from './utilities'

const MODEL = new ApiModel('FEARS', 'api/v1/fears')
const ADMIN_MODEL = new ApiModel('ADMIN_FEARS', 'admin/api/v1/fears')

registerModelReducer('fear', (state = { readMyFears: false }, { type, response }) => {
  if (type === Action.CREATE.successType(MODEL)) {
    return { ...state, newestFearId: response.fear.id }
  } else if (type === Action.READ.successType(MODEL)) {
    return { ...state, readMyFears: true }
  }

  return state
})

const SHAPE = PropTypes.shape({
  id: PropTypes.number.isRequired,
  initial_description: PropTypes.string,
  smart_description: PropTypes.string,
  anxiety_rating: PropTypes.number,
  description: PropTypes.string,
  peak_anxiety_rating: PropTypes.number,
  exposure_complete: PropTypes.bool,
  exposure_plan_id: PropTypes.number,
})

const EMPTY = []
const actingIdsSelector = pendingSource => createSelector(
  [pendingSource],
  pending => {
    const acting = Object
      .entries(pending)
      .filter(([url, { failed }]) => url.startsWith(MODEL.baseUrl) && failed == null)
    // Return the same object every time if there are none, to prevent property thrashing
    if (acting.length === 0) return EMPTY

    return acting.map(([url]) => parseInt(parseId(MODEL, url)))
  },
)

const selectSavingIds = createSelector(
  [
    MODEL.isCreatingSelector(),
    actingIdsSelector(Action.UPDATE_MODIFY.pendingSource),
  ],
  (isCreating, updatingIds) => !isCreating && updatingIds.length === 0
    ? EMPTY
    : [...(isCreating ? [-1] : []), ...updatingIds],
)

const selectSorted = createSelector(
  [
    userModelSelector('me', 'fears'),
    state => state.models.fear.readMyFears,
  ],
  (fears, readMyFears) =>
    // The modelMap system doesn't know how to map to user_id when the response contains
    // an empty list with no models that have a user_id, so we have to track when our own read
    // request is complete so that we can correctly send back an empty array when it's complete
    // but the userModelSelector is returning null
    readMyFears && fears == null ? [] : fears && sortBy(fears.filter(fear => fear != null), 'anxiety_rating'),
)

const selectClientConnection = createSelector(
  [
    selectSorted,
    state => state.models.fear.newestFearId,
    selectSavingIds,
    actingIdsSelector(Action.DESTROY.pendingSource),
  ],
  (fears, newestFearId, fearsSaving, fearsDeleting) => ({
    fears,
    newestFearId,

    fearsSaving,
    fearsDeleting,

    saveFear: fear => fear.id == null
      ? MODEL.create({ fear }, { modelMapKey: ['user_id'] })
      : MODEL.updateModify({ fear }, { id: fear.id }),
    deleteFear: id => MODEL.destroy({ id }, { modelMapKey: ['user_id'] }),
  }),
)

const selectCoachConnection = selectorCache(
  ({ userId }) => userId,
  ({ userId }) => {
    if (userId == null) return () => ({ })

    const selectSortedFears = createSelector(
      [getUserModelIds(userId, 'fears'), selectModelStore('fears')],
      (ids, fears) => {
        if (ids != null && ids.length === 0) return []
        if (ids == null || fears == null) return null
        return ids
          .map(id => fears[id])
          .filter(fear => fear != null)
          .sort(({ anxiety_rating: ratingA }, { anxiety_rating: ratingB }) =>
            ratingA < ratingB ? 1 : (ratingB < ratingA ? -1 : 0))
      },
    )

    const selectFearsPending = createSelector(
      [Action.UPDATE_MODIFY.pendingSource, Action.CREATE.pendingSource],
      (updatePending, createPending) => {
        const updatePendingEntries = updatePending &&
          Object.entries(updatePending).filter(([url]) => url.startsWith(ADMIN_MODEL.baseUrl))
        const createUrl = ADMIN_MODEL.getUrl({ query: { user_id: userId } })
        const createPendingEntry = createPending && createPending[createUrl]

        const noUpdates = updatePendingEntries == null || updatePendingEntries.length === 0
        if (noUpdates && createPendingEntry == null) return null

        return [
          ...updatePendingEntries.map(([url, pending]) => ({ ...pending, url })),
          ...[createPendingEntry && { ...createPendingEntry, url: createUrl }],
        ]
      },
    )

    const createFear = (description, rating) => ADMIN_MODEL.create(
      { fear: { description, anxiety_rating: rating } },
      { query: { user_id: userId }, modelMapKey: ['user_id', userId] },
    )

    const deleteFear = fearId => ADMIN_MODEL.destroy(
      { id: fearId, modelMapKey: ['user_id', userId] },
    )

    const updateFear = (fearId, description, rating) => ADMIN_MODEL.updateModify(
      { fear: { id: fearId, description, anxiety_rating: rating } },
      { id: fearId },
    )

    const getFearId = ({ url, method }) => {
      const id = parseId(ADMIN_MODEL, url)
      if (id == null) return method === 'POST' ? -1 : null
      return parseInt(id)
    }

    return createSelector(
      [selectSortedFears, selectFearsPending],
      (fears, pendings) => ({
        fears,
        fearsUpdating: pendings == null
          ? []
          : pendings
            .map(pending => Action.isActing(pending) ? getFearId(pending) : null)
            .filter(id => id != null),

        createFear,
        deleteFear,
        updateFear,
      }),
    )
  },
)

export const Fears = {
  SHAPE,

  clientConnection: {
    load: ({ fears }) => {
      if (fears == null) MODEL.read({ modelMapKey: ['user_id'] })
    },

    isLoaded: ({ fears }) => fears != null,

    selector: selectClientConnection,

    shape: {
      // array of fears sorted by fear rating.
      fears: PropTypes.arrayOf(SHAPE).isRequired,
      newestFearId: PropTypes.number,

      // Will contain -1 if a new fear is saving.
      fearsSaving: PropTypes.arrayOf(PropTypes.number).isRequired,
      fearsDeleting: PropTypes.arrayOf(PropTypes.number).isRequired,

      saveFear: PropTypes.func.isRequired,
      deleteFear: PropTypes.func.isRequired,
    },
  },

  /**
   * model connection { userId }
   */
  coachConnection: {
    load: ({ fears }, { userId }) => {
      if (fears == null) {
        ADMIN_MODEL.read({
          query: { user_id: userId },
          modelMapKey: ['user_id', userId],
        })
      }
    },

    isLoaded: ({ fears }) => fears != null,

    selector: selectCoachConnection,

    shape: {
      fears: PropTypes.arrayOf(SHAPE).isRequired,
      fearsUpdating: PropTypes.arrayOf(PropTypes.number).isRequired,

      createFear: PropTypes.func.isRequired,
      deleteFear: PropTypes.func.isRequired,
      updateFear: PropTypes.func.isRequired,
    },
  },
}
