import moment from 'moment-timezone'

export const dateFormat = 'ddd, MMM D'

export const SESSION_DAY = 1
// US/Eastern timezone is the default timezone EnableTo operates with and to avoid discrepency,
// we request availability by parsing the start date we send, in that timezone
export const DEFAULT_TIMEZONE = 'US/Eastern'
export const DEFAULT_SLOT_MINUTES = 60
export const DEFAULT_PROVIDERS_TO_SHOW = 5
export const DEFAULT_SLOTS_TO_SHOW = 3
export const INFO_TYPES = {
  LAST_APPOINTMENT: 'closeToLastAppointment',
  NEXT_APPOINTMENT: 'closeToNextAppointment',
  NO_AVAILABILITY: 'noAvailability',
  NO_AVAILABILITY_FOR_LAST_SESSION: 'noAvailabilityForLastSession',
  PREVIOUS_PROVIDER_NO_AVAILABILITY: 'previousProviderNoAvailability',
}
export const DIALOG_BANNER_TYPES = {
  MOVE_TO_NEXT_WEEK: 'moveToNextWeek',
  NEXT_MID_SESSION: 'nextMidSession',
}

/**
 * The legacy 3.X system underlying the call to schedule an
 * IC appointment expects these deprecated timezone formats
 */
const ZONES = [
  { pattern: /AK[DS]T/i, label: 'US/Alaska' },
  { pattern: /A[DS]T/i, label: 'US/Atlantic' },
  { pattern: /C[DS]T/i, label: 'US/Central' },
  { pattern: /E[DS]T/i, label: 'US/Eastern' },
  { pattern: /H[DS]T/i, label: 'US/Hawaii' },
  { pattern: /M[DS]T/i, label: 'US/Mountain' },
  { pattern: /P[DS]T/i, label: 'US/Pacific' },
]

export function getLegacyTimezone () {
  const timezone = moment.tz.guess()
  const zoneAbbr = moment.tz(timezone).zoneAbbr()
  return ZONES.find(({ pattern }) => pattern.test(zoneAbbr))?.label ?? 'US/Arizona'
}

// Normalize data used for E2 requests, providing expected timezone and date format
export const normalizeDateAndTimezone = (date, format) => {
  const timezone = getLegacyTimezone()
  const datetime = date != null
    ? moment.tz(date, timezone).set({ second: 0, millisecond: 0 }).format(format)
    : date

  return { datetime, timezone }
}

export const getElementsQuantity = (maxAvailableSlotsPerRow, showMore, timeSlots, showAllAvailableSlots) => {
  maxAvailableSlotsPerRow = Math.max(maxAvailableSlotsPerRow, DEFAULT_SLOTS_TO_SHOW)
  const slotsToShow = showMore ? timeSlots.length : DEFAULT_SLOTS_TO_SHOW

  return showAllAvailableSlots ? maxAvailableSlotsPerRow : slotsToShow
}

export const isEven = (index) => index % 2 === 0

export const blockWithinTime = (time, block) => {
  switch (time) {
    case 'morning':
      return block.period.begin.hour() >= 6 && block.period.begin.hour() < 12
    case 'afternoon':
      return block.period.begin.hour() >= 12 && block.period.begin.hour() < 18
    case 'evening':
      return (block.period.begin.hour() >= 18 && block.period.begin.hour() < 24) ||
        (block.period.begin.hour() >= 0 && block.period.begin.hour() < 6)
    default:
      return true
  }
}

export const blocksWithinTime = (time, allBlocks) => {
  return allBlocks.filter(block => blockWithinTime(time, block))
}

export const blocksInDay = (day, allBlocks) => {
  return allBlocks.filter(({ period }) => moment(period.begin).isSame(day, 'day'))
}

export const getInfoBannerType = (day, offsetRules) => {
  if (offsetRules.midSessionSoftGuard != null && day.isSameOrAfter(offsetRules.midSessionSoftGuard, 'day')) {
    return DIALOG_BANNER_TYPES.NEXT_MID_SESSION
  }

  return null
}

export const getInfoMessageType = (day, offsetRules) => {
  if (day.isSame(offsetRules.previousSessionGuard, 'day')) return INFO_TYPES.LAST_APPOINTMENT
  if (day.isSame(offsetRules.nextSessionGuard, 'day')) return INFO_TYPES.NEXT_APPOINTMENT

  return null
}

export const getAvailabilityType = (provider, schedulingType, offsetRules, isLastSessionCurrent) => {
  if (schedulingType === 'scheduleFua') {
    return provider.isPreviousProvider && provider.slots.length === 0
      ? INFO_TYPES.PREVIOUS_PROVIDER_NO_AVAILABILITY
      : null
  }

  if (schedulingType !== 'rescheduleSession') return null

  if (provider.slots.length === 0 && isLastSessionCurrent) return INFO_TYPES.NO_AVAILABILITY_FOR_LAST_SESSION
  if (provider.slots.length === 0 && offsetRules.nextSessionGuard != null) return INFO_TYPES.NO_AVAILABILITY

  return null
}

export const getNextAvailableSlot = (provider, lastVisibleDate, selectedFilter) =>
  provider.slots.find(
    slot => slot.period.begin.isAfter(lastVisibleDate, 'day') && blockWithinTime(selectedFilter, slot),
  )

export const getInsuranceProviderName = (e2profileResponse, me) => {
  const hasMultipleLob = me?.campaign_detail?.has_multiple_lob
  const providerNameBasedOnLob =
    hasMultipleLob ? me?.eligibility_client_name : me?.campaign_detail?.eligibility_details?.payer_id

  return e2profileResponse?.data?.company_name ?? providerNameBasedOnLob
}

export const getUserState = (e2profileResponse, profile) =>
  e2profileResponse.data?.mailing_address?.state ??
  profile?.state

export const getAvailabilityStartDate = (isRescheduleSession, shouldUseQueryParams, queryAppointmentTime, timezone) => {
  const defaultStartDate = isRescheduleSession ? moment.tz(timezone) : moment.tz(timezone).add(1, 'days')

  if (shouldUseQueryParams) {
    const parsedQueryDate = moment.tz(queryAppointmentTime, timezone)
    // When using the query data, we want to display at least 10 days before the session we reschedule
    parsedQueryDate.subtract(10, 'days')

    // If the desired start date is in the past, we show the default start date
    return parsedQueryDate.diff(defaultStartDate, 'hours') < 0 ? defaultStartDate : parsedQueryDate
  }

  return defaultStartDate
}

/**
 * @param {*} sessionProtocolCode code of the session which is currently being rescheduled
 * @param {*} sessionsData sessions data from E2 request(dates are parsed to *moment* objects)
 * @param {*} shouldGetCoachSessions activity option indicating if we should consider coach sessions
 * @returns object containing e2 sessions data in comfortable form to be used for the activity purpose
 *  - `currentSession` - the session being rescheduled
 *  - `previousSession` - the prior session to the one being rescheduled
 *  - `nextSession` - the next session of same type with the one being rescheduled
 *  - `midSession` - the next session of type different than the one being rescheduled
 *  - `session` - sessions subject of the rescheduling activity, sorted by date
 */
export const normalizeSessionsData = (sessionProtocolCode, sessionsData, options) => {
  if (sessionsData == null || sessionProtocolCode == null) return null

  const { shouldGetCoachSessions, shouldUseMidSession } = options

  /**
   * Sessions sorted by date and filtered, if necessary.
   * For performance benefit:
   *  - filter is ran before sort
   *  - sort by date is done via Moment.valueOf() - Moment.valueOf() instead of Moment.diff(Moment)
   */
  const sessions = (shouldGetCoachSessions
    ? sessionsData
    : sessionsData.filter(session => !(/^\d+B$/.test(session.code))))
    .sort((prev, next) => prev.start_time.valueOf() - next.start_time.valueOf())

  const [, number, type] = sessionProtocolCode.match(/(\d)([AB])/)
  const nextSessionCode = `${parseInt(number) + 1}${type}`
  const midSessionCode = (() => {
    if (!(shouldUseMidSession && shouldGetCoachSessions)) return

    return type === 'B' ? `${parseInt(number) + 1}A` : `${number}B`
  })()

  const nextSession = sessions.find(session => session.code === nextSessionCode)
  const midSession = midSessionCode != null
    ? sessions.find(session => session.code === midSessionCode)
    : null

  const currentSessionIndex = sessions.findIndex(session => session.code === sessionProtocolCode)

  return {
    currentSession: sessions[currentSessionIndex],
    previousSession: sessions[currentSessionIndex - 1],
    nextSession,
    midSession,
    sessions,
  }
}

/**
 * @param {*} normalizedSessionsData Object with calculated currentSession, previousSession and nextSession if any
 * @param {*} availabilityRules Rescheduling rules defined on the BE activity config
 * @param {*} availabilityStartDate The initial start date we would display availability
 * @param {*} timezone The timezone to be used, when parsing dates
 * @returns object with the following attributes:
 *  - `previousSessionGuard` - date too close to the previous session(should display message on the calendar)
 *  - `nextSessionGuard` - date too close to the next session(should display message on the calendar)
 *  - `midSessionSoftGuard` - date from which subsequent sessions will be affected(additional info banners are shown)
 *  - `firstOpenSlot` - the first date on the calendar with visible slots
 *  - `lastOpenSlot` - the last date on the calendar with visible slots
 *  - `startCalendarDate` - the first date to be shown on the calendar
 *  - `endCalendarDate` - the last date to be shown on the calendar
 */
export const getCalendarViewRules = (
  normalizedSessionsData,
  availabilityRules,
  availabilityStartDate,
  timezone,
  daysOut,
) => {
  const defaultRules = {
    startCalendarDate: availabilityStartDate.clone(),
    endCalendarDate: availabilityStartDate.clone().add(daysOut, 'days'),
  }

  if (availabilityRules == null || normalizedSessionsData == null) return defaultRules
  const { daysBeforeAppointment = 0, daysAfterAppointment = 0 } = availabilityRules

  if (moment.tz(normalizedSessionsData.currentSession.start_time, timezone).diff(moment.tz(timezone), 'hours') < 0) {
    return defaultRules
  }

  let firstOpenSlot = availabilityStartDate.clone().startOf('day')
  let lastOpenSlot
  let previousSessionGuard = null
  let nextSessionGuard = null

  if (normalizedSessionsData.previousSession != null) {
    if (daysAfterAppointment > 0) {
      previousSessionGuard = moment.tz(normalizedSessionsData.previousSession.start_time, timezone)
        .startOf('day')
        .add(daysAfterAppointment, 'days')
    }

    const firstOpenDay = moment.tz(normalizedSessionsData.previousSession.start_time, timezone)
      .startOf('day')
      .add(daysAfterAppointment + SESSION_DAY, 'days')

    if (firstOpenDay.diff(firstOpenSlot, 'days') > 0) firstOpenSlot = firstOpenDay
  }

  if (normalizedSessionsData.nextSession != null) {
    lastOpenSlot = moment.tz(normalizedSessionsData.nextSession.start_time, timezone)
      .endOf('day')
      .subtract(daysBeforeAppointment + SESSION_DAY, 'days')
    nextSessionGuard = moment.tz(normalizedSessionsData.nextSession.start_time, timezone)
      .startOf('day')
      .subtract(daysBeforeAppointment, 'days')
  /*
    When there is no next session of the same type, we first check if there is a mid-session
    and add 6 days of availability past that session date. We do not go over 6 days,
    because if the user choose to reschedule on any of these dates, their mid-session will be moved out a week
    and we dont want the mid-session and the current session to end up on the same date(or flip order).
    If there is no mid-session, this means the current session is the last session of the treatment,
    in which case we add 7 days of availability past the current session date.
    Examples:
      - If the current session is 7B, we add 6 days from the date of 8A.
      - If the current session is 8A, we add 7 days.
  */
  } else if (normalizedSessionsData.midSession != null) {
    lastOpenSlot = moment.tz(normalizedSessionsData.midSession.start_time, timezone)
      .endOf('day')
      .add(6, 'days')
  } else {
    lastOpenSlot = moment.tz(normalizedSessionsData.currentSession.start_time, timezone)
      .endOf('day')
      .add(7, 'days')
  }

  const midSessionSoftGuard = normalizedSessionsData.midSession != null
    ? moment.tz(normalizedSessionsData.midSession.start_time, timezone)
    : null

  // TODO: Once we start supporting rescheduling sessions in the future, moment.tz(timezone) should be replaced
  // with availabilityStartDate.clone()* to ensure we dont end up in a case where there is a gap of empty dates
  // between the previous session date and the date our availability starts.
  // * Moment.startOf() mutates the moment object it is called on, this is why we make a clone()
  const startCalendarDate = previousSessionGuard != null &&
    previousSessionGuard.diff(moment.tz(timezone).startOf('day'), 'days') >= 0
    ? previousSessionGuard
    : firstOpenSlot

  return {
    previousSessionGuard,
    nextSessionGuard,
    midSessionSoftGuard,
    firstOpenSlot,
    lastOpenSlot,
    startCalendarDate,
    endCalendarDate: nextSessionGuard ?? lastOpenSlot,
  }
}

export const getMoveSessionTo = (normalizedSessionsData, timezone) => {
  if (normalizedSessionsData == null || normalizedSessionsData.nextSession == null) return null

  return {
    date: moment.tz(normalizedSessionsData.nextSession.start_time, timezone),
    period: {
      begin: moment.tz(normalizedSessionsData.nextSession.start_time, timezone),
      end: moment.tz(normalizedSessionsData.nextSession.end_time, timezone),
    },
    infoBanner: { type: DIALOG_BANNER_TYPES.MOVE_TO_NEXT_WEEK },
    isExtendingTreatment: true,
  }
}

export const getCanExtendSession = ({
  isRescheduleSession,
  reschedulingRules,
  sessionReschedulingRecords,
  totalTreatmentExtendedTimes,
  sessionProtocolCode,
}) => {
  if (!isRescheduleSession) return false
  if (reschedulingRules == null) return true
  if (sessionReschedulingRecords == null && totalTreatmentExtendedTimes == null) return null

  const canExtendTreatment = reschedulingRules.maxExtendTotal == null ||
    reschedulingRules.maxExtendTotal > totalTreatmentExtendedTimes

  const sessionReschedulingRecord = sessionReschedulingRecords
    .find(record => record.session_code === sessionProtocolCode)

  const canExtendSession = reschedulingRules.maxExtendSession == null ||
    sessionReschedulingRecord == null ||
    reschedulingRules.maxExtendSession > sessionReschedulingRecord.treatment_extended_times

  return canExtendTreatment && canExtendSession
}

/**
 * Rule functions take the following parameters:
 *  - a slot object: { period: { begin: MomentDate, end: MomentDate } }
 *  - a rule value, defined in the activity config options, e.g. "daysBeforeAppointment(rule): 2(value)"
 *  - an object with additional dependencies
 *
 * Should return true if the slot match the rule criteria
 */
const daysBeforeAppointment = ({ period }, _value, { lastOpenSlot }) => {
  return period.end?.isSameOrBefore(lastOpenSlot)
}

const daysAfterAppointment = ({ period }, _value, { firstOpenSlot }) => {
  return period.begin?.isSameOrAfter(firstOpenSlot)
}

const removeSlotsWithinHours = ({ period }, hoursToExceed, { timezone }) => {
  const timeToExceed = moment.tz(timezone).add(hoursToExceed, 'hours')
  return period.begin.isSameOrAfter(timeToExceed, 'minute')
}

const startFromTomorrow = ({ period }, _value, { tomorrow }) => {
  return period.begin.isSameOrAfter(tomorrow)
}

// List of slot filter(rule) functions
// Names must match with a key in the activity backend option "availabilityRules",
// or custom filters applied in the frontend
export const slotFilters = {
  // BE rules
  daysBeforeAppointment,
  daysAfterAppointment,
  removeSlotsWithinHours,

  // FE filters
  startFromTomorrow,
}
