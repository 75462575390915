import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'
import { registerModelReducer } from '../reducers'
import Action from '../Action'

const MODEL = new ApiModel('HEALTH_HABITS', 'api/v1/health_habits')

registerModelReducer('healthHabits',
  (state = { loadedMine: false, healthHabits: [] }, { type, response }) => {
    if (type === Action.READ.successType(MODEL)) {
      return { ...state, loadedMine: true, healthHabits: response.health_habits }
    }

    if (type === Action.DESTROY.successType(MODEL)) {
      return { ...state, loadedMine: false, healthHabits: [] }
    }

    const habitResponse = response?.health_habits?.health_habit

    // If we have just disabled the habit (soft deleted it), remove it from the state
    if (type === Action.UPDATE_MODIFY.successType(MODEL) && habitResponse?.status === 'disabled') {
      const filteredHealthHabits = state.healthHabits
        .filter((habit) => habit.health_habit_id !== habitResponse?.id)

      return { ...state, healthHabits: filteredHealthHabits }
    }

    if (type === Action.UPDATE_MODIFY.successType(MODEL)) {
      // Replace with the updated habit object
      const healthHabits = state.healthHabits
        .map((habit) => habit.health_habit_id === habitResponse?.id ? habitResponse : habit)

      return { ...state, healthHabits }
    }

    return state
  },
)

export const SHAPE = PropTypes.shape({
  actual: PropTypes.number,
  default_habit: PropTypes.boolean,
  description: PropTypes.string,
  entry_date: PropTypes.string,
  goal: PropTypes.number,
  habit_type: PropTypes.string,
  icon_name: PropTypes.string,
  is_positive: PropTypes.boolean,
  status: PropTypes.string,
  title: PropTypes.string,
  unit: PropTypes.string,
  user_id: PropTypes.number,
})

const select = state => state.models.healthHabits.healthHabits
const selectIsLoaded = state => state.models.healthHabits.loadedMine

const createHealthHabit = async (habit) => await MODEL.create({ health_habit: habit })

const selectAvailableConnection = createSelector(
  [select, selectIsLoaded],
  (healthHabits, loaded) => ({
    healthHabits,
    loaded,
    createHealthHabit,
    updateLookBack: date => MODEL.read({ query: { date } }),
    deleteHealthHabit: async id => MODEL.updateModify({ health_habit: { status: 'disabled' } }, { id }),
    editHabit: async (habit, id) => MODEL.updateModify({ health_habit: habit }, { id }),
  }),
)

export const HealthHabits = {
  SHAPE,
  connection: {
    load: ({ loaded }, { date }) => {
      if (!loaded && date != null) MODEL.read({ query: { date } })
    },

    isLoaded: ({ loaded }) => loaded,

    selector: selectAvailableConnection,

    shape: {
      healthHabits: PropTypes.arrayOf(SHAPE),
      loaded: PropTypes.bool,
      deleteHealthHabit: PropTypes.func.isRequired,
      editHabit: PropTypes.func.isRequired,
      createHealthHabit: PropTypes.func.isRequired,
      updateLookBack: PropTypes.func.isRequired,
    },
  },
}
