import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'
import get from 'lodash/get'
import Action from '../Action'
import { ApiFailure, Connection } from './types'
import { ApiState } from '../types'

/* eslint-disable camelcase */
type Otp = {
  status: 'enabled' | 'disabled' | 'pending',
  uri?: string,
  backup_codes?: string[],
}
/* eslint-enable camelcase */

type OtpConnection = {
  otp?: Otp,
  otpUpdateFailed?: ApiFailure,
  otpIsUpdating: boolean,
  clearFailedOtpUpdate: () => void,
  otpEnable: (attempt: string) => void,
  otpDisable: () => void,
}

type OtpSetup = {
  status?: 'disabled',
  attempt?: string
}

const MODEL = new ApiModel('OTP', 'api/v1/otp')

const update = (otp: OtpSetup, userId = 'me') => MODEL.updateModify({ otp }, userOptions(userId))
const load = (userId = 'me') => MODEL.read(userOptions(userId))
const select = (userId = 'me') => (state: ApiState) =>
  get(state.modelMap, ['user_id', userId, 'otp']) as Otp | undefined

const userOptions = (userId = 'me') => {
  return { query: { user_id: userId }, modelMapKey: ['user_id', userId] }
}

const updateFailed = (userId = 'me') => (state: ApiState) => {
  const url = MODEL.getUrl(userOptions(userId))
  const pending = Action.UPDATE_MODIFY.getPending(state, url)
  return Action.getError(pending)
}

const clearFailedUpdate = (userId = 'me') => () => {
  const url = MODEL.getUrl(userOptions(userId))
  const action = Action.UPDATE_MODIFY.getClearPendingAction(MODEL, url)
  return ApiModel.dispatch(action)
}

const selectConnection = createSelector(
  [
    select(),
    updateFailed(),
    MODEL.isUpdatingSelector(userOptions()),
  ],
  (otp, otpUpdateFailed, otpIsUpdating): OtpConnection => ({
    otp,
    otpUpdateFailed,
    otpIsUpdating,

    clearFailedOtpUpdate: clearFailedUpdate(),
    otpEnable: (attempt: string) => void update({ attempt }),
    otpDisable: () => {
      ApiModel.pruneCache('otp')
      void update({ status: 'disabled' }).then(() => load())
    },
  }),
)

export const Otp: {
  connection: Connection<OtpConnection>,
} = {
  connection: {
    load: ({ otp, otpIsUpdating }) => {
      if (otp == null && !otpIsUpdating) void load()
    },

    isLoaded: ({ otp }) => otp != null,

    selector: selectConnection,
  },
}
