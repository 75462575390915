import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'

const MODEL = new ApiModel('ACTIVITIES', 'api/v1/activities')

const SHAPE = PropTypes.shape({
  slug: PropTypes.string.isRequired,
  start_path: PropTypes.string,
  name: PropTypes.string,
  category: PropTypes.string,
  description: PropTypes.string,
  email_description: PropTypes.string,
  first_time_description: PropTypes.string,
})

const selectActivities = state => state.api.activities

export const Activities = {
  connection: {
    load: ({ activities }) => {
      if (activities == null) MODEL.read()
    },

    isLoaded: ({ activities }) => activities != null,

    selector: createSelector([selectActivities], activities => ({ activities })),

    shape: {
      activities: PropTypes.arrayOf(SHAPE).isRequired,
    },
  },
}
