import { connectionContext } from '@app/js/components/modelConnect'
import { i18nWithNamespace } from '@app/js/lib/i18nNamespace'
import { Profile, ProgramActivity, Promotion, User, Branch, Authentication } from 'joyable-js-api'

export const promotionContext = connectionContext(Promotion.connection)
export const promotion = () => promotionContext.select().promotion

export const profileContext = connectionContext(Profile.myProfileConnection)
export const profile = () => profileContext.select().profile

export const hotlineNumber = () => promotionContext.select().promotion?.hotlines?.[0]?.number || ''

export const meContext = connectionContext(User.meConnection)

export const authenticationContext = connectionContext(Authentication.isLoggedInConnection)

export const redirectBranchContext = connectionContext(Branch.redirectBranchConnection)

export const getMe = () => meContext.select().me

export const getExperiments = () => meContext.select().me.experiments

export const getCampaign = () => meContext.select().me.campaign_detail

export const getIsLoggedIn = () => authenticationContext.select().isLoggedIn

export const programActivitiesContext = connectionContext(ProgramActivity.connection)

export const succeedingActivity = (currentSlug, currentProgramActivityId) => {
  const programActivities = programActivitiesContext.select().programActivities ?? []
  const currentIndex = programActivities.findIndex(
    ({ slug, program_activity_id: programActivityId }) =>
      slug === currentSlug &&
        (currentProgramActivityId == null || programActivityId === currentProgramActivityId),
  )
  if (currentIndex < 0 || currentIndex === programActivities.length - 1) return null
  return programActivities[currentIndex + 1]
}

export const activitiesI18nContext = connectionContext(i18nWithNamespace('activities'))

export const activitiesT = (key, options) => activitiesI18nContext.select().t(key, options)
