/* eslint-disable lines-between-class-members */
import { Enum } from '@app/js/lib/enum'
import { awaitConnection, Promotion, User, UserActivity, Profile } from 'joyable-js-api'
import kebabCase from 'lodash/kebabCase'
import snakeCase from 'lodash/snakeCase'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { generatePath, matchPath, Redirect, Route } from 'react-router-dom'
import { compose } from 'redux'
import { E2_USER_ROLES } from '../constants'
import Loading from '../components/icons/Loading'
import { useLastCompletedActivity, useParticipant, useQueryParamsSnapshot } from '../lib/hooks'
import DelayedPage from './DelayedPage'
import Pages from './Pages'
import Requirement from './Requirement'
import queryString from 'query-string'
import { setDeepLinkedLocation } from '../store/ui'

// snakeCase and kebabCase put a mark in front of numbers, and we want to avoid that in our activity
// paths and slugs that match the patterns in the NUMBER_SNUGGLE list. Other cases of numbers in
// activity slugs are expected to go ahead and prefix the number with an underscore or dash, as is
// the default in snakeCase() and kebabCase()
const NUMBER_SNUGGLE = [
  { pattern: 'gad[-_]7', replacement: 'gad7' },
  { pattern: 'phq[-_]9', replacement: 'phq9' },
  { pattern: 'v[-_]2', replacement: 'v2' },
].map(({ pattern, replacement }) => ({
  // Take the simple pattern from above, and expand it to match correctly against line starts,
  // line ends, and wrapping delineators (_ or -)
  pattern: new RegExp(`(^|[-_])${pattern}($|[-_])`, 'g'),
  replacement: `$1${replacement}$2`,
}))
const replacePattern = ({ pattern, replacement }) => string => string.replace(pattern, replacement)
const replaceNumberSnuggles = compose(...NUMBER_SNUGGLE.map(replacePattern))
const kebabSlug = slug => slug && replaceNumberSnuggles(kebabCase(slug))
const snakePath = path => path && replaceNumberSnuggles(snakeCase(path))

const customActivityPath = slug => Activities.enumValues.find(page => page.slug === slug)?.path
const genericActivityPath = (slug, pathSpec) =>
  generatePath(pathSpec, { activityPath: kebabSlug(slug) })
export const activityPath = (slug, pathSpec = Pages.ACTIVITIES.path) =>
  customActivityPath(slug) ?? genericActivityPath(slug, pathSpec)

export const activityPathWithProgramActivityID = (slug, programActivityId) =>
  `${activityPath(slug)}/?program_activity_id=${programActivityId}`

// Allows for check if user is redirected from a deep linked location
export const deepLinkRedirectActivityPath = ({ dispatch, location: { pathname, search } = { } }, slug) => {
  const fromDeepLink = queryString.parse(search).deep_link
  if (fromDeepLink) {
    dispatch(setDeepLinkedLocation(`${pathname.slice(1)}${search}`))
  }
  return activityPath(slug)
}

const customActivitySlug = path =>
  Activities.enumValues.find(page => page.path.split('/')[1] === path.split('/')[1])?.slug
const genericActivitySlug = (path, pathSpec) =>
  snakePath(matchPath(path, { path: pathSpec })?.params?.activityPath)
export const activitySlug = (path, pathSpec = Pages.ACTIVITIES.path) =>
  customActivitySlug(path) ?? genericActivitySlug(path, pathSpec)

const userRedirect = predicate => () =>
  awaitConnection(User.meConnection, null, ({ me }) => me != null).then(
    ({ me }) => !predicate(me) ? <Redirect to={Pages.DASHBOARD.path} /> : null,
  )

const loadMeAndRedirect = predicate => () => {
  awaitConnection(User.meConnection).then(({ loadMe, me }) => {
    loadMe()

    return !predicate(me) ? <Redirect to={Pages.DASHBOARD.path} /> : null
  })
}

export async function useProviderOnboardingRedirect () {
  const { me } = await awaitConnection(User.meConnection, null, ({ me }) => me != null)
  return me?.role !== 'client'
    ? <Redirect to={Pages.ERROR.path + '?message_key=onboarding&error_type=provider'} />
    : null
}

export async function useCommunityOnboardingRedirect (to, from) {
  const { profile } = await awaitConnection(Profile.myProfileConnection, null, ({ profile }) => profile != null)
  return profile?.accepts_community_guidelines !== true || !profile?.community_nickname
    ? <Redirect to={`${activityPath(to)}?launched_from=${from}`} />
    : null
}

const usesSingleFrontDoorRedirect = () =>
  awaitConnection(Promotion.connection).then(({ promotion }) =>
    promotion?.features?.includes('single_front_door')
      ? <Redirect to={genericActivityPath(promotion?.initial_activity ?? 'warm_up', Pages.PLACEMENT.path)} />
      : null)

const dashboardIfCompleteRedirect = activitySlug => () =>
  awaitConnection(UserActivity.anyLatestConnection, { activitySlug }).then(({ userActivity }) =>
    userActivity === UserActivity.NOT_FOUND || userActivity.state !== 'completed'
      ? null
      : <Redirect to={Pages.DASHBOARD.path} />)

const sessionsOrDashboardIfCompleteRedirect = activitySlug => () =>
  awaitConnection(Promotion.connection).then(({ promotion }) =>
    promotion?.features?.includes('referrals_display_safety_screener')
      ? awaitConnection(UserActivity.anyLatestConnection, { activitySlug }).then(({ userActivity }) =>
          userActivity === UserActivity.NOT_FOUND || userActivity.state !== 'completed'
            ? null
            : <Redirect to={Pages.DASHBOARD.path} />)
      : awaitConnection(UserActivity.anyLatestConnection, { activitySlug }).then(({ userActivity }) =>
        userActivity === UserActivity.NOT_FOUND || userActivity.state !== 'completed'
          ? null
          : <Redirect to={Pages.SESSIONS.path} />))

const takeFirst = (...redirectors) => async () => {
  for (const redirector of redirectors) {
    const redirect = await redirector()
    if (redirect != null) return redirect
  }
  return null
}

// This enum only contains activities that need some sort of customization to how their rendered, or
// what their path is. Activities that act like normal program activities are handled generically
// and are mounted under the /activities/ root path.
export default class Activities extends Enum {
  // Common activities
  static TRIAGE = new Activities({
    requirement: Requirement.NONE,
    redirect: dashboardIfCompleteRedirect('triage'),
  })
  static TRIAGE_RESULTS = new Activities({ requirement: Requirement.LOGIN })
  static RETRIAGE = new Activities()
  static FINALIZE_ACCOUNT_PREP = new Activities({
    requirement: Requirement.NONE,
    redirect: dashboardIfCompleteRedirect('finalize_account_prep'),
  })
  static SETUP = new Activities({
    requirement: Requirement.LOGIN,
    redirect: loadMeAndRedirect(user => !user.onboarding_complete),
  })
  static CONCIERGE_PREFERENCES = new Activities({
    requirement: Requirement.LOGIN,
    redirect: userRedirect(user => !user.onboarding_complete),
  })
  static FIT = new Activities({
    requirement: Requirement.NONE,
    redirect: takeFirst(useProviderOnboardingRedirect, usesSingleFrontDoorRedirect, dashboardIfCompleteRedirect('fit')),
  })
  static FIT_RESULTS = new Activities({
    requirement: Requirement.LOGIN,
    redirect: takeFirst(useProviderOnboardingRedirect, dashboardIfCompleteRedirect('fit_results')),
  })
  static INSIGHT = new Activities({
    requirement: Requirement.NONE,
    redirect: dashboardIfCompleteRedirect('insight'),
  })
  static INSIGHT_RESULTS = new Activities({
    requirement: Requirement.LOGIN,
    redirect: dashboardIfCompleteRedirect('insight_results'),
  })
  static PARTNER_SETUP = new Activities({
    requirement: Requirement.LOGIN,
    redirect: dashboardIfCompleteRedirect('partner_setup'),
  })
  static TERAPIA = new Activities({
    requirement: Requirement.NONE,
    redirect: dashboardIfCompleteRedirect('terapia'),
  })
  static TERAPIA_RESULTS = new Activities({
    requirement: Requirement.LOGIN,
    redirect: dashboardIfCompleteRedirect('terapia_results'),
  })
  static REFERRALS_WELCOME_TO_ABLETO = new Activities({
    requirement: Requirement.LOGIN,
    redirect: sessionsOrDashboardIfCompleteRedirect('referrals_welcome_to_ableto'),
  })

  static EMPATHIA_INTAKE = new Activities({ requirement: Requirement.NONE })

  static TPLUS_GAD7 = new Activities({ allowedRoles: E2_USER_ROLES })
  static TPLUS_PHQ9 = new Activities({ allowedRoles: E2_USER_ROLES })
  static TPLUS_FUNCTIONAL_IMPAIRMENT =
    new Activities({ allowedRoles: E2_USER_ROLES })

  static _ = this.closeEnum()

  constructor ({
    redirect,
    requirement = Requirement.CHAT_ONBOARDING,
    allowedRoles = null,
  } = {}) {
    super()
    this._requirement = requirement
    this._allowedRoles = allowedRoles
    this._redirect = redirect
  }

  get route () {
    return <Route path={this.path} key={this.enumKey} render={this.renderResolver} />
  }

  get slug () {
    this._slug ??= snakePath(this.enumKey)
    return this._slug
  }

  get path () {
    this._path ??= `/${kebabSlug(this.enumKey)}`
    return this._path
  }

  get requirement () {
    return this._requirement
  }

  get allowedRoles () {
    return this._allowedRoles
  }

  renderResolver = () => (
    <ActivityResolver
      requirement={this.requirement}
      allowedRoles={this.allowedRoles}
      redirect={this._redirect}
      activitySlug={this.slug}
    />
  )
}

async function loadActivity ({ redirect, activitySlug, programActivityId }) {
  const [{ default: Activity }, redirectResult] = await Promise.all([
    import('../components/activities/Activity'),
    redirect?.() || Promise.resolve(null),
  ])

  return redirectResult || <Activity {...{ activitySlug, programActivityId }} />
}

function ActivityResolver ({ requirement, allowedRoles, redirect, activitySlug }) {
  const { program_activity_id: programActivityId, is_read_only: readOnly, ...rest } =
    useQueryParamsSnapshot()
  const [participantLoaded, { participant, participantError }] = useParticipant()
  const { user_id: userId } = participant || {}
  const { session_id: sessionId } = rest
  const activityPage = useMemo(
    () => {
      const load = () => loadActivity({ redirect, activitySlug, programActivityId })
      return <DelayedPage {...{ requirement, allowedRoles, load }} />
    },
    [redirect, activitySlug, programActivityId, requirement, allowedRoles],
  )

  if (!participantLoaded) {
    return <Loading fullPage={true} />
  } else if (participantError != null) {
    return Pages.ERROR.redirect({ message_key: participantError, link_help: true })
  } else if (readOnly === 'true') {
    return Pages.ACTIVITY_RESULTS.redirect({ ...rest, activity_slug: activitySlug })
  } else if (sessionId == null || userId == null) {
    return activityPage
  } else {
    return <CheckSession {...{ activitySlug, userId, sessionId, activityPage }} />
  }
}

ActivityResolver.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
  requirement: PropTypes.oneOf(Requirement.values),
  redirect: PropTypes.func,
  activitySlug: PropTypes.string,
}

function CheckSession ({ activitySlug, userId, sessionId, activityPage }) {
  const predicate = useCallback(ua => ua.content.session_id === sessionId, [sessionId])
  const [loaded, userActivity] = useLastCompletedActivity({ activitySlug, userId, predicate })
  const readOnly = queryString.parse(window.location.search).is_read_only === 'true'

  if (!loaded) {
    return <Loading fullPage={true} />
  } else if (userActivity == null) {
    return activityPage
  } else {
    return Pages.ACTIVITY_RESULTS.redirect({ user_activity_id: userActivity.id, is_read_only: readOnly })
  }
}

export const goToLogin = () => {
  if (window.NativeApp.isHosted()) {
    // Close the web view and go to the Enter Email screen in RN
    return window.NativeApp.exitActivity('enterEmail')
  } else if (window.NativeiOSApp.isHosted()) {
    // Close the web view and go to the Enter Email screen in iOS
    return window.NativeiOSApp.exitActivity('enterEmail')
  }
  Pages.LOGIN.go()
}

CheckSession.propTypes = {
  activitySlug: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  sessionId: PropTypes.string.isRequired,
  activityPage: PropTypes.node.isRequired,
}
