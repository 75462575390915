import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useConnection } from '@app/js/lib/useConnection'
import { skipNullQueryString } from '@app/js/lib/queryString'
import { activityPath } from '@app/js/routing/Activities'
import Pages from '@app/js/routing/Pages'
import analytics from '@app/js/services/analytics'
import { Participant, Promotion, User, UserActivity } from 'joyable-js-api'
import isEqual from 'lodash/isEqual'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import isNull from 'lodash/isNull'
import defaultTo from 'lodash/defaultTo'
import flow from 'lodash/flow'
import queryString from 'query-string'
import { Dialog } from '../services/Dialog'
import { getScrollPosition, setScrollPosition } from '../store/ui'
import { isMobile } from './browserSize'
import dateCompare from './dateCompare'
import findFirst from './findFirst'
import { getPrevPageSlug } from '@app/js/components/pages/Login/utilities'

/**
 * Uses a ref and an effect to return the previous value that was passed to this hook each time
 * it's called. Useful for tracking state/props changes.
 */
export function usePrevious (value) {
  const ref = useRef()
  useEffect(() => { ref.current = value }, [value])
  return ref.current
}

/**
 * Use ref and lodash isEqual to deep compare value, returning previous, if there are no changes.
 * Useful for accurate tracking of dependency changes.
 */
export function useDeepCompareMemo (value) {
  const ref = useRef()

  if (!isEqual(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

/**
 * A simple state machine hook. Uses useEffect internally so that it's evaluated after every render.
 * May return an undefined state in order to terminate processing.
 *
 * @param start The name of the initial state.
 * @param definitions An object that maps state names to a function that may return another state
 * name when appropriate
 * @param dependencies An array of objects to pass to useEffect so that the machine isn't evaluated
 * on every update, if desired.
 * @returns The name of the current state the machine is in.
 */
export function useMachine (start, definitions, dependencies) {
  const [state, setState] = useState(start)
  useEffect(
    () => {
      // the machine can go to an undefined state to terminate processing.
      if (isFunction(definitions[state])) {
        const next = definitions[state]()
        if (isString(next)) setState(next)
      }
    },
    // either evaluate on every update if there are no dependencies passed, or honor the
    // passed dependencies, but add our state variable to the mix.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies == null ? null : [...dependencies, state],
  )
  return state
}

/**
 * A quick and dirty hook to prevent the need for
 * `//eslint-disable-next-line react-hooks/exhaustive-deps` when building some code that should run
 * on component mount only.
 */
export function useDidMount (closure) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(closure, [])
}

/**
 * A hook that only executes the closure when the connection transitions to loaded. The closure is
 * passed the selected state of the connection, and this hook returns the same signature as
 * useConnection.
 */
export function useWhenLoaded (closure, connection, options = null) {
  const [loaded, selected] = useConnection(connection, options)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { if (loaded) closure(selected) }, [loaded])

  return [loaded, selected]
}

/**
 * If we're on a page that is iterated in Pages.jsx, this will return the instance of the page
 * we're on.
 */
export const useCurrentPage = (exactMatch = false) => Pages.getPageAtLocation(useLocation(), exactMatch, useParams())

export function useIsMobile (breakpoint) {
  const [isMobileValue, setIsMobile] = useState(isMobile(breakpoint))
  useDidMount(() => {
    const updateIsMobile = () => setIsMobile(isMobile(breakpoint))
    window.addEventListener('resize', updateIsMobile)
    return () => window.removeEventListener('resize', updateIsMobile)
  })
  return isMobileValue
}

const measureHeight = () => document.documentElement?.clientHeight ?? window.innerHeight

/**
 * A hook to enable workarounds for the well-known 100vh but in mobile webkit:
 * https://medium.com/@susiekim9/how-to-compensate-for-the-ios-viewport-unit-bug-46e78d54af0d
 */
export function useBrowserHeight () {
  const [height, setHeight] = useState(measureHeight())
  useDidMount(() => {
    const updateHeight = () => { setHeight(measureHeight()) }
    window.addEventListener('resize', updateHeight)
    return () => window.removeEventListener('resize', updateHeight)
  })
  return height
}

/**
 * A hook that will always return the same value as was passed in on mount. It will reset its value
 * if the (optional) deps array changes, but will otherwise keep returning the same value.
 */
export const useSnapshot = (value, deps = []) =>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useMemo(() => value, deps)

/**
 * A hook that returns true if the arguments passed in change
 */
export function useArgumentsChanged (...args) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stamp = useMemo(() => new Date().toString(), [...args])
  const previous = usePrevious(stamp)
  return stamp != null && stamp !== previous
}

// Returns true if the both params are either null or undefined. If they're both defined, it then
// casts both to a String to compare values.
const stringMatches = (first, second) => (first == null && second == null) ||
  String(first) === String(second)

/**
 * Ensures that the given params are part of the current window.location.search query string. If
 * they are not, the hook returns true and in a post-render hook, will do a history.replace() to
 * put them there.
 */
export function useEnsureQueryParams (params) {
  const history = useHistory()
  // useCurrentQueryParams uses useLocation under the hood so this hook will execute on every
  // location change.
  const currentParams = useCurrentQueryParams()
  const needsUpdate = Object.keys(params)
    .some(key => !stringMatches(params[key], currentParams[key]))
  useEffect(() => {
    if (needsUpdate) {
      const query = skipNullQueryString({ ...currentParams, ...params })
      history.replace(`${window.location.pathname}?${query}`)
    }
  })

  return needsUpdate
}

/**
 * As long as the component using this hook is mounted and the (optional) deps don't change, the URL
 * params returned will be the same, regardless of changes in the current query params
 */
export const useQueryParamsSnapshot = (deps = []) =>
  useSnapshot(queryString.parse(window.location.search), deps)

/**
 * Uses the currently available query params, and will trigger a re-render each time the location
 * changes
 */
export const useCurrentQueryParams = () => queryString.parse(useLocation().search)

/**
 * If the user is a therapist in a frame, we need to load the participant for this session.
 */
export function useParticipant () {
  const { lead_id: leadId, is_therapist_for_participant: isTherapist } = useQueryParamsSnapshot()

  // Conditional hook usage is an anti-pattern, and very likely to blow up in your face. The danger
  // is that if the number of hooks executed for a component changes between renders, the hook state
  // will be complete garbage. In this case, since we're preserving the data that goes into the
  // condition via a ref, we can be certain that the condition will never change, and this is
  // therefore safe in this case.
  if (leadId == null) return [true, {}]

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [loaded, { participant, participantError }] = useConnection(
    Participant.connection,
    { leadId, isTherapist },
  )

  if (loaded && participantError != null) {
    const participantErrorRedirect =
      Pages.ERROR.redirect({ message_key: participantError, link_help: true })
    return [true, { participantError, participantErrorRedirect }]
  }

  return [loaded, { participant }]
}

/**
 * Given an activity slug, optional userId (default is `me`) and an optional predicate, returns last
 * completed user activity of this activity slug that matches the provided predicate.
 *
 * @param activitySlug The activity slug to search UAs for.
 * @param userId (optional) The user to search with. Uses the current user if not provided.
 * @param predicate (optional) A predicate with which to filter the resulting UAs.
 */
export function useLastCompletedActivity ({ activitySlug, userId, predicate }) {
  const [loaded, { userActivities }] = useConnection(
    UserActivity.allCompleteConnection,
    { activitySlug, userId },
  )
  const ua = useMemo(
    () => {
      if (!loaded || !Array.isArray(userActivities)) return null
      const matching = predicate == null ? userActivities : userActivities.filter(predicate)
      return findFirst(matching, dateCompare(false, 'completed_at'))
    },
    [predicate, loaded, userActivities],
  )

  return [loaded, ua]
}

/**
 * Open confirmation dialog and run a callback when the result has changed
 *
 * @param slug Then name of the conformation dialog
 * @param callback callback to run when the result has changed
 */
export function useConfirmationDialog (slug, callback) {
  const [, {
    openDialog,
    dialogResult,
    clearDialogResult,
  }] = useConnection(Dialog.CONFIRMATION.connection, { slug })

  const previousDialogResult = usePrevious(dialogResult)

  useEffect(() => {
    if (
      dialogResult != null &&
      previousDialogResult == null
    ) {
      callback(dialogResult)
      clearDialogResult()
    }
  }, [callback, clearDialogResult, dialogResult, previousDialogResult])

  return openDialog
}

/**
  @param  {Object} activity     The user activity object
  @param  {Object} history      The history object
  @param  {String} launchedFrom The value is used in goToPostActivityLocation.
                                It can be an articleId or the path of the page we are currently on
*/
export const useStartActivity = (activity, history, launchedFrom) => useCallback((event) => {
  event.preventDefault()

  const { slug, program_activity_id: programActivityId, user_activity_id: userActivityId } = activity
  const path = activityPath(slug)
  const queryString = skipNullQueryString({
    launched_from: launchedFrom,
    program_activity_id: programActivityId !== slug ? programActivityId : null,
    user_activity_id: userActivityId,
  })

  analytics.trackButtonClick(`Started ${slug}`, () => {
    history.push(`${path}?${queryString}`)
  })
}, [activity, history, launchedFrom])

/**
 * A hook to ensure that when the component using it mounts, the users/me cache is refreshed
 * from the server.
 */
export function useReloadMe () {
  const [reloadState, setReloadState] = useState('UNSTARTED')
  const [meLoaded, { loadMe }] = useConnection(User.meConnection)

  useEffect(() => {
    if (!meLoaded || reloadState !== 'UNSTARTED') return

    loadMe().then(() => setReloadState('DONE'))
  }, [loadMe, meLoaded, reloadState])

  return reloadState === 'DONE'
}

export function useReloadPromotion () {
  const [reloadState, setReloadState] = useState('UNSTARTED')
  const [
    promotionLoaded,
    { loadError, clearError, loadPromotion, promotion },
  ] = useConnection(Promotion.connection)

  useDidMount(() => {
    if (loadError != null) clearError()

    if (!promotionLoaded || reloadState !== 'UNSTARTED') return

    loadPromotion()?.then(() => setReloadState('DONE'))
  }, [loadPromotion, promotionLoaded, reloadState, loadError, clearError])

  return [reloadState === 'DONE' && promotionLoaded, { promotion }]
}

export function useScrollPosition (key) {
  const dispatch = useDispatch()

  const scrollPosition = flow([
    () => getScrollPosition(key),
    useSelector,
    scrollPos => defaultTo(scrollPos, null),
  ])()

  const storeScrollPosition = useCallback((scrollPosition = { y: window.scrollY }) => {
    flow([
      () => setScrollPosition(key, scrollPosition),
      dispatch,
    ])()
  }, [dispatch, key])

  const restoreScrollPosition = useCallback(() => {
    if (isNull(scrollPosition)) return

    window.scrollTo(0, scrollPosition.y)
    flow([
      () => setScrollPosition(key, null),
      dispatch,
    ])()
  }, [dispatch, key, scrollPosition])

  return {
    restoreScrollPosition,
    storeScrollPosition,
  }
}

export const usePrevLocationCallback = () => {
  const { launched_from: launchedFrom } = useQueryParamsSnapshot()
  const { state } = useLocation()
  const pageSlug = launchedFrom ?? getPrevPageSlug(state?.from)
  const page = pageSlug != null ? Pages.getPageBySlug(pageSlug) : null
  return useCallback(() => {
    if (page == null) {
      Pages.HOME.go()
    } else {
      page.go()
    }
  }, [page])
}
