import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { memo } from 'react'
import '../../../scss/components/material/joyableButton.scss'

const SIZES = ['small', 'light', 'regular', 'big']

const THEMES = [
  'black',
  'gray',
  'fadedOrange',
  'fadedOrangeOutline',
  'silver',
  'lightSkyBlue',
  'white',
  'emptyWhite',
  'emptyGray',
  'emptySilver',
  'emptySeafoamBlue',
]

const classes = (size, theme) => ({
  root: `joyableButton ${size}ButtonSize ${theme}ButtonTheme`,
  label: 'joyableButtonLabel',
})
const TouchRippleProps = { classes: { child: 'joyableButtonRipple' } }

const JoyableButton = ({
  className, size, theme, label, onClick, type, disabled, href, target,
}) => (
  <Button
    {...{ className, type, onClick, disabled, href, target, TouchRippleProps }}
    classes={classes(size, theme)}
    variant='outlined'
  >
    {label}
  </Button>
)

JoyableButton.propTypes = {
  size: PropTypes.oneOf(SIZES),
  theme: PropTypes.oneOf(THEMES),
  label: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  target: PropTypes.string,
}

JoyableButton.defaultProps = {
  size: 'regular',
  theme: 'gray',
}

export default memo(JoyableButton)
