import hoistStatics from 'hoist-non-react-statics'
import { awaitConnection, Connection, OptionalProps, StatePredicate } from 'joyable-js-api'
import isFunction from 'lodash/isFunction'
import PropTypes from 'prop-types'
import { Component, NamedExoticComponent, ReactNode, ValidationMap, Validator } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import Log from '../services/log'
import ApiModel from 'joyable-js-api/src/ApiModel'
import { ApiState } from 'joyable-js-api/src/types'

type ModelConnectionProps = {
  modelConnectionState: Record<string, Record<string, any>>
}

type Shape = { [key: string]: Validator<any> }
type ShapeMutator = (alwaysAvailable: boolean) => Shape

export type HOCConnection<S, P extends OptionalProps> = Connection<S, P> & {
  shape?: Shape | ShapeMutator,
  namespace?: string,
  normalizeProps?: (props: Record<string, any>) => P,
}

/**
 * A utility for controller and other non-component contexts that use several connection-related
 * methods in succession.
 */
export const connectionContext =
  <SelectedType, PropsType extends OptionalProps>(
    connection: HOCConnection<SelectedType, PropsType>,
    props: PropsType | Record<any, never> = {},
  ) => {
    connection = normalizeConnection(connection)

    const awaitContextConnection = (statePredicate: StatePredicate<SelectedType>) =>
      awaitConnection(connection, props, statePredicate)

    return {
      select: () => selectConnection(connection, props),

      await: () => awaitConnection(connection, props),

      awaitState: awaitContextConnection,

      awaitFlagOn: (flag: string) => awaitContextConnection(
        (selected) => selected[flag] === true,
      ),

      awaitFlagOff: (flag: string) => awaitContextConnection(
        (selected) => selected[flag] === false,
      ),

      awaitValueSet: (name: string) => awaitContextConnection(
        (selected) => selected[name] != null,
      ),
    }
  }

/**
 * Select the value from a connection with the current redux store contents. Do not use within
 * components; use modelConnect() in that context instead.
 */
export const selectConnection = <SelectedType, PropsType extends OptionalProps = undefined>(
  { selector }: Connection<SelectedType, PropsType>,
  props: PropsType | Record<any, never> = {},
) => selector(ApiModel.reduxState, props)

export const normalizeConnection = <
  S,
  P extends OptionalProps
>(connection: HOCConnection<S, P>): HOCConnection<S, P> => {
  const { normalizeProps, selector, onMount, load, isLoaded } = connection
  if (selector == null) {
    Log.error('All connections are required to have a selector function', connection)
  }

  if (normalizeProps == null) return connection

  return {
    ...connection,
    selector: (state, ownProps) => selector(state, normalizeProps(ownProps ?? {})),
    onMount: onMount && (ownProps => onMount(normalizeProps(ownProps ?? {}))),
    load: load && ((shape, ownProps) => load(shape, normalizeProps(ownProps ?? {}))),
    isLoaded: isLoaded && ((shape, ownProps) => isLoaded(shape, normalizeProps(ownProps ?? {}))),
    shape: shapeGetter(false)(connection),
  }
}

const shapeGetter = (alwaysAvailable: boolean) =>
  ({ shape }: { shape?: Shape | ShapeMutator }): Shape | undefined => {
    if (isFunction(shape)) return shape(alwaysAvailable)
    return shape
  }

/**
 * Modify a connection so that the load function is still called, but the connection will not
 * prevent the connected component from rendering while the connection is loading.
 */
export const alwaysAvailable = <S, P extends OptionalProps>(connection: HOCConnection<S, P>) => ({
  ...connection,
  isLoaded: () => true,
  shape: shapeGetter(true)(connection),
})

type MapConnectionProps<P extends OptionalProps> = {
  mapOptions: P[],
}

/**
 * A utility to create a connection by mapping a single connection multiple times against an array
 * of options (i.e. model ids)
 *
 * Example (assumes `messages` is coming in on the props):
 * mapConnection('coaches', Coach.coachConnection, ({ messages }) => ({
 *   mapOptions: messageCoachIds(messages).map(coachId => ({ coachId }))
 * }))
 *
 * This results in a connection that applies the following props to its wrapped component:
 * {
 *   coaches: PropTypes.arrayOf(PropTypes.shape(Coach.coachConnection.shape)),
 *   // in this case, this will be an array of { coachId } objects.
 *   coachesMapOptions: PropTypes.arrayOf(PropTypes.object)
 * }
 */
export const mapConnection = <S, P extends OptionalProps>(
  name: string,
  connection: HOCConnection<S, P>,
  normalizeProps = undefined,
): HOCConnection<Record<string, unknown[]>, MapConnectionProps<P>> => ({
    load: (shape, { mapOptions }) => {
      const { load } = connection
      if (load == null) return
      mapOptions.forEach((options, index) => {
        load(shape[name][index] as S, options)
      })
    },

    isLoaded: (shape, { mapOptions }) => {
      const { isLoaded } = connection
      if (isLoaded == null) return true

      return mapOptions.reduce(
        (acc, options, index) =>
          isLoaded(shape[name][index] as S, options) && acc,
        true,
      )
    },

    selector: createSelector(
      [
        (state: ApiState, { mapOptions }: MapConnectionProps<P>) =>
          mapOptions.map(options => connection.selector(state, options)),
        (state: ApiState, { mapOptions }: MapConnectionProps<P>) => mapOptions,
      ],
      (shapes, mapOptions) => ({
        [name]: shapes,
        [`${name}MapOptions`]: mapOptions,
      }),
    ),

    shape: {
      [name]: PropTypes.arrayOf(PropTypes.shape(connection.shape as ValidationMap<any>)).isRequired,
      [`${name}MapOptions`]: PropTypes.arrayOf(PropTypes.object).isRequired,
    },

    normalizeProps,
  })

/**
 * An HOC that wraps a component that needs models from the API in order to simplify the logic
 * within such components to avoid a lot of repeated boilerplate.
 *
 * @param connections Connections with the shape mentioned in the {@link modelConnectWithOptions}
 *   docs
 *
 * @deprecated use useConnection instead.
 */
export const modelConnect = (...connections: HOCConnection<any, any>[]) =>
  modelConnectWithOptions({ connections })

/**
 * Note: In most cases, [modelConnect]{@link modelConnect} should be sufficient.
 *
 * An HOC that wraps a component that needs models from the API in order to simplify the logic
 * within such components to avoid a lot of repeated boilerplate. The wrapped component will receive
 * propTypes associated with the expected shape of the connections as a debugging tool in dev. Each
 * connection has this structure:
 *
 * {
 *   selector: (required) A selector function that returns the properties that should be applied
 *     to a component using this connection. The results of this selector are also passed to
 *     the isLoaded function.
 *
 *   isLoaded: A function that, given the results of the selector returns a boolean indicating if
 *     this connection is ready to consume. If not provided, the connection is assumed to always
 *     be ready.
 *
 *   load: A function that, if provided will kick off a load of the data required by this
 *     connection. Is called any time the properties on modelConnect update, so this function must
 *     protect against multiple calls.
 *
 *   shape: A PropTypes shape that describes the members that a connected component should expect to
 *     receive. If `shape` is a function, it is expected to return a different structure for the
 *     alwaysAvailable case.
 *
 *   normalizeProps: A function that, if supplied, takes the components ownProps and returns a props
 *     object that should be applied to this connection's selector instead. If not supplied, the
 *     component's ownProps is passed along instead.
 *
 *   namespace: If provided, the properties resulting from this selector will be placed under
 *     this key on the component's props, instead of at the root level.
 *
 *   onMount: A function that is used the same way as the non-connection onMount described below.
 * }
 *
 * @param {Array} connections (required) Connections with the shape specified above.
 * @param {Node} loadingNode The react node to display while connection is loading. Default is null
 * @param {Function} onMount If specified, a method that will be called on the initial mount of
 *        this component each time. If onMount returns `false`, the component will not process
 *        connections until something causes the connection in modelConnect to be evaluated again.
 *
 * @deprecated use useConnection instead.
 */
export const modelConnectWithOptions = ({
  connections,
  loadingNode = null,
  onMount = null,
}: {
  connections: HOCConnection<Record<string, any>, any>[],
  loadingNode?: ReactNode | null,
  onMount?: ((props: any) => boolean | void) | null,
}) => (ChildComponent: NamedExoticComponent) => {
  class ModelConnection extends Component<ModelConnectionProps> {
    static getDerivedStateFromProps (
      { modelConnectionState, ...ownProps }: { modelConnectionState: Record<string, Record<string, unknown>> },
      { initialMount },
    ) {
      const hasOnMount =
        onMount != null || connections.find(({ onMount }) => onMount != null) != null
      if (initialMount && hasOnMount) {
        let haltInitialMount = false
        if (onMount != null) haltInitialMount = onMount(ownProps) === false
        haltInitialMount = connections.reduce((haltInitialMount, { onMount }) => {
          if (onMount != null) haltInitialMount = (onMount(ownProps) === false) || haltInitialMount
          return haltInitialMount
        }, haltInitialMount)
        if (haltInitialMount) return { initialMount: false, ready: false }
      }

      const ready = connections.reduce(
        (ready, { isLoaded, load }, index) => {
          if (isLoaded == null && load == null) return ready

          const modelState = modelConnectionState[index]
          if (load != null) load(modelState, ownProps)
          const connectionReady = isLoaded == null || isLoaded(modelState, ownProps)
          return connectionReady && ready
        },
        true,
      )

      if (!ready) return { initialMount: false, ready }

      return {
        initialMount: false,
        ready,
        ownProps,

        // Since we're ready, reduce the model connection state to a set of named props to hand
        // off to our child component.
        childComponentProps: Object.entries(modelConnectionState).reduce(
          (childComponentProps, [key, props]) => {
            const { namespace } = connections[parseInt(key)]
            if (namespace == null) {
              return { ...childComponentProps, ...props }
            } else {
              return { ...childComponentProps, [namespace]: props }
            }
          },
          {},
        ),
      }
    }

    state = {
      initialMount: true,
      ready: false,
      childComponentProps: {},
      ownProps: {},
    }

    static displayName = `modelConnect(${ChildComponent.displayName || ChildComponent.name})`

    static get WrappedComponent () {
      const withProp = ChildComponent as unknown as { WrappedComponent: NamedExoticComponent }
      return withProp.WrappedComponent ?? ChildComponent
    }

    render () {
      const { ready, ownProps, childComponentProps } = this.state
      return ready
        ? <ChildComponent {...ownProps} {...childComponentProps} />
        : loadingNode
    }
  }

  // create a selector that memoizes the result of all of our connections into a single object that
  // maps to the modelConnectionState property of ModelConnection. This is necessary so that connect
  // below gets the same object handed to it when the state changes if nothing changed about our
  // connections.
  connections = connections.map(normalizeConnection)
  const modelConnectionStateSelector = createSelector(
    connections.map(({ selector }) => selector),
    (...connectionResults) => connectionResults.reduce(
      (modelConnectionState: Record<string, Record<string, unknown>>, result, index) => ({
        ...modelConnectionState,
        [index]: result,
      }), {}),
  )

  const mapStateToProps = (state: ApiState, ownProps: Record<string, unknown>) =>
    ({ modelConnectionState: modelConnectionStateSelector(state, ownProps) })

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return connect(mapStateToProps)(hoistStatics(ModelConnection, ChildComponent))
}
