// As our codebase gets more mature, there are more places that have a need to access the redux
// getState() and dispatch() functions outside of the context of a connected component. These
// methods should only be used for those cases! Do not rely on these methods to access state
// in a component.

let store
export const setStore = reduxStore => { store = reduxStore }

export const getStore = () => store

export const getState = () => store.getState()

export const dispatch = action => store.dispatch(action)
