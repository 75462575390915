import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel from '../ApiModel'
import { registerModelReducer, setIn } from '../reducers'
import { selectorCache } from '../util/selectorCache'
import { parseQuery } from './utilities'

const MODEL = new ApiModel('QUICK_FACTS', 'api/v1/quick_facts')

registerModelReducer('quickFacts', (state = { users: { } }, { type, url, response }) => {
  const isLoaded = type === Action.READ.successType(MODEL) ||
    (type === Action.READ.failureType(MODEL) && response.status === 404)
  if (isLoaded) {
    const { user_id: userId } = parseQuery(url)
    return setIn(state, ['users', userId], true)
  }

  return state
})

const SHAPE = PropTypes.shape({
  id: PropTypes.number.isRequired,
  body: PropTypes.string,
})

const quickFactsOptions = userId => ({ query: { user_id: userId } })

const load = userId => MODEL.read(quickFactsOptions(userId))

export const selectQuickFacts = userId => createSelector(
  [state => state.api.quick_facts],
  quickFacts => quickFacts && Object.values(quickFacts).find(fact => fact.user_id === userId))

const selectUpdating = userId => state =>
  MODEL.isCreatingSelector(quickFactsOptions(userId))(state)

const selectLoaded = userId => state =>
  selectQuickFacts(userId)(state) != null || state.models.quickFacts.users[userId] === true

const selectConnection = selectorCache(({ userId }) => userId,
  ({ userId }) => createSelector(
    [selectQuickFacts(userId), selectUpdating(userId), selectLoaded(userId)],
    (quickFacts, quickFactsUpdating, quickFactsLoaded) => ({
      quickFacts,
      quickFactsUpdating,
      quickFactsLoaded,

      updateQuickFacts: body =>
        MODEL.create({ quick_facts: { body } }, quickFactsOptions(userId)),
    }),
  ))

export const QuickFacts = {
  selectQuickFacts,

  connection: {
    load: ({ quickFactsLoaded }, { userId }) => {
      if (!quickFactsLoaded) load(userId)
    },

    isLoaded: ({ quickFactsLoaded }) => quickFactsLoaded,

    /**
     * Selector: (state, { userId }) => connectionShape
     */
    selector: selectConnection,

    shape: {
      quickFacts: SHAPE,
      quickFactsUpdating: PropTypes.bool.isRequired,
      quickFactsLoaded: PropTypes.bool.isRequired,

      updateQuickFacts: PropTypes.func.isRequired,
    },
  },
}
