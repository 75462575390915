/* global Response */
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import { selectorCache } from '../util/selectorCache'

/**
 * selector: (state, { action })
 * action: instance of Action enum
 */
const failedSelector = selectorCache(
  ({ action }) => action,
  ({ action }) => createSelector(
    [action.pendingSource],
    requests => Object.entries(requests).reduce((failedRequests, [endpointUrl, { failed }]) => {
      if (failed == null) return failedRequests
      return { ...failedRequests, [endpointUrl]: failed }
    }, {}),
  ),
)

const failedSelectorPartial = action => state => failedSelector(state, { action })

/**
 * selector: (state, { action, endpointPrefix })
 * action: instance of Action enum.
 */
const selectFailuresForPrefixConnection = selectorCache(
  ({ action, endpointPrefix }) => `${action.name}|${endpointPrefix}`,
  ({ action, endpointPrefix }) => createSelector(
    [failedSelectorPartial(action)],
    failed => ({
      failures: Object.entries(failed).reduce((matchEndpoint, [endpointUrl, pending]) => {
        if (!endpointUrl.startsWith(endpointPrefix)) return matchEndpoint
        return [...matchEndpoint, { ...pending, url: endpointUrl }]
      }, []),
    }),
  ),
)

export const Pending = {
  /**
   * selector: (state, { action, endpointPrefix })
   */
  failuresForPrefixConnection: {
    selector: selectFailuresForPrefixConnection,

    shape: {
      failures: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        response: PropTypes.instanceOf(Response).isRequired,
        error: PropTypes.shape({
          message: PropTypes.string,
          reason: PropTypes.string,
          details: PropTypes.object,
        }),
      })),
    },
  },
}
