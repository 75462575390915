import get from 'lodash/get'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import { formatModelTimestamps } from '../../../../app/js/components/admin/utils'
import Action from '../Action'
import ApiModel, { isPruneOf } from '../ApiModel'
import { registerModelReducer } from '../reducers'
import { selectorCache } from '../util/selectorCache'
import { LOAD_SUCCESS_TYPE as USER_LOAD_SUCCESS_TYPE } from './User'
import { parseId } from './utilities'

registerModelReducer('coach', (state = { }, { type, url, response, pruneKeys }) => {
  if (type === USER_LOAD_SUCCESS_TYPE) {
    const myCoachId = get(response, 'coaches[0].id')
    return { ...state, myCoachId, coachAssigned: myCoachId != null }
  } else if (type === COACH_READ_SUCCESS_TYPE) {
    const { id: myCoachId, coach_assigned: coachAssigned } = response.coach
    return { ...state, myCoachId, coachAssigned }
  } else if (type === COACH_UPDATE_SUCCESS_TYPE) {
    return { ...state, myCoachId: response.coach.id, coachAssigned: true }
  } else if (isPruneOf(['my_coach'])({ type, pruneKeys }) || type === COACH_UPDATE_REQUEST_TYPE) {
    return { ...state, myCoachId: null, coachAssigned: null }
  } else if (
    (type === COACHES_READ_SUCCESS_TYPE || (
      type === COACHES_READ_FAILURE_TYPE && response.status === 404
    )) &&
    parseId(COACHES_MODEL, url) === 'me'
  ) {
    return { ...state, meCoachLoaded: true }
  }

  return state
})

registerModelReducer('activeCoaches', (state = { }, { type, response }) => {
  switch (type) {
    case ADMIN_COACHES_READ_SUCCESS_TYPE:
      return {
        ...state,
        coach: formatModelTimestamps(response.coach, ['created_at']),
        coaches: formatModelTimestamps(response.coaches, ['created_at']),
        totalCount: response.total_count,
        currentPage: response.current_page,
        sortedBy: response.sorted_by,
      }
    case ADMIN_COACHES_UPDATE_SUCCESS_TYPE:
      return {
        ...state,
        coach: response.coach,
      }
    default:
      return state
  }
})

// api/v1/coach/me might return a 404 if the current user is not a coach, which isn't actually an
// error.
const COACHES_MODEL = new ApiModel('COACHES', 'api/v1/coaches', (json, response, fetch) =>
  !fetch.url.match(/\/me$/),
)
const COACHES_READ_SUCCESS_TYPE = Action.READ.successType(COACHES_MODEL)
const COACHES_READ_FAILURE_TYPE = Action.READ.failureType(COACHES_MODEL)

const ADMIN_COACHES_MODEL = new ApiModel('ADMIN_COACHES', 'admin/api/v1/coaches')
const ADMIN_COACHES_READ_SUCCESS_TYPE = Action.READ.successType(ADMIN_COACHES_MODEL)
const ADMIN_COACHES_UPDATE_SUCCESS_TYPE = Action.UPDATE_MODIFY.successType(ADMIN_COACHES_MODEL)

const MODEL = new ApiModel('COACH', 'api/v1/coach')
const COACH_READ_SUCCESS_TYPE = Action.READ.successType(MODEL)
const COACH_UPDATE_REQUEST_TYPE = Action.UPDATE_MODIFY.requestType(MODEL)
const COACH_UPDATE_SUCCESS_TYPE = Action.UPDATE_MODIFY.successType(MODEL)
const NO_COACH_ASSIGNED = Symbol('NO_COACH_ASSIGNED')
const NOT_FOUND = Symbol('COACH_NOT_FOUND')

const SHAPE = PropTypes.oneOfType([
  // for NO_COACH_ASSIGNED
  PropTypes.symbol,

  PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    intro: PropTypes.string,
    sms: PropTypes.string,
    pronouns: PropTypes.shape({
      subjective: PropTypes.string.isRequired,
      objective: PropTypes.string.isRequired,
      possessive_adj: PropTypes.string.isRequired,
    }).isRequired,
    client_quote: PropTypes.string,
    email: PropTypes.string,
    title: PropTypes.string,
    full_name: PropTypes.string,
    coach_can_schedule_recurring_session: PropTypes.any,
  }),
])

const ADMIN_SHAPE = PropTypes.shape({
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  created_at: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
})

const selectAdminConnection = selectorCache(
  ({ coachId }) => coachId,
  ({ coachId }) => createSelector(
    [
      state => state.models.activeCoaches.coach,
      state => state.models.activeCoaches.coaches,
      state => state.models.activeCoaches.totalCount,
      state => state.models.activeCoaches.currentPage,
      state => state.models.activeCoaches.sortedBy,
      state => ADMIN_COACHES_MODEL.getUpdateErrorMessage(state, { id: coachId }),
    ],
    (coach, activeCoaches, totalCount, currentPage, sortedBy, updateErrorMessage) => ({
      coach,
      activeCoaches,
      totalCount,
      currentPage,
      sortedBy,
      updateErrorMessage,
      getActiveCoaches: ({ page, field, order }) => ADMIN_COACHES_MODEL.read({ query: { page, field, order } }),
      deleteCoach: async id => await ADMIN_COACHES_MODEL.destroy({ id }),
      updateCoach: async coach => await ADMIN_COACHES_MODEL.updateModify({ coach }, { id: coachId }),
      uploadCoachProfileImage: async formData => await ADMIN_COACHES_MODEL.updateModify(formData, { id: coachId }),
      clearDeleteError: (id) => {
        ApiModel.dispatch(
          Action.DESTROY.getClearPendingAction(ADMIN_COACHES_MODEL, ADMIN_COACHES_MODEL.getUrl({ id })),
        )
      },
      clearUpdateError: (id) => {
        if (updateErrorMessage != null) {
          ApiModel.dispatch(Action.UPDATE_MODIFY.getClearPendingAction(
            ADMIN_COACHES_MODEL,
            ADMIN_COACHES_MODEL.getUrl({ id })),
          )
        }
      },
      sortCoaches: ({ field, order }) => ADMIN_COACHES_MODEL.read({ query: { page: 1, field, order } }),
    }),
  ),
)

/**
 * If called without an id, selects the coach assigned to the current user
 */
const select = state => {
  const { myCoachId, coachAssigned } = state.models.coach
  return coachAssigned === true
    ? get(state.api.coaches, [myCoachId])
    : (coachAssigned === false ? NO_COACH_ASSIGNED : null)
}

const pick = coachId => MODEL.updateModify({ coach: { id: coachId } })

export const selectCoaches = state => state.api.coaches
export const selectMeId = createSelector([selectCoaches], coaches => coaches && coaches.me)
const selectMeLoaded = state => state.models.coach.meCoachLoaded === true
const selectMe = createSelector(
  [selectCoaches, selectMeId],
  (coaches, meId) => meId && coaches[meId],
)

const selectCoach = coachId => state => selectCoaches(state)[coachId]

const selectCoachConnection = selectorCache(
  ({ coachId }) => coachId,
  ({ coachId }) => createSelector(
    [selectCoach(coachId), COACHES_MODEL.isNotFoundSelector({ id: coachId })],
    (coach, isNotFound) => ({
      coach: coach || (isNotFound ? NOT_FOUND : null),
    }),
  ),
)

export const Coach = {
  NO_COACH_ASSIGNED,
  NOT_FOUND,
  SHAPE,
  ADMIN_SHAPE,

  /**
   * Connection for the coach with the given id
   */
  coachConnection: {
    load: ({ coach }, { coachId }) => {
      if (coach == null) COACHES_MODEL.read({ id: coachId })
    },

    isLoaded: ({ coach }) => coach != null,

    selector: selectCoachConnection,

    shape: {
      coach: PropTypes.oneOfType([PropTypes.instanceOf(Symbol), SHAPE]).isRequired,
    },
  },

  /**
   * Connection for the coach for the current user
   */
  myCoachConnection: {
    load: ({ coach, coachIsPicking }) => {
      if (coach == null && !coachIsPicking) MODEL.read()
    },

    isLoaded: ({ coach, coachIsPicking }) => !coachIsPicking && coach != null,

    selector: createSelector(
      [select, MODEL.isUpdatingSelector()],
      (coach, coachIsPicking) => ({
        coach,
        coachIsPicking,
        reloadCoach: () => ApiModel.pruneCache('my_coach'),
        pickCoach: coachId => pick(coachId),
      }),
    ),

    shape: {
      coach: SHAPE.isRequired,
      coachIsPicking: PropTypes.bool.isRequired,
      reloadCoach: PropTypes.func.isRequired,
      pickCoach: PropTypes.func.isRequired,
    },
  },

  /**
   * Connection for the "me" coach object when a coach is logged into the coach tool.
   */
  meCoachConnection: {
    load: ({ meLoaded }) => {
      if (!meLoaded) COACHES_MODEL.read({ id: 'me' })
    },

    isLoaded: ({ meLoaded }) => meLoaded,

    selector: createSelector(
      [selectMe, selectMeLoaded],
      (meCoach, meLoaded) => ({ meCoach, meLoaded })),

    shape: { meCoach: SHAPE },
  },

  /**
   * Connection used in Coaches Admin Tool to view all active coaches
   */
  adminConnection: {
    load: ({ activeCoaches, coach }, { coachId }) => {
      if (activeCoaches == null && coachId == null) {
        ADMIN_COACHES_MODEL.read({ query: { page: 1 } })
      } else if (coachId != null && coach == null) {
        ADMIN_COACHES_MODEL.read({ id: coachId })
      }
    },

    isLoaded: ({ activeCoaches, totalCount, currentPage, coach }) =>
      (activeCoaches != null && totalCount != null && currentPage != null) || coach != null,

    selector: selectAdminConnection,

    shape: {
      coach: PropTypes.any,
      activeCoaches: PropTypes.arrayOf(ADMIN_SHAPE).isRequired,
      totalCount: PropTypes.number,
      currentPage: PropTypes.number,
      updateErrorMessage: PropTypes.string,
      getActiveCoaches: PropTypes.func.isRequired,
      deleteCoach: PropTypes.func,
      updateCoach: PropTypes.func,
      clearDeleteError: PropTypes.func,
      clearUpdateError: PropTypes.func,
      uploadCoachProfileImage: PropTypes.func,
    },
  },
}
