import { connectionContext } from '@app/js/components/modelConnect'
import Pages from '@app/js/routing/Pages'
import { Authentication, Treatment, User, Firebase, FirebaseToken } from 'joyable-js-api'
import queryString from 'query-string'
import { Redirect } from 'react-router-dom'
import { defaultLaunchPage } from '@app/js/components/dashboard/dashboardStates'
import { useConnection } from '@app/js/lib/useConnection'
import { parseQuery } from 'joyable-js-api/src/models/utilities'
import { isAuth0Enabled } from 'joyable-js-api/src/ApiFetch'

export const getQuery = () => queryString.parse(globalThis.location.search)
export const getPrevPageSlug = (prevLocation) => {
  if (prevLocation?.includes(window.location.host)) {
    const prevLocationToArr = prevLocation.split('/')
    return prevLocationToArr[prevLocationToArr.length - 1]
  }
  return null
}

export const getInitialEmail = () => getQuery().verify_email
export const getInitialEmailType = () => getInitialEmail() == null ? null : 'pwless_auth'

const currentTreatmentContext = connectionContext(Treatment.currentConnection)

// In a separate module so it can be mocked
export function getInitialFormName () {
  if (getInitialEmail() != null) return 'check_email'

  const { mode, reset_password_token: resetToken } = getQuery()
  if (mode === 'resetPassword' || resetToken != null) return 'reset_password'
  if (Authentication.isSignInWithEmailLink()) return 'create_password'
  return 'email'
}

export const postLoginRedirect = ({ role }) => {
  const { login_redirect: redirect } = getQuery()

  if (redirect != null) {
    if (redirect.match(/https:\/\/en.*\.ableto.com/)) {
      const redirectToE2 = async (user, options) => {
        const redirect = options.redirect
        if (!isAuth0Enabled()) {
          const firebaseCustomToken = await FirebaseToken.connection.selector().createCustomToken(user)
          window.location.replace(`${redirect}?idp_custom_token=${firebaseCustomToken}`)
        } else {
          window.location.replace(`${redirect}`)
        }
      }
      if (!isAuth0Enabled()) {
        Firebase.callOnUserLoaded(redirectToE2, { redirect })
      }
    } else {
      return <Redirect to={redirect} push={true} />
    }
  }
  const queryParamObject = parseQuery(window.location.search)
  const paramsToPersist = Object.fromEntries(Object.entries(queryParamObject).filter((k) => !k.includes('utm')))

  if (isAuth0Enabled()) {
    const path = window.localStorage.getItem('fromDeepLink')
    if (path) {
      window.localStorage.removeItem('fromDeepLink')
      window.location.replace(path)
    }
  }

  return (retrievePostLoginPage(role)).redirect(paramsToPersist)
}

export function useLoginRedirect () {
  const [, { me }] = useConnection(User.meConnection)
  const [, { isLoggedIn }] = useConnection(Authentication.isLoggedInConnection)

  return isLoggedIn && !isAuth0Enabled() ? postLoginRedirect(me) : null
}

export function retrievePostLoginPage (role) {
  const { currentTreatment } = currentTreatmentContext.select()

  switch (role) {
    case 'coach':
    case 'escalations_reviewer':
    case 'coach_advisor':
      return Pages.COACHING
    case 'admin':
    case 'tech_admin':
      return Pages.ADMIN_TOOLS
    case 'therapist':
    case 'program_advisor':
    case 'therapy_reviewer':
      return Pages.ACCOUNT
    case 'community_moderator':
      return Pages.COMMUNITY_PROMPTS
    default:
      return defaultLaunchPage(currentTreatment)
  }
}
