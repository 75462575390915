import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'

const MODEL = new ApiModel('CALENDAR_ACCESS', 'admin/api/v1/calendar_access')

export const CalendarAccess = {
  connection: {
    load: ({ calendarAccess }) => {
      if (calendarAccess == null) MODEL.read()
    },

    isLoaded: ({ calendarAccess }) => calendarAccess != null,

    selector: createSelector(
      [state => state.api.calendar_access],
      calendarAccess => ({ calendarAccess }),
    ),

    shape: {
      calendarAccess: PropTypes.shape({ url: PropTypes.string }).isRequired,
    },
  },
}
