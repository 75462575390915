import { createSelector } from 'reselect'
import PropTypes from 'prop-types'
import ApiModel from '../ApiModel'
import { Firebase } from '../Firebase'
import awaitState from '../awaitState'

const MODEL = new ApiModel('FIREBASE_TOKEN', 'api/v1/firebase_tokens')

const select = state => state?.api?.custom_firebase_tokens?.custom_token?.token

export const createCustomToken = (user) => {
  if (user == null) {
    user = Firebase.getCurrentUser()
  }

  MODEL.create({ user }, { id: 'custom_token' })
  return awaitState(currState => !MODEL.isCreatingSelector({ id: 'custom_token' })(currState))
    .then(updatedState => select(updatedState))
}

export const FirebaseToken = {
  connection: {
    selector: createSelector(
      [select],
      (firebaseToken) => ({
        firebaseToken,
        createCustomToken: (user) => createCustomToken(user),
      }),
    ),

    shape: {
      firebaseToken: PropTypes.object,
      createCustomToken: PropTypes.func.isRequired,
    },
  },
}
