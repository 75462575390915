/* global NativeApp */
import { selectorCache } from 'joyable-js-api'
import i18n from 'i18next'
import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import { connectionContext } from '../components/modelConnect'
import { clearDialogResult, dialogResultSelector, SET_ACTIVE_DIALOG } from '../store/ui'
import { dispatch } from './ReduxService'
import { SCHEDULE_IC_DIALOG_SLUG } from '../activities/templates/AbleToScheduleICTemplate'

const openDialog = (name, props = {}) => dispatch({
  type: SET_ACTIVE_DIALOG,
  dialog: { name: `${upperFirst(camelCase(name))}Dialog`, props },
})

const closeDialog = () => dispatch({ type: SET_ACTIVE_DIALOG, dialog: null })

const openConfirmationDialog = (
  title,
  body,
  options,
  answerSlug,
  footer,
  iconName = null,
  horizontalButtons = false,
  additionalContext,
  hideCloseButton = false,
  infoBanner = null,
  headerIconProps = null,
) => {
  openDialog(
    'Confirmation',
    {
      title,
      body,
      options,
      answerSlug,
      footer,
      iconName,
      horizontalButtons,
      additionalContext,
      hideCloseButton,
      infoBanner,
      headerIconProps,
    },
  )
}

const withDefaults = dialog => ({
  open: () => openDialog(dialog.name),

  ...dialog,
})

// An enum with dialogs and functions for opening them.
export const Dialog = Object.entries({
  ALERT: {
    // Use the model connection in components.
    // Can add an opener like in `CONFIRMATION` if a usecase pops up.
    open: null,

    connection: {
      /**
       * modelConnect { slug }
       */
      selector: selectorCache(
        ({ slug }) => slug,
        ({ slug }) => createSelector(
          [dialogResultSelector(slug)],
          alertDialogResult => ({
            openAlertDialog: (message, body = null, okLabel) => openConfirmationDialog(
              message,
              body,
              { confirm: { slug: 'ok', text: okLabel || i18n.t('buttons.ok') } },
              slug,
            ),

            alertDialogResult,

            clearAlertDialogResult: () => dispatch(clearDialogResult(slug)),
          }),
        ),
      ),

      shape: {
        openAlertDialog: PropTypes.func.isRequired,
        alertDialogResult: PropTypes.any,
        clearAlertDialogResult: PropTypes.func.isRequired,
      },
    },
  },

  TWO_FACTOR_SETUP: {},

  CONFIRMATION: {
    // Use the model connection in components
    open: (
      title,
      body,
      options,
      slug,
      footer,
      iconName = null,
      horizontalButtons = false,
      additionalContext,
      hideCloseButton = false,
      infoBanner,
      headerIconProps = null,
    ) => {
      const { select, awaitValueSet } = connectionContext(Dialog.CONFIRMATION.connection, { slug })
      select().openDialog(
        title,
        body,
        options,
        footer,
        iconName,
        horizontalButtons,
        additionalContext,
        hideCloseButton,
        infoBanner,
        headerIconProps,
      )
      return awaitValueSet('dialogResult').then(
        ({ dialogResult, clearDialogResult }) => {
          clearDialogResult()
          return dialogResult
        },
      )
    },

    // Note that withSlug may only be used in connectionContext or modelConnect usages of connections
    // because `normalizeProps` is not a valid member of a base Connection type. If a slug is
    // desired when using useConnection, pass it as a prop:
    // useConnection(Dialog.CONFIRMATION.connection, { slug: 'mySpecialDialog' })
    // also note that namespaces only have an effect in the modelConnect case, so they are not
    // needed for the useConnection hook.
    // TODO: When this file is converted to TS, this method will return an HOCConnection instead
    // of Connection, which will make this distinction clear(er).
    withSlug: (slug, useNamespace = false) => ({
      ...Dialog.CONFIRMATION.connection,
      normalizeProps: () => ({ slug }),
      ...(useNamespace ? { namespace: slug } : null),
    }),

    connection: {
      /**
       * modelConnect: { slug }
       */
      selector: selectorCache(
        ({ slug }) => slug,
        ({ slug }) => createSelector(
          [dialogResultSelector(slug)],
          dialogResult => ({
            openDialog:
            (
              title,
              body,
              options,
              footer,
              iconName = null,
              horizontalButtons = false,
              additionalContext,
              hideCloseButton = false,
              infoBanner = null,
              headerIconProps = null,
            ) => {
              // The schedule IC dialog is a complex dialog that uses interpolations
              // not currently supported on RN. We'll use the web version for now.
              // TODO: Remove this slug check when variable and component library icon
              // interpolations are supported.
              if (NativeApp.isHosted() && slug !== SCHEDULE_IC_DIALOG_SLUG) {
                const confirmOption = { ...options.confirm, variant: options.confirm.variant || 'primary' }
                const cancelOption = options.cancel
                  ? { ...options.cancel, variant: options.cancel.variant || 'tertiary' }
                  : null
                const parsedOptions = Array.isArray(options)
                  ? options
                  : [confirmOption, cancelOption]

                NativeApp.showNativeConfirmationDialog(title, body || '', JSON.stringify(parsedOptions), slug)
                closeDialog()
              } else {
                openConfirmationDialog(
                  title,
                  body,
                  options,
                  slug,
                  footer,
                  iconName,
                  horizontalButtons,
                  additionalContext,
                  hideCloseButton,
                  infoBanner,
                  headerIconProps,
                )
              }
            },

            dialogResult,

            clearDialogResult: () => dispatch(clearDialogResult(slug)),
          }),
        ),
      ),

      shape: {
        openDialog: PropTypes.func.isRequired,
        dialogResult: PropTypes.any,
        clearDialogResult: PropTypes.func.isRequired,
      },
    },
  },

  DEPRESSION_UPGRADE: {},

  FEAR: {
    open: (fearId = null) => openDialog('EditFear', { fearId }),
  },

  ALLOCATION: {
    open: coachId => openDialog('EditAllocation', { coachId }),
  },

  RECURRING_ASSESSMENT: {
    open: props => openDialog('RecurringAssessment', props),
  },

  PROGRAM_COMING_SOON: {},

  SWITCH_PROGRAM: {
    open: (program, typeOfProgramChange) =>
      openDialog('SwitchProgram', { program, typeOfProgramChange }),
  },

  UPDATE_PAYMENT: {
    open: title => openDialog('UpdatePayment', { title }),
  },

  LOG_TEMPLATE_DETAIL: {
    open: activityController => openDialog('LogTemplateDetail', { activityController }),
  },

  INFO: {
    open: infoProps => openDialog('AbleToInfo', infoProps),
  },

  MEMBER_ID: {
    open: props => openDialog('AbleToMemberId', props),
  },

  NEW_COACH_CALL_NOTE: {
    open: props => openDialog('NewCoachCallNote', props),
  },

  PROGRAM_COMPLETION: {
    open: props => openDialog('ProgramCompletion', props),
  },

  VIDEO_HELPER: {
    open: props => openDialog('VideoHelper', props),
  },

  CONSENT_TO_TREAT: {
    open: props => openDialog('ConsentToTreat', props),
  },

  CONSENT_SIGNED: {
    open: props => openDialog('ConsentSigned', props),
  },

  LEAVE_ACTIVITY: {
    open: ({ onConfirm }) => openDialog('LeaveActivity', { onConfirm }),
  },

  EDIT_DATE_TIME: {
    open: (onDateSave, savedDate) => openDialog('EditDateTime', { onDateSave, savedDate }),
  },

  ADD_LIST_ITEM: {
    open: props => openDialog('AddListItem', props),
  },

  RESCHEDULE_REQUEST: {
    open: props => openDialog('RescheduleRequest', props),
  },

  EDIT_ACTIVITY: {
    open: props => openDialog('EditActivity', props),
  },

  ACCOUNT_DELETION_REQUEST: {
    open: props => openDialog('AccountDeletionRequest', props),
  },

  EMOTIONAL_EMERGENCY: {
    open: props => openDialog('EmotionalEmergency', props),
  },

  SC_CHECKLIST_COMPLETION: {
    open: props => openDialog('ScChecklistCompletion', props),
  },

  C_PLUS_WEEK_0_COMPLETION: {
    open: props => openDialog('CPlusWeek0Completion', props),
  },

  ADD_NEW_SELECTION_LIST_ITEM: {
    open: props => openDialog('AddNewSelectionListItem', props),
  },

  DEFAULT_ERROR: {
    open: props => openDialog('DefaultError', props),
  },

  CP_REMINDER: {
    open: props => openDialog('CPReminder', props),
  },

  NPS_SURVEY: {
    open: props => openDialog('NpsSurvey', props),
  },

  REMINDER_IS_SET: {
    open: props => openDialog('ReminderIsSet', props),
  },

  REDIRECT_CONTEXT: {
    open: props => openDialog('RedirectContext', props),
  },

  IDLE_CONFIRMATION: {
    open: props => openDialog('IdleConfirmation', props),
  },

  DEEP_LINK: {
    open: props => openDialog('DeepLink', props),
  },

}).reduce((Dialogs, [name, dialog]) => ({
  ...Dialogs,
  [name]: withDefaults({ name, ...dialog }),
}), {})
