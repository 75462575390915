// Utilities to access the browser history in places where access the react component tree
// is awkward or dangerous.

let browserHistory
export const setBrowserHistory = history => { browserHistory = history }

export default {
  get action () { return browserHistory.action },
  get location () { return browserHistory.location },
  push: path => browserHistory.push(path),
  replace: path => browserHistory.replace(path),
  back: () => browserHistory.goBack(),
}
