import Action from '../Action'
import ApiModel from '../ApiModel'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'

const MODEL = new ApiModel('NEXT_ACTIVITY', 'api/v1/next_activity')

export const SUCCESS_TYPE = Action.READ.successType(MODEL)

const SHAPE = PropTypes.shape({
  next_activity: PropTypes.shape({
    next_activity_slug: PropTypes.string.isRequired,
  }),
})

// eslint-disable-next-line camelcase
const selectNextActivitySlug = state => state.api.next_activity?.next_activity_slug

export const NextActivity = {
  connection: {
    load: ({ nextActivitySlug }, { currentActivitySlug, goToActivitySlug }) => {
      MODEL.read({ query: { current_activity_slug: currentActivitySlug, go_to_activity_slug: goToActivitySlug } })
    },

    isLoaded: ({ nextActivitySlug }) => nextActivitySlug != null,

    isLoading: (state, _, { currentActivitySlug, goToActivitySlug }) => {
      return !!Action.READ.getPending(
        state,
        MODEL.getUrl({ query: { current_activity_slug: currentActivitySlug, go_to_activity_slug: goToActivitySlug } }),
      )
    },

    onMount: () => ApiModel.pruneCache('next_activity'),

    selector: createSelector([selectNextActivitySlug], nextActivitySlug => ({ nextActivitySlug })),

    shape: { nextActivitySlug: SHAPE.isRequired },
  },
}
