import 'core-js'
import queryString from 'query-string'
import 'whatwg-fetch'
import '../scss/global.scss'
import { IN_IFRAME } from './constants'
import prepareNativeAppInterface from './nativeApp/prepareNativeAppInterface'
import Log from './services/log'
import configureStore from './store/configureStore'
import {
  AUTH0_FEATURE_FLAG_KEY, clearJwt, isAuth0Enabled, loginRedirect, logoutAndClearAuth0Tokens, getAuth0Tokens,
} from 'joyable-js-api/src/ApiFetch'
import Pages from '@app/js/routing/Pages'
import { activityPath } from '@app/js/routing/Activities'

// needed to make getDeviceId() a valid call
prepareNativeAppInterface()

const {
  login_redirect: redirect,
  token: jwtToken,
  saml_campaign: samlCampaign,
  sign_in_method: signInMethod,
  deep_link: deepLinkFlag,
  continueUrl,
} = queryString.parse(window.location.search)

const EMAIL_SIGN_IN_METHOD = 'email_auth_link'
const EXPIRED_LINK_ACTIVITY_SLUG = 'link_expired'

const isAuthSuccess = ({ type }) => type === 'AUTHENTICATION_READ_SUCCESS'
const isAuthExpired = ({ type, error }) =>
  type === 'AUTHENTICATION_READ_FAILURE' &&
  error?.message === 'Signature has expired'
const isDeactivated = ({ type, error }) =>
  type === 'AUTHENTICATION_READ_FAILURE' &&
  error?.message === 'Your account is not activated yet.'
const isApiError = ({ type, error }) =>
  type === 'AUTHENTICATION_READ_FAILURE' &&
  (
    error?.message === 'Error handling Api response' ||
    error?.message === 'Api::V1::ApiController::InvalidAuthenticationToken'
  )
const isSsoRelogin = ({ type, error }) =>
  type === 'AUTHENTICATION_READ_FAILURE' &&
  error?.message === 'Attempting SSO while logged in'

async function authenticate () {
  const [store, { Authentication, initializeApiLayer, Firebase, FirebaseToken, Connect }] = await Promise.all([
    configureStore(),
    import('joyable-js-api'),
  ])

  initializeApiLayer(store, Log, process.env.GOOGLE_API_KEY, process.env.GOOGLE_AUTH_DOMAIN)

  if (isAuth0Enabled()) {
    const { accessToken } = await Authentication.loadAuth0Tokens()
    if (accessToken) return store
  }

  const action = await Authentication.loadToken({ jwtToken, samlCampaign, signInMethod })

  if (isAuthSuccess(action)) {
    const ssoUrl = action.response?.jwt?.sso_redirect_url
    if (ssoUrl != null && IN_IFRAME) {
      // we're in an iframe and cannot do a SAML redirect. Ignore. An error message will be
      // displayed later, if appropriate
      return store
    }

    if (ssoUrl != null || redirect != null) {
      if (redirect?.match(/https:\/\/en.*\.ableto.com/)) {
        const redirectToE2 = async (user, options) => {
          const redirect = options.redirect
          if (!isAuth0Enabled()) {
            const firebaseCustomToken = await FirebaseToken.connection.selector().createCustomToken(user)
            window.location.replace(`${redirect}?idp_custom_token=${firebaseCustomToken}`)
          } else {
            window.location.replace(`${redirect}`)
          }
        }
        if (!isAuth0Enabled()) {
          Firebase.callOnUserLoaded(redirectToE2, { redirect })
        }
      } else {
        window.location = ssoUrl || redirect
      }

      // prevent the promise chain from resolving further
      return new Promise(() => {})
    }
  } else if (isAuthExpired(action)) {
    const url = Array.isArray(continueUrl) ? continueUrl[0] : continueUrl
    const email = url != null ? queryString.parseUrl(url).query?.email : null
    const wasAuth0User = Object.values(getAuth0Tokens()).some(value => value)
    const shouldGenerateReentryLink = email && signInMethod === EMAIL_SIGN_IN_METHOD && !wasAuth0User

    // Clearing expired token from the local storage
    clearJwt()

    if (shouldGenerateReentryLink) {
      await Connect.generateReentryLink(email)
      window.location = activityPath(EXPIRED_LINK_ACTIVITY_SLUG, Pages.PLACEMENT.path)
      return new Promise(() => {})
    }

    if (isAuth0Enabled()) {
      await logoutAndClearAuth0Tokens()
    }

    // If the user is logging via SSO, we want to reload the page to attempt to get a new token
    if (samlCampaign != null) {
      window.location.reload()
      return new Promise(() => {})
    }

    // In this case we know that we _were_ logged in, so send to login redirect with a helpful
    // error message
    const query = { login_redirect: window.location.pathname, reason: 'timed_out' }
    window.location = `/?${queryString.stringify(query)}`
    return new Promise(() => {})
  } else if (isDeactivated(action) || isApiError(action)) {
    await Authentication.clearSession()
  } else if (isSsoRelogin(action) || deepLinkFlag) {
    window.location = loginRedirect()
  }

  return store
}

const fetchFeatureFlags = async () => {
  const url = `${process.env.API_ROOT}api/v1/feature_flags`
  const res = await fetch(url)
  if (res.status !== 200) throw new Error('Error fetching feature flags')
  return res.json()
}

const bootup = async () => {
  // Code splitting here is to prevent app.js from getting huge.
  // depend on the auth call so we don't do any further processing until our identity is established
  const featureFlags = (await fetchFeatureFlags()).feature_flags
  window.sessionStorage.setItem(AUTH0_FEATURE_FLAG_KEY, featureFlags.auth0_login)
  const [{ bootstrap }, store] = await Promise.all([import('./bootstrap'), authenticate()])
  bootstrap(store)
}

if (!IN_IFRAME || document.hasStorageAccess == null) {
  // if we're not in an iframe or this browser doesn't support the storage access api, proceed
  bootup()
} else {
  // Safari has gone full draconian, and requires the user to push a button to indicate that they're
  // willing to let iframe content access the cookies for the iframe's 1st party site. This step
  // will request that access from the user
  import('./bootstrap/requestAccess').then(({ requestAccess }) => requestAccess(bootup))
}
