import { ActivityControllerContext } from '@app/js/contexts/ActivityControllerContext'
import { useCurrentPage } from '@app/js/lib/hooks'
import i18n from 'i18next'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { ThemeContext } from 'styled-components'
import META_OG_LOGO_URL from '../../images/meta_og_logo.png'

function useTitleTab (useExactPageTitle = false) {
  const { getPageTitle, getFaviconUrl } = useContext(ThemeContext)
  const page = useCurrentPage(useExactPageTitle)
  const activityController = useContext(ActivityControllerContext)
  const title = getPageTitle(page, activityController)
  const faviconUrl = getFaviconUrl(page, activityController?.config)
  return { title, faviconUrl }
}

function renderGoogleTagManagerScript () {
  const googleTagContainer = process.env.GOOGLE_TAG_CONTAINER

  return `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${googleTagContainer}');
  `
}

function JoyableHelmet ({ title, meta, children, useExactPageTitle }) {
  const { title: themeTitle, faviconUrl } = useTitleTab(useExactPageTitle)
  title ??= themeTitle
  meta ??= title

  return (
    <Helmet>
      <title>{title}</title>
      <link rel='shortcut icon' type='image/png' href={faviconUrl} />
      <meta name='description' content={meta} />
      <meta property='og:url' content='https://app.ableto.com' />
      <meta property='og:type' content='website' />
      <meta property='og:description' content={i18n.t('pages.ogDescription')} />
      <meta property='og:title' content={i18n.t('pages.ogTitle')} />
      <meta property='og:image' content={META_OG_LOGO_URL} />
      <script>
        {renderGoogleTagManagerScript()}
      </script>
      {children}
    </Helmet>
  )
}

JoyableHelmet.propTypes = {
  // Please avoid using the title parameter, except for debug tools. It is supplied by the theme
  // for normal user-facing pages.
  title: PropTypes.string,
  meta: PropTypes.string,
  children: PropTypes.node,
  useExactPageTitle: PropTypes.bool,
}

JoyableHelmet.defaultProps = {
  faviconColor: 'red',
}

export default JoyableHelmet
