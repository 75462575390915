import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'
import { registerModelReducer } from '../reducers'
import Action from '../Action'

const MODEL = new ApiModel('MOOD_RATINGS', 'api/v1/mood_ratings')

registerModelReducer('moodRatings',
  (state = { loadedMine: false }, { type }) => {
    if (type === Action.READ.successType(MODEL)) {
      return { ...state, loadedMine: true }
    }

    if (type === Action.DESTROY.successType(MODEL)) {
      return { ...state, loadedMine: false, moodRatings: null }
    }

    return state
  },
)

export const SHAPE = PropTypes.shape({
  value: PropTypes.number,
  user_id: PropTypes.number,
})

const select = state => state.api.mood_ratings
const selectIsLoaded = state => state.models.moodRatings.loadedMine

const selectAvailableConnection = createSelector(
  [select, selectIsLoaded],
  (moodRatings, loaded) => ({
    moodRatings: moodRatings ?? (loaded ? [] : null),
    loaded,
    updateLookBack: startDate => MODEL.read({ query: { start_date: startDate } }),
    deleteRating: id => MODEL.destroy({ id }),
  }),
)

export const MoodRatings = {
  SHAPE,
  connection: {
    load: ({ loaded }, { startDate }) => {
      if (!loaded && startDate != null) MODEL.read({ query: { start_date: startDate } })
    },

    isLoaded: ({ loaded }) => loaded,

    selector: selectAvailableConnection,

    shape: {
      moodRatings: PropTypes.arrayOf(SHAPE),
      moodIsLoaded: PropTypes.bool,
      deleteMood: PropTypes.func.isRequired,
    },
  },
  delete: (id) => MODEL.destroy({ id }),
}
