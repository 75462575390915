import { Action } from 'redux'
import { PRUNE_API_TREE } from './ApiModel'
import { SocketActions } from './socketActions'
import { FailedApiResolution } from './models/types'

export type ApiKey = string | number
export type ApiPrimitive = string | number | boolean | undefined | null | RequestInit | Response
export type ApiState = {
  [key: ApiKey]: ApiPrimitive | ApiState | (ApiPrimitive | ApiState)[]
}
export type ApiValue = ApiPrimitive | ApiState

export type ApiSuccessValue = {
  key: ApiKey[],
  value: ApiValue | symbol | ApiValue[],
}

export type HTTPAction = Action<string> & {
  url: string,
  options: RequestInit,
}

export type ApiSuccessData = { values: ApiSuccessValue[], modelMap: ApiSuccessValue[] }
export type ApiSuccessAction = HTTPAction & {
  response: ApiState,
  method: string,
  successData: ApiSuccessData,
  socketData?: ApiState,
  pruneKeys?: ApiKey[][],
  keepKeys?: ApiKey[][],
}

export type ApiError = {
  message: string,
}

export type ApiFailureAction = HTTPAction & {
  error: ApiError,
  response?: Response,
  recordFailure?: (response: Response) => boolean,
}

export type PruneAction = Action<string> & {
  pruneKeys: ApiKey[][],
  keepKeys?: ApiKey[][],
  modelMapPruneKeys?: ApiKey[][],
}

export type TypingAction = Action<string> & {
  // eslint-disable-next-line camelcase
  user_id: string | number
}

export type ApiAction =
  HTTPAction |
  ApiSuccessAction |
  ApiFailureAction |
  PruneAction |
  TypingAction

export type PendingErrorState = { failed: FailedApiResolution }
export type Pending = RequestInit | PendingErrorState

const isOneOf = <BroadT, NarrowT extends BroadT>(
  guards: ((object: BroadT) => boolean)[],
  object: BroadT,
): object is NarrowT => guards.find((guard) => guard(object)) != null

export const isApiAction = (action: Action<string>): action is ApiAction =>
  isOneOf([isHttpAction, isPruneAction, isTypingAction], action)
export const isHttpAction = (action: Action<string>): action is HTTPAction =>
  isOneOf([isSuccessAction, isFailureAction, isPendingAction, isClearPendingAction], action)
export const isSuccessAction = (action: Action<string>): action is ApiSuccessAction =>
  action.type.endsWith('_SUCCESS') || action.type === SocketActions.CHANNEL_RECEIVED
export const isFailureAction = (action: Action<string>): action is ApiFailureAction =>
  action.type.endsWith('_FAILURE')
export const isPruneAction = (action: Action<string>): action is PruneAction =>
  action.type === PRUNE_API_TREE
export const isPendingAction = (action: Action<string>): boolean =>
  action.type.endsWith('_REQUEST')
export const isClearPendingAction = (action: Action<string>): boolean =>
  action.type.endsWith('_CLEAR_PENDING')
export const isTypingAction = (action: Action<string>): action is TypingAction =>
  action.type === SocketActions.TYPING || action.type === SocketActions.CLEAR_TYPING
