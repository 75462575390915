/**
 * Makes a promise cancelable by returning an object with a cancel() method available, and the
 * wrapped promise for further chaining.
 *
 * Modified to meet our style standards from
 * https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
 */
export const makeCancelable = promise => {
  let hasCanceled = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      result => hasCanceled ? reject(new CanceledPromiseError()) : resolve(result),
      error => hasCanceled ? reject(new CanceledPromiseError()) : reject(error),
    )
  })

  return {
    promise: wrappedPromise,
    cancel () {
      hasCanceled = true
    },
  }
}

class CanceledPromiseError extends Error {
  isCanceled = true

  constructor () {
    super('promise is canceled')
  }
}
