/* eslint-disable camelcase */
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'
import { Connection } from './types'
import { ApiState } from '../types'

const ADMIN_MODEL = new ApiModel('ADMIN_PROVIDERS', 'admin/api/v1/payers')

type AdminPayer = {
  id: number,
  name: string,
  created_at: string,
  updated_at: string,
}

type PayersConnection = {
  payers: Array<AdminPayer>
  payersError?: string
}

const adminConnection = createSelector(
  [
    (state: ApiState) => {
      const payers = (state.api as ApiState).payers as { [key: string]: AdminPayer }
      return (payers && Object.values(payers)) || null
    },
    (state: ApiState) => ADMIN_MODEL.getReadErrorMessage(state),
  ],
  (payers, payersError) => ({ payers, payersError }),
)

export const Payer: {
  adminConnection: Connection<PayersConnection>,
} = {
  adminConnection: {
    load: ({ payers }) => {
      if (!payers) void ADMIN_MODEL.read()
    },

    isLoaded: ({ payers, payersError }) => {
      return !!payers || !!payersError
    },

    selector: adminConnection,
  },
}
