import get from 'lodash/get'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import ApiModel from '../ApiModel'

const MODEL = new ApiModel('SSO', 'api/v1/sso/signup_url')

const loadSso = (redirectTo, eligibilityFailureUrl) => MODEL.read({
  query: { redirect_to: redirectTo, eligibility_failure_url: eligibilityFailureUrl },
})

// double `signup_url` path is due to some awkwardness around how this API is implemented
const selectSignupUrl = state => get(state.api.ssos, ['signup_url', 'signup_url'])

const selectSignupUrlError = state => MODEL.getReadErrorMessage(state)

export const Sso = {
  connection: {
    load: ({ ssoSignupUrl, ssoSignupError }, { redirectTo, eligibilityFailureUrl }) => {
      if (ssoSignupUrl == null && ssoSignupError == null) {
        loadSso(redirectTo, eligibilityFailureUrl)
      }
    },

    isLoaded: ({ ssoSignupUrl, ssoSignupError }) => ssoSignupUrl != null || ssoSignupError != null,

    selector: createSelector(
      [selectSignupUrl, selectSignupUrlError],
      (ssoSignupUrl, ssoSignupError) => ({ ssoSignupUrl, ssoSignupError }),
    ),

    shape: {
      ssoSignupUrl: PropTypes.string,
      ssoSignupError: PropTypes.string,
    },
  },
}
