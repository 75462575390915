import ApiModel from './ApiModel'
import { Logger, setLoggingTarget } from './log'
import { Firebase } from './Firebase'
import { Store } from 'redux'

export function initializeApiLayer (
  store: Store,
  logTarget: Logger,
  googleApiKey?: string,
  googleAuthDomain?: string,
) {
  ApiModel.store = store
  setLoggingTarget(logTarget)

  if (googleApiKey != null && googleAuthDomain != null) {
    Firebase.initialize(googleApiKey, googleAuthDomain)
  }
}

export { Firebase } from './Firebase'
export { SessionStorage } from './SessionStorage'

export * from './gateway'
export * from './models'
export * from './models/types'

export { ApiGatewayProvider, useApiGateway } from './ApiGateway'

export { default as Action } from './Action'

export { default as Log } from './log'

export { getDeviceId } from './ApiFetch'

export { apiMiddleware } from './apiMiddleware'
export { socketMiddleware } from './socketMiddleware'
export { default as awaitState } from './awaitState'

export { WorklistStructure, formatFromNow } from './util/WorklistStructure'
export { selectorCache } from './util/selectorCache'
export { default as awaitConnection } from './util/awaitConnection'

export { default as Reducers, setIn, deleteIn, mergeIn } from './reducers'

export { invertPredicate, trace } from './models/utilities'

export * as Constants from './apiConstants'

export { getJobResult } from './JobHandler'
