import moment from 'moment-timezone'
import qs from 'query-string'
import { useCallback, useMemo } from 'react'
import { useMutation, useQuery } from 'react-query'
import { FirebaseToken, User } from 'joyable-js-api'
import { useConnection } from '@app/js/lib/useConnection'
import { useApiGateway } from '../ApiGateway'
import { Firebase } from '../Firebase'
import { useEnableToMockApi } from './mock'
import { getAuth0Tokens, isAuth0Enabled } from 'joyable-js-api/src/ApiFetch'

const getErrorMessage = data => data?.message ?? data?.error?.message ?? 'something went wrong'

export const formatProviderAvailabilities = (providers, timezone) => {
  return providers?.map((provider) => ({
    ...provider,
    first_available_date: provider.first_available_date ? moment(provider.first_available_date).tz(timezone) : null,
    slots: provider.slots.map(slot => ({
      ...slot,
      period: {
        begin: moment(slot.period.begin).tz(timezone),
        end: moment(slot.period.end).tz(timezone),
      },
    })),
  }),
  )
}

export const formatSessionsData = sessions => {
  return sessions?.map(session => ({
    ...session,
    start_time: moment.utc(session.start_time),
    end_time: moment.utc(session.end_time),
  }))
}

const checkIsE2MockActive = (insuranceMemberId) => {
  return process.env.E2_MOCK_ENABLED && insuranceMemberId?.includes('useE2Mock=true')
}

const createHeaders = (customIdpToken, includeContentType = true) => {
  const headers = {}
  if (includeContentType) {
    headers['content-type'] = 'application/json'
  }
  if (isAuth0Enabled()) {
    headers.Authorization = `Bearer ${getAuth0Tokens().accessToken}`
  } else {
    headers['idp-custom-token'] = customIdpToken
  }
  return headers
}

/**
 * Hook used to access a hook-based interface for the EnableTo API.
 * Check lib/joyable-js-api/src/ApiGateway.js#ApiGatewayProvider for the set of default options.
 */
export function useEnableToApi () {
  const [, { me }] = useConnection(User.meConnection)
  const api = useApiGateway('enableto', isAuth0Enabled() ? 2 : 1)
  const isE2MockActive = checkIsE2MockActive(me?.insurance_member_id)

  const enableToApi = useMemo(() => ({
    /**
     * Hook used to lookup participant sessions asynchronously.
     */
    useTreatmentSessionsQuery (treatmentId, options = {}) {
      const [, { createCustomToken }] = useConnection(FirebaseToken.connection)

      const select = useCallback(({ data }) => {
        return data != null
          ? { data: formatSessionsData(data) }
          : null
      }, [])

      return useQuery([treatmentId], async () => {
        let customIdpToken
        let headers
        if (isAuth0Enabled()) {
          headers = createHeaders(null, false)
        } else {
          customIdpToken = await createCustomToken()
          headers = createHeaders(customIdpToken, false)
        }

        const response = await api.get(`/api/external/treatment/${treatmentId}/sessions`, {
          headers,
        })
        const data = await response.json()

        if (!response.ok) {
          return { error: getErrorMessage(data) }
        }

        return data
      }, {
        select,
        ...options,
      })
    },

    /**
     * Hook used to lookup multiple providers availability asynchronously
     */
    useMultipleProvidersAvailabilityQuery ({
      state,
      insuranceProviderName,
      slotMinutes,
      daysOut,
      startDate,
      timezone,
      legacyTimezone,
    }, options = {}) {
      const select = useCallback(({ data }) => {
        return formatProviderAvailabilities(data?.attributes?.users, timezone)
      }, [timezone])

      return useQuery([state, insuranceProviderName, slotMinutes, daysOut, startDate, legacyTimezone], async () => {
        const query = qs.stringify({
          state,
          insurance_provider: insuranceProviderName,
          slot_minutes: slotMinutes,
          days: daysOut,
          start_date: startDate,
          timezone: legacyTimezone,
        })
        const serviceUrl = '/api/availability-schedules'
        const response = await api.get(`${serviceUrl}?${query}`)
        const data = await response.json()

        return data
      }, {
        select,
        ...options,
      })
    },
    /**
     * Hook used to lookup single provider availability asynchronously
     */
    useSingleProviderAvailabilityQuery ({
      state,
      treatmentId,
      isCoachSession,
      slotMinutes,
      daysOut,
      startDate,
      timezone,
      legacyTimezone,
    }, options = {}) {
      const select = useCallback(({ data }) => {
        const providers = data?.attributes?.users
        if (providers == null) return []

        const provider = isCoachSession ? providers.coach : providers.therapist
        return provider != null ? formatProviderAvailabilities([provider], timezone) : []
      }, [isCoachSession, timezone])

      return useQuery([state, treatmentId, slotMinutes, daysOut, startDate, legacyTimezone], async () => {
        const query = qs.stringify({
          state,
          slot_minutes: slotMinutes,
          days: daysOut,
          start_date: startDate,
          timezone: legacyTimezone,
        })
        const response = await api.get(`/api/external/treatment/${treatmentId}/availability?${query}`, {
          headers: isAuth0Enabled() ? createHeaders(null, false) : {},
        })
        const data = await response.json()

        return data
      }, {
        select,
        ...options,
      })
    },
    /**
     * Hook used to schedule IC appointments asynchronously
     * @param {UseMutationOptions} options
     */
    useIcAppointmentMutation (options = {}) {
      return useMutation(async ([{ leadId, appointment }]) => {
        const response = await api.post(`/api/schedule-lead/${leadId}`, {
          body: JSON.stringify(appointment),
          headers: {
            'content-type': 'application/json',
          },
        })
        const data = await response.json()

        if (!response.ok) {
          throw Error(getErrorMessage(data))
        }

        return data
      }, options)
    },
    /**
     * Hook used to reschedule IC appointments asynchronously
     * @param {UseMutationOptions} options
     */
    useIcAppointmentRescheduleMutation (options = {}) {
      return useMutation(async ([{ treatmentId, appointment, customIdpToken }]) => {
        const response = await api.patch(`/api/external/treatment/${treatmentId}/reschedule-intake`, {
          body: JSON.stringify(appointment),
          headers: createHeaders(customIdpToken),
        })
        const data = await response.json()

        if (!response.ok) {
          throw Error(getErrorMessage(data))
        }

        return data
      }, options)
    },
    /**
     * Hook used to reschedule A session appointments asynchronously
     * @param {UseMutationOptions} options
     */
    useSessionRescheduleMutation (options = {}) {
      return useMutation(async ([{ sessionId, appointment, customIdpToken }]) => {
        const response = await api.patch(`/api/external/session/${sessionId}/reschedule`, {
          body: JSON.stringify(appointment),
          headers: createHeaders(customIdpToken),
        })

        const data = await response.json()
        if (!response.ok) {
          switch (response.status) {
            case 401:
              throw Error('Unauthorized to reschedule session')
            case 403:
              throw Error('Forbidden')
            case 404:
              throw Error('Treatment not found')
            case 422:
              throw Error('Unprocessable Content')
            default:
              throw Error(getErrorMessage(data))
          }
        }

        return data
      }, options)
    },
    /**
     * Hook used to fetch participant Profile data asynchronously
     */
    useProfile (options) {
      let notAuthorized = false
      const shouldRetry = (failureCount) => {
        if (failureCount >= 3 || notAuthorized) return false
        return true
      }
      const queryOptions = {
        ...options,
        refetchOnMount: false,
        retry: shouldRetry,
        retryOnMount: false,
        cacheTime: 'Infinity',
      }

      return useQuery('profile', async () => {
        let token
        let query
        let response

        if (!isAuth0Enabled()) {
          token = await Firebase.getIdpToken()

          if (token == null) {
            throw Error('Missing idp token for profile fetch')
          }

          query = qs.stringify({ identity_token: token })
          response = await api.get(`/api/external/participant-profile?${query}`)
        } else {
          token = getAuth0Tokens().accessToken
          response = await api.get('/api/external/participant-profile', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        }

        const data = await response.json()

        if (!response.ok && response.status !== 401) {
          throw Error('Unable to fetch EnableTo profile')
        } else if (response.status === 401) {
          notAuthorized = true
          throw Error('Not Authorized to access EnableTo Profile')
        }

        return data
      }, queryOptions)
    },
  }), [api])

  const mockedEnableToApi = useEnableToMockApi()

  return isE2MockActive ? mockedEnableToApi : enableToApi
}
