import PropTypes from 'prop-types'
import { createSelector } from 'reselect'

const SHAPE = PropTypes.shape({
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  headshot_url: PropTypes.string,
  phone: PropTypes.string.isRequired,
  pronouns: PropTypes.shape({
    subjective: PropTypes.string.isRequired,
    objective: PropTypes.string.isRequired,
    possessive_adj: PropTypes.string.isRequired,
  }).isRequired,
  address: PropTypes.string.isRequired,
  specialization: PropTypes.string.isRequired,
  name_and_license: PropTypes.string.isRequired,
  provider_type: PropTypes.string.isRequired,
})

function select (state) {
  return state.api.providers && Object.values(state.api.providers)[0]
}

export const Provider = {
  connection: {
    selector: createSelector([select], provider => ({ provider })),

    shape: {
      provider: SHAPE,
    },
  },
}
