/* global Response */
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel from '../ApiModel'
import { registerModelReducer } from '../reducers'
import { selectorCache } from '../util/selectorCache'
import { CALL_MEDIUM_LABELS } from '@app/js/constants'
import { LOAD_SUCCESS_TYPE as USER_LOAD_SUCCESS_TYPE } from './User'

const MODEL = new ApiModel('CALL_STATUS', 'api/v1/call_status')
const ADMIN_MODEL = new ApiModel('ADMIN_CALL_STATUS', 'admin/api/v1/call_statuses')
const ADHOC_MODEL = new ApiModel('ADHOC_CALL', 'admin/api/v1/adhoc_call')

registerModelReducer('callStatus', (state = { }, { type, response }) => {
  switch (type) {
    case USER_LOAD_SUCCESS_TYPE:
      return { ...state, myCallStatusId: response.user.call_status_id }
    case Action.READ.successType(MODEL):
      return { ...state, myCallStatusId: response.call_status.id }
    case Action.READ.successType(ADMIN_MODEL):
      return { ...state, hasClientIndex: true }
    default:
      return state
  }
})

const SHAPE = PropTypes.shape({
  // the other statuses should never be sent to the client.
  status: PropTypes.oneOf([
    'scheduling',
    'scheduled',
    'missed',
    'canceled',
    'completed',
  ]),
  call_type: PropTypes.oneOf(['kickoff', 'checkin']),
  client_timezone: PropTypes.string,
  scheduled_for: PropTypes.string,
  recurring_status: PropTypes.oneOf(['once', 'weekly', 'biweekly']),
  duration: PropTypes.string,
  medium: PropTypes.oneOf(['call', 'chat', 'twilio_video']),
})

const load = () => MODEL.read()

function updateClientTimezoneIfNeeded () {
  const currentTimezone = moment.tz.guess()
  const { callStatus } = selectConnection(ApiModel.reduxState)

  if (callStatus && callStatus.client_timezone !== currentTimezone &&
    window.location.pathname !== '/referrals/scheduling') {
    setClientTimezone(callStatus, currentTimezone)
  }
}

export const selectCallStatuses = state => state.api.call_statuses

const select = createSelector(
  [state => state.models.callStatus, selectCallStatuses],
  ({ myCallStatusId }, callStatuses) =>
    (myCallStatusId && callStatuses && callStatuses[myCallStatusId]),
)

const update = (callStatus, updates) =>
  MODEL.updateModify({ call_status: { ...callStatus, ...updates } })

function setClientTimezone (callStatus, timezone) {
  ApiModel.pruneCache('call_statuses')
  update(callStatus, { client_timezone: timezone })
}

const updateUserPreferenceCall = (callStatus, medium) => {
  ApiModel.pruneCache('call_statuses')
  update(callStatus, { medium: medium })
}

function scheduleCall (callStatus, callTime, callMedium, fromReferrals = false) {
  update(callStatus, {
    scheduled_for: callTime.format(),
    medium: callMedium || callStatus.medium,
    from_referrals: fromReferrals,
    status: 'scheduled',
  })
}

function cancelCall (callStatus, cancelSeries = false) {
  update(callStatus, {
    schedule_for: null,
    status: 'canceled',
    recurring_status: cancelSeries ? 'once' : callStatus.recurring_status,
  })
}

function rescheduleCall (callStatus, callTime, callMedium) {
  update(callStatus, {
    scheduled_for: callTime.format(),
    medium: callMedium || callStatus.medium,
    status: 'rescheduled',
  })
}

const isUpdating = (state) => MODEL.isUpdatingSelector()(state)

const updateError = (state) => MODEL.getUpdateErrorMessage(state)

const clearUpdateError = () =>
  ApiModel.dispatch(Action.UPDATE_MODIFY.getClearPendingAction(MODEL, MODEL.baseUrl))

function formatScheduledFor (callStatus, {
  includeDayOfWeek = true,
  includeTimezone = false,
  includeDuration = false,
  includeInterpunct = true, // can be used only if 'includeDayOfWeek' is 'true'
} = {}) {
  if (callStatus == null || callStatus.scheduled_for == null) return null
  const scheduledFor = moment.tz(callStatus.scheduled_for,
    callStatus.client_timezone || moment.tz.guess())
  const date = includeDayOfWeek
    ? scheduledFor.format(`dddd${includeInterpunct ? ' ·' : ','} MMMM Do`)
    : scheduledFor.format('MMMM Do')
  let startFormat = 'h:mm'
  if (!includeDuration) {
    startFormat += ' A'
    if (includeTimezone) startFormat += ' z'
  }
  const start = scheduledFor.format(startFormat)
  const glue = includeDayOfWeek ? ', ' : ' at '
  if (!includeDuration) return `${date}${glue}${start}`

  const endFormat = includeTimezone ? 'h:mm A z' : 'h:mm A'
  const end = scheduledFor.add(parseInt(callStatus.duration), 'm').format(endFormat)
  return `${date}${glue}${start} - ${end}`
}

const selectConnection = createSelector(
  [select, isUpdating, updateError],
  (callStatus, callStatusIsUpdating, callStatusUpdateError) => ({
    callStatus,
    callStatusIsUpdating,
    callStatusUpdateError,
    clearCallStatusUpdateError: clearUpdateError,
    setClientTimezone: timezone => setClientTimezone(callStatus, timezone),
    scheduleCall: (callTime, callMedium, fromReferrals) =>
      scheduleCall(callStatus, callTime, callMedium, fromReferrals),
    cancelCall: (cancelSeries = false) => cancelCall(callStatus, cancelSeries),
    rescheduleCall: (callTime, callMedium) => rescheduleCall(callStatus, callTime, callMedium),
    setCallMedium: medium => updateUserPreferenceCall(callStatus, medium),
    updateCallStatus: callStatus => update(callStatus, {}),
    getMinutesUntilCallStarts,
    getCallLabelForMedium,
  }),
)

const loadCallStatus = id => ADMIN_MODEL.read({ id })

const updateCallStatus = (callStatus, updateAvailabilityStartTime = null) => {
  ADMIN_MODEL.updateModify({ call_status: callStatus }, { id: callStatus.id })
  if (updateAvailabilityStartTime != null) {
    // TODO (NJC): handle updateAvailability
  }
}

const createAdhocData = (userId, scheduledFor, status) =>
  ({ adhoc_call: { user_id: userId, status, call_type: 'checkin', scheduled_for: scheduledFor, medium: 'call' } })

const missedAdhocCall = (userId, calledAt) =>
  ADHOC_MODEL.create(createAdhocData(userId, calledAt, 'missed'))

const completedAdhocCall = (userId, calledAt) =>
  ADHOC_MODEL.create(createAdhocData(userId, calledAt, 'completed'))

const selectUserConnection = selectorCache(
  ({ user, callStatusId }) => callStatusId || user.call_status_id,
  ({ user, callStatusId }) => {
    const id = callStatusId || user.call_status_id

    const selectCallStatus = createSelector(
      [selectCallStatuses],
      callStatuses => callStatuses && callStatuses[id],
    )

    const callStatusPendingSelector = createSelector(
      [Action.UPDATE_MODIFY.pendingSource],
      pending => pending[ADMIN_MODEL.getUrl({ id })],
    )

    const adhocPendingSelector = createSelector(
      [Action.CREATE.pendingSource],
      pending => pending[ADHOC_MODEL.getUrl()],
    )

    return createSelector(
      [selectCallStatus, callStatusPendingSelector, adhocPendingSelector],
      (callStatus, callStatusPending, adhocPending) => ({
        callStatus,
        callStatusIsUpdating: Action.isActing(callStatusPending),
        callStatusError: Action.getFailed(callStatusPending),
        adhocIsUpdating: Action.isActing(adhocPending),
        adhocError: Action.getFailed(adhocPending),

        scheduleCall: (
          startTime,
          callType,
          recurringStatus,
          numberOfSessions,
          duration,
          medium,
          mediumException,
        ) => updateCallStatus({
          ...callStatus,
          status: 'scheduled',
          scheduled_for: startTime,
          call_type: callType,
          recurring_status: recurringStatus,
          number_of_sessions: numberOfSessions,
          medium_exception: mediumException,
          medium,
          duration,
        }),

        updateCallDuration: (availabilityStartTime, callType, duration, medium) => updateCallStatus({
          ...callStatus,
          call_type: callType,
          duration,
          medium,
        }, availabilityStartTime),

        missedCall: () => updateCallStatus({
          ...callStatus,
          status: 'missed',
        }),

        completedCall: (startTime, endTime, duration) => updateCallStatus({
          ...callStatus,
          status: 'completed',
          start_time: startTime,
          end_time: endTime,
          call_duration: duration,
        }),

        cancelCall: cancelSeries => updateCallStatus({
          ...callStatus,
          recurring_status: cancelSeries ? 'once' : callStatus.recurring_status,
          status: 'canceled',
        }),

        rescheduleCall: (startTime, medium, mediumException) => updateCallStatus({
          ...callStatus,
          scheduled_for: startTime,
          medium,
          medium_exception: mediumException,
          status: 'rescheduled',
        }),

        clearCallStatusError: () => ApiModel.dispatch(
          Action.UPDATE_MODIFY.getClearPendingAction(ADMIN_MODEL, ADMIN_MODEL.getUrl({ id })),
        ),

        missedAdhocCall: calledAt => missedAdhocCall(callStatus.user_id, calledAt),

        completedAdhocCall: calledAt => completedAdhocCall(callStatus.user_id, calledAt),

        getMinutesUntilCallStarts,

        getCallLabelForMedium,
      }),
    )
  },
)

const selectCallsPerDayConnection = createSelector(
  // Once we've fetched our index once, we trust that our socket connection will keep us up to date
  // on further changes.
  [state => state.models.callStatus.hasClientIndex ? selectCallStatuses(state) : null],
  callStatuses => {
    const scheduledCallTimes = callStatuses && Object
      .values(callStatuses)
      .map(({ scheduled_for: scheduled }) => scheduled && moment(scheduled))
      .filter(scheduled => scheduled != null)
      .sort((scheduledA, scheduledB) =>
        scheduledA.isBefore(scheduledB) ? -1 : (scheduledB.isBefore(scheduledA) ? 1 : 0))

    const callsOnDay = day => {
      const dayPlusOne = day.clone().add(1, 'days')
      return scheduledCallTimes.filter(
        callTime => callTime.isSameOrAfter(day) && callTime.isBefore(dayPlusOne),
      ).length
    }

    return { scheduledCallTimes, callsOnDay }
  },
)

const getMinutesUntilCallStarts = (startTime) => {
  if (startTime == null) {
    return null
  }

  const start = moment(startTime)
  const minutesUntilStart = moment.duration(start.diff(moment.now())).asMinutes()
  return minutesUntilStart
}

const getCallLabelForMedium = medium => CALL_MEDIUM_LABELS[medium] ?? 'Unknown'

export const CallStatus = {
  SHAPE,
  formatScheduledFor,
  updateClientTimezoneIfNeeded,

  connection: {
    load: ({ callStatus }) => {
      if (callStatus == null) load()
    },

    isLoaded: ({ callStatus }) => callStatus != null,

    selector: selectConnection,

    shape: {
      callStatus: SHAPE,
      callStatusIsUpdating: PropTypes.bool.isRequired,
      callStatusUpdateError: PropTypes.string,
      clearCallStatusUpdateError: PropTypes.func.isRequired,
      setClientTimezone: PropTypes.func.isRequired,
      scheduleCall: PropTypes.func.isRequired,
      cancelCall: PropTypes.func.isRequired,
      rescheduleCall: PropTypes.func.isRequired,
      setCallMedium: PropTypes.func.isRequired,
      getMinutesUntilCallStarts: PropTypes.func.isRequired,
      getCallLabelForMedium: PropTypes.func.isRequired,
    },
  },

  coachConnection: {
    load: ({ callStatus }, { user, callStatusId }) => {
      const id = callStatusId || user.call_status_id
      if (callStatus == null && id != null) loadCallStatus(id)
    },

    isLoaded: ({ callStatus }) => callStatus != null,

    selector: selectUserConnection,

    shape: {
      callStatus: SHAPE,
      callStatusError: PropTypes.shape({
        response: PropTypes.instanceOf(Response),
        error: PropTypes.shape({
          message: PropTypes.string,
          reason: PropTypes.string,
          details: PropTypes.object,
        }),
      }),
      callStatusIsUpdating: PropTypes.bool,
      adhocError: PropTypes.shape({
        response: PropTypes.instanceOf(Response),
        error: PropTypes.shape({
          message: PropTypes.string,
          reason: PropTypes.string,
          details: PropTypes.object,
        }),
      }),
      adhocIsUpdating: PropTypes.bool,

      clearCallStatusError: PropTypes.func.isRequired,
      scheduleCall: PropTypes.func.isRequired,
      updateCallDuration: PropTypes.func.isRequired,
      missedCall: PropTypes.func.isRequired,
      completedCall: PropTypes.func.isRequired,
      cancelCall: PropTypes.func.isRequired,
      rescheduleCall: PropTypes.func.isRequired,
      missedAdhocCall: PropTypes.func.isRequired,
      completedAdhocCall: PropTypes.func.isRequired,
      getMinutesUntilCallStarts: PropTypes.func.isRequired,
      getCallLabelForMedium: PropTypes.func.isRequired,
    },
  },

  callsPerDayConnection: {
    load: ({ scheduledCallTimes }) => {
      if (scheduledCallTimes == null) ADMIN_MODEL.read()
    },

    isLoaded: ({ scheduledCallTimes }) => scheduledCallTimes != null,

    selector: selectCallsPerDayConnection,

    shape: {
      // a list of moment instances
      scheduledCallTimes: PropTypes.arrayOf(PropTypes.object).isRequired,
      callsOnDay: PropTypes.func.isRequired,
    },
  },
}
